import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  FETCH_CHOSEN_LANGUAGE_SPEAKERS,
  FETCH_CONFERENCE_SLOT_LANGUAGES,
  FETCH_LANGUAGES_ON_MODERATOR
} from '@constants/ActionTypes';
import { ENDPOINTS } from '@constants/Endpoints';
import { fetchError } from '@actions/Common';
import RestManager from '@util/RestManager';
import {
  fetchChosenLanguageSpeakersSuccess,
  fetchConferenceSlotLanguagesSuccess,
  fetchLanguagesOnModeratorSuccess,
} from '@actions/conference/ConferenceActions';

/**
 * @description Request to get the slot languages
 * @returns {Object}
 */
const doFetchConferenceSlotLanguages = async () => RestManager.request(ENDPOINTS.slotLanguages);

const doFetchChosenLanguageSpeakers = async (lang) =>
  await RestManager.request(ENDPOINTS.chosenLanguageSpeakers(lang));

/**
 * @description Request to GET slot languages for endpoint /slots/{slotId}/languages
 * @param {Number} slotId
 * @returns {Array}
 */
const doFetchModeratorLanguages = async (slotId) => RestManager
  .request(ENDPOINTS.languagesBySlot(slotId));

/**
 * @description Fetch slots languages
 * @returns {Object}
 */
function* fetchModeratorLanguages() {
  try {
    const slotId = sessionStorage.getItem('slot_id');
    const slotLanguages = yield call(doFetchModeratorLanguages, slotId);

    if (slotLanguages) {
      yield put(fetchLanguagesOnModeratorSuccess(slotLanguages));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Fetch conference slot languages
 * @returns {Object}
 */
function* fetchSlotLanguages() {
  try {
    const slotLanguages = yield call(doFetchConferenceSlotLanguages);

    if (slotLanguages) {
      yield put(fetchConferenceSlotLanguagesSuccess(slotLanguages));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Fetch conference slot languages
 * @returns {Object}
 */
function* fetchChosenLanguageSpeakers({ language }) {
  try {
    const speakers = yield call(doFetchChosenLanguageSpeakers, language);

    if (speakers) {
      yield put(fetchChosenLanguageSpeakersSuccess(speakers));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_CONFERENCE_SLOT_LANGUAGES, fetchSlotLanguages);
  yield takeEvery(FETCH_CHOSEN_LANGUAGE_SPEAKERS, fetchChosenLanguageSpeakers);
  yield takeEvery(FETCH_LANGUAGES_ON_MODERATOR, fetchModeratorLanguages);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
