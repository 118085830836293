import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Select } from 'antd';
import {
  setLoggedUserDefaultAudioDeviceId,
  setLoggedUserDefaultSpeakerDeviceId,
  setLoggedUserDefaultVideoDeviceId,
} from '@actions/loggedUser/LoggedUserActions';
import JitsiTrack from '@jitsi/JitsiTrack';
import { hideNotification, showNotification } from '@actions/Common';

const { Option } = Select;

const DeviceTypeSelect = (props) => {
  const {
    deviceType, devices, onSetDefaultVideoDeviceId, onSetDefaultAudioDeviceId,
    defaultVideoId, defaultAudioId, defaultSpeakerId, onSetDefaultSpeakerDeviceId,
    hasMutedLocalAudioTrack, hasMutedLocalVideoTrack, onShowNotification, onHideNotification,
  } = props;

  const onChangeHandler = (deviceID, deviceTypeForChange) => {
    if (deviceTypeForChange.label === 'Camera') {
      JitsiTrack.changeLocalTrackOnTheConferenceWindowObject(deviceID, deviceTypeForChange)
        .then(() => {
          onSetDefaultVideoDeviceId(deviceID);
        })
        .catch((error) => {
          onShowNotification('error', error);
          onHideNotification();
        });
    } else if (deviceTypeForChange.label === 'Microphone') {
      JitsiTrack.changeLocalTrackOnTheConferenceWindowObject(deviceID, deviceTypeForChange)
        .then(() => {
          onSetDefaultAudioDeviceId(deviceID);
        })
        .catch((error) => {
          onShowNotification('error', error);
          onHideNotification();
        });
    } else {
      onSetDefaultSpeakerDeviceId(deviceID);
    }
  };

  const getSelectDefaultValue = (devType) => {
    if (devType.label === 'Camera') {
      return defaultVideoId;
    }

    if (devType.label === 'Microphone') {
      return defaultAudioId;
    }

    return defaultSpeakerId;
  };

  const disabledSelect = (devType) => {
    if (devType.key === 'audiooutput') {
      return false;
    }

    return (
      (devType.type === 'audio' && hasMutedLocalAudioTrack)
      || (devType.type === 'video' && hasMutedLocalVideoTrack)
    );
  };

  return (
    <div style={{
      display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '300px', marginBottom: '20px',
    }}
    >
      <span>
        {`${deviceType.label}:`}
      </span>
      {
        devices
          ? (
            <Select
              style={{ width: '200px' }}
              onChange={(val) => onChangeHandler(val, deviceType)}
              defaultValue={getSelectDefaultValue(deviceType)}
              value={getSelectDefaultValue(deviceType)}
              disabled={disabledSelect(deviceType)}
              size="small"
            >
              {
                devices.map((device) => <Option key={`${device.deviceId}${device.kind}`} value={device.deviceId}>{device.label}</Option>)
              }
            </Select>
          )
          : null
      }
    </div>
  );
};

DeviceTypeSelect.propTypes = {
  deviceType: PropTypes.oneOfType([PropTypes.object]).isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultVideoId: PropTypes.string,
  defaultAudioId: PropTypes.string,
  defaultSpeakerId: PropTypes.string,
  onSetDefaultAudioDeviceId: PropTypes.func.isRequired,
  onSetDefaultVideoDeviceId: PropTypes.func.isRequired,
  onSetDefaultSpeakerDeviceId: PropTypes.func.isRequired,
  hasMutedLocalAudioTrack: PropTypes.bool.isRequired,
  hasMutedLocalVideoTrack: PropTypes.bool.isRequired,
  onShowNotification: PropTypes.func.isRequired,
  onHideNotification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ loggedUser }) => ({
  defaultVideoId: loggedUser.defaultVideoId,
  defaultAudioId: loggedUser.defaultAudioId,
  defaultSpeakerId: loggedUser.defaultSpeakerId,
  hasMutedLocalAudioTrack: loggedUser.hasMutedLocalAudioTrack,
  hasMutedLocalVideoTrack: loggedUser.hasMutedLocalVideoTrack,
});

const mapDispatchToProps = (dispatch) => ({
  onSetDefaultVideoDeviceId: (deviceId) => dispatch(setLoggedUserDefaultVideoDeviceId(deviceId)),
  onSetDefaultAudioDeviceId: (deviceId) => dispatch(setLoggedUserDefaultAudioDeviceId(deviceId)),
  onSetDefaultSpeakerDeviceId: (deviceId) => dispatch(setLoggedUserDefaultSpeakerDeviceId(deviceId)),
  onShowNotification: (notificationType, notificationMessage) => dispatch(showNotification(notificationType, notificationMessage)),
  onHideNotification: () => dispatch(hideNotification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTypeSelect);
