import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import IntlMessages from '@util/IntlMessages';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {injectIntl} from 'react-intl';
import {getStartToken, hideMessage, userSignIn, userSignOut,} from '@actions/Auth';
import {onFetchEvent, onFetchEvents} from '@actions/events/EventsActions';
import {fetchSlot, onFetchSlotsByEvent} from '@actions/groups/GroupsActions';
import logo from 'assets/images/logo/Audi_Rings_Medium_wh-RGB.svg';

const { Option } = Select;

const Login = (props) => {
  const {
    intl,
    onGetStartToken,
    onFetchEventData,
    onFetchEventGroupsData,
    onFetchGroupData,
    selectedEvent,
    onUserSignIn,
    alertMessage,
    showMessage,
    onHideMessage,
    loader,
    haveStartToken,
    events,
    groups,
    onUserSignOut,
    onFetchEventsData,
  } = props;

  const [state, setState] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setState(true);
    }, 300);

    if (sessionStorage.getItem('startToken')) {
      onFetchEventsData();
    }
  }, []);

  const handleGetStartToken = (values) => {
    onGetStartToken(values);
  };

  const handleUserJoin = (values) => {
    const valuesWithoutEventId = {
      slot_id: values.slot_id,
    };

    onUserSignIn(valuesWithoutEventId);
  };

  /**
   * Notification (when the response from the login is error)
   *
   * @param type - type of the notification (warning, success...)
   * @param description - description
   * @param title - the title of the notification
   */
  const openNotification = (type, description, title) => {
    notification[type]({
      message: title,
      description,
      style: { width: 320 },
    });
  };

  /**
   * @description Effect when we the 'showMessage' in Auth reducer is true, if so
   * that means we have a 'alertMessage' in Auth reducer,
   * and we show that 'alertMessage' with notification
   *
   * (atm used to show only login error)
   */
  useEffect(() => {
    if (showMessage) {
      openNotification('error', alertMessage, 'Error');
      onHideMessage();
    }
  }, [showMessage]);

  useEffect(() => {
    if (!_.isEmpty(selectedEvent)) {
      onFetchEventGroupsData(selectedEvent.id);
    }
  }, [selectedEvent]);

  const handleLogout = () => {
    onUserSignOut();
  };

  return state === true ? (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg" />
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.login" /></h1>
            </div>
            <div className="gx-app-logo login-logo">
              <img alt="example" src={logo} />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              id="loginFormStartToken"
              name="login"
              layout="vertical"
              className="loginForm"
              onFinish={(values) => {
                handleGetStartToken(values);
              }}
              style={{ display: !haveStartToken ? 'block' : 'none' }}
            >
              <Form.Item
                label={intl.formatMessage({ id: 'appModule.username' })}
                name="username"
                rules={[
                  {
                    whitespace: true,
                    message: intl.formatMessage({ id: 'login.loginForm.field.username.errorBlankChars' }),
                  },
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'login.loginForm.field.username.errorRequired' }),
                  }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={intl.formatMessage({ id: 'appModule.password' })}
                name="password"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'login.loginForm.field.password.errorRequired' }),
                  },
                  {
                    whitespace: true,
                    message: intl.formatMessage({ id: 'login.loginForm.field.password.errorBlankChars' }),
                  }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="loginForm__submit" style={{ float: 'right' }} loading={loader}>
                  <IntlMessages id="app.userAuth.login" />
                </Button>
              </Form.Item>
            </Form>

            <Form
              id="loginFormJoin"
              name="login"
              layout="vertical"
              className="loginForm"
              onFinish={(values) => {
                handleUserJoin(values);
              }}
              style={{ display: haveStartToken ? 'block' : 'none' }}
            >
              <Form.Item
                label={intl.formatMessage({ id: 'login.loginForm.event.label' })}
                name="event_id"
                rules={[{
                  required: true,
                  message: intl.formatMessage({ id: 'login.loginForm.field.event.errorRequired' }),
                }]}
              >
                <Select onChange={(value) => onFetchEventData(value)}>
                  {events.map((event) => (
                    <Option key={event.id} value={event.id}>
                      {event.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({ id: 'login.loginForm.slot.label' })}
                name="slot_id"
                rules={[{
                  required: true,
                  message: intl.formatMessage({ id: 'login.loginForm.field.slot.errorRequired' }),
                }]}
              >
                <Select
                  disabled={groups.length === 0}
                  onChange={(value) => onFetchGroupData(value)}
                >
                  {groups.map((group) => (
                    (!group.disabled) ?
                      (
                        <Option key={group.slot_id} value={group.slot_id}>
                          {`${group.slot_room} - (${group.slot_id})`}
                        </Option>
                      ) : null
                  ))}
                </Select>
              </Form.Item>
              <div className="loginForm__buttonsWrapper">
                <Form.Item>
                  <Button type="primary" className="loginForm__submit" loading={loader} onClick={() => handleLogout()}>
                    <IntlMessages id="app.logout" />
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="loginForm__submit" loading={loader}>
                    <IntlMessages id="login.loginForm.joinButtonText" />
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

Login.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onGetStartToken: PropTypes.func.isRequired,
  onFetchEventData: PropTypes.func.isRequired,
  onFetchEventGroupsData: PropTypes.func.isRequired,
  onFetchGroupData: PropTypes.func.isRequired,
  onUserSignIn: PropTypes.func.isRequired,
  haveStartToken: PropTypes.bool.isRequired,
  alertMessage: PropTypes.string.isRequired,
  showMessage: PropTypes.bool.isRequired,
  onHideMessage: PropTypes.func.isRequired,
  selectedEvent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  loader: PropTypes.bool.isRequired,
  onUserSignOut: PropTypes.func.isRequired,
  onFetchEventsData: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, eventsReducer, groupsReducer }) => ({
  showMessage: auth.showMessage,
  alertMessage: auth.alertMessage,
  haveStartToken: auth.haveStartToken,
  events: eventsReducer.events,
  groups: groupsReducer.groups,
  selectedEvent: eventsReducer.selectedEvent,
  loader: auth.loader,
});

const mapDispatchToProps = (dispatch) => ({
  onGetStartToken: (values) => dispatch(getStartToken(values)),
  onFetchEventData: (eventId) => dispatch(onFetchEvent(eventId)),
  onFetchEventGroupsData: (eventId) => dispatch(onFetchSlotsByEvent(eventId)),
  onFetchGroupData: (slotId) => dispatch(fetchSlot(slotId)),
  onUserSignIn: (values) => dispatch(userSignIn(values)),
  onHideMessage: () => dispatch(hideMessage()),
  onUserSignOut: () => dispatch(userSignOut()),
  onFetchEventsData: () => dispatch(onFetchEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login));
