export const ACCESS_TOKEN_KEY = 'accessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const APPLICATION_TOKEN = 'token';

export const LISTING_TABLES_PAGE_SIZE = 20;

export const HTTP_ERROR_MESSAGES = {
  400: 'bad_request',
  403: 'forbidden',
  404: 'not_found',
  409: 'already_exists',
  500: 'server_error',
  502: 'bad_gateway',
  503: 'service_not_available',
  504: 'gateway_timeout',
};

export const PARTICIPANTS_TABLE = {
  rowClass: 'tableRow',
  columnClass: 'tableRow__column',
  columnClassConnStatus: 'tableRow__column tableRow__column--connStatus',
  rowStatusClasses: {
    online: 'online',
    offline: 'offline',
    onlineRaised: 'onlineRaised',
    signed: 'signed',
    unknown: 'unknown',
  },
};

export const CHAT_STATUS = {
  online: 'online',
  offline: 'offline',
  onlineRaised: 'onlineRaised',
  signed: 'signed',
  unknown: 'unknown',
};

export const PARTICIPANTS_SELECT_VALUES = {
  all: 'all',
  online: 'online',
  live: 'live',
  offline: 'offline',
};

export const EVENT_ID_POSITION_FROM_URL = 2;
export const SLOT_ID_POSITION_FROM_URL = 4;

export const USER_ROLES = {
  visitor: 'VISITOR',
  stage: 'STAGE',
  translator: 'TRANSLATOR',
  moderator: 'MODERATOR',
};

export const DEVICES_TYPES = {
  desktop: 'desktop',
  mobile: 'mobile',
};

export const VIDEO_QUALITY = {
  fullHd: '1080',
  hd: '720',
  medium: '480',
  low: '360',
  veryLow: '180',
};

// ---------------------------------------- CUSTOM MUCS ----------------------------------------
export const MUC_PROPERTIES = {
  BOSH_SERVICE: (token) => `${JitsiDomains.url}/http-bind?token=${token}`,
  WSS_SERVICE: (token) => `wss://${JitsiDomains.domain}/xmpp-websocket?token=${token}`,
  MUC: (roomName) => `${roomName}@conference.${JitsiDomains.domain}`,
  MUC_ROOM_NAME_SPLITTER: '__',
};

export const StropheConnectionStatuses = {
  CONNECTING: 'CONNECTING',
  CONNFAIL: 'CONNFAIL',
  DISCONNECTING: 'DISCONNECTING',
  DISCONNECTED: 'DISCONNECTED',
  AUTHENTICATING: 'AUTHENTICATING',
  AUTHFAIL: 'AUTHFAIL',
  CONNECTED: 'CONNECTED',
};

// ---------------------------------------- JITSI RELATED ----------------------------------------
export const JITSI_DEVICES_TYPES_MAP = {
  audioinput: {
    label: 'Microphone',
    type: 'audio',
    key: 'audioinput',
  },
  videoinput: {
    label: 'Camera',
    type: 'video',
    key: 'videoinput',
  },
  audiooutput: {
    label: 'Speaker',
    type: 'audio',
    key: 'audiooutput',
  },
};

export const JITSI_PARTICIPANT_CUSTOM_PROPS = {
  raiseHand: 'raiseHand',
  visitorCode: 'visitorCode',
  hasMutedLocalAudioTrack: 'hasMutedLocalAudioTrack',
  hasMutedLocalVideoTrack: 'hasMutedLocalVideoTrack',
  browserName: 'browserName',
};

export const JITSI_PARTICIPANT_PREVIEW_CODE = 'M&.KE]n3d%9rjaS8TVzS?]+WjW.N+f-W]:W3v(CDiKxqg9B&%.t9*KBirt;PNtPE5beHxHF4&DE2k,nC &]v*uF=U.}DM._@&JyKcwJW-t-3dSg_WrcD}Ew}Y76!BC_WXk34UyPp-d=2_LtMEp8HeiR47WB_cQc9r)9jevCc(XUfa45mkc!=RmYguv[7C=C4G9eyBqE@.K24)fAppMKMTKzY4XFcY,u:T{a?zKz-k#n/q)!L:Ap5yE3brdwC(+kLxZ3]iH]rhw/a{Kt(Qtdm2?]eQ(y*QF})4NU-dHrCqMb$ge53Aub2NRSN,Gqdx{}3$tuqESgi85T}G:c596Wmv(H.7_wHJ6]xnV/HJ*,Xddm_SuUT}%;eJ)FwL!]{)7HGvNfR@jh$%vBH)9hDB';
export const JITSI_NEW_SPEAKER_CODE = '=@/%!7c2{R!5!A*DMR=+j?L=z2]?pZv/Z8;!3wdv:E]]7iaTbp#HVZ,y-S_UPSG.FBqtuA/d&gC4(dLT5a3Az9Rga_uz[Lb}nf,a44bH_)D,PFbSjapz:;%aNTj6a9*De;MgFbj*,tYuHYgdE@3kC,59-/ng$U+k4$inSFrj*pRibyMUbqv5MazKHKMmAGUJ%%m$aSiA7U+.t)g2recy6a{=SH%zT*#mqr-:HqwQ2v+x3t:)S$XJ5zmfK%_m*(!ezth:zGWVZFn&:nVS#Pn#&}_M5vrXi?f6bW;zHg[z43=/tA#Ac!y94AHRAeH=&#ZW?G(Bgp+tB/L8mt@P-&RH{PBf).Hrw?P7VDht6M:wRe;r-tX8keir}vLMHUY:j,%67hFmg*Zm*d6tniYG';
export const JITSI_NEW_SPEAKER_WITH_VIDEO_CODE = ':4E@e*xy!CMejRJGh%:?3_PJRg%N5dD#d!zF+&Ur?Pg6?y%FgjPS]9/J26yHB.W?6-pM9Syrn!W6)Ph]*2c?ZjfX9@8zL+dDLr?u!-cX&b]%!]dVf5=V%JahbNKxWyXR,A)+;_7jR_SwhuGqw2#D!X2{;3nyS(xM3nkadmg!DL,$&x@{}9$3;zB+dDbF2d967q-dpVTvv+9Vj)UH7M:prqSH[485$i!4DbfgSd)=Rg/p=trn3*Rq!vTyiN/BaKa]4)v%(S3dU%,zSrT;x@)g=wM=7znh2).9c6[-=CBt5wC#SZ*mU_{bV!Kzj:M4geeU;:Z5D$,5=h,PN-Cry(*uJBqBN9:X[ctZjT+J/[Ec#XkE9?/y!dTR]b{(X.{g5P(+E4Fib4qc:;)_!CR!';
export const JITSI_REMOVE_SPEAKER_VIDEO_CODE = '+/W)w!YD*9]vNu;SwkZ&4H$z=)!@/5w;.$tG&:.[$@+)B%F2G7{-iHHhq:FF-eWZbv[JNeRqDB=9N4}SqBy;cpYKCDGzL]T_;e#(#Uc-r7/igV.-[L}7i5.VFN$M*cnka8]:}2wU[/=:ej/*Y%8v.UED)(5JW(Fj}+H!x?9JUvCwD.dJAZdJ#wvz/ih}Rj}eDu$%kZcdd4KWn.S!Yn5-_N3=wQ}j]*ip:L;Ya&]6!3gbbJ.Xvq}7ZcnU#d/,N)3,xn7Yx7kTn3C-bhz8-:Zv(@eMiSdy%xCkQ{L=WT]g8zp=.6%EYu;y-wXdVQmVvhiZp(WRw.D7C2!EF96S$UNwN9vk*#2gb3Hxc}Vt:XS=HHP[#@V8/gHJCf-5wu?;7WedNmind+c*9,V%@_Zy';
export const JITSI_MUTE_CODE = 'w-[aUie$Xm6;L6BX;SnrGjUq(TZ!8b[Ln2_J3,L?HWc#cJdn&?x$uP%=B-7dK[:%.PF278nYxkRZ#6-My;R@?U@hA7j$VgqRrv_z:*u6$y}4_?iwC/Ji7nwS3q%ct(kD?@NxqpGU%4gzf-b8azR-e%wmw;$dyM[QzBzrdZH*5uTgtnFwX22HLL;Ly6hGth@[*Eh4u$K:PG$FW-@;7Y/v@5-tq3yHpxqB.(B*ZiFFpar[#%)Fw=bGbH2D@8:M@W[Mq?g3Bp7M?;?6prRrpe_D.C*cK/qy/6?b8Z-pTgP&BR[$ECbqDAe)j6/X8rTHc[9eyZaE{3jeX((6@6r[%dp/H-Ur/Cij:{6LD9kjV.cA$W(F3VPb(kPHgBL4M]X_DE@)HQa:;%mXunT38QP@';
export const JITSI_ALREADY_IN_CONFERENCE_CODE = '$ia@bLc6EhUWz%/hf(h3:Gy7(.:fi@BQxVa?St8f(kkwJqVq6ZuRtAUb{+jVtf5@Zz6*9AN@x8-T+i=GteQzZj_N...NLw-&&Uf!me%h6rxrf%unpn@b8[(vkxH]eEEX%p&Mp?-:BE@iPEx[?YXJjP@qfkHZXMmB2Bxf-?uCrPg2QF5@SBT:Dz&D+PtT-+ze-{4KW-R#,#4nUwgQSCH3BC8/Vr2CYNFb%_kd4=.eG%*biC28?kA4C+C3BJLh!Bky8}t.;c*a:jU4UW},j6cCv?yYPg{6SKy-AQ*hZ_66wCyrYpZDMxhNLMjnR]n:z5?eFxMMbxH(L=#b%abd8&.yw@C,B+r},xNMAt)gtH4gcykLw98vr/*5+?5PviY[ZW5yU6kAiZ9fi%:GPTS%';
export const JITSI_PING_MESSAGE_SEND_CODE = '$hwrX&?f*awey}c/V%ttBfEEkWF4%waHcyMDM,GVu[RWgT*u*M5v*SQdEd@3Q$!]-D#a{n,PT;Ax-_q=e{ur+Vr=2$yZ5(*Nra5G_z6-GDX6x)9Qe/Ee(LQ{d25)2mkB?PS?&SLr_c9tN/RqH3Xxbrt%E]Z[Q$FZFpgf;w(Tcwc:qgh]T8X%X-[;!+VVx7U#gfuZ*MDMz)nFQmMF;WEu;_V96#vm9b/kfhr,GUV)yN%a(YT$[yFmk-kc=qXR,VdZ-Dme_[j62,N*4)yQxhLP}S3ZRa2(r3h4?9@e+q=qHCTRGPM:z%+?%4T4;Uw!QAe;HirD,rKaMCJ-kn/gd,UxM)F&v]Pzf8[uH6hK-#.(McuP:H?)k9D@GFY_%Hr{gv{.nz/tY}SttuGm7xw/';
export const JITSI_PING_MESSAGE_RECEIVED_CODE = 'x=qu-2hn#kGxjm/@(5Nx/7/!X(3apayf4h2[DVf4Y2*D.$GZ4Fd!Nv4fVmG2XDcWQCDB(%H3Gd)YeU:;$Q]!4@$bw;KUwdK$Fj};_kP.,#KEmii)PR{gWQ!}+Hfd4-uP=X7rfyqtzJC=7?tSEr9;Lg4+c35?r-%uA,B/LJrrP7d_7hTmgTP&jZUSR%:(QQ_?4k]g$TTa2ZF=q3RLvJ=2CTDtqZA7i)h:bUP(v!RSbP_3M4r%ij2ii7%z/fGHWx5cAbYv(pZx}j8EjRagcR%NY#B4W][VdMyz9cQmiz#,T9;3;2%@+b2*#,Y.LxdCETjt-r{Y?bhV/aTJJ[!S$Nr3_Ly,jn*zpzFpqDLP/@[78g!*3_8Hudg9AJ&Gx!-[z756Cy)V+dp8n7nDEDK*';
export const JITSI_PARTICIPANT_VISITOR_PREVIEW_MESSAGES = {
  startPreview: `startPreview_${JITSI_PARTICIPANT_PREVIEW_CODE}`,
  endPreview: `endPreview_${JITSI_PARTICIPANT_PREVIEW_CODE}`,
};

export const JITSI_SPEAKER_MESSAGES = {
  willAddNewSpeaker: `willAddNewSpeaker_${JITSI_NEW_SPEAKER_CODE}`,
  newSpeakerAdded: `newSpeakerAdded_${JITSI_NEW_SPEAKER_CODE}`,
  newSpeakerAddedWithVideo: `newSpeakerAddedWithVideo_${JITSI_NEW_SPEAKER_WITH_VIDEO_CODE}`,
  newSpeakerRemoved: `newSpeakerRemoved_${JITSI_NEW_SPEAKER_CODE}`,
  startSpeaker: `startSpeaker_${JITSI_NEW_SPEAKER_CODE}`,
  startSpeakerWithVideo: `startSpeakerWithVideo_${JITSI_NEW_SPEAKER_CODE}`,
  endSpeaker: `endSpeaker_${JITSI_NEW_SPEAKER_CODE}`,
  removeSpeakerVideo: `removeSpeakerVideo_${JITSI_REMOVE_SPEAKER_VIDEO_CODE}`,
};

export const JITSI_MUTE_MESSAGES = {
  muteAudio: `muteAudio_${JITSI_MUTE_CODE}`,
  unmuteAudio: `unmuteAudio_${JITSI_MUTE_CODE}`,
};

export const JITSI_OTHER_MESSAGES = {
  alreadyInConference: `alreadyInConference_${JITSI_ALREADY_IN_CONFERENCE_CODE}`,
  pingMessageToVisitor: `pingMessage_${JITSI_PING_MESSAGE_SEND_CODE}`,
  pingMessageReceivedResponseToModerator: `pingMessage_${JITSI_PING_MESSAGE_RECEIVED_CODE}`,
};

export const JITSI_PARTICIPANT_CONNECTION_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  INTERRUPTED: 'interrupted',
  RESTORING: 'restoring',
  UNKNOWN: 'unknown',
};

export const JITSI_CONNECTION_EVENTS = {
  connectionEstablished: window.JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
  connectionDisconnect: window.JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
  connectionFailed: window.JitsiMeetJS.events.connection.CONNECTION_FAILED,
  connectionWrongState: window.JitsiMeetJS.events.connection.WRONG_STATE,
};

export const JITSI_CONFERENCE_EVENTS = {
  conferenceJoined: window.JitsiMeetJS.events.conference.CONFERENCE_JOINED,
  conferenceJoinFailed: window.JitsiMeetJS.events.conference.CONFERENCE_FAILED,
  conferenceLeft: window.JitsiMeetJS.events.conference.CONFERENCE_LEFT,
  conferenceUserJoined: window.JitsiMeetJS.events.conference.USER_JOINED,
  conferenceUserLeft: window.JitsiMeetJS.events.conference.USER_LEFT,
  conferenceUserPropertyChanged: window.JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
  conferenceTrackAdded: window.JitsiMeetJS.events.conference.TRACK_ADDED,
  conferenceTrackRemoved: window.JitsiMeetJS.events.conference.TRACK_REMOVED,
  conferenceTrackMuteChanged: window.JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
  conferenceUserDisplayNameChanged: window.JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
  conferenceMessageReceived: window.JitsiMeetJS.events.conference.MESSAGE_RECEIVED,
  conferencePrivateMessageReceived: window.JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED,
  conferenceKicked: window.JitsiMeetJS.events.conference.KICKED,
  conferenceParticipantConnectionStatusChanged: window.JitsiMeetJS.events.conference.PARTICIPANT_CONN_STATUS_CHANGED,
};

export const JITSI_ERROR_EVENTS = {
  conferenceConnectionError: window.JitsiMeetJS.errors.conference.CONNECTION_ERROR,
  conferenceChatError: window.JitsiMeetJS.errors.conference.CHAT_ERROR,
  conferenceVideoBridgeNotAvailable: window.JitsiMeetJS.errors.conference.VIDEOBRIDGE_NOT_AVAILABLE,
  conferenceVideoJingleFatalError: window.JitsiMeetJS.errors.conference.JINGLE_FATAL_ERROR,
  connectionConnectionDroppedError: window.JitsiMeetJS.errors.connection.CONNECTION_DROPPED_ERROR,
  connectionOtherError: window.JitsiMeetJS.errors.connection.OTHER_ERROR,
  trackDeviceNotFoundError: window.JitsiMeetJS.errors.track.NOT_FOUND,
  trackStreamNotFoundError: window.JitsiMeetJS.errors.track.TRACK_NO_STREAM_FOUND,
};

export const JITSI_TRACK_EVENTS = {
  trackMuteChanged: window.JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
  trackStopped: window.JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
};

export const JITSI_CONFIG = {
  startAudioMuted: true,
  startVideoMuted: true,
};

export const CONFERENCE_LISTENERS_CASES_TYPES = {
  joinSuccess: 'joinSuccess',
  joinFail: 'joinFail',
  leave: 'leave',
  userJoined: 'userJoined',
  userLeft: 'userLeft',
  userRaiseHand: 'userRaiseHand',
  trackAdded: 'trackAdded',
  trackRemoved: 'trackRemoved',
  trackMuteChanged: 'trackMutedChanged',
  trackLocalAdded: 'trackLocalAdded',
  trackLocalRemoved: 'trackLocalRemoved',
  messageReceived: 'messageReceived',
  privateMessageReceived: 'privateMessageReceived',
  participantConnStatusChanged: 'participantConnStatusChanged',
  kickedFromConference: 'kickedFromConference',
  userLocalAudioTrackMutedChanged: 'userLocalAudioTrackMutedChanged',
  userLocalVideoTrackMutedChanged: 'userLocalVideoTrackMutedChanged',
  errorConferenceConnectionLost: 'conferenceConnectionLost',
  errorConferenceVideoBridgeNotAvailable: 'conferenceVideoBridgeNotAvailable',
  errorConferenceJingleFatalError: 'conferenceJingleFatalError',
  errorConferenceChatError: 'conferenceChatError',
};

export const COUNTRY_LANGUAGE_MAPPING = {
  ad: 'ca',
  af: 'fa',
  al: 'sq',
  am: 'hy',
  az: 'az',
  ba: 'bs',
  bd: 'bn',
  bg: 'bg',
  bt: 'dz',
  by: 'be',
  cn: 'zh',
  cz: 'cs',
  de: 'de',
  dk: 'da',
  ee: 'et',
  er: 'ti',
  es: 'es',
  et: 'am',
  fi: 'fi',
  fo: 'fo',
  fr: 'fr',
  gb: 'en',
  ge: 'ka',
  gl: 'kl',
  gr: 'el',
  hr: 'hr',
  hu: 'hu',
  id: 'id',
  il: 'he',
  in: 'hi',
  is: 'is',
  it: 'it',
  jp: 'ja',
  ke: 'sw',
  kg: 'ky',
  kh: 'km',
  kr: 'ko',
  kz: 'kk',
  la: 'lo',
  lk: 'si',
  lt: 'lt',
  lu: 'lb',
  lv: 'lv',
  me: 'srp',
  mg: 'mg',
  mk: 'mk',
  mm: 'my',
  mn: 'mn',
  mt: 'mt',
  mu: 'mfe',
  mv: 'dv',
  my: 'ms',
  nl: 'nl',
  no: 'nb',
  np: 'ne',
  nr: 'na',
  nu: 'niu',
  nz: 'mi',
  ph: 'tl',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  rs: 'sr',
  ru: 'ru',
  rw: 'rw',
  sa: 'ar',
  se: 'sv',
  si: 'sl',
  sj: 'no',
  sk: 'sk',
  so: 'so',
  th: 'th',
  tj: 'tg',
  tk: 'tkl',
  tm: 'tk',
  tr: 'tr',
  ua: 'uk',
  uz: 'uz',
  vn: 'vi',
  vu: 'bi',
  ws: 'sm',
  za: 'af',
};

export const SUPPORTED_STREAM_TYPES = {
  WEBRTC: 'WEBRTC',
  HLS: 'HLS',
};

export const CHAT_RECONNECTION_DELAY = 5000;
