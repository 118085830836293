import {
  CHANGE_LANGUAGE,
  CHANGING_LOCAL_TRACK,
  CHANGING_LOCAL_TRACK_FAIL,
  CHANGING_LOCAL_TRACK_SUCCESS,
  CHAT_SOUND_NOTIFICATION,
  FETCH_LOGGED_USER_DATA,
  FETCH_LOGGED_USER_DATA_SUCCESS,
  FETCH_LOGGED_USER_JOIN_DATA,
  FETCH_LOGGED_USER_JOIN_DATA_FAIL,
  FETCH_LOGGED_USER_JOIN_DATA_SUCCESS,
  MESSAGE_RECEIVED,
  RAISE_HAND,
  SEND_ENDPOINT_MESSAGE_REQUEST,
  SEND_ENDPOINT_MESSAGE_SUCCESS,
  SET_BEING_PREVIEWED,
  SET_BEING_SPEAKER,
  SET_HAS_CAMERA_DEVICE,
  SET_HAS_MICROPHONE_DEVICE,
  SET_HAS_MUTED_LOCAL_AUDIO_TRACK,
  SET_HAS_MUTED_LOCAL_VIDEO_TRACK,
  SET_HAS_RAISED_HAND,
  SET_IS_ON_FULLSCREEN,
  SET_LOGGED_USER_BROWSER,
  SET_LOGGED_USER_CONVERSATION,
  SET_LOGGED_USER_DEFAULT_AUDIO_DEVICE_ID,
  SET_LOGGED_USER_DEFAULT_SPEAKER_DEVICE_ID,
  SET_LOGGED_USER_DEFAULT_VIDEO_DEVICE_ID,
  SET_LOGGED_USER_DEVICE_TYPE,
  SET_LOGGED_USER_DEVICES,
  SET_LOGGED_USER_IS_CONNECTED,
  SET_LOGGED_USER_IS_KICKED,
  SET_LOGGED_USER_IS_IN_CONFERENCE,
  UPDATE_MESSAGE_AS_READ,
  FETCH_LOGGED_USER_SLOT_DATA,
  FETCH_LOGGED_USER_SLOT_DATA_SUCCESS,
  FETCH_LOGGED_USER_SLOT_DATA_FAIL,
  TOGGLE_STREAM_TYPE,
} from '@constants/ActionTypes';

export const fetchLoggedUser = () => ({
  type: FETCH_LOGGED_USER_DATA,
});

export const fetchLoggedUserSuccess = (result) => ({
  type: FETCH_LOGGED_USER_DATA_SUCCESS,
  result,
});

export const fetchLoggedUserJoinData = () => ({
  type: FETCH_LOGGED_USER_JOIN_DATA,
});

export const fetchLoggedUserJoinDataSuccess = (result) => ({
  type: FETCH_LOGGED_USER_JOIN_DATA_SUCCESS,
  result,
});

export const fetchLoggedUserJoinDataFail = (error) => ({
  type: FETCH_LOGGED_USER_JOIN_DATA_FAIL,
  error,
});

export const fetchLoggedUserSlotData = () => ({
  type: FETCH_LOGGED_USER_SLOT_DATA,
});

export const fetchLoggedUserSlotDataSuccess = (result) => ({
  type: FETCH_LOGGED_USER_SLOT_DATA_SUCCESS,
  result,
});

export const fetchLoggedUserSlotDataFail = (error) => ({
  type: FETCH_LOGGED_USER_SLOT_DATA_FAIL,
  error,
});

export const setLoggedUserDevices = (devices) => ({
  type: SET_LOGGED_USER_DEVICES,
  devices,
});

export const setLoggedUserDefaultVideoDeviceId = (deviceId) => ({
  type: SET_LOGGED_USER_DEFAULT_VIDEO_DEVICE_ID,
  deviceId,
});

export const setLoggedUserDefaultAudioDeviceId = (deviceId) => ({
  type: SET_LOGGED_USER_DEFAULT_AUDIO_DEVICE_ID,
  deviceId,
});

export const setLoggedUserDefaultSpeakerDeviceId = (deviceId) => ({
  type: SET_LOGGED_USER_DEFAULT_SPEAKER_DEVICE_ID,
  deviceId,
});

export const setLoggedUserIsConnected = (bool) => ({
  type: SET_LOGGED_USER_IS_CONNECTED,
  bool,
});

export const setLoggedUserIsKicked = (bool) => ({
  type: SET_LOGGED_USER_IS_KICKED,
  bool,
});

export const setLoggedUserIsInConference = (bool) => ({
  type: SET_LOGGED_USER_IS_IN_CONFERENCE,
  bool,
});

export const changingLocalTrack = () => ({
  type: CHANGING_LOCAL_TRACK,
});

export const changingLocalTrackSuccess = () => ({
  type: CHANGING_LOCAL_TRACK_SUCCESS,
});

export const changingLocalTrackFail = () => ({
  type: CHANGING_LOCAL_TRACK_FAIL,
});

export const setLoggedUserConversation = (message) => ({
  type: SET_LOGGED_USER_CONVERSATION,
  message,
});

export const raiseHandInConference = (userId) => ({
  type: RAISE_HAND,
  userId,
});

export const receivedEndpointMessage = (userId, message) => ({
  type: MESSAGE_RECEIVED,
  userId,
  message,
});

export const sendMessageRequest = (userId, message) => ({
  type: SEND_ENDPOINT_MESSAGE_REQUEST,
  userId,
  message,
});

export const sendMessageSuccess = (userId, message) => ({
  type: SEND_ENDPOINT_MESSAGE_SUCCESS,
  userId,
  message,
});

export const setLoggedUserDeviceType = (deviceType) => ({
  type: SET_LOGGED_USER_DEVICE_TYPE,
  deviceType,
});

export const setLoggedUserIsOnFullScreen = (isOnFullScreen) => ({
  type: SET_IS_ON_FULLSCREEN,
  isOnFullScreen,
});

export const setHasRaisedHand = (hasRaisedHand) => ({
  type: SET_HAS_RAISED_HAND,
  hasRaisedHand,
});

export const setHasMutedLocalVideoTrack = (hasMutedLocalVideoTrack) => ({
  type: SET_HAS_MUTED_LOCAL_VIDEO_TRACK,
  hasMutedLocalVideoTrack,
});

export const setHasMutedLocalAudioTrack = (hasMutedLocalAudioTrack) => ({
  type: SET_HAS_MUTED_LOCAL_AUDIO_TRACK,
  hasMutedLocalAudioTrack,
});

export const updateReadMessages = (conversation) => ({
  type: UPDATE_MESSAGE_AS_READ,
  conversation,
});

export const setBeingPreviewed = (beingPreviewed) => ({
  type: SET_BEING_PREVIEWED,
  beingPreviewed,
});

export const setBeingSpeaker = (beingSpeaker, beingSpeakerWithVideo) => ({
  type: SET_BEING_SPEAKER,
  beingSpeaker,
  beingSpeakerWithVideo,
});

export const setHasMicrophoneDevice = (hasMicrophoneDevice) => ({
  type: SET_HAS_MICROPHONE_DEVICE,
  hasMicrophoneDevice,
});

export const setHasCameraDevice = (hasCameraDevice) => ({
  type: SET_HAS_CAMERA_DEVICE,
  hasCameraDevice,
});

export const changeLanguage = (newLanguage) => ({
  type: CHANGE_LANGUAGE,
  newLanguage,
});

export const chatSoundNotification = (payload) => ({
  type: CHAT_SOUND_NOTIFICATION,
  payload,
});

export const setLoggedUserBrowser = (browserName) => ({
  type: SET_LOGGED_USER_BROWSER,
  browserName,
});

export const toggleStreamType = (streamType) => ({
  type: TOGGLE_STREAM_TYPE,
  streamType,
});
