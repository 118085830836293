import {
  JITSI_CONNECTION_FAIL,
  JITSI_CONNECTION_SUCCESS, JITSI_DISCONNECT_FAIL, JITSI_DISCONNECT_SUCCESS,
  START_JITSI_CONNECTION,
  START_JITSI_DISCONNECT,
} from '@constants/ActionTypes';

export const startJitsiConnection = (jitsiToken) => ({
  type: START_JITSI_CONNECTION,
  jitsiToken,
});

export const jitsiConnectionSuccess = () => ({
  type: JITSI_CONNECTION_SUCCESS,
});

export const jitsiConnectionFail = () => ({
  type: JITSI_CONNECTION_FAIL,
});

export const startJitsiDisconnect = () => ({
  type: START_JITSI_DISCONNECT,
});

export const jitsiDisconnectSuccess = () => ({
  type: JITSI_DISCONNECT_SUCCESS,
});

export const jitsiDisconnectFail = () => ({
  type: JITSI_DISCONNECT_FAIL,
});
