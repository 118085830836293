import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { USER_ROLES } from '@constants/Settings';
import logo from 'assets/images/logo/Audi_Rings_bl-RGB.svg';

const SentMessageCell = ({ conversation, userRole, displayName }) => {
  /**
   * @description Get name initials, in case names are more than two,
   * get initials of first two names
   * @returns {String}
   */
  const getNameInitials = () => {
    const names = displayName.split(' ');
    const firstNameInitial = names[0].charAt(0);
    const lastNameInitial = names[1].charAt(0);

    return firstNameInitial + lastNameInitial;
  };

  return (
    <div className="gx-chat-item gx-flex-row-reverse">
      {userRole === USER_ROLES.moderator
        ? (
          <Avatar
            className="gx-size-40 gx-align-self-end moderator-logo"
            src={logo}
            alt={displayName}
          />
        )
        : (
          <Avatar
            className="gx-size-40 gx-align-self-end user-avatar"
            alt={displayName}
          >
            {getNameInitials()}
          </Avatar>
        )}

      <div className="gx-bubble-block">
        <div className="gx-bubble sent-message-cell">
          <div>
            <div className="gx-message">{conversation.message}</div>
            {/* <div className="gx-time gx-text-muted gx-text-left gx-mt-2">{conversation.sentAt}</div> */}
          </div>
        </div>
      </div>

    </div>
  );
};

SentMessageCell.propTypes = {
  conversation: PropTypes.oneOfType([PropTypes.object]).isRequired,
  userRole: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
};

const mapStateToProps = ({ loggedUser }) => ({
  userRole: loggedUser.userRole,
  displayName: loggedUser.displayName,
});

export default connect(mapStateToProps, null)(SentMessageCell);
