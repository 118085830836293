import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS
} from "constants/ActionTypes";
import _ from 'lodash';
import {
  GET_START_TOKEN,
  GET_START_TOKEN_FAIL,
  GET_START_TOKEN_SUCCESS,
  SIGNIN_LANDING_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_FAIL
} from '@constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  haveStartToken: !!sessionStorage.getItem('startToken'),
  authUser: !!sessionStorage.getItem('token'),
};

/**
 * Function to handle the signIn success case
 * @param {object} state
 * @param {object} result
 * @returns {object}
 */
const onSignInUserSuccess = (state, result) => {
  const newState = _.cloneDeep(state);
  newState.authUser = true;
  newState.loader = false;
  newState.showMessage = false;
  newState.alertMessage = '';

  return { ...state, ...newState };
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }

    case GET_START_TOKEN: {
      return {
        ...state,
        loader: true,
      };
    }

    case GET_START_TOKEN_SUCCESS: {
      return {
        ...state,
        haveStartToken: true,
        loader: false,
      };
    }

    case GET_START_TOKEN_FAIL: {
      return {
        ...state,
        showMessage: true,
        alertMessage: action.message || 'not_found',
      };
    }

    case SIGNIN_USER: {
      return {
        ...state,
        loader: true,
      }
    }

    case SIGNIN_USER_SUCCESS: {
      return onSignInUserSuccess(state, action.result);
    }

    case SIGNIN_USER_FAIL: {
      return {
        ...state,
        showMessage: true,
        alertMessage: action.message || 'not_found',
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case SIGNIN_LANDING_SUCCESS: {
      return {
        ...state,
        authUser: true,
      };
    }
    default:
      return state;
  }
}
