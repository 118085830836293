import React from 'react';
import { Space, Row, Col } from 'antd';
import IntlMessages from '@util/IntlMessages';

const TableLegend = () => (
  <Row
    className="tableLegend"
    gutter={[10, 10]}
  >
    <Col>
      <Space size={5}>
        <div
          className={['tableLegend__block', 'tableLegend__block--online'].join(' ')}
        />
        <IntlMessages id="participants.table.legend.online" />
      </Space>
    </Col>
    <Col>
      <Space size={5}>
        <div
          className={['tableLegend__block', 'tableLegend__block--offline'].join(' ')}
        />
        <IntlMessages id="participants.table.legend.offline" />
      </Space>
    </Col>
    <Col>
      <Space size={5}>
        <div
          className={['tableLegend__block', 'tableLegend__block--onlineRaised'].join(' ')}
        />
        <IntlMessages id="participants.table.legend.onlineRaised" />
      </Space>
    </Col>
    <Col>
      <Space size={5}>
        <div
          className={['tableLegend__block', 'tableLegend__block--signedOnLanding'].join(' ')}
        />
        <IntlMessages id="participants.table.legend.signedOnLanding" />
      </Space>
    </Col>
    <Col>
      <Space size={5}>
        <div
          className={['tableLegend__block', 'tableLegend__block--unknown'].join(' ')}
        />
        <IntlMessages id="participants.table.legend.unknown" />
      </Space>
    </Col>
  </Row>
);

export default TableLegend;
