import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import { Button, Modal, Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import IntlMessages from '../../util/IntlMessages';

const { Dragger } = Upload;
/**
 * @description Renders Schedule Header view, including create schedule and import event buttons
 */
class ScheduleHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  /**
   * @description Open modal for import file
   * @returns {void}
   */
  showModal = () => {
    this.setState({ visible: true });
  }

  handleOk = () => {
    this.setState({ visible: false });
  }

  /**
   * @description Close modal for import file
   */
  handleCancel = () => {
    this.setState({ visible: false });
  }

  /**
   * @description Add file 
   * @param {Object} event
   * @returns {void}
   */
  onFileChange = (event) => {
    event.preventDefault();
    event.persist();

    const fileToUpload = event.target.files[0];
    this.setState({ fileToUpload });
  }

  /**
   * @description Render table header
   * @returns {JSX}
   */
  renderTableHeader = () => {
    const { match } = this.props;
    const getRouteName = match.path.split('/').pop();
    
    if (getRouteName === 'schedule') {
      return (
        <IntlMessages id="schedule.table.header.events" />
      );
    }
    if (getRouteName === 'slots') {
      return (
        <IntlMessages id="schedule.table.header.groups" />
      );
    }
    if (getRouteName === 'users') {
      return (
        <IntlMessages id="schedule.table.header.users" />
      );
    }
  }

  render() {
    const { intl } = this.props;

    return (
      <div className="gx-header-horizontal-main-flex">
        <h1>
          {this.renderTableHeader()}
        </h1>
        <div>
          <Button
            type="primary"
            className="schedule-header-btn"
          >
            <Link to="/schedule/create">
              <IntlMessages id="schedule.create.schedule" />
            </Link>
          </Button>
          <Button 
          type="primary" 
          onClick={this.showModal}
          className="schedule-header-btn"
          >
            <IntlMessages id="schedule.import.event" />
          </Button>
          <Modal
            title={intl.formatMessage({ id: 'schedule.import.event'})}
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <Dragger
              accept=".csv"
            >
              <p className="ant-upload-drag-icon">
                <FontAwesomeIcon icon={faUpload} />
              </p>
              <p className="ant-upload-text">
                <IntlMessages id="schedule.import.text" />
              </p>
              <p className="ant-upload-hint">
                <IntlMessages id="schedule.import.hint" />
              </p>
            </Dragger>
          </Modal>
        </div>
      </div>
    );
  }
}

ScheduleHeader.propTypes = {
  intl: PropTypes.object,
  match: PropTypes.object,
};

export default injectIntl(withRouter(ScheduleHeader));
