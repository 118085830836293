import React from 'react';
import UserCell from './UserCell/index';

const ChatUserList = ({
  chatUsers,
  selectedSectionId,
  onSelectUser,
  isTextAreaFocused,
  selectedUser,
  props,
}) => (
  <div className="gx-chat-user">
    {chatUsers.map((chat, index) => (
      <UserCell
        key={index}
        chat={chat}
        selectedSectionId={selectedSectionId}
        onSelectUser={onSelectUser}
        isTextAreaFocused={isTextAreaFocused}
        selectedUser={selectedUser}
        props={props}
      />
    ))}
  </div>
);

export default ChatUserList;
