import React from 'react';
import PropTypes from 'prop-types';
import { faSignal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'antd';
import {
  JITSI_OTHER_MESSAGES,
  JITSI_SPEAKER_MESSAGES,
} from '@constants/Settings';
import JitsiConference from '@jitsi/JitsiConference';
import { useDispatch } from "react-redux";
import {
  hideNotification,
  showNotification,
} from "@actions";
import { put } from 'redux-saga/effects';

const sendPingMessage = (e, participantId, dispatch) => {
  e.stopPropagation();

  if (participantId) {
    sessionStorage.setItem('pingedUser', participantId);
    JitsiConference.sendPrivateSystemMessage(JITSI_OTHER_MESSAGES.pingMessageToVisitor, participantId);

    /**
     * When the moderator sends a "ping message", we put the visitor id in the session storage,
     * and when the visitor receives the "ping message" he will automatically return back a response.
     * The moderator handles that response and remove the visitor id from the session storage.
     *
     * Below after 1 sec we check that IF there is still a visitor ID in the local storage, that means in has not been removed,
     * so the visitor has not received the message
     */
    setTimeout(() => {
      if (sessionStorage.getItem('pingedUser')) {
        dispatch(showNotification('warning', 'notification.participantIsNotReceivingMessages'));
        dispatch(hideNotification());
        sessionStorage.removeItem('pingedUser');
      }
    }, 1500);
  }
};

const ParticipantConnStatusIndicator = (props) => {
  const {
    connStatus,
    participantId,
    intl,
  } = props;
  const dispatch = useDispatch();
  const tooltipText = intl.formatMessage({ id: `participants.table.connStatus.${connStatus}` });

  return (
    <Tooltip title={tooltipText} color="blue">
      <div
        className={`participantConnStatus participantConnStatus--${connStatus}`}
        onClick={(e) => sendPingMessage(e, participantId, dispatch)}
      >
        <div className="participantConnStatus__content">
          <FontAwesomeIcon icon={faSignal} size="xs" />
        </div>
      </div>
    </Tooltip>
  );
};

ParticipantConnStatusIndicator.propTypes = {
  connStatus: PropTypes.string.isRequired,
  participantId: PropTypes.string,
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default (injectIntl(ParticipantConnStatusIndicator));
