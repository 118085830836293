import React from 'react';
import ReactDOM from 'react-dom';

// Add this import:
import Logger from 'js-logger';
import NextApp from './NextApp';

window.JitsiMeetJS.init();
window.JitsiMeetJS.setLogLevel(window.JitsiMeetJS.logLevels.INFO);
window.audi = {};
Logger.useDefaults();

// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    <Component />,
    document.getElementById('root'),
  );
};

// Do this once (COMMENT THE WIELDY THEME service worker registration)
// registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
