import {
  FETCH_PREDEFINED_MESSAGES_REQUEST,
  FETCH_PREDEFINED_MESSAGES_SUCCESS,
  ADD_PREDEFINED_MESSAGE,
  ADD_PREDEFINED_MESSAGE_SUCCESS,
  SAVE_PREDEFINED_MESSAGE,
  SAVE_PREDEFINED_MESSAGE_SUCCESS,
  DELETE_PREDEFINED_MESSAGE,
  DELETE_PREDEFINED_MESSAGE_SUCCESS,
} from '@constants/ActionTypes';

/**
 * @description Fetch predefined messages request
 * @returns {Object}
 */
export const onFetchPredefinedMessages = () => ({
  type: FETCH_PREDEFINED_MESSAGES_REQUEST,
});

/**
 * @description Fetch predefined messages success
 * @returns {Object}
 */
export const onFetchPredefinedMessagesSuccess = (payload) => ({
  type: FETCH_PREDEFINED_MESSAGES_SUCCESS,
  payload,
});

/**
 * @description Handles add predefined message request
 * @param {Object} message
 * @return {Object}
 */
export const addPredefinedMessage = (message) => ({
  type: ADD_PREDEFINED_MESSAGE,
  message,
});

/**
 * @description Handles add predefined message success
 * @return {Object}
 */
export const addPredefinedMessageSuccess = (message) => ({
  type: ADD_PREDEFINED_MESSAGE_SUCCESS,
  message,
});

/**
 * @description Handles Save predefined message request
 * @param {Object} data
 * @return {Object}
 */
export const savePredefinedMessage = (messageId, message) => ({
  type: SAVE_PREDEFINED_MESSAGE,
  messageId,
  message,
});

/**
 * @description Handles save predefined message success action
 * @param {Object} payload
 * @returns {Object}
 */
export const savePredefinedMessageSuccess = (messageId) => ({
  type: SAVE_PREDEFINED_MESSAGE_SUCCESS,
  messageId,
});

/**
 * @description Handles delete predefined message request
 * @param {Object} data
 * @returns {Promise}
 */
export const deletePredefinedMessage = (messageId) => ({
  type: DELETE_PREDEFINED_MESSAGE,
  messageId,
});

/**
 * @description Handles delete predefined message success
 * @param {Object} data
 * @return {Promise}
 */
export const deletePredefinedMessageSuccess = (messageId) => ({
  type: DELETE_PREDEFINED_MESSAGE_SUCCESS,
  messageId,
});
