import React, { useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';

const CustomScrollbars = (props) => {
  const scrollItem = useRef(null);
  const {
    className,
    devicetype,
  } = props;

  useEffect(() => {
    if (scrollItem.current !== null) {
      scrollItem.current.scrollToBottom();
    }
  });

  return (
    <Scrollbars
      {...props}
      autoHide
      renderTrackHorizontal={(props) => (
        <div
          {...props}
          style={{ display: 'none' }}
          className="track-horizontal"
        />
      )}
      ref={className === `gx-chat-list-scroll gx-chat-list-scroll--${devicetype || 'desktop'}` ? scrollItem : null}
    />
  );
};

CustomScrollbars.propTypes = {
  className: PropTypes.string.isRequired,
  devicetype: PropTypes.string,
};

export default CustomScrollbars;
