import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Row, Col, Table, Form, Input } from 'antd';
import { onFetchSlotsByEvent, fetchSlot, updateSlot } from '@actions/groups/GroupsActions';
import { onFetchUsers } from '@actions/users/UsersActions';
import { onFetchQuestions } from '@actions/questions/QuestionsActions';
import ScheduleHeader from '@routes/Schedule/ScheduleHeader';
import IntlMessages from '../../util/IntlMessages';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              whitespace: true,
              message: <IntlMessages id='schedule.table.groups.required' />
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

/**
 * @description Renders Groups table in Schedule view
 */
class ScheduleGroups extends React.Component {
  constructor(props) {
    super(props);
    const { intl } = this.props;
    this.formRef = React.createRef();

    this.columns = [];

    this.tableKeys = [
      'slot_start_time',
      'slot_end_time',
      'slot_room',
      'actions',
      'operations',
    ];

    this.tableKeys.forEach((value) => {
      if (value === 'operations') {
        this.columns.push({
          title: intl.formatMessage({ id: `schedule.table.groups.${value}` }),
          minWidth: 200,
          dataIndex: value,
          render: (_, record) => {
            const editable = this.isEditing(record);
            return editable ? (
              <span>
                <a href="#" onClick={(e) => { e.preventDefault(); this.save(record); }} style={{ marginRight: 8 }}>
                  Save
                </a>
                <a onClick={() => this.cancel()}>
                  Cancel
                </a>
              </span>
            ) : (
              <a disabled={this.state.editingKey !== ''} onClick={() => this.edit(record)}>
                Edit
              </a>
            )
          }
        });
      } else {
          this.columns.push({
            title: intl.formatMessage({ id: `schedule.table.groups.${value}` }),
            minWidth: 200,
            dataIndex: value,
            editable: (value !== 'actions'),
            render: (content) => this.cellRenderer(content, value),
          });
        }
    });

    this.mergedColumns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    this.state = {
      editingKey: '',
    }
  }

  /**
   * @description Handles edit functionality
   * @param {Object} record
   * @returns {void}
   */
  edit = (record) => {
    this.formRef.current.setFieldsValue({
      ...record
    });

    this.setState({ editingKey: record.key });
  }

  /**
   * @description Cancel edit mode
   * @returns {void}
   */
  cancel = () => {
    this.setState({ editingKey: '' });
  }

  /**
   * @description Handles save on edit mode
   * @param {Object} record
   * @returns {void}
   */
  save = async (record) => {
    const { groups, updateSlot, match } = this.props;
    const slotId = record.actions;

    try {
      const row = await this.formRef.current.validateFields();
      const newData = this.dataAdapter(groups);
      const index = newData.findIndex((item) => record.key === item.key);
      const getEventIdFromURL = match.params.eventId;

      row.room = row.slot_room;
      row.time_start = row.slot_start_time;
      row.time_end = row.slot_end_time;
      row.event_id = Number(getEventIdFromURL);
      delete row.slot_room;
      delete row.slot_start_time;
      delete row.slot_end_time;
  
      if (index !== -1) {
        updateSlot(slotId, row);
        this.setState({ editingKey: '' });
      } else {
        this.setState({ editingKey: ''});
      }
    } catch (error) {
      console.log('Validation failed', error);
    }
  }

  /**
   * @description Check if edit a row
   * @param {Object} record
   * @returns {void}
   */
  isEditing = (record) => record.key === this.state.editingKey;

  /**
   * @description Updates component on componentDidMount
   * @returns {void}
   */
  componentDidMount() {
    const { onFetchSlotsByEvent, match } = this.props;
    const getEventIdFromURL = match.params.eventId;

    onFetchSlotsByEvent(getEventIdFromURL);
  }

  /**
   * @description Adapts the data
   * @param {Array<Object>} data
   * @returns {Object}
   */
  dataAdapter = (data = []) => {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        slot_start_time: value.slot_start_time,
        slot_end_time: value.slot_end_time,
        slot_room: value.slot_room,
        actions: value.slot_id,
      });
    });

    return adaptedData;
  };

  /**
   * @description Load all users in slot
   * @param {Number} id
   * @returns {void}
   */
  loadUsersBySlot = (id) => {
    const { onFetchUsers, fetchSlot, onFetchQuestions } = this.props;

    onFetchUsers(id);
    fetchSlot(id);
    onFetchQuestions(id);
  }

  /**
   * @description Render cells in table
   * @param {string} content
   * @param {string} key
   * @returns {JSX}
   */
  cellRenderer = (content, key) => {
    let cell;
    const { selectedEvent } = this.props;
    const { editingKey } = this.state;

    switch (key) {
      case 'actions':
        cell = (
          <Link
            to={`/schedule/${selectedEvent.id}/slots/${content}/users`}
            onClick={() => this.loadUsersBySlot(content)}
          >
            <IntlMessages id="schedule.table.show_users" />
          </Link>
        );
        break;

      default:
        cell = content
    }

    return cell;
  }

  render() {
    const { groups, loading } = this.props;

    const slotsColumns = this.columns.map((col, index) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
        }),
      };
    });

    return (
      <div>
        <ScheduleHeader />
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24} className="schedule-details-col">
            <div className="audi-table">
              <Form ref={this.formRef} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={this.dataAdapter(groups)}
                  pagination={false}
                  columns={this.mergedColumns}
                  loading={loading}
                />
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ScheduleGroups.propTypes = {
  intl: PropTypes.object,
  match: PropTypes.object,
  groups: PropTypes.array,
  onFetchSlotsByEvent: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ eventsReducer, groupsReducer }) => {
  const { groups } = groupsReducer || { groups: [] };
  const { loading } = groupsReducer;
  const { selectedEvent } = eventsReducer || { selectedEvent: {} };

  return { groups, selectedEvent, loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchSlotsByEvent: (id) => dispatch(onFetchSlotsByEvent(id)),
  onFetchUsers: (slotId) => dispatch(onFetchUsers(slotId)),
  fetchSlot: (slotId) => dispatch(fetchSlot(slotId)),
  updateSlot: (slotId, data) => dispatch(updateSlot(slotId, data)),
  onFetchQuestions: (slotId) => dispatch(onFetchQuestions(slotId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(ScheduleGroups)));
