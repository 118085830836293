import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS
} from "constants/ActionTypes";
import {
  GET_START_TOKEN,
  GET_START_TOKEN_FAIL,
  GET_START_TOKEN_SUCCESS,
  SIGNIN_LANDING_FAIL,
  SIGNIN_LANDING_PAGE,
  SIGNIN_LANDING_SUCCESS,
  SIGNIN_USER_FAIL
} from '@constants/ActionTypes';

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};

export const userSignIn = (values) => ({
  type: SIGNIN_USER,
  values,
});

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (result) => ({
  type: SIGNIN_USER_SUCCESS,
  result,
});

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};
export const userSignInFail = (message) => ({
  type: SIGNIN_USER_FAIL,
  message,
});
export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userFacebookSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const getStartToken = (values) => ({
  type: GET_START_TOKEN,
  values,
});

export const getStartTokenSuccess = (result) => ({
  type: GET_START_TOKEN_SUCCESS,
  result,
});

export const getStartTokenFail = (error) => ({
  type: GET_START_TOKEN_FAIL,
  error,
});

export const signInLandingPage = (token, startVideoMuted, startAudioMuted) => ({
  type: SIGNIN_LANDING_PAGE,
  token,
  startVideoMuted,
  startAudioMuted,
});

export const signInLandingPageSuccess = () => ({
  type: SIGNIN_LANDING_SUCCESS,
});

export const signInLandingPageFail = () => ({
  type: SIGNIN_LANDING_FAIL,
});
