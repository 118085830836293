import {
  CHECK_PARTICIPANTS_TABLE,
  FETCH_CHOSEN_LANGUAGE_SPEAKERS_SUCCESS,
  FETCH_CONFERENCE_SLOT_LANGUAGES_SUCCESS,
  FETCH_LANGUAGES_ON_MODERATOR_SUCCESS,
  MODERATOR_HAS_JOINED,
  MODERATOR_HAS_LEFT,
  PRESENTER_HAS_JOINED,
  PRESENTER_HAS_LEFT,
  SET_CONFERENCE_VOLUME,
  SET_HAS_CONFERENCE_AUDIO_TRACK,
  SET_HAS_PRESENTER_VIDEO_TRACK,
  SET_HAS_SPEAKER_VIDEO_TRACK,
  SET_HAS_TO_FETCH_SPEAKERS_AUDIO_TRACKS,
  SET_SELECTED_LANGUAGE,
  SET_VISITORS_SPEAKERS,
} from '@constants/ActionTypes';
import _ from 'lodash';
import { addModeratorIdToStorage } from '../../../CustomHooks/HelperFuncs';

const INIT_STATE = {
  presenterId: null,
  moderatorsIds: [],
  hasPresenterVideoTrack: false,
  hasConferenceAudioTrack: false,
  chosenLanguageSpeakers: [],
  visitorsSpeakers: [],
  slotLanguages: [],
  selectedLanguage: null,
  hasToFetchSpeakersAudioTracks: { value: false },
  hasSpeakerVideoTrack: { value: false },
  checkIsSeen: true,
  conferenceVolume: 1,
};

const onModeratorHasLeft = (state, moderatorWhoHasLeftId) => {
  const newState = _.cloneDeep(state);
  newState.moderatorsIds = state.moderatorsIds.filter((id) => id !== moderatorWhoHasLeftId);

  return { ...state, ...newState };
};

const onModeratorHasJoined = (state, moderatorId) => {
  addModeratorIdToStorage(moderatorId);

  const newState = _.cloneDeep(state);
  const moderatorIdAlreadyExists = state.moderatorsIds.find((id) => id === moderatorId);

  if (moderatorIdAlreadyExists) {
    return { ...state, ...newState };
  }

  newState.moderatorsIds = [...newState.moderatorsIds, moderatorId];

  return { ...state, ...newState };
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PRESENTER_HAS_JOINED: {
      return {
        ...state,
        presenterId: action.presenterId,
      };
    }

    case MODERATOR_HAS_JOINED: {
      return onModeratorHasJoined(state, action.moderatorId);
    }

    case PRESENTER_HAS_LEFT: {
      return {
        ...state,
        presenterId: '',
      };
    }

    case MODERATOR_HAS_LEFT: {
      return onModeratorHasLeft(state, action.moderatorId);
    }

    case SET_HAS_PRESENTER_VIDEO_TRACK: {
      return {
        ...state,
        hasPresenterVideoTrack: action.hasPresenterVideoTrack,
      };
    }

    case SET_HAS_CONFERENCE_AUDIO_TRACK: {
      return {
        ...state,
        hasConferenceAudioTrack: action.hasConferenceAudioTrack,
      };
    }

    case FETCH_CONFERENCE_SLOT_LANGUAGES_SUCCESS: {
      return {
        ...state,
        slotLanguages: action.result,
      };
    }

    case FETCH_LANGUAGES_ON_MODERATOR_SUCCESS: {
      return {
        ...state,
        slotLanguages: action.result,
      };
    }

    case SET_SELECTED_LANGUAGE: {
      return {
        ...state,
        selectedLanguage: action.language
      };
    }

    case FETCH_CHOSEN_LANGUAGE_SPEAKERS_SUCCESS: {
      return {
        ...state,
        chosenLanguageSpeakers: [...action.result],
      };
    }

    case SET_HAS_TO_FETCH_SPEAKERS_AUDIO_TRACKS: {
      return {
        ...state,
        hasToFetchSpeakersAudioTracks: { value: action.hasToFetch },
      };
    }

    case SET_VISITORS_SPEAKERS: {
      return {
        ...state,
        visitorsSpeakers: action.visitorsIds,
      };
    }

    case SET_HAS_SPEAKER_VIDEO_TRACK: {
      return {
        ...state,
        hasSpeakerVideoTrack: { value: action.hasSpeakerVideoTrack },
      };
    }

    case CHECK_PARTICIPANTS_TABLE: {
      return {
        ...state,
        checkIsSeen: action.bool,
      };
    }

    case SET_CONFERENCE_VOLUME: {
      return {
        ...state,
        conferenceVolume: action.volumeValue,
      };
    }

    default:
      return state;
  }
};
