import React from 'react';

const TermsOfUse = () => {
  return (
    <>
      <p>
        With the use of the Digital Conference Tool
        for Events (DCT) we are providing you with a platform for the presentation of
        Audi communications content and for interactive discussion with experts at AUDI AG.
      </p>
      <p>
        Once you have received a personal invitation by e-mail to
        participatein the digital event, you can, if you wish, register on the website
        using your e-mail address and an automatically generated password.
      </p>
      <p>
        Once you have received a personal invitation by e-mail
        to participate in the digital event, you can,
        if you wish, register on the website using your e-mail address and an automatically
        generated password.
      </p>
      <ul>
        <li>Chat (questions by e-mail in text format)</li>
        <li>Audio signal (questions by means of audio contribution)</li>
        <li>Video signal (questions by means of camera and sound)</li>
      </ul>
      <p>
        Please note that, without your consent for the use of audio/video signals,
        questions can be put only in text form.
      </p>
      <p>
        Following the event, information about the event
        may be published on the Audi Media Center (AMC) page. This involves
        a compilation of content from the presentation.
      </p>
      <p>
        Following the event, information about the event may be published
        on the Audi Media Center (AMC) page.
        This involves a compilation of content from the presentation.
      </p>
      <p>
        It goes without saying that you can object at any time to the processing of this data.
      </p>
    </>
  );
};

export default TermsOfUse;
