import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import JitsiConference from '@jitsi/JitsiConference';
import SpeakersTracks from '@routes/UserView/SpeakersTracks';
import DevicesSelectors from '@components/audi/DevicesSelectors/DevicesSelectors';
import VideoTrack from '@components/audi/VideoTrack/VideoTrack';
import { JITSI_TRACK_EVENTS } from '@constants/Settings';
import JitsiTrack from '../../JITSI/JitsiTrack';
import {useNotificationHook} from '../../CustomHooks/useNotificationHook';
import {useConferenceCleanupHook} from '../../CustomHooks/useConferenceCleanupHook';
import {useLocalTracksInConferenceHook} from '../../CustomHooks/useLocalTracksInConferenceHook';
import {useFirstRender} from '../../CustomHooks/useFirstRender';
import {useHasMutedLocalAudioTrackHook} from '../../CustomHooks/useHasMutedLocalAudioTrackHook';

const Translator = (props) => {
  const {
    intl,
    isConnected, isInConference,
    devices,
    hasPresenterVideoTrack,
    presenterId,
    isBeingSpeaker,
  } = props;

  const { localTracks } = useLocalTracksInConferenceHook();
  const [remoteTracks, _setRemoteTracks] = useState([]);
  const [presenterVideoTrackMuted, setPresenterVideoTrackMuted] = useState(false);

  const isFirstRender = useFirstRender();

  useNotificationHook(intl);

  useConferenceCleanupHook();

  useHasMutedLocalAudioTrackHook(localTracks);

  const conferenceRemoteTracks = useRef(remoteTracks);

  const setConferenceRemoteTracks = (data) => {
    conferenceRemoteTracks.current = data;
    _setRemoteTracks(data);
  };

  /**
   * Effect which executes when the presenter has added his video track in
   * the conference. Then we get it and display the video
   */
  useEffect(() => {
    if (hasPresenterVideoTrack) {
      const conferencePresenter = JitsiConference.getConferencePresenterParticipant(presenterId);

      if (conferencePresenter) {
        const remoteVideoTracks = conferencePresenter.getTracksByMediaType('video');
        if (remoteVideoTracks.length > 0) {
          remoteVideoTracks[0].addEventListener(
            JITSI_TRACK_EVENTS.trackMuteChanged,
            (track) => setPresenterVideoTrackMuted(track.isMuted()),
          );
        }
        setConferenceRemoteTracks(conferencePresenter.getTracks());
        JitsiConference.setReceiverConstraints(conferencePresenter.getId());
      }
    }
  }, [hasPresenterVideoTrack]);

  /**
   * @description Effect which executes when 'isBeingSpeaker' property is changed.
   *
   * @descriptionSo when the visitor receive the private message to be a speaker, we set the value to 'true'.
   * If the value is 'true' we add the visitor local AUDIO track to the conference
   * If the value is 'false' we remove his 'audio' track from the conference.
   */
  useEffect(() => {
    if (!isFirstRender) {
      if (isBeingSpeaker.withAudio) {
        JitsiTrack.addLocalTracksToTheConference(localTracks.filter((tr) => tr.getType() === 'audio'));
      } else if (isBeingSpeaker.withAudio === false) {
        JitsiTrack.removeLocalTrackByType('audio');
      }
    }
  }, [isBeingSpeaker.withAudio, isBeingSpeaker.withVideo]);

  return (
    <div className="translatorContainer">
      <h1>Translator</h1>
      {!presenterVideoTrackMuted
        ? <VideoTrack tracks={conferenceRemoteTracks.current} classesArr={[]} width={70} />
        : (
          <div className="presenterVideoMuted">
            <h3 style={{ color: 'white' }}>{ intl.formatMessage({ id: 'userView.presenterCameraMuted.text' })}</h3>
          </div>
        )}
      {
        (isConnected && isInConference && devices && Object.keys(devices).length > 0)
          ? (
            <>
              <DevicesSelectors
                withVideoDeviceSelect={false}
                withAudioDeviceSelect
                withSpeakersDeviceSelect
                withVideoDeviceMuteButton={false}
                withAudioDeviceMuteButton
              />
              <SpeakersTracks />
            </>
          )
          : null
      }
    </div>
  );
};

Translator.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isConnected: PropTypes.bool.isRequired,
  isInConference: PropTypes.bool.isRequired,
  devices: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hasPresenterVideoTrack: PropTypes.bool.isRequired,
  isBeingSpeaker: PropTypes.oneOfType([PropTypes.object]).isRequired,
  presenterId: PropTypes.string,
};

const mapStateToProps = ({ loggedUser, conferenceReducer }) => ({
  isConnected: loggedUser.isConnected,
  isInConference: loggedUser.isInConference,
  changingLocalTrack: loggedUser.changingLocalTrack,
  devices: loggedUser.devices,
  presenterId: conferenceReducer.presenterId,
  isBeingSpeaker: loggedUser.beingSpeaker,
  hasPresenterVideoTrack: conferenceReducer.hasPresenterVideoTrack,
  hasMicrophoneDevice: loggedUser.hasMicrophoneDevice,
});

export default connect(mapStateToProps, null)(injectIntl(Translator));
