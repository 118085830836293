import { put, select } from 'redux-saga/effects';
import { checkIfTrackIsATrackOfOneOfOurSpeakers, checkIfUserIsModeratorByUserId } from '@jitsi/helper';
import { JITSI_MUTE_MESSAGES, JITSI_OTHER_MESSAGES } from '@constants/Settings';
import { setHasMutedLocalAudioTrack } from '@actions/loggedUser/LoggedUserActions';
import { setHasToFetchSpeakersAudioTracks } from '@actions/conference/ConferenceActions';
import { hideNotification, showNotification } from '@actions/Common';

/**
 * STAGE ROLE Generator which we execute when the stage has received a private message
 * @param adaptedMessage
 */
export function* conferencePrivateMessageReceivedGeneratorStage(adaptedMessage) {
  const isTheSenderModerator = yield checkIfUserIsModeratorByUserId(
    adaptedMessage.userId,
  );
  if (adaptedMessage.message === JITSI_MUTE_MESSAGES.muteAudio
    && isTheSenderModerator) {
    yield put(setHasMutedLocalAudioTrack(true));
  } else if (adaptedMessage.message === JITSI_OTHER_MESSAGES.alreadyInConference) {
    yield put(showNotification('warning', 'notification.alreadyInConference', 0)); //using intl id for the message
    yield put(hideNotification());
  }
}

/**
 * STAGE ROLE Generator which we execute when a track has been added in the conference
 * @param track
 */
export function* conferenceTrackAddedGeneratorStage(track) {
  const speakers = yield select((state) => state.conferenceReducer.chosenLanguageSpeakers);

  if (track.getType() === 'audio' && checkIfTrackIsATrackOfOneOfOurSpeakers(track.ownerEndpointId, speakers)) {
    yield put(setHasToFetchSpeakersAudioTracks(true));
  }
}

/**
 * STAGE ROLE Generator which we execute when a track has been removed from the conference
 * @param track
 */
export function* conferenceTrackRemovedGeneratorStage(track) {
  const speakers = yield select((state) => state.conferenceReducer.chosenLanguageSpeakers);

  if (track.getType() === 'audio' && checkIfTrackIsATrackOfOneOfOurSpeakers(track.ownerEndpointId, speakers)) {
    yield put(setHasToFetchSpeakersAudioTracks(true));
  }
}
