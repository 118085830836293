import {
  all, call, fork, put, takeEvery,
} from 'redux-saga/effects';
import {
  onFetchPredefinedMessagesSuccess,
} from '@actions/predefinedMessages/PredefinedMessagesActions';
import {
  FETCH_PREDEFINED_MESSAGES_REQUEST,
  ADD_PREDEFINED_MESSAGE,
  DELETE_PREDEFINED_MESSAGE,
  SAVE_PREDEFINED_MESSAGE,
} from '@constants/ActionTypes';
import { ENDPOINTS } from '@constants/Endpoints';
import { fetchError } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * @description Send GET request to fetch predefined messages list
 * @returns {Array}
 */
const doFetchPredefinedMessages = async () => RestManager.request(`${ENDPOINTS.predefinedMessages}`);

/**
 * @description Fetching predefined messages list
 * @returns {void}
 */
function* fetchPredefinedMessagesList() {
  try {
    const messagesList = yield call(doFetchPredefinedMessages);
    if (messagesList) {
      yield put(onFetchPredefinedMessagesSuccess(messagesList));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends POST request to add new predefined message in list
 * @param {Object} message
 * @returns {Object}
 */
const addPredefinedMessage = async (message) => RestManager.requestWithoutQueryParams(`${ENDPOINTS.predefinedMessages}`, 'POST', message);

/**
 * @description Adds new message in list
 * @param {Object} message
 * @returns {void}
 */
function* doAddPredefinedMessage(message) {
  try {
    const msg = yield call(addPredefinedMessage, message.message);

    if (msg) {
      const predefinedMessagesList = yield call(doFetchPredefinedMessages);
      yield put(onFetchPredefinedMessagesSuccess(predefinedMessagesList));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Send DELETE request
 * @param {Number} messageId
 * @returns {Object}
 */
const deletePredefinedMessage = async (messageId) => RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.predefinedMessages}/${messageId}`, 'DELETE', messageId,
);

/**
 * @description Handles delete predefined message
 * @param {Object} payload
 * @returns {void}
 */
function* doDeletePredefinedMessage(payload) {
  try {
    const response = yield call(deletePredefinedMessage, payload.messageId);

    if (response.status >= 200 && response.status < 300) {
      const predefinedMessagesList = yield call(doFetchPredefinedMessages);
      yield put(onFetchPredefinedMessagesSuccess(predefinedMessagesList));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends PATCH request
 * @param {Number} messageId
 * @param {Object} message
 * @returns {Object}
 */
const updatePredefinedMessage = async (messageId, message) => (
  RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.predefinedMessages}/${messageId}`, 'PATCH', message,
  ));

/**
 * @description Updates predefined message from list
 * @param {Number} messageId
 * @param {Object} message
 * @returns {void}
 */
function* doUpdatePredefinedMessage({ messageId, message }) {
  try {
    const updatedMessage = yield call(updatePredefinedMessage, messageId, message);

    if (updatedMessage) {
      const predefinedMessagesList = yield call(doFetchPredefinedMessages);
      yield put(onFetchPredefinedMessagesSuccess(predefinedMessagesList));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_PREDEFINED_MESSAGES_REQUEST, fetchPredefinedMessagesList);
  yield takeEvery(ADD_PREDEFINED_MESSAGE, doAddPredefinedMessage);
  yield takeEvery(DELETE_PREDEFINED_MESSAGE, doDeletePredefinedMessage);
  yield takeEvery(SAVE_PREDEFINED_MESSAGE, doUpdatePredefinedMessage);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
