// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

// Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

// Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const GET_START_TOKEN = 'GET_START_TOKEN';
export const GET_START_TOKEN_SUCCESS = 'GET_START_TOKEN_SUCCESS';
export const GET_START_TOKEN_FAIL = 'GET_START_TOKEN_FAIL';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAIL = 'SIGNIN_USER_FAIL';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const SIGNIN_LANDING_PAGE = 'SIGNIN_LANDING_PAGE';
export const SIGNIN_LANDING_SUCCESS = 'SIGNIN_LANDING_PAGE_SUCCESS';
export const SIGNIN_LANDING_FAIL = 'SIGNIN_LANDING_PAGE_FAIL';

// Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

// Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

// Schedule Create
export const SAVE_GROUPS = 'SAVE_GROUPS';
export const SAVE_GROUPS_SUCCESS = 'SAVE_GROUPS_SUCCESS';
export const SAVE_GROUPS_FAIL = 'SAVE_GROUPS_FAIL';

// Predefined Messages
export const FETCH_PREDEFINED_MESSAGES_REQUEST = 'FETCH_PREDEFINED_MESSAGES_REQUEST';
export const FETCH_PREDEFINED_MESSAGES_SUCCESS = 'FETCH_PREDEFINED_MESSAGES_SUCCESS';
export const ADD_PREDEFINED_MESSAGE = 'ADD_PREDEFINED_MESSAGE';
export const ADD_PREDEFINED_MESSAGE_SUCCESS = 'ADD_PREDEFINED_MESSAGE_SUCCESS';
export const SAVE_PREDEFINED_MESSAGE = 'SAVE_PREDEFINED_MESSAGE';
export const SAVE_PREDEFINED_MESSAGE_SUCCESS = 'SAVE_PREDEFINED_MESSAGE_SUCCESS';
export const DELETE_PREDEFINED_MESSAGE = 'DELETE_PREDEFINED_MESSAGE';
export const DELETE_PREDEFINED_MESSAGE_SUCCESS = 'DELETE_PREDEFINED_MESSAGE_SUCCESS';

// Events
export const FETCH_ALL_EVENTS_REQUEST = 'FETCH_ALL_EVENTS_REQUEST';
export const FETCH_ALL_EVENTS_SUCCESS = 'FETCH_ALL_EVENTS_SUCCESS';
export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_REQUEST_SUCCESS = 'UPDATE_EVENT_REQUEST_SUCCESS';
export const ADD_EVENT_REQUEST = 'ADD_EVENT_REQUEST';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const SET_SELECTED_EVENT = 'SET_SELECTED_EVENT';

// Groups
export const FETCH_GROUPS_BY_EVENT_REQUEST = 'FETCH_GROUPS_BY_EVENT_REQUEST';
export const FETCH_GROUPS_BY_EVENT_SUCCESS = 'FETCH_GROUPS_BY_EVENT_SUCCESS';
export const FETCH_SLOT_REQUEST = 'FETCH_SLOT_REQUEST';
export const FETCH_SLOT_SUCCESS = 'FETCH_SLOT_SUCCESS';
export const FETCH_SLOT_FAILURE = 'FETCH_SLOT_FAILURE';
export const UPDATE_SLOT_REQUEST = 'UPDATE_SLOT_REQUEST';
export const UPDATE_SLOT_SUCCESS = 'UPDATE_SLOT_SUCCESS';
export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';

// Users
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FILTER_SEARCH_PARTICIPANTS_SUCCESS = 'FILTER_SEARCH_PARTICIPANTS_SUCCESS';

export const SET_PARTICIPANT_LIVE_VIDEO = 'SET_PARTICIPANT_LIVE_VIDEO';
export const SET_PARTICIPANT_LIVE_VIDEO_SUCCESS = 'SET_PARTICIPANT_LIVE_VIDEO_SUCCESS';

export const SET_PARTICIPANT_LIVE_AUDIO = 'SET_PARTICIPANT_LIVE_AUDIO';
export const SET_PARTICIPANT_LIVE_AUDIO_SUCCESS = 'SET_PARTICIPANT_LIVE_AUDIO_SUCCESS';

export const SELECT_PARTICIPANTS_SUCCESS = 'SELECT_PARTICIPANTS_SUCCESS';

export const UNSELECT_PARTICIPANTS_SUCCESS = 'UNSELECT_PARTICIPANTS_SUCCESS';

export const CHECK_PARTICIPANT_SUCCESS = 'CHECK_PARTICIPANT_SUCCESS';

export const TOGGLE_PARTICIPANT_CHAT = 'TOGGLE_PARTICIPANT_CHAT';

export const SET_PARTICIPANT_STATUS = 'SET_PARTICIPANT_STATUS';
export const RESET_OR_LEAVE_CURRENT_PARTICIPANT_STATUS = 'RESET_OR_LEAVE_CURRENT_PARTICIPANT_STATUS';

export const MUTE_PARTICIPANTS = 'MUTE_PARTICIPANTS';
export const MUTE_PARTICIPANTS_SUCCESS = 'MUTE_PARTICIPANTS_SUCCESS';

export const KICK_PARTICIPANT = 'KICK_PARTICIPANT';
export const KICK_PARTICIPANT_SUCCESS = 'KICK_PARTICIPANT_SUCCESS';
export const KICK_PARTICIPANT_FAIL = 'KICK_PARTICIPANT_FAIL';
export const BAN_PARTICIPANT = 'BAN_PARTICIPANT';
export const BAN_PARTICIPANT_SUCCESS = 'BAN_PARTICIPANT_SUCCESS';
export const BAN_PARTICIPANT_FAIL = 'BAN_PARTICIPANT_FAIL';

export const CLEAR_LIVE_PARTICIPANTS = 'CLEAR_LIVE_PARTICIPANTS';
export const CLEAR_LIVE_PARTICIPANTS_SUCCESS = 'CLEAR_LIVE_PARTICIPANTS_SUCCESS';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const ADD_USER_TO_GROUP = 'ADD_USER_TO_GROUP';
export const ADD_USER_TO_GROUP_SUCCESS = 'ADD_USER_TO_GROUP_SUCCESS';

export const ADD_CONVERSATION = 'ADD_CONVERSATION';
export const CHECK_MESSAGE_AS_READ = 'CHECK_MESSAGE_AS_READ';

export const SET_USERS_FROM_STORAGE = 'SET_USERS_FROM_STORAGE';

export const ADD_USER_AS_SPEAKER = 'ADD_USER_AS_SPEAKER';
export const ADD_USER_AS_SPEAKER_SUCCESS = 'ADD_USER_AS_SPEAKER_SUCCESS';
export const ADD_USER_AS_SPEAKER_FAIL = 'ADD_USER_AS_SPEAKER_FAIL';

export const REMOVE_USER_AS_SPEAKER = 'REMOVE_USER_AS_SPEAKER';
export const REMOVE_USER_AS_SPEAKER_SUCCESS = 'REMOVE_USER_AS_SPEAKER_SUCCESS';
export const REMOVE_USER_AS_SPEAKER_FAIL = 'REMOVE_USER_AS_SPEAKER_FAIL';

export const REMOVE_USER_VIDEO = 'REMOVE_USER_VIDEO';
export const REMOVE_USER_VIDEO_SUCCESS = 'REMOVE_USER_VIDEO_SUCCESS';
export const REMOVE_USER_VIDEO_FAIL = 'REMOVE_USER_VIDEO_FAIL';

export const USER_TRACK_WAS_ADDED = 'USER_TRACK_WAS_ADDED';
export const USER_TRACK_WAS_REMOVED = 'USER_TRACK_WAS_REMOVED';
export const USER_TRACK_MUTE_CHANGED = 'USER_TRACK_MUTE_CHANGED';

//LoggedUser
export const FETCH_LOGGED_USER_DATA = 'FETCH_LOGGED_USER_DATA';
export const FETCH_LOGGED_USER_DATA_SUCCESS = 'FETCH_LOGGED_USER_DATA_SUCCESS';
export const FETCH_LOGGED_USER_DATA_FAIL = 'FETCH_LOGGED_USER_DATA_FAIL';
export const FETCH_LOGGED_USER_JOIN_DATA = 'FETCH_LOGGED_USER_JOIN_DATA';
export const FETCH_LOGGED_USER_JOIN_DATA_SUCCESS = 'FETCH_LOGGED_USER_JOIN_DATA_SUCCESS';
export const FETCH_LOGGED_USER_JOIN_DATA_FAIL = 'FETCH_LOGGED_USER_JOIN_DATA_FAIL';

export const FETCH_LOGGED_USER_SLOT_DATA = 'FETCH_LOGGED_USER_SLOT_DATA';
export const FETCH_LOGGED_USER_SLOT_DATA_SUCCESS = 'FETCH_LOGGED_USER_SLOT_DATA_SUCCESS';
export const FETCH_LOGGED_USER_SLOT_DATA_FAIL = 'FETCH_LOGGED_USER_SLOT_DATA_FAIL';

export const SET_LOGGED_USER_DEVICES = 'SET_LOGGED_USER_DEVICES';
export const SET_LOGGED_USER_DEFAULT_VIDEO_DEVICE_ID = 'SET_LOGGED_USER_DEFAULT_VIDEO_DEVICE_ID';
export const SET_LOGGED_USER_DEFAULT_AUDIO_DEVICE_ID = 'SET_LOGGED_USER_DEFAULT_AUDIO_DEVICE_ID';
export const SET_LOGGED_USER_DEFAULT_SPEAKER_DEVICE_ID = 'SET_LOGGED_USER_DEFAULT_SPEAKER_DEVICE_ID';
export const SET_LOGGED_USER_IS_CONNECTED = 'SET_LOGGED_USER_IS_CONNECTED';
export const SET_LOGGED_USER_IS_IN_CONFERENCE = 'SET_LOGGED_USER_IS_IN_CONFERENCE';
export const SET_LOGGED_USER_IS_KICKED = 'SET_LOGGED_USER_IS_KICKED';
export const CHANGING_LOCAL_TRACK = 'CHANGING_LOCAL_TRACK';
export const CHANGING_LOCAL_TRACK_SUCCESS = 'CHANGING_LOCAL_TRACK_SUCCESS';
export const CHANGING_LOCAL_TRACK_FAIL = 'CHANGING_LOCAL_TRACK_FAIL';
export const SET_LOGGED_USER_CONVERSATION = 'SET_LOGGED_USER_CONVERSATION';
export const SET_LOGGED_USER_DEVICE_TYPE = 'SET_LOGGED_USER_DEVICE_TYPE';
export const SET_IS_ON_FULLSCREEN = 'SET_IS_ON_FULLSCREEN';
export const SET_HAS_RAISED_HAND = 'SET_HAS_RAISED_HAND';
export const SET_HAS_MUTED_LOCAL_VIDEO_TRACK = 'SET_HAS_MUTED_LOCAL_VIDEO_TRACK';
export const SET_HAS_MUTED_LOCAL_AUDIO_TRACK = 'SET_HAS_MUTED_LOCAL_AUDIO_TRACK';
export const UPDATE_MESSAGE_AS_READ = 'UPDATE_MESSAGE_AS_READ';
export const SET_BEING_PREVIEWED = 'SET_BEING_PREVIEWED';
export const SET_BEING_SPEAKER = 'SET_BEING_SPEAKER';
export const SET_HAS_MICROPHONE_DEVICE = 'SET_HAS_MICROPHONE_DEVICE';
export const SET_HAS_CAMERA_DEVICE = 'SET_HAS_CAMERA_DEVICE';
export const SET_LOGGED_USER_BROWSER = 'SET_LOGGED_USER_BROWSER';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHAT_SOUND_NOTIFICATION = 'CHAT_SOUND_NOTIFICATION';
export const TOGGLE_STREAM_TYPE = 'TOGGLE_STREAM_TYPE';

//Questions
export const GET_ALL_QUESTIONS_REQUEST = 'GET_ALL_QUESTIONS_REQUEST';
export const GET_ALL_QUESTIONS_SUCCESS = 'GET_ALL_QUESTIONS_SUCCESS';
export const GET_QUESTION_BY_ID = 'GET_QUESTION_BY_ID';
export const GET_QUESTION_BY_ID_SUCCESS = 'GET_QUESTION_BY_ID_SUCCESS';
export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_REQUEST = 'UPDATE_QUESTION_REQUEST';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const ADD_QUESTION_REQUEST = 'ADD_QUESTION_REQUEST';
export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS';

// JitsiConnection
export const START_JITSI_CONNECTION = 'START_JITSI_CONNECTION';
export const JITSI_CONNECTION_SUCCESS = 'JITSI_CONNECTION_SUCCESS';
export const JITSI_CONNECTION_FAIL = 'JITSI_CONNECTION_FAIL';
export const START_JITSI_DISCONNECT = 'START_JITSI_DISCONNECT';
export const JITSI_DISCONNECT_SUCCESS = 'JITSI_DISCONNECT_SUCCESS';
export const JITSI_DISCONNECT_FAIL = 'JITSI_DISCONNECT_FAIL';

// JitsiConference
export const JOIN_JITSI_CONFERENCE = 'JOIN_JITSI_CONFERENCE';
export const JOIN_JITSI_CONFERENCE_SUCCESS = 'JOIN_JITSI_CONFERENCE_SUCCESS';
export const JOIN_JITSI_CONFERENCE_FAIL = 'JOIN_JITSI_CONFERENCE_FAIL';
export const LEAVE_JITSI_CONFERENCE = 'LEAVE_JITSI_CONFERENCE';
export const LEAVE_JITSI_CONFERENCE_SUCCESS = 'LEAVE_JITSI_CONFERENCE_SUCCESS';
export const LEAVE_JITSI_CONFERENCE_FAIL = 'LEAVE_JITSI_CONFERENCE_FAIL';
export const ADD_JITSI_LOCAL_TRACK_TO_CONF = 'ADD_JITSI_LOCAL_TRACK_TO_CONF';
export const ADD_JITSI_LOCAL_TRACK_TO_CONF_SUCCESS = 'ADD_JITSI_LOCAL_TRACK_TO_CONF_SUCCESS';
export const ADD_JITSI_LOCAL_TRACK_TO_CONF_FAIL = 'ADD_JITSI_LOCAL_TRACK_TO_CONF_FAIL';
export const USER_HAS_JOINED = 'USER_HAS_JOINED';
export const USER_HAS_LEFT = 'USER_HAS_LEFT';
export const USER_RAISE_HAND_CHANGE = 'USER_RAISE_HAND_CHANGE';
export const USER_LOCAL_TRACK_MUTE_CHANGE = 'USER_LOCAL_TRACK_MUTE_CHANGE';
export const RAISE_HAND = 'RAISE_HAND';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const SEND_ENDPOINT_MESSAGE_REQUEST = 'SEND_ENDPOINT_MESSAGE_REQUEST';
export const SEND_ENDPOINT_MESSAGE_SUCCESS = 'SEND_ENDPOINT_MESSAGE_SUCCESS';
export const USER_CONN_STATUS_HAS_CHANGED = 'USER_CONN_STATUS_HAS_CHANGED';

// JitsiTrack
export const CREATE_JITSI_LOCAL_TRACKS = 'CREATE_JITSI_LOCAL_TRACKS';
export const CREATE_JITSI_LOCAL_TRACKS_SUCCESS = 'CREATE_JITSI_LOCAL_TRACKS_SUCCESS';
export const CREATE_JITSI_LOCAL_TRACKS_FAIL = 'CREATE_JITSI_LOCAL_TRACKS_FAIL';

// Conference
export const PRESENTER_HAS_JOINED = 'PRESENTER_HAS_JOINED';
export const MODERATOR_HAS_JOINED = 'MODERATOR_HAS_JOINED';
export const PRESENTER_HAS_LEFT = 'PRESENTER_HAS_LEFT';
export const MODERATOR_HAS_LEFT = 'MODERATOR_HAS_LEFT';
export const SET_HAS_PRESENTER_VIDEO_TRACK = 'SET_HAS_PRESENTER_VIDEO_TRACK';
export const SET_HAS_CONFERENCE_AUDIO_TRACK = 'SET_HAS_CONFERENCE_AUDIO_TRACK';
export const FETCH_CONFERENCE_SLOT_LANGUAGES = 'FETCH_CONFERENCE_SLOT_LANGUAGES';
export const FETCH_CONFERENCE_SLOT_LANGUAGES_SUCCESS = 'FETCH_CONFERENCE_SLOT_LANGUAGES_SUCCESS';
export const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE';
export const FETCH_CHOSEN_LANGUAGE_SPEAKERS = 'FETCH_CHOSEN_LANGUAGE_SPEAKERS';
export const FETCH_CHOSEN_LANGUAGE_SPEAKERS_SUCCESS = 'FETCH_CHOSEN_LANGUAGE_SPEAKERS_SUCCESS';
export const FETCH_CHOSEN_LANGUAGE_SPEAKERS_FAIL = 'FETCH_CHOSEN_LANGUAGE_SPEAKERS_FAIL';
export const SET_VISITORS_SPEAKERS = 'SET_VISITORS_SPEAKERS';
export const SET_HAS_TO_FETCH_SPEAKERS_AUDIO_TRACKS = 'SET_HAS_TO_FETCH_SPEAKERS_AUDIO_TRACKS';
export const SET_HAS_SPEAKER_VIDEO_TRACK = 'SET_HAS_SPEAKER_VIDEO_TRACK';
export const FETCH_LANGUAGES_ON_MODERATOR = 'FETCH_LANGUAGES_ON_MODERATOR';
export const FETCH_LANGUAGES_ON_MODERATOR_SUCCESS = 'FETCH_LANGUAGES_ON_MODERATOR_SUCCESS';
export const CHECK_PARTICIPANTS_TABLE = 'CHECK_PARTICIPANTS_TABLE';
export const SET_CONFERENCE_VOLUME = 'SET_CONFERENCE_VOLUME';

// MUCS
export const CREATE_STROPHE_CONNECTION = 'CREATE_STROPHE_CONNECTION';
export const CREATE_STROPHE_CONNECTION_SUCCESS = 'CREATE_STROPHE_CONNECTION_SUCCESS';
export const CREATE_STROPHE_CONNECTION_FAIL = 'CREATE_STROPHE_CONNECTION_FAIL';
export const STROPHE_CONNECTION_DISCONNECT = 'STROPHE_CONNECTION_DISCONNECT';
export const STROPHE_JOIN_MUCS = 'STROPHE_JOIN_MUCS';
export const STROPHE_MUC_MESSAGE_SEND = 'STROPHE_MUC_MESSAGE_SEND';
export const STROPHE_MUC_MESSAGE_RECEIVED = 'STROPHE_MUC_MESSAGE_RECEIVED';
export const SET_NICKNAME_UNIQUE_ID = 'SET_NICKNAME_UNIQUE_ID';
export const SET_JOINED_MUCS = 'SET_JOINED_MUCS';
export const LEAVE_MUC_ROOM = 'LEAVE_MUC_ROOM';
export const CHAT_IS_CONNECTED = 'CHAT_IS_CONNECTED';
