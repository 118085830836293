import {
  ADD_JITSI_LOCAL_TRACK_TO_CONF,
  ADD_JITSI_LOCAL_TRACK_TO_CONF_FAIL,
  ADD_JITSI_LOCAL_TRACK_TO_CONF_SUCCESS,
  JOIN_JITSI_CONFERENCE,
  JOIN_JITSI_CONFERENCE_FAIL,
  JOIN_JITSI_CONFERENCE_SUCCESS,
  LEAVE_JITSI_CONFERENCE,
  LEAVE_JITSI_CONFERENCE_FAIL,
  LEAVE_JITSI_CONFERENCE_SUCCESS,
} from '@constants/ActionTypes';

export const joinJitsiConference = () => ({
  type: JOIN_JITSI_CONFERENCE,
});

export const joinJitsiConferenceSuccess = () => ({
  type: JOIN_JITSI_CONFERENCE_SUCCESS,
});

export const joinJitsiConferenceFail = () => ({
  type: JOIN_JITSI_CONFERENCE_FAIL,
});

export const leaveJitsiConference = () => ({
  type: LEAVE_JITSI_CONFERENCE,
});

export const leaveJitsiConferenceSuccess = () => ({
  type: LEAVE_JITSI_CONFERENCE_SUCCESS,
});

export const leaveJitsiConferenceFail = () => ({
  type: LEAVE_JITSI_CONFERENCE_FAIL,
});

export const addJitsiLocalTrackToConf = () => ({
  type: ADD_JITSI_LOCAL_TRACK_TO_CONF,
});

export const addJitsiLocalTrackToConfSuccess = () => ({
  type: ADD_JITSI_LOCAL_TRACK_TO_CONF_SUCCESS,
});

export const addJitsiLocalTrackToConfFail = () => ({
  type: ADD_JITSI_LOCAL_TRACK_TO_CONF_FAIL,
});
