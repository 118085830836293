import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {SIGNIN_USER, SIGNOUT_USER,} from 'constants/ActionTypes';
import {
  getStartTokenFail,
  getStartTokenSuccess,
  showAuthMessage,
  signInLandingPageSuccess,
  userSignInSuccess,
} from "../../appRedux/actions/Auth";

import RestManager from '@util/RestManager';
import {ENDPOINTS} from '@constants/Endpoints';
import {signInLandingPageFail, userSignInFail} from '@actions/Auth';
import {push} from 'react-router-redux';
import {GET_START_TOKEN, SIGNIN_LANDING_PAGE} from '@constants/ActionTypes';
import {onFetchEvents} from '@actions/events/EventsActions';
import JitsiConference from '@jitsi/JitsiConference';
import JitsiConnection from '@jitsi/JitsiConnection';
import {startAudioVideoToBool} from '@jitsi/helper';
import {JITSI_CONFIG, USER_ROLES} from '@constants/Settings';

/** -------------------------------- REQUESTS -------------------------------- **/

/**
 * Request to join with slot_id and role (after the login with username and password)
 * @param {object} values - slot_id, role
 * @returns {Promise}
 */
const joinWithSlotIdAndRoleRequest = async (values) => {
  return await RestManager.requestWithoutQueryParams(ENDPOINTS.join, 'POST', values);
};

/**
 * Request to get the start token to continues the login process
 * @param {object} values - username, password
 * @returns {Promise}
 */
const getStartTokenRequest = async (values) =>
  await RestManager.requestWithoutQueryParams(ENDPOINTS.login, 'POST', values);

const joinWithLandingPageTokenReq = async (token) =>
  await RestManager.requestWithoutQueryParams(ENDPOINTS.loginLP, 'GET', {}, [{ name: 'Authorization', value: `Bearer ${token}` }]);

const signOutRequest = async () => {
  if (window.audi.room && window.audi.connection) {
    try {
      await JitsiConnection.disconnect();
    } catch (error) {
      console.log(error);
    }
  }
  await sessionStorage.clear();
}

/** -------------------------------- REQUESTS  END -------------------------------- **/

/** -------------------------------- GENERATORS TO MAKE REQUEST -------------------------------- **/

function* joinUserWithSlotIdAndRole(values) {
  try {
    const result = yield call(joinWithSlotIdAndRoleRequest, values.values);

    if (result) {
      yield sessionStorage.setItem('token', result.token);
      yield sessionStorage.setItem('userRole', result.user_role.toUpperCase());
      yield sessionStorage.setItem('slot_id', values.values.slot_id);

      yield put(userSignInSuccess(result));
      yield put(push('/start'));
    }
  } catch (error) {
    yield put(userSignInFail(error.message));
  }
}

function* joinWithLandingPageToken(values) {
  try {
    const result = yield call(joinWithLandingPageTokenReq, values.token);
    const { startAudioMuted, startVideoMuted } = values;

    if (result) {
      yield sessionStorage.setItem('token', result.token);
      yield sessionStorage.setItem('userRole', USER_ROLES.visitor);

      if (!startAudioVideoToBool(startAudioMuted)) {
        yield sessionStorage.setItem('hasMutedLocalAudioTrack', 'false');
      } else {
        yield sessionStorage.setItem('hasMutedLocalAudioTrack', JITSI_CONFIG.startAudioMuted.toString());
      }

      if (!startAudioVideoToBool(startVideoMuted)) {
        yield sessionStorage.setItem('hasMutedLocalVideoTrack', 'false');
      } else {
        yield sessionStorage.setItem('hasMutedLocalVideoTrack', JITSI_CONFIG.startVideoMuted.toString());
      }

      yield put(signInLandingPageSuccess());
    }
  } catch (e) {
    yield put(signInLandingPageFail());
  }
}

function* signOut() {
  try {
    yield call(signOutRequest);
    yield window.location.reload();
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* getStartTokenGenerator(values) {
  try {
    const result = yield call(getStartTokenRequest, values.values);

    if (result) {
      yield sessionStorage.setItem('startToken', result.token);
      yield put(onFetchEvents());

      yield put(getStartTokenSuccess(result));
    }
  } catch (error) {
    yield put(getStartTokenFail(error.message));
  }
}

/** -------------------------------- GENERATORS TO MAKE REQUEST END -------------------------------- **/

export function* joinUser() {
  yield takeEvery(SIGNIN_USER, joinUserWithSlotIdAndRole);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* getStartToken() {
  yield takeEvery(GET_START_TOKEN, getStartTokenGenerator);
}

export function* joinUserWithLPToken() {
  yield takeEvery(SIGNIN_LANDING_PAGE, joinWithLandingPageToken);
}

export default function* rootSaga() {
  yield all([fork(joinUser),
    fork(signOutUser),
    fork(getStartToken),
    fork(joinUserWithLPToken)]
  );
}
