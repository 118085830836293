import {
  delay,
  put,
  select,
} from 'redux-saga/effects';
import {
  hideNotification,
  showNotification,
} from '@actions/Common';
import {
  setLoggedUserIsInConference,
  setLoggedUserIsKicked
} from '@actions/loggedUser/LoggedUserActions';
import {
  joinJitsiConferenceFail,
  joinJitsiConferenceSuccess,
  leaveJitsiConferenceSuccess,
} from '@actions/jitsiConference/JitsiConferenceActions';
import {
  checkIfTrackIsATrackOfOneOfOurSpeakers,
  checkIfUserIsModeratorByUserId,
  decodeDisplayName,
  getUserJitsiParticipantObjectByUserDatabaseId,
} from '@jitsi/helper';
import {
  fetchChosenLanguageSpeakers,
  setHasPresenterVideoTrack,
  setHasSpeakerVideoTrack,
  setHasToFetchSpeakersAudioTracks,
  setVisitorsSpeakers,
} from '@actions/conference/ConferenceActions';
import {
  JITSI_SPEAKER_MESSAGES,
  USER_ROLES,
} from '@constants/Settings';

/**
 * A file which includes generators which are the same on couple places
 *
 * For example:
 * 1. For the Conference JoinSuccess case, on all roles listeners
 * we dispatch an action to show notification about that, so we can just use
 * one generator function on all places
 * 2. For the 'userLeft' case on translator and visitor listeners we are executing same code
 * that why we make the generator here
 */

/**
 * Generator function in which we dispatch action to show notification that we
 * have joined the conference successfully
 */
export function* conferenceJoinSuccessGeneratorCommon() {
  yield put(joinJitsiConferenceSuccess());
  yield put(setLoggedUserIsInConference(true));
  yield put(showNotification('success', 'notification.conferenceJoinSuccess')); //using intl id for the message
  yield put(hideNotification());
}

/**
 * Generator function in which we dispatch action to show notification that
 * the conference join has failed
 */
export function* conferenceJoinFailGeneratorCommon() {
  yield put(joinJitsiConferenceFail());
  yield put(setLoggedUserIsInConference(false));
  yield put(showNotification('error', 'notification.conferenceJoinFail')); //using intl id for the message
  yield put(hideNotification());
}

/**
 * Generator which we execute when we leave the conference
 * @param jitsiConference
 */
export function* conferenceLeaveGeneratorCommon(jitsiConference) {
  yield put(leaveJitsiConferenceSuccess());
  yield put(setLoggedUserIsInConference(false));
  yield jitsiConference.removeConferenceListeners();
}

/**
 * Generator which we execute when we have been kicked from the conference
 */
export function* conferenceKickedGeneratorCommon() {
  yield put(setLoggedUserIsKicked(true));
  yield put(showNotification('warning', 'notification.kicked')); //using intl id for the message
  yield put(hideNotification());
}

/**
 * Generator which we execute when we receive a message
 * (used in Translator, Stage listeners)
 * @param adaptedMessage
 */
export function* conferenceMessageReceivedGeneratorCommon(adaptedMessage) {
  const isTheSenderModerator = yield checkIfUserIsModeratorByUserId(adaptedMessage.userId);
  const loggedUserLang = yield select((state) => state.loggedUser.lang);
  const loggedUserDatabaseId = yield select((state) => state.loggedUser.databaseId);
  const splitMessage = yield adaptedMessage.message.split('::');

  if (splitMessage.length >= 2
    && (splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerAdded
      || splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerRemoved
      || splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerAddedWithVideo)
    && isTheSenderModerator) {

    const addedSpeakerAsJitsiParticipantObject =
      getUserJitsiParticipantObjectByUserDatabaseId(splitMessage[1]);

    if (splitMessage[1] !== loggedUserDatabaseId.toString()) {
      yield put(fetchChosenLanguageSpeakers(loggedUserLang));

      if (addedSpeakerAsJitsiParticipantObject) {
        const decodedDisplayName = decodeDisplayName(addedSpeakerAsJitsiParticipantObject.getDisplayName());
        const addSpeakerRole = decodedDisplayName.confRole.toUpperCase();

        if (splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerAdded && addSpeakerRole === USER_ROLES.visitor) {
          yield put(setVisitorsSpeakers([addedSpeakerAsJitsiParticipantObject.getId()]));
          yield put(setHasSpeakerVideoTrack(false));
        } else if (splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerAddedWithVideo && addSpeakerRole === USER_ROLES.visitor) {
          yield put(setVisitorsSpeakers([addedSpeakerAsJitsiParticipantObject.getId()]));
          yield put(setHasSpeakerVideoTrack(true));
        } else if (splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerRemoved && addSpeakerRole === USER_ROLES.visitor) {
          yield put(setVisitorsSpeakers([]));
          yield delay(200);
          yield put(setHasSpeakerVideoTrack(false));
        }
      }
    }
  } else if (splitMessage.length === 1 && isTheSenderModerator) {
    if (splitMessage[0] === JITSI_SPEAKER_MESSAGES.removeSpeakerVideo) {
      yield put(setHasSpeakerVideoTrack(false));
    }
  }
}

/**
 * Generator which we execute when a track is removed from the conference
 * (used in Translator, Visitors listeners)
 * @param track
 */
export function* conferenceTrackRemovedGeneratorCommon(track) {
  const speakers = yield select((state) => state.conferenceReducer.chosenLanguageSpeakers);
  const hasPresenterId = yield select((state) => state.conferenceReducer.presenterId);
  const currentVisitorsSpeakers = yield select((state) => state.conferenceReducer.visitorsSpeakers);

  if (track.getType() === 'audio' && checkIfTrackIsATrackOfOneOfOurSpeakers(track.ownerEndpointId, speakers)) {
    const audioElement = document.getElementById(track.ownerEndpointId);
    track.detach(audioElement);
    yield put(setHasToFetchSpeakersAudioTracks(true));
  } else if (hasPresenterId === track.ownerEndpointId && track.getType() === 'video') {
    yield put(setHasPresenterVideoTrack(false));
  } else if (track.getType() === 'video' && currentVisitorsSpeakers.find((id) => id === track.ownerEndpointId)) {
    yield put(setHasSpeakerVideoTrack(false));
  }
}

/**
 * Generator which we execute when a track is added in the conference
 * (used in Translator, Visitor listeners)
 * @param track
 */
export function* conferenceTrackAddedGeneratorCommon(track) {
  const chosenLanguageSpeakers = yield select((state) => state.conferenceReducer.chosenLanguageSpeakers);
  const currentVisitorsSpeakers = yield select((state) => state.conferenceReducer.visitorsSpeakers);
  const hasPresenterId = yield select((state) => state.conferenceReducer.presenterId);
  const hasPresenterVideoTrackAtm = yield select((state) => state.conferenceReducer.hasPresenterVideoTrack);


  if (track.getType() === 'audio' && checkIfTrackIsATrackOfOneOfOurSpeakers(track.ownerEndpointId, chosenLanguageSpeakers)) {
    yield put(setHasToFetchSpeakersAudioTracks(true));
  } else if (hasPresenterId === track.ownerEndpointId && track.getType() === 'video') {
    if (hasPresenterVideoTrackAtm) {
      yield put(setHasPresenterVideoTrack(false));
    }
    yield put(setHasPresenterVideoTrack(true));
  } else if (track.getType() === 'video' && currentVisitorsSpeakers.find((id) => id === track.ownerEndpointId)) {
    yield put(setHasSpeakerVideoTrack(true));
  }
}

export function* conferenceConnectionErrorCommon() {
  yield put(showNotification('error', 'notification.jitsiError.conference.connectionError')); //using intl id for the message
  yield put(hideNotification());
}

export function* conferenceVideoBridgeNotAvailableCommon() {
  yield put(showNotification('error', 'notification.jitsiError.conference.videoBridgeNotAvailable')); //using intl id for the message
  yield put(hideNotification());
}

export function* conferenceJingleFatalError() {
  yield put(showNotification('error', 'notification.jitsiError.conference.jingleFatalError')); //using intl id for the message
  yield put(hideNotification());
}

export function* conferenceChatError() {
  yield put(showNotification('error', 'notification.jitsiError.conference.chatError')); //using intl id for the message
  yield put(hideNotification());
}
