import React from 'react';
import {connect, useSelector} from 'react-redux';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {setLoggedUserIsOnFullScreen} from '@actions/loggedUser/LoggedUserActions';
import {Badge, Col, Row, Tooltip} from 'antd';
import {ReactComponent as HandIcon} from '@assets/images/actions-icons/hand_icon_white.svg';
import {ReactComponent as HandIconSelected} from '@assets/images/actions-icons/hand_icon_black.svg';
import {ReactComponent as ChatIcon} from '@assets/images/actions-icons/chat_icon_white.svg';
import {useDevicesButtonsActionsHook} from '../../../../CustomHooks/useDevicesButtonsActionsHook';
import {toggleFullscreenHelper} from '../../../../CustomHooks/HelperFuncs';

const Topbar = (props) => {
  const {
    unreadMessages,
    deviceType,
    roomName,
    isOnFullScreen,
    intl,
    onSetFullscreen,
  } = props;

  const hasMicrophoneDevice = useSelector((state) => state.loggedUser.hasMicrophoneDevice);
  const hasRaisedHand = useSelector((state) => state.loggedUser.hasRaisedHand);
  const hasMutedLocalAudioTrack = useSelector((state) => state.loggedUser.hasMutedLocalAudioTrack);

  const {
    toggleRaiseHand,
    raiseHandTooltip,
  } = useDevicesButtonsActionsHook(
    intl,
    hasRaisedHand,
    hasMutedLocalAudioTrack,
    null,
    null,
    hasMicrophoneDevice,
  );

  /**
   * Function to toggle the fullscreen
   */
  const toggleFullscreen = (isItOnFullscreen) => toggleFullscreenHelper(isItOnFullscreen, onSetFullscreen);
  const isHLS = JSON.parse(sessionStorage.getItem('streamTypeHLS'));

  return (
    <Row justify="space-around" align="middle" className="userView__topbar">
      <Col span={20}>
        <div className={`userView__title userView__title--${deviceType}`} />
      </Col>
      <Col
        span={4}
        className="userView__topbarIconsWrapper"
      >
        {!isHLS && (
        <Tooltip
          placement="topRight"
          title={raiseHandTooltip}
        >
          <div
            className={`userViewActionButton userViewActionButton--${deviceType} ${hasRaisedHand ? `userViewActionButton--${deviceType}--selected` : ''}`}
            onClick={() => toggleRaiseHand()}
          >
            { hasRaisedHand ? <HandIconSelected /> : <HandIcon /> }
          </div>
        </Tooltip>
      )}
        <Badge
          count={unreadMessages > 0 ? unreadMessages : null}
          className="userViewActionButton__iconBadge"
          size="small"
        >
          <div
            className={`userViewActionButton userViewActionButton--${deviceType}`}
            onClick={() => toggleFullscreen(!isOnFullScreen)}
          >
            <ChatIcon />
          </div>
        </Badge>
      </Col>
    </Row>
  );
};

Topbar.propTypes = {
  unreadMessages: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.number]),
  deviceType: PropTypes.string,
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  roomName: PropTypes.string,
  isOnFullScreen: PropTypes.bool.isRequired,
  onSetFullscreen: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSetFullscreen: (isOnFullScreen) => dispatch(setLoggedUserIsOnFullScreen(isOnFullScreen)),
});
export default connect(null, mapDispatchToProps)(injectIntl(React.memo(Topbar)));
