import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {Button, Drawer, Menu, Modal,} from 'antd';
import IntlMessages from '@util/IntlMessages';
import LegalNotice from '@containers/PolicyModals/LegalNotice';
import DataProtection from '@containers/PolicyModals/DataProtection';
import CookieGuidelines from '@containers/PolicyModals/CookieGuidelines';
import TermsOfUse from '@containers/PolicyModals/TermsOfUse';
import UserSettings from '@routes/UserView/UserSettings';

const VisitorMobileMenu = (props) => {
  const { isMenuVisible, onHideMenu, intl, isOnFullScreen, browserName } = props;
  const [termsOfUseModal, setTermsOfUseModal] = useState(false);
  const [legalNoticeModal, setLegalNoticeModal] = useState(false);
  const [dataProtectionModal, setDataProtectionModal] = useState(false);
  const [revocationOfConsent, setRevocationOfConsent] = useState(false);
  const [settings, setSettings] = useState(false);

  const showDataProtection = () => {
    setDataProtectionModal(true);
  };

  const closeDataProtection = () => {
    setDataProtectionModal(false);
  };

  const showRevocationOfConsent = () => {
    setRevocationOfConsent(true);
  };

  const closeRevocationOfConsent = () => {
    setRevocationOfConsent(false);
  };

  const showTermsOfUse = () => {
    setTermsOfUseModal(true);
  };

  const closeTermsOfUse = () => {
    setTermsOfUseModal(false);
  };

  const showLegalNotice = () => {
    setLegalNoticeModal(true);
  };

  const closeLegalNotice = () => {
    setLegalNoticeModal(false);
  };

  return (
    <div>
      <Drawer
        title={intl.formatMessage({ id: 'sidebar.navigation.menu' })}
        placement={isOnFullScreen ? 'right' : 'top'}
        width="100%"
        height="auto"
        closable
        onClose={onHideMenu}
        visible={isMenuVisible}
        className={`userViewMenuDrawer userViewMenuDrawer--${browserName} ${isOnFullScreen ? `userViewMenuDrawer--fullscreen userViewMenuDrawer--fullscreen--${browserName}` : ''}`}
      >
        <Menu mode="vertical" className="userViewMenu" style={{ borderRight: '0px', paddingTop: '30px' }}>
          <>
            <Menu.Item className="userViewMenuItem">
              <Button onClick={showDataProtection} className="userViewMenuItem__button">
                <IntlMessages id="user.menu.dataProtection" />
              </Button>
              <Modal
                title={<IntlMessages id="user.menu.dataProtection.header" />}
                visible={dataProtectionModal}
                closable={false}
                bodyStyle={{
                  height: '210px',
                  overflowY: 'scroll',
                }}
                style={{ top: '15px' }}
                footer={[
                  <Button key="dataProtection" onClick={closeDataProtection}>
                    <IntlMessages id="user.menu.modal.done" />
                  </Button>,
                ]}
                className="userViewMenu__modal"
              >
                <DataProtection />
              </Modal>
            </Menu.Item>
            <Menu.Item className="userViewMenuItem">
              <Button onClick={showRevocationOfConsent} className="userViewMenuItem__button">
                <IntlMessages id="user.menu.cookieGuidelines" />
              </Button>
              <Modal
                title={<IntlMessages id="user.menu.cookieGuidelines" />}
                visible={revocationOfConsent}
                closable={false}
                bodyStyle={{
                  height: '210px',
                  overflowY: 'scroll',
                }}
                style={{ top: '15px' }}
                footer={[
                  <Button key="revocationOfConsent" onClick={closeRevocationOfConsent}>
                    <IntlMessages id="user.menu.modal.done" />
                  </Button>,
                ]}
              >
                <CookieGuidelines />
              </Modal>
            </Menu.Item>
            <Menu.Item className="userViewMenuItem">
              <Button onClick={showTermsOfUse} className="userViewMenuItem__button">
                <IntlMessages id="user.menu.termsOfUse" />
              </Button>
              <Modal
                title={<IntlMessages id="user.menu.termsOfUse" />}
                visible={termsOfUseModal}
                closable={false}
                bodyStyle={{
                  height: '210px',
                  overflowY: 'scroll',
                }}
                style={{ top: '15px' }}
                footer={[
                  <Button key="termsOfUse" onClick={closeTermsOfUse}>
                    <IntlMessages id="user.menu.modal.done" />
                  </Button>,
                ]}
              >
                <TermsOfUse />
              </Modal>
            </Menu.Item>
            <Menu.Item className="userViewMenuItem">
              <Button onClick={showLegalNotice} className="userViewMenuItem__button">
                <IntlMessages id="user.menu.legalNotice" />
              </Button>
              <Modal
                title={<IntlMessages id="user.menu.legalNotice" />}
                visible={legalNoticeModal}
                closable={false}
                bodyStyle={{
                  height: '210px',
                  overflowY: 'scroll',
                }}
                style={{ top: '15px' }}
                footer={[
                  <Button key="legalNotice" onClick={closeLegalNotice}>
                    <IntlMessages id="user.menu.modal.done" />
                  </Button>,
                ]}
              >
                <LegalNotice />
              </Modal>
            </Menu.Item>
            <Menu.Item className="userViewMenuItem">
              <Button onClick={() => setSettings(true)} className="userViewMenuItem__button">
                <IntlMessages id="user.menu.modal.settings" />
              </Button>
              <Modal
                title={<IntlMessages id="user.menu.modal.settings" />}
                visible={settings}
                closable={false}
                bodyStyle={{
                  height: '210px',
                }}
                style={{ top: '15px' }}
                footer={[
                  <Button key="settings" onClick={() => setSettings(false)}>
                    <IntlMessages id="user.menu.modal.done" />
                  </Button>,
                ]}
              >
                <UserSettings />
              </Modal>
            </Menu.Item>
          </>
        </Menu>
      </Drawer>
    </div>
  );
};

VisitorMobileMenu.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isMenuVisible: PropTypes.bool.isRequired,
  onHideMenu: PropTypes.func.isRequired,
  browserName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default (injectIntl)(VisitorMobileMenu);
