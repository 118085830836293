import { useEffect, useRef } from 'react';

/**
 * Simple custom hook, which we can use to check if we render the component for FIRST time
 *
 * @returns {boolean}
 */
export function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}
