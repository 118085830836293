import {eventChannel} from 'redux-saga';
import {CONFERENCE_LISTENERS_CASES_TYPES, JITSI_CONFERENCE_EVENTS, JITSI_ERROR_EVENTS} from '@constants/Settings';
import {put, select} from 'redux-saga/effects';
import Moment from 'moment';
import {
  fetchChosenLanguageSpeakers,
  fetchChosenLanguageSpeakersSuccess,
  fetchConferenceSlotLanguages,
} from '@actions/conference/ConferenceActions';
import {
  conferenceChatError,
  conferenceConnectionErrorCommon,
  conferenceJingleFatalError,
  conferenceJoinFailGeneratorCommon,
  conferenceJoinSuccessGeneratorCommon,
  conferenceKickedGeneratorCommon,
  conferenceLeaveGeneratorCommon,
  conferenceTrackAddedGeneratorCommon,
  conferenceTrackRemovedGeneratorCommon,
  conferenceVideoBridgeNotAvailableCommon
} from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/CommonCaseGenerators';
import {
  conferenceMessageReceivedGeneratorVisitor,
  conferencePrivateMessageReceivedGeneratorVisitor,
  conferenceUserJoinedGeneratorVisitor,
  conferenceUserLeftGeneratorVisitor,
} from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/VisitorCaseGenerators';

/**
 * @description The EventChannel where we put all conference listeners for the VISITOR ROLE
 * @param jitsiConference
 * @returns {any}
 */
export const visitorChannel = (jitsiConference) => eventChannel((emitter) => {
  /**
   * @description Listens for conference join success
   * @returns {void}
   */
  const joinSuccess = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.joinSuccess, data: [] });
  };

  /**
   * @description Listens for join failed
   * @returns {void}
   */
  const joinFailed = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.joinFail, data: [] });
  };

  /**
   * @description Listens for conference left
   * @returns {void}
   */
  const leave = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.leave, data: [] });
  };

  /**
   * @description Listens for user join
   * @param {Number} userId
   * @param {Object} user
   * @returns {void}
   */
  const userJoined = (userId, user) => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.userJoined, data: [user] });
  };

  /**
   * @description Listens for user left
   * @param {Number} userId
   * @param {Object} user
   * @returns {void}
   */
  const userLeft = (userId, user) => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.userLeft, data: [user] });
  };

  /**
   * @description Listens for any track which has been added in the conference
   * @param {object} track
   */
  const trackAdded = (track) => {
    if (!track.isLocal()) {
      emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackAdded, data: [track] });
    }
  };

  /**
   * @description Listens for any track which has been removed from the conference
   * @param {object} track
   */
  const trackRemoved = (track) => {
    if (!track.isLocal()) {
      emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackRemoved, data: [track] });
    }
  };

  /**
   * @description Listens for private received message
   * @param {Number} from
   * @param {String} message
   * @returns {void}
   */
  const privateMessageReceived = (from, message) => {
    const adaptMessage = {
      message,
      type: 'received',
      userId: from,
      sentAt: Moment().format('hh:mm:ss A'),
      read: false,
    };
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.privateMessageReceived, data: [adaptMessage] });
  };

  const messageReceived = (from, message) => {
    const adaptMessage = {
      message,
      type: 'received',
      userId: from,
      sentAt: Moment().format('hh:mm:ss A'),
      read: false,
    };

    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.messageReceived, data: [adaptMessage] });
  };

  /**
   * Listens if we have been kicked from the conference
   */
  const kickedFromConference = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.kickedFromConference, data: [] });
  };

  /**
   * Listens if the connection with the conference is lost
   */
  const conferenceConnectionLost = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.errorConferenceConnectionLost, data: [] });
  };

  /**
   * Listens if there is error with the videobridge
   */
  const videoBridgeNotAvailable = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.errorConferenceVideoBridgeNotAvailable, data: [] });
  };

  /**
   * Listens if there is a error with the webRTC
   */
  const jingleFatalError = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.errorConferenceJingleFatalError, data: [] });
  };

  const chatError = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.errorConferenceChatError, data: [] });
  };

  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceJoined, joinSuccess);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceJoinFailed, joinFailed);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceLeft, leave);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceUserJoined, userJoined);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceUserLeft, userLeft);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceTrackAdded, trackAdded);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceTrackRemoved, trackRemoved);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferencePrivateMessageReceived, privateMessageReceived);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceMessageReceived, messageReceived);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceKicked, kickedFromConference);
  jitsiConference.addListener(JITSI_ERROR_EVENTS.conferenceConnectionError, conferenceConnectionLost);
  jitsiConference.addListener(JITSI_ERROR_EVENTS.conferenceVideoBridgeNotAvailable, videoBridgeNotAvailable);
  jitsiConference.addListener(JITSI_ERROR_EVENTS.conferenceVideoJingleFatalError, jingleFatalError);
  jitsiConference.addListener(JITSI_ERROR_EVENTS.conferenceChatError, chatError);

  return () => {
    jitsiConference.removeConferenceListeners();
  };
});

/**
 * @description Generator function with SWITCH inside, which depending on the
 * conference event which was "caught" dispatches actions
 * @description Explanation:
 * (On the channel above, for the successful conference joining event, we are executing "joinSuccess" function listener,
 * inside that listener we are emitting object with "type: 'JoinSuccess'" and "data: []"
 * in the switch statement below for the 'JoinSuccess' case we are dispatching two actions)
 * @param channelResult
 * @param jitsiConference
 * @returns {any}
 */
export function* visitorSwitch(channelResult, jitsiConference) {
  switch (channelResult.type) {
    case CONFERENCE_LISTENERS_CASES_TYPES.joinSuccess:
      const loggedUserLang = yield select((state) => state.loggedUser.lang);

      yield conferenceJoinSuccessGeneratorCommon();
      /**
       * When we join the conference successfully, we get the languages available for the slot
       * we have joined
       */
      yield put(fetchConferenceSlotLanguages());
      yield put(fetchChosenLanguageSpeakers(loggedUserLang));
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.joinFail:
      yield conferenceJoinFailGeneratorCommon();
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.leave:
      yield conferenceLeaveGeneratorCommon(jitsiConference);
      yield put(fetchChosenLanguageSpeakersSuccess([]));
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.userJoined:
      yield conferenceUserJoinedGeneratorVisitor(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.userLeft:
      yield conferenceUserLeftGeneratorVisitor(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackAdded:
      yield conferenceTrackAddedGeneratorCommon(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackRemoved:
      yield conferenceTrackRemovedGeneratorCommon(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.privateMessageReceived:
      yield conferencePrivateMessageReceivedGeneratorVisitor(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.messageReceived:
      yield conferenceMessageReceivedGeneratorVisitor(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.kickedFromConference:
      yield conferenceKickedGeneratorCommon();
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.errorConferenceConnectionLost:
      yield conferenceConnectionErrorCommon();
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.errorConferenceVideoBridgeNotAvailable:
      yield conferenceVideoBridgeNotAvailableCommon();
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.errorConferenceJingleFatalError:
      yield conferenceJingleFatalError();
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.errorConferenceChatError:
      yield conferenceChatError();
      break;
    default:
      break;
  }
}
