import {useCallback, useEffect, useRef, useState} from 'react';
import JitsiTrack from '@jitsi/JitsiTrack';
import {hideNotification, showNotification} from '@actions/Common';
import {useDispatch, useSelector} from 'react-redux';
import {
  setLoggedUserDefaultAudioDeviceId,
  setLoggedUserDefaultVideoDeviceId,
} from '@actions/loggedUser/LoggedUserActions';
import {createLocalTracksOptions} from '@jitsi/helper';

/**
 * Custom hook which we use to
 * 1. Create the local tracks
 * 2. Get and set user devices
 *
 * @returns {{setLocalTracks: function, localTracks: []}} - we
 * return an object which contains a setter for the local tracks, and the local tracks
 * themself
 */
export function useCreateLocalTracksHook() {
  const [localTracks, _setLocalTracks] = useState([]); // the local user tracks
  const localTracksRef = useRef(localTracks);
  const dispatch = useDispatch();
  const isConnected = useSelector((state) => state.loggedUser.isConnected);
  const hasMicrophoneDevice = useSelector((state) => state.loggedUser.hasMicrophoneDevice);
  const hasCameraDevice = useSelector((state) => state.loggedUser.hasCameraDevice);
  const loggedUserRole = useSelector((state) => state.loggedUser.userRole);
  const loggedUserBrowserName = useSelector((state) => state.loggedUser.browserName);

  const setLocalTracks = (data) => {
    localTracksRef.current = data;
    _setLocalTracks(data);
  };

  /**
   * Function which we use to set the default 'video', 'audio' devices IDs in redux
   * The default IDs depends on the tracks which Jitsi has made.
   *
   * When jitsi make a video track, it automatically sets a default video device
   * (from all our video devices) for that track,
   * we get that device, get his ID and set it as 'defaultVideoDeviceId' in redux
   * @param tracks
   */
  const setDevicesIds = useCallback((tracks) => {
    for (const track of tracks) {
      if (track.getType() === 'video' && hasCameraDevice) {
        dispatch(setLoggedUserDefaultVideoDeviceId(track.deviceId));
      }
      if (track.getType() === 'audio' && hasMicrophoneDevice) {
        dispatch(setLoggedUserDefaultAudioDeviceId(track.deviceId));
      }
    }
  }, [localTracks.current]);

  useEffect(() => {
    if (isConnected) {
      const localTracksOptions = createLocalTracksOptions(hasMicrophoneDevice, hasCameraDevice, loggedUserRole);

      if (loggedUserBrowserName === 'chrome' && hasCameraDevice) {
        Object.assign(localTracksOptions, {
          constraints: {
            video: {
              aspectRatio: 16 / 9,
            },
          },
        });
      }

      JitsiTrack.createLocalTracks(localTracksOptions)
        .then((result) => {
          setLocalTracks(result);
          setDevicesIds(localTracksRef.current);
        })
        .catch((e) => {
          dispatch(showNotification('error', 'notification.localTrackCreationError'));
          dispatch(hideNotification());
        });
    }
  }, [isConnected, dispatch]);

  return {
    localTracks: localTracksRef.current,
    setLocalTracks,
  };
}
