import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactComponent as HandIcon } from '@assets/images/actions-icons/hand_icon_white-thin.svg';
import { ReactComponent as HandIconSelected } from '@assets/images/actions-icons/hand_icon_black-thin.svg';
import { ReactComponent as CameraIcon } from '@assets/images/actions-icons/camera_icon_white-thin.svg';
import { ReactComponent as CameraIconMuted } from '@assets/images/actions-icons/camera_icon_white-muted-thin.svg';
import { ReactComponent as MicrophoneIcon } from '@assets/images/actions-icons/microphone_icon_white-thin.svg';
import { ReactComponent as MicrophoneIconMuted } from '@assets/images/actions-icons/microphone_icon_white-muted-thin.svg';
import { ReactComponent as ChatIcon } from '@assets/images/actions-icons/chat_icon_black-thin.svg';

import { Badge, Tooltip } from 'antd';
import { useDevicesButtonsActionsHook } from '../../CustomHooks/useDevicesButtonsActionsHook';

const MobileActionsButtons = (props) => {
  const {
    deviceType,
    unreadMessages,
    intl,
  } = props;

  const hasCameraDevice = useSelector((state) => state.loggedUser.hasCameraDevice);
  const hasMicrophoneDevice = useSelector((state) => state.loggedUser.hasMicrophoneDevice);
  const hasRaisedHand = useSelector((state) => state.loggedUser.hasRaisedHand);
  const hasMutedLocalAudioTrack = useSelector((state) => state.loggedUser.hasMutedLocalAudioTrack);
  const hasMutedLocalVideoTrack = useSelector((state) => state.loggedUser.hasMutedLocalVideoTrack);

  const {
    toggleRaiseHand,
    toggleMicrophone,
    toggleCamera,
    raiseHandTooltip,
    cameraTooltip,
    microphoneTooltip,
  } = useDevicesButtonsActionsHook(
    intl,
    hasRaisedHand,
    hasMutedLocalAudioTrack,
    hasMutedLocalVideoTrack,
    hasCameraDevice,
    hasMicrophoneDevice,
  );

  return (
    <div className={`userViewMobileActionsButtons userViewMobileActionsButtons--${deviceType}`}>
      <Tooltip title={raiseHandTooltip} trigger={['click', 'hover']}>
        <div
          className={`userViewActionButton userViewActionButton--${deviceType} ${hasRaisedHand ? 'userViewActionButton--selected' : ''}`}
          onClick={() => toggleRaiseHand()}
        >
          {
            hasRaisedHand ? <HandIconSelected /> : <HandIcon />
          }
        </div>
      </Tooltip>
      <Tooltip title={cameraTooltip}>
        <div className={`userViewActionButton userViewActionButton--${deviceType}`}>
          {
            (hasMutedLocalVideoTrack || !hasCameraDevice)
              ? <CameraIconMuted onClick={() => toggleCamera()} />
              : <CameraIcon onClick={() => toggleCamera()} />
          }
        </div>
      </Tooltip>
      <Tooltip title={microphoneTooltip}>
        <div className={`userViewActionButton userViewActionButton--${deviceType}`}>
          {
            (hasMutedLocalAudioTrack || !hasMicrophoneDevice)
              ? <MicrophoneIconMuted onClick={() => toggleMicrophone()} />
              : <MicrophoneIcon onClick={() => toggleMicrophone()} />
          }
        </div>
      </Tooltip>
      <Badge
        count={unreadMessages > 0 ? unreadMessages : null}
        showZero
        size="small"
        className="userViewActionButton__iconBadge"
      >
        <div className={`userViewActionButton userViewActionButton--${deviceType} userViewActionButton--selected`}>
          <ChatIcon />
        </div>
      </Badge>
    </div>
  );
};

MobileActionsButtons.propTypes = {
  deviceType: PropTypes.string,
  unreadMessages: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default (injectIntl(React.memo(MobileActionsButtons)));
