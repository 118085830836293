import {
  all, call, fork, put, select, takeEvery,
} from 'redux-saga/effects';
import {
  onFetchQuestionsSuccess,
} from '@actions/questions/QuestionsActions';
import {
  GET_ALL_QUESTIONS_REQUEST,
  DELETE_QUESTION_REQUEST,
  ADD_QUESTION_REQUEST,
} from '@constants/ActionTypes';
import { USER_ROLES } from '@constants/Settings';
import { ENDPOINTS } from '@constants/Endpoints';
import { fetchError, hideNotification, showNotification } from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * @description Send GET request to fetch all questions in given slot
 * @param {Number} slotId
 * @returns {Array}
 */
const fetchQuestions = async (slotId) => RestManager.request(
  `${ENDPOINTS.questions}/${slotId}/questions/`,
);

/**
 * @description Fetching all questions in slot
 * @param {Object} payload
 * @returns {void}
 */
function* doFetchQuestions(payload) {
  try {
    const questions = yield call(fetchQuestions, payload.slotId);
    if (questions) {
      yield put(onFetchQuestionsSuccess(questions));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends POST request
 * @param {Number} slotId
 * @param {Object} question
 * @returns {Object}
 */
const addQuestion = async (slotId, question) => RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.questions}/${slotId}/questions/`,
  'POST',
  question,
);

/**
 * @description Adds new question in questions list
 * @param {Object} payload
 * @returns {void}
 */
function* doAddQuestion(payload) {
  try {
    const question = yield call(addQuestion, payload.slotId, payload.question);
    const userRole = yield select((state) => state.loggedUser.userRole);

    if (question) {
      const questionsList = yield call(fetchQuestions, payload.slotId);
      yield put(onFetchQuestionsSuccess(questionsList));
      if (userRole === USER_ROLES.moderator) {
        yield put(showNotification('info', 'notification.question.add'));
        yield put(hideNotification());
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Sends DELETE request
 * @param {Number} slotId
 * @param {Number} questionId
 */
const deleteQuestion = async (slotId, questionId) => RestManager.requestWithoutQueryParams(
  `${ENDPOINTS.questions}/${slotId}/questions/${questionId}`,
  'DELETE',
);

/**
 * @description Delete question from list
 * @param {Object} payload
 */
function* doDeleteQuestion(payload) {
  try {
    const response = yield call(deleteQuestion, payload.slotId, payload.questionId);
    const userRole = yield select((state) => state.loggedUser.userRole);

    if (response.status === 204) {
      const questionsList = yield call(fetchQuestions, payload.slotId);
      yield put(onFetchQuestionsSuccess(questionsList));
      if (userRole === USER_ROLES.moderator) {
        yield put(showNotification('info', 'notification.question.delete'));
        yield put(hideNotification());
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(GET_ALL_QUESTIONS_REQUEST, doFetchQuestions);
  yield takeEvery(ADD_QUESTION_REQUEST, doAddQuestion);
  yield takeEvery(DELETE_QUESTION_REQUEST, doDeleteQuestion);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
