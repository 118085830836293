import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { fetchLoggedUser, setHasCameraDevice, setHasMicrophoneDevice } from '@actions/loggedUser/LoggedUserActions';
import { Modal } from 'antd';
import { USER_ROLES } from '@constants/Settings';
import RestManager from '@util/RestManager';
import { ENDPOINTS } from '@constants/Endpoints';
import IntlMessages from '@util/IntlMessages';
import audiSVG from '../../assets/images/logo/loader.svg';
import { useFirstRender } from '../../CustomHooks/useFirstRender';

const Start = (props) => {
  const {
    onSetLoggedUser, error,
    onSetHasCameraDevice,
    onSetHasMicrophoneDevice,
    intl,
    hasCameraDevice,
    hasMicrophoneDevice,
  } = props;
  const history = useHistory();
  const userRoleFromStorage = sessionStorage.getItem('userRole');
  const [hasPermissions, setHasPermissions] = useState({ value: null });
  const isFirstRender = useFirstRender();
  const { confirm } = Modal;
  const [noActiveStream, setNoActiveStream] = useState(false);

  const doFetchLoggedUserSlotData = async () => await RestManager.request(ENDPOINTS.userSlotData);

  /**
   * Logic used in the situation where the app user click the back browser button.
   * Here we check that this component has been loaded with 'history.action = POP'
   * which means that the 'BACK' browser has been clicked to get to this component
   */
  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    const eventRef = window.addEventListener('NO_ACTIVE_STREAMS', noActiveStreamsHandler);

    if (history.action === 'POP') {
      history.goForward();
    }

    return () => {
      // eslint-disable-next-line no-use-before-define
      window.removeEventListener(eventRef, noActiveStreamsHandler);
    };
  }, []);

  useEffect(() => {
    if (userRoleFromStorage === USER_ROLES.moderator) {
      onSetLoggedUser();
      sessionStorage.setItem('hasToShowSoundConfirmation', 'firstRender');
    } else {
      // eslint-disable-next-line no-use-before-define
      checkCameraAndMicrophoneDevices();
    }
  }, []);


  /**
   * Function to check if the user have camera and microphone devices.
   */
  const checkCameraAndMicrophoneDevices = async () => {
    const { stream_types } = await doFetchLoggedUserSlotData();

    if (stream_types && stream_types?.length === 2 && stream_types !== null) {
      sessionStorage.setItem('switchableStreamType', true);
    }

    if (stream_types && stream_types?.length === 1 && stream_types[0] === 'HLS') {
      sessionStorage.setItem('streamTypeHLS', true);
      setHasPermissions({ value: false });
    } else {
      window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
        const hasCamera = _.some(devices, { kind: 'videoinput' });
        const hasMic = _.some(devices, { kind: 'audioinput' });

        onSetHasCameraDevice(hasCamera);
        onSetHasMicrophoneDevice(hasMic);
        // eslint-disable-next-line no-use-before-define
        askForCameraAndMicrophonePermissions(hasCamera, hasMic);
      });
    }
  };

  /**
   * Function to ask for devices permissions (for devices which the user has)
   */
  const askForCameraAndMicrophonePermissions = (hasCamera, hasMicrophone) => {
    if (hasMicrophone === false && hasCamera === false) {
      setHasPermissions(true);
    } else {
      const getUserMediaConstraints = {
        audio: hasMicrophone,
        video: hasCamera,
      };
      navigator.mediaDevices.getUserMedia(getUserMediaConstraints)
        .then((result) => {
          setHasPermissions({ value: true });
        })
        .catch((e) => {
          setHasPermissions({ value: false });
        });
    }
  };

  const askForMicrophonePermissions = (hasMicrophone) => {
    const getUserMediaConstraints = {
      audio: hasMicrophone,
    };
    navigator.mediaDevices.getUserMedia(getUserMediaConstraints)
      .then(() => {
        onSetHasCameraDevice(false);
        sessionStorage.setItem('hasMutedLocalVideoTrack', 'true');
        onSetHasMicrophoneDevice(true);
        setHasPermissions({ value: true });
      })
      .catch((e) => {
        setHasPermissions({ value: false });
      });
  };

  const allowUserToContinue = () => {
    onSetLoggedUser();
  };

  const noActiveStreamsHandler = () => {
    setNoActiveStream(true);
  };

  const spinner = <img src={audiSVG} alt="loader" className="startPageLoader" />;


  const noPermissionsWarning = () => {
    const browseName = sessionStorage.getItem('browserName');

    if (browseName && browseName !== 'safari') {
      Modal.warning({
        title: intl.formatMessage({ id: 'startPage.noPermissionsWarning.header' }),
        content: intl.formatMessage({ id: 'startPage.noPermissionsWarning.message' }),
        className: 'noPermissionsWarningModal',
        okText: intl.formatMessage({ id: 'startPage.noPermissionsWarning.okText' }),
        onOk() {
          onSetHasCameraDevice(false);
          onSetHasMicrophoneDevice(false);
          sessionStorage.setItem('hasMutedLocalVideoTrack', 'true');
          sessionStorage.setItem('hasMutedLocalAudioTrack', 'true');
          allowUserToContinue();
        },
      });
    } else {
      confirm({
        title: intl.formatMessage({ id: 'startPage.noPermissionsWarning.header' }),
        content: intl.formatMessage({ id: 'startPage.noPermissionsWarning.messageSafari' }),
        className: 'noPermissionsWarningModal',
        okText: intl.formatMessage({ id: 'startPage.noPermissionsWarning.okText' }),
        cancelText: intl.formatMessage({ id: 'startPage.noPermissionsWarning.cancelText' }),
        onCancel() {
          askForMicrophonePermissions(hasMicrophoneDevice);
        },
        onOk() {
          onSetHasCameraDevice(false);
          onSetHasMicrophoneDevice(false);
          sessionStorage.setItem('hasMutedLocalVideoTrack', 'true');
          sessionStorage.setItem('hasMutedLocalAudioTrack', 'true');
          allowUserToContinue();
        },
      });
    }
  };

  useEffect(() => {
    const isHLS = JSON.parse(sessionStorage.getItem('streamTypeHLS'));

    if (hasPermissions.value || isHLS) {
      allowUserToContinue();
    } else if (!isFirstRender) {
      noPermissionsWarning();
    }
  }, [hasPermissions]);

  if (error !== '') {
    console.warn('[ERROR] start:', error);
    // history.goBack();
  }

  if (noActiveStream) {
    return (
      <Modal
        title="Alert"
        visible
        onOk={() => { history.replace('/login'); }}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>
          <IntlMessages id="startPage.noActiveStreams" />
        </p>
      </Modal>
    );
  }

  return spinner;
};

Start.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onSetLoggedUser: PropTypes.func.isRequired,
  onSetHasCameraDevice: PropTypes.func.isRequired,
  onSetHasMicrophoneDevice: PropTypes.func.isRequired,
  intl: PropTypes.oneOfType([PropTypes.object]),
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hasCameraDevice: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  hasMicrophoneDevice: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

const mapStateToProps = ({ router, common, loggedUser }) => ({
  location: router.location,
  error: common.error,
  hasCameraDevice: loggedUser.hasCameraDevice,
  hasMicrophoneDevice: loggedUser.hasMicrophoneDevice,
});

const mapDispatchToProps = (dispatch) => ({
  onSetLoggedUser: () => dispatch(fetchLoggedUser()),
  onSetHasCameraDevice: (hasCamera) => dispatch(setHasCameraDevice(hasCamera)),
  onSetHasMicrophoneDevice: (hasMicrophone) => dispatch(setHasMicrophoneDevice(hasMicrophone)),
});
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Start));
