import React from 'react';
import { Redirect, Route, Switch, useLocation, } from 'react-router-dom';
import ScheduleDetails from '@routes/Schedule/ScheduleDetails';
import Chat from '@routes/ChatPage/Chat';
import Participants from '@routes/Participants/Participants';
import Create from '@routes/Schedule/Create/ScheduleCreate';
import ScheduleGroups from '@routes/Schedule/ScheduleGroups';
import ScheduleUsers from '@routes/Schedule/ScheduleUsers';
import UserView from '@routes/UserView/UserView';
import Start from '@routes/StartPage/Start';
import Presenter from '@routes/Presenter/Presenter';
import Translator from '@routes/Translator/Translator';

const App = ({ match }) => {
  const location = useLocation();

  return (
    <div className={`gx-main-content-wrapper gx-main-content-wrapper--${location.pathname.substring(1)}`}>
      <Switch>
        {/* <Route exact path="/">
        <Redirect to="/schedule" />
      </Route> */}
        <Route exact path={`${match.url}user`} component={UserView}/>
        <Route exact path={`${match.url}start`} component={Start}/>
        <Route exact path={`${match.url}presenter`} component={Presenter}/>
        <Route exact path={`${match.url}translator`} component={Translator}/>
        <Route path={`${match.url}schedule/create`} component={Create}/>
        <Route
          path={`${match.url}schedule/:eventId/slots/:slotId/users`}
          render={(props) => <ScheduleUsers key="scheduleUsers" {...props} />}
        />
        <Route
          path={`${match.url}schedule/:eventId/slots`}
          render={(props) => <ScheduleGroups key="scheduleGroups" {...props} />}
        />
        <Route path={`${match.url}schedule`} component={ScheduleDetails}/>
        <Route path={`${match.url}chat`} component={Chat}/>
        <Route path={`${match.url}participants`} component={Participants}/>
        <Route path={`${match.url}user-view`} component={UserView}/>
        <Route path="*">
          <Redirect from={`${match.url}`} to="/"/>
        </Route>
      </Switch>
    </div>
  )
};

export default App;
