import {useEffect} from 'react';
import JitsiConference from '@jitsi/JitsiConference';
import {useSelector} from 'react-redux';

/**
 * Custom hook where we handle the change of the 'hasMutedLocalAudioTrack' property
 *
 * Here the hook depends on the 'localTracks' and 'hasMutedLocalAudioTrack'
 *
 * For example if the user doesn't have Camera and Microphone, we wont have any tracks,
 * so 'localTracks.length > 0' will be false and we wont execute anything
 *
 * @param localTracks - the user tracks
 */
export function useHasMutedLocalAudioTrackHook(localTracks) {
  const hasMutedLocalAudioTrack = useSelector((state) => state.loggedUser.hasMutedLocalAudioTrack);

  useEffect(() => {
    if (localTracks.length > 0) {
      const audioTrack = localTracks.find((tr) => tr.getType() === 'audio');

      if (audioTrack) {
        if (hasMutedLocalAudioTrack === true) {
          audioTrack.mute();
        } else {
          audioTrack.unmute();
        }

        JitsiConference.changeHasMutedLocalAudioTrack(hasMutedLocalAudioTrack.toString());
      }
    }
  }, [localTracks, hasMutedLocalAudioTrack]);
}
