import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import {
  connect,
  useDispatch,
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  JITSI_CONFERENCE_EVENTS,
  JITSI_CONNECTION_EVENTS,
  JITSI_SPEAKER_MESSAGES,
  JITSI_TRACK_EVENTS,
  USER_ROLES,
} from "@constants/Settings";
import JitsiConnection from "@jitsi/JitsiConnection";
import JitsiConference from "@jitsi/JitsiConference";
import {
  checkIfUserIsModeratorByUserId,
  createLocalTracksOptionsWithSpecificDevices,
  decodeDisplayName,
  getUserJitsiParticipantObjectByUserDatabaseId,
} from "@jitsi/helper";
import {
  hideNotification,
  showNotification,
} from "@actions";
import {
  setLoggedUserDefaultAudioDeviceId,
  setLoggedUserDefaultSpeakerDeviceId,
  setLoggedUserDefaultVideoDeviceId,
} from "@actions/loggedUser/LoggedUserActions";
import JitsiTrack from "@jitsi/JitsiTrack";
import {
  Col,
  Row,
} from "antd";
import VideoTrack from "@components/audi/VideoTrack/VideoTrack";
import {
  fetchChosenLanguageSpeakers,
  setHasSpeakerVideoTrack,
  setVisitorsSpeakers,
} from "@actions/conference/ConferenceActions";
import { useFirstRender } from "../../CustomHooks/useFirstRender";

const MinimalInterface = (props) => {
  const { location } = props;

  const {
    lang,
    audioDeviceId,
    videoDeviceId,
    speakerDeviceId,
  } = location.query;

  const [room, setRoom] = useState(null);

  const [localTracks, _setLocalTracks] = useState([]); // the local user tracks
  const [speakerVideoTracks, _setSpeakerVideoTracks] = useState([]);

  const localTracksRef = useRef(localTracks);
  const speakerVideoTracksRef = useRef(speakerVideoTracks);
  const dispatch = useDispatch();

  const hasSpeakerVideoTrack = useSelector((state) => state.conferenceReducer.hasSpeakerVideoTrack);
  const visitorsSpeakers = useSelector((state) => state.conferenceReducer.visitorsSpeakers);

  const [isInConference, setIsInConference] = useState(false);
  const [isConnectedToJitsi, setIsConnectedToJitsi] = useState(false);
  const isFirstRender = useFirstRender();

  const [visitorSpeakerVideoTrackMuted, setVisitorSpeakerVideoTrackMuted] = useState(true);

  const setLocalTracks = (data) => {
    localTracksRef.current = data;
    _setLocalTracks(data);
  };

  const setSpeakerVideoTracks = (data) => {
    speakerVideoTracksRef.current = data;
    _setSpeakerVideoTracks(data);
  };

  const messageReceivedHandler = (from, message) => {
    const isTheSenderModerator = checkIfUserIsModeratorByUserId(from);
    const splitMessage = message.split('::');

    if (splitMessage.length >= 2
    && (splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerAdded
      || splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerRemoved
      || splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerAddedWithVideo)
    && isTheSenderModerator) {

      const addedSpeakerAsJitsiParticipantObject = getUserJitsiParticipantObjectByUserDatabaseId(splitMessage[1]);

      dispatch(fetchChosenLanguageSpeakers(lang));

      if (addedSpeakerAsJitsiParticipantObject) {
        const decodedDisplayName = decodeDisplayName(addedSpeakerAsJitsiParticipantObject.getDisplayName());
        const addSpeakerRole = decodedDisplayName.confRole.toUpperCase();
        const addedSpeakerConferenceId = addedSpeakerAsJitsiParticipantObject.getId();

        if (splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerAdded && addSpeakerRole === USER_ROLES.visitor) {
          dispatch(setVisitorsSpeakers([addedSpeakerConferenceId]));
          dispatch(setHasSpeakerVideoTrack(false));
        } else if (splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerAddedWithVideo && addSpeakerRole === USER_ROLES.visitor) {
          dispatch(setVisitorsSpeakers([addedSpeakerConferenceId]));
          dispatch(setHasSpeakerVideoTrack(true));
          JitsiConference.selectParticipants([addedSpeakerConferenceId]);
        } else if (splitMessage[0] === JITSI_SPEAKER_MESSAGES.newSpeakerRemoved && addSpeakerRole === USER_ROLES.visitor) {
          dispatch(setVisitorsSpeakers([]));
          JitsiConference.selectParticipants([]);
          setTimeout(() => {
            dispatch(setHasSpeakerVideoTrack(false));
          }, 200);
        }
      }
    } else if (splitMessage.length === 1 && isTheSenderModerator) {
      if (splitMessage[0] === JITSI_SPEAKER_MESSAGES.removeSpeakerVideo) {
        dispatch(setHasSpeakerVideoTrack(false));
      }
    }
  };

  useEffect(() => {
    let speakerVideoTrack = null;

    if (visitorsSpeakers.length > 0 || hasSpeakerVideoTrack.value) {
      const visitorSpeaker = window.audi.room.getParticipants().find((p) => p.getId() === visitorsSpeakers[0]);

      if (visitorSpeaker) {
        setTimeout(() => {
          const remoteTracks = visitorSpeaker._tracks.filter((tr) => tr.getType() === 'video');

          if (remoteTracks.length > 0 && hasSpeakerVideoTrack.value) {
            speakerVideoTrack = remoteTracks[0];
            setSpeakerVideoTracks(remoteTracks);
            speakerVideoTrack.addEventListener(JITSI_TRACK_EVENTS.trackMuteChanged, (track) => setVisitorSpeakerVideoTrackMuted(track.isMuted()));
            setVisitorSpeakerVideoTrackMuted(speakerVideoTrack.isMuted());
          } else {
            setVisitorSpeakerVideoTrackMuted(true);
          }
        }, 2000);
      }
    } else if ((visitorsSpeakers.length === 0 || !hasSpeakerVideoTrack.value) && !isFirstRender) {
      setVisitorSpeakerVideoTrackMuted(true);
      setSpeakerVideoTracks([]);
    }

  }, [hasSpeakerVideoTrack, visitorsSpeakers.length]);

  useEffect(() => {
    const decodedToken = jwtDecode(sessionStorage.getItem('jitsiToken'));

    setRoom(decodedToken.context.user.slotUid);

    const timeOut = setTimeout(async () => {
      const jitsiConnection = new JitsiConnection(sessionStorage.getItem('jitsiToken'));

      await jitsiConnection.initConnection();
      jitsiConnection.addListener(JITSI_CONNECTION_EVENTS.connectionEstablished,
        () => setIsConnectedToJitsi(true))
        .connect();
    }, 1500);

    /**
     * Making a cleanup function which will be called when the window/tab is closed
     * (when closing the window we send a message to the visitor that the preview has ended)
     */
    const cleanup = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      clearTimeout(timeOut);
      JitsiConnection.disconnect()
        .catch((error) => {
          console.log(error);
        });
    };
  }, []);

  useEffect(() => {
    if (isConnectedToJitsi) {
      const jitsiConference = new JitsiConference(room);

      jitsiConference.initConference();
      jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceJoined, () => setIsInConference(true));
      jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceMessageReceived, messageReceivedHandler);
      jitsiConference.join();
    }
  }, [isConnectedToJitsi]);

    /**
   * Function which we use to set the default 'video', 'audio' devices IDs in redux
   * The default IDs depends on the tracks which Jitsi has made.
   *
   * When jitsi make a video track, it automatically sets a default video device
   * (from all our video devices) for that track,
   * we get that device, get his ID and set it as 'defaultVideoDeviceId' in redux
   * @param tracks
   */
  const setDevicesIds = useCallback((tracks) => {
    for (const track of tracks) {
      if (track.getType() === 'video') {
        dispatch(setLoggedUserDefaultVideoDeviceId(track.deviceId));
      }
      if (track.getType() === 'audio') {
        dispatch(setLoggedUserDefaultAudioDeviceId(track.deviceId));
      }
    }

    if (speakerDeviceId) {
      dispatch(setLoggedUserDefaultSpeakerDeviceId(speakerDeviceId));
    }
  }, [localTracks.current]);

  useEffect(() => {
    if (isConnectedToJitsi) {
      const localTracksOptions = createLocalTracksOptionsWithSpecificDevices(audioDeviceId, videoDeviceId);

      localTracksOptions.constraints = {
        video: {
          aspectRatio: 16 / 9,
        },
      };

      JitsiTrack.createLocalTracks(localTracksOptions)
        .then((result) => {
          setLocalTracks(result);
          setDevicesIds(localTracksRef.current);
        })
        .catch((e) => {
          dispatch(showNotification('error', 'notification.localTrackCreationError'));
          dispatch(hideNotification());
        });
    }
  }, [isConnectedToJitsi]);

  return (
    <section className="ant-layout gx-app-layout">
      <section className="ant-layout">
        <main className="ant-layout-content gx-layout-content gx-container-wrap">
          <div className="gx-main-content-wrapper gx-main-content-wrapper--user">
            <div className="userView userView--desktop">
              <Row style={{ flex: '1' }}>
                <Col
                  span={24}
                  className={
                    `userView__videoAndActionsWrapper
             userView__videoAndActionsWrapper--desktop
             userView__videoAndActionsWrapper--desktop'
            `
                  }
                >
                  {(isConnectedToJitsi && isInConference)
                    ? (
                      <>
                        <div className="userViewVideoTracks userViewVideoTracks--desktop userViewVideoTracks--desktop--fullscreen">
                          {
                            visitorsSpeakers.length <= 0 || (visitorSpeakerVideoTrackMuted && visitorsSpeakers.length > 0) || (visitorsSpeakers.length > 0 && speakerVideoTracks.length < 0)
                              ? (
                                <VideoTrack
                                  tracks={localTracks}
                                  classesArr={['userViewVideoTracks__mainVideoTag',
                                    'userViewVideoTracks__mainVideoTag--desktop',
                                    'userViewVideoTracks__mainVideoTag--desktop--chrome',
                                  ]}
                                  width={100}
                                />
                              )
                              : null
                          }
                          {
                            speakerVideoTracks.length > 0 && !visitorSpeakerVideoTrackMuted && visitorsSpeakers.length > 0
                              ? (
                                <VideoTrack
                                  tracks={speakerVideoTracks}
                                  classesArr={['userViewVideoTracks__mainVideoTag',
                                    'userViewVideoTracks__mainVideoTag--desktop',
                                    'userViewVideoTracks__mainVideoTag--desktop--chrome',
                                  ]}
                                  width={100}
                                />
                              )
                              : null
                          }
                        </div>
                      </>
                    )
                    : null
                  }
                </Col>
              </Row>
            </div>
          </div>
        </main>
      </section>
    </section>);
};

MinimalInterface.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hasSpeakerVideoTrack: PropTypes.bool,
  visitorsSpeakers: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = ({ router, common }) => ({
  location: router.location,
  error: common.error,
});

export default connect(mapStateToProps, null)(injectIntl(MinimalInterface));
