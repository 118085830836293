import {HTTP_ERROR_MESSAGES} from '@constants/Settings';
import {HTTPResponseError, HTTPResponseWarning, ServerError} from '@constants/ServerExceptions';

const defaultPostHeaders = [
  {
    name: 'Content-Type',
    value: 'application/json',
  },
];

const RestManager = {
  request: async (url, method = 'GET', headers = [], isEssential = true) => {
    const options = {
      method,
      headers: RestManager.getHeaders(headers),
      mode: 'cors',
      cache: 'default',
      credentials:"include"
    };
    const json = await fetch(url, options)
      .then(response => {
        RestManager.checkResponseStatus(response, isEssential);
        return response.json()
      });

    return json;
  },

  requestWithoutQueryParams: async (url, method, bodyData = {}, headers = [], isEssential = true) => {
    const options = {
      method,
      headers: RestManager.getHeaders([...defaultPostHeaders, ...headers]),
      mode: 'cors',
      cache: 'default',
      //body: JSON.stringify(bodyData),
    };

    if (method !== 'GET') {
      options.body = JSON.stringify(bodyData);
    }

    const finalUrl = url;
    const json = await fetch(finalUrl, options)
      .then((response) => {
        RestManager.checkResponseStatus(response, isEssential);
        if (method === 'DELETE') {
          return { status: response.status };
        }
        return response.json();
      });

    return json;
  },

  getHeaders(additionalHeaders) {
    const headers = new Headers();
    const accessToken = sessionStorage.getItem('token');
    const hasAuthorizationHeaderInTheAdditionalHeaders = additionalHeaders.find((h) => h.name === 'Authorization');

    if (accessToken) {
      /**
       * Here we check if we have the 'main' token, IF so we use it
       * as auth header, IF NOT we will use the 'startToken' (the one
       * we get on the login process)
       */
      headers.append('Authorization', `Bearer ${accessToken}`);
    } else if (!hasAuthorizationHeaderInTheAdditionalHeaders) {
      headers.append('Authorization', `Bearer ${sessionStorage.getItem('startToken')}`);
    }

    if (hasAuthorizationHeaderInTheAdditionalHeaders) {
      headers.delete('Authorization');
    }

    additionalHeaders.forEach((item) => {
      headers.append(item.name, item.value);
    });

    return headers;
  },

  checkResponseStatus(response, isEssential = true) {
    if (response.status === 401) {
      RestManager.handleErrorResponse(response, isEssential);
    } else if (response.status < 200 || response.status > 204) {
      RestManager.handleErrorResponse(response, isEssential);
    }
  },

  handleErrorResponse(response, isEssential) {
    let error;

    switch (response.status) {
      case 400:
      case 403:
      case 404:
      case 409:
        if (response.status === 403) {
          const event = new Event('EXPIRED_TOKEN');
          window.dispatchEvent(event);
        }

        if (isEssential) {
          error = new HTTPResponseError(
            HTTP_ERROR_MESSAGES[response.status],
            response.status,
          );
        } else {
          error = new HTTPResponseWarning(
            HTTP_ERROR_MESSAGES[response.status],
            response.status,
          );
        }
        break;
      case 500:
      case 502:
      case 503:
      case 504:
        error = new ServerError(
          HTTP_ERROR_MESSAGES[response.status],
        );
        break;
      default:
        error = new HTTPResponseError(
          response.message,
          response.status,
        );
    }

    throw error;
  }
};

export default RestManager;
