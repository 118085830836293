import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ActionsTop from './ModerationActions/ActionsTop';
import DataTable from './Table/DataTable';
import ActionsBottom from './ModerationActions/ActionsBottom';
import { useNotificationHook } from '../../CustomHooks/useNotificationHook';
import { useConferenceCleanupHook } from '../../CustomHooks/useConferenceCleanupHook';

const Participants = (props) => {
  const { intl } = props;

  useNotificationHook(intl);

  useConferenceCleanupHook();

  return (
    <>
      <ActionsTop />
      <DataTable />
      <ActionsBottom />
    </>
  );
};

Participants.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default (injectIntl(withRouter(Participants)));
