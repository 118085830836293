import JitsiConference from '@jitsi/JitsiConference';
import {useDispatch} from 'react-redux';
import {
  setHasMutedLocalAudioTrack,
  setHasMutedLocalVideoTrack,
  setHasRaisedHand
} from '@actions/loggedUser/LoggedUserActions';
import {useCallback} from 'react';

/**
 * Simple hook (function) from where we return the functions necessary to
 * 1. raise hand,
 * 2. to mute microphone
 * 3. to mute camera
 * 4. and the tooltips for the icons
 *
 *
 * @param intl
 * @param hasRaisedHand - bool from redux
 * @param hasMutedLocalAudioTrack - bool from redux
 * @param hasMutedLocalVideoTrack - bool from redux
 * @param hasCameraDevice - redux value
 * @param hasMicrophoneDevice - redux value
 *
 * @returns {{
 * toggleRaiseHand: (function()),
 * toggleCamera: (function()),
 * toggleMicrophone: (function()),
 * tooltips: object,
 * raiseHandTooltip: (function())
 * }}
 */
export function useDevicesButtonsActionsHook(
  intl,
  hasRaisedHand,
  hasMutedLocalAudioTrack,
  hasMutedLocalVideoTrack,
  hasCameraDevice,
  hasMicrophoneDevice,
) {
  const dispatch = useDispatch();

  /**
   * Object with difference tooltips for raiseHand, camera, microphone
   */
  const tooltips = {
    raisedHand: intl.formatMessage({ id: 'participants.table.raisedHand' }),
    handNotRaised: intl.formatMessage({ id: 'participants.table.raisedHandNot' }),
    raisedHandDisabled: intl.formatMessage({ id: 'userView.raiseHand.disabled' }),
    cameraOn: intl.formatMessage({ id: 'participants.table.cameraON' }),
    cameraOff: intl.formatMessage({ id: 'participants.table.cameraOFF' }),
    cameraMissing: intl.formatMessage({ id: 'userView.camera.missing' }),
    micOn: intl.formatMessage({ id: 'participants.table.microphoneON' }),
    micOff: intl.formatMessage({ id: 'participants.table.microphoneOFF' }),
    micMissing: intl.formatMessage({ id: 'userView.microphone.missing' }),
    showChat: intl.formatMessage({ id: 'userView.chatIcon.tooltip' }),
  };

  /**
   * Function which we use in all our components with 'Raise Hand' icon button,
   * which we toggle the raise hand
   */
  const toggleRaiseHand = useCallback(() => {
    JitsiConference.raiseHand(!hasRaisedHand);
    dispatch(setHasRaisedHand(!hasRaisedHand));
  }, [hasMutedLocalAudioTrack, hasMicrophoneDevice, hasRaisedHand]);

  /**
   * Function which we use in all our components with 'Microphone icon button,
   * which we toggle the microphone
   */
  const toggleMicrophone = useCallback(() => {
    if (hasMicrophoneDevice) {
      if (!hasMutedLocalAudioTrack && hasRaisedHand) {
        JitsiConference.raiseHand(false);
        dispatch(setHasRaisedHand(false));
      }
      dispatch(setHasMutedLocalAudioTrack(!hasMutedLocalAudioTrack));
    }
  }, [hasMicrophoneDevice, hasMutedLocalAudioTrack, hasRaisedHand]);

  /**
   * Function which we use in all our components with 'Camera icon button,
   * which we toggle the camera
   */
  const toggleCamera = useCallback(() => {
    if (hasCameraDevice) {
      dispatch(setHasMutedLocalVideoTrack(!hasMutedLocalVideoTrack));
    }
  }, [hasCameraDevice, hasMutedLocalVideoTrack]);

  /**
   * Function which we use to return the proper tooltip for the 'Raise Hand' icon button
   * @returns {*}
   */
  const raiseHandTooltip = useCallback(() => {
    if (hasRaisedHand) {
      return tooltips.raisedHand;
    }

    return tooltips.handNotRaised;
  }, [hasMutedLocalAudioTrack, hasMicrophoneDevice, hasRaisedHand]);

  /**
   * Function which we use to return the proper tooltip for the 'Camera' icon button
   * @returns {*}
   */
  const cameraTooltip = useCallback(() => {
    if (!hasCameraDevice) {
      return tooltips.cameraMissing;
    }

    if (hasMutedLocalVideoTrack) {
      return tooltips.cameraOff;
    }

    return tooltips.cameraOn;
  }, [hasCameraDevice, hasMutedLocalVideoTrack]);

  /**
   * Function which we use to return the proper tooltip for the 'Microphone' icon button
   * @returns {*}
   */
  const microphoneTooltip = useCallback(() => {
    if (!hasMicrophoneDevice) {
      return tooltips.micMissing;
    }

    if (hasMutedLocalAudioTrack) {
      return tooltips.micOff;
    }

    return tooltips.micOn;
  }, [hasMicrophoneDevice, hasMutedLocalAudioTrack]);

  return {
    toggleRaiseHand,
    toggleMicrophone,
    toggleCamera,
    raiseHandTooltip,
    cameraTooltip,
    microphoneTooltip,
    tooltips,
  };
}
