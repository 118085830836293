import {
  JITSI_SPEAKER_MESSAGES,
  MUC_PROPERTIES,
  USER_ROLES,
  SUPPORTED_STREAM_TYPES,
} from '@constants/Settings';
import _ from 'lodash';
import {
  LoggerHeaders,
  LoggerMessage,
  LoggerType,
  LogMessage,
} from './LoggerHelper';

export const isSafariBrowser = (browserName) => {
  return browserName.toLowerCase() === 'ios' || browserName.toLowerCase() === 'safari';
};

/**
 * Little 'hack' to remove the bug on MAC when we go to fullscreen
 * a white bar shows up on the bottom of the page and it gets removed for example if we toggle the chat
 *
 * with that function we do exactly that:
 * We set the fullscreen value (true, false) - that will hide/show the chat
 * After 700ms we show/hide the chat again and then immediately switch back to previous state
 * @param fullscreenValue
 * @param onSetFullscreen
 */
export const toggleFullscreenHelperSafari = (fullscreenValue, onSetFullscreen) => {
  onSetFullscreen(fullscreenValue);

  setTimeout(() => {
    onSetFullscreen(!fullscreenValue);
    onSetFullscreen(fullscreenValue);
  }, 700);
};

export const toggleFullscreenHelper = (isOnFullscreen, onSetFullscreen, withBrowserFullscreen = false, userBrowserName = null) => {
  if (withBrowserFullscreen) {
    const doc = window.document;
    const docEl = doc.documentElement;
    const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (requestFullScreen && cancelFullScreen) {
      if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        requestFullScreen.call(docEl);

        if (userBrowserName && isSafariBrowser(userBrowserName)) {
          toggleFullscreenHelperSafari(true, onSetFullscreen);
        } else {
          onSetFullscreen(true);
        }
      } else {
        cancelFullScreen.call(doc);
        if (userBrowserName && isSafariBrowser(userBrowserName)) {
          toggleFullscreenHelperSafari(false, onSetFullscreen);
        } else {
          onSetFullscreen(false);
        }
      }
    } else {
      onSetFullscreen(isOnFullscreen);
    }
  } else {
    onSetFullscreen(isOnFullscreen);
  }
};

/**
 * Helper function to set the liveAudio, liveAV properties for a participant in the participant table
 *
 * (used on the moderator side when track is ADDED)
 *
 * @param {Array<Object>} users - from the usersReducer
 * @param {Object} decodedDisplayName
 * @param userIndex
 * @param {Object} track
 */
export const participantConfPropsOnTrackAdded = (users, decodedDisplayName, userIndex, track) => {
  if (userIndex === -1) {
    LogMessage(LoggerType.ERROR, LoggerHeaders.USER_TRACK_ADDED, LoggerMessage.userIndexWasNotFoundInTheUsersList(decodedDisplayName.displayName));
  }

  if (track.getType() === 'video') {
    if (decodedDisplayName.confRole === USER_ROLES.stage) {
      return {
        camera: !track.muted,
        liveAV: true,
      };
    }

    return {
      camera: !track.muted,
      liveAV: users[userIndex]?.confProps.liveAudio || false,
    };
  }

  return {
    microphone: !track.muted,
    liveAudio: true,
    liveAV: users[userIndex]?.confProps.liveAV || false,
  };
};

/**
 * Helper function to set the liveAudio, liveAV properties for a participant in the participant table
 *
 * (used on the moderator side when track is REMOVED)
 *
 * @param {Array<Object>} users - from the usersReducer
 * @param {Object} decodedDisplayName
 * @param userIndex
 * @param {Object} track
 */
export const participantConfPropsOnTrackRemoved = (users, decodedDisplayName, userIndex, track) => {
  if (userIndex === -1) {
    LogMessage(LoggerType.ERROR, LoggerHeaders.USER_TRACK_REMOVED, LoggerMessage.userIndexWasNotFoundInTheUsersList(decodedDisplayName.displayName));
  }

  if (track.getType() === 'audio') {
    return {
      liveAudio: false,
      liveAV: users[userIndex]?.confProps.liveAV || false,
    };
  } else if (track.getType() === 'video') {
    return {
      liveAV: false,
    };
  }
};

/**
 * Get the array indexes for particular user from
 * the usersReducer.users and userReducers.participantsPage
 *
 * by the user database id
 *
 * @param decodedDisplayName
 * @param users
 * @param tableData
 * @returns {{tableDataIndex: number, userIndex: number}}
 */
export const getUserIndexFromUsersAndTableDataByUserDatabaseId = (decodedDisplayName, users, tableData) => {
  const tableDataIndex = tableData ? _.findIndex(tableData, (p) => p.user_id === parseInt(decodedDisplayName.databaseId), 0) : -1;
  const userIndex = users ? _.findIndex(users, (p) => p.user_id === parseInt(decodedDisplayName.databaseId), 0) : -1;

  return { userIndex, tableDataIndex };
};

/**
 * Get the array indexes for particular user from
 * the usersReducer.users and userReducers.participantsPage
 *
 * by the user UID
 * @param userUid
 * @param users
 * @param tableData
 * @returns {{tableDataIndex: number, userIndex: number}}
 */
export const getUserIndexFromUsersAndTableDataByUserUid = (userUid, users = null, tableData = null) => {
  const tableDataIndex = tableData ? _.findIndex(tableData, (p) => p.uid === userUid, 0) : -1;
  const userIndex = users ? _.findIndex(users, (p) => p.uid === userUid, 0) : -1;

  return { userIndex, tableDataIndex };
};

/**
 * Get the array indexes for particular user from
 * the usersReducer.users and userReducers.participantsPage
 *
 * by the user conference id
 * @param userId
 * @param users
 * @param tableData
 * @returns {{tableDataIndex: number, userIndex: number}}
 */
export const getUserIndexFromUsersAndTableDataByUserConfId = (userId, users = null, tableData = null) => {
  const tableDataIndex = tableData ? _.findIndex(tableData, (p) => p.confProps.userConfId === userId, 0) : -1;
  const userIndex = users ? _.findIndex(users, (p) => p.confProps.userConfId === userId, 0) : -1;

  return { userIndex, tableDataIndex };
};

/**
 *
 * @param {Object} state
 * @returns {{tableData: (*), users: (*)}}
 */
export const cloneDeepUsersAndTableData = (state) => {
  const tableData = _.cloneDeep(state.participantsPage.tableData);
  const users = _.cloneDeep(state.users);

  return { tableData, users };
};

/**
 * Helper function to construct the nickname which every visitor or moderator will
 * use when the join their MUC/s
 *
 * @param role
 * @param uniqueId
 * @returns {string} - with structure `{user_role}@_@${unique_id}`}
 */
export const constructTheNicknameForMucRoom = (role, uniqueId) => {
  if (role === USER_ROLES.moderator) {
    return `${USER_ROLES.moderator}@_@${uniqueId}`;
  }

  return `${USER_ROLES.visitor}@_@${uniqueId}`;
};

/**
 * Helper function to construct the name or MUC room which will be joined
 * @param slotUid
 * @param userUid
 * @returns {string} - with structure `{slotUid}/{userUid}@conference.{JITSI_DOMAIN}`
 */
/* eslint-disable no-undef */
export const constructMucRoomName = (slotUid, userUid) => `${slotUid}${MUC_PROPERTIES.MUC_ROOM_NAME_SPLITTER}${userUid}@conference.${JitsiDomains.domain}`;

/**
 * Helper function to add an ID in the moderatorsIds array in the session storage
 * @param moderatorId
 */
export const addModeratorIdToStorage = (moderatorId) => {
  const moderatorsIds = sessionStorage.getItem('moderatorsIds');
  const moderatorIdsToJSON = moderatorsIds ? JSON.parse(moderatorsIds) : [];
  moderatorIdsToJSON.push(moderatorId);

  sessionStorage.setItem('moderatorsIds', JSON.stringify(moderatorIdsToJSON));
};

/**
 * Helper function to get all moderators ids from the session storage as array
 * @returns {any|*[]}
 */
export const getModeratorsIdsFromStorage = () => {
  const moderatorsIds = sessionStorage.getItem('moderatorsIds');

  return moderatorsIds ? JSON.parse(moderatorsIds) : [];
};

/**
 * Helper function to check if a message is one of the public system messages
 *
 * (system message which is send to all on the JITSI chat, for example
 * the message when there is a visitor speaker added in the conference)
 * @param message
 * @returns {string|boolean}
 */
export const isPublicSystemMessage = (message) => {
  const messageSplit = message.split('::');
  const systemMessages = [
    JITSI_SPEAKER_MESSAGES.newSpeakerAdded,
    JITSI_SPEAKER_MESSAGES.newSpeakerRemoved,
    JITSI_SPEAKER_MESSAGES.newSpeakerAddedWithVideo,
    JITSI_SPEAKER_MESSAGES.removeSpeakerVideo,
  ];

  if (messageSplit.length >= 2 && systemMessages.find((m) => m === messageSplit[0])) {
    return true;
  }

  return systemMessages.find((m) => m === message);
};


export const getPartsOfMucRoomName = (name) => {
  const parts = name.split(MUC_PROPERTIES.MUC_ROOM_NAME_SPLITTER);

  if (parts.length === 2) {
    const slotUid = parts[0];
    const userUid = parts[1];

    return { slotUid, userUid };
  }

  return null;
};

/**
 * Helper function to check if the stream type is supported by the browser
 *
 * @param  {string} streamType
 *
 * @return {boolean}
 */
export const isStreamTypeSupportedByBrowser = (streamType) => {
  if (streamType === SUPPORTED_STREAM_TYPES.WEBRTC) {
    const PeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    const getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.msGetUserMedia || navigator.mozGetUserMedia;

    return !!PeerConnection && !!getUserMedia;

  } if (streamType === SUPPORTED_STREAM_TYPES.HLS) {
    const video = document.createElement('video');

    return Boolean(video.canPlayType('video/mp4; codecs="avc1.42E01E,mp4a.40.2"') || video.canPlayType('audio/mpegurl'));
  }

  return false;
};

/**
 * Helper function for converting conversation object to array
 *
 * @param  {object/array} chat
 *
 * @return {array} array
 */
export const chatObjectToArray = (chat) => {
  if (_.isArray(chat)) {
    return chat;
  }

  const array = [];
  Object.entries(chat).forEach((entry) => {
    const [key, value] = entry;
    const temp = {
      messageId: key,
      message: value.message,
      read: value.read,
      sentAt: value.sentAt,
      type: value.type,
      userId: value.userId,
    };
    array.push(temp);
  });
  return array;
};

/**
 * Helper function for converting conversation array to object
 *
 * @param  {array} chat
 *
 * @return {object} object
 */
export const chatArrayToObject = (chat) => {
  const object = {};
  chat.forEach((el) => {
    object[el.messageId] = {
      message: el.message,
      read: el.read,
      sentAt: el.sentAt,
      type: el.type,
      userId: el.userId,
    };
  });
  return object;
};