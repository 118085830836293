import { eventChannel } from 'redux-saga';
import { CONFERENCE_LISTENERS_CASES_TYPES, JITSI_CONFERENCE_EVENTS } from '@constants/Settings';
import { put } from 'redux-saga/effects';
import Moment from 'moment';
import { changingLocalTrack, changingLocalTrackSuccess } from '@actions/loggedUser/LoggedUserActions';
import {
  conferenceJoinFailGeneratorCommon,
  conferenceJoinSuccessGeneratorCommon,
  conferenceLeaveGeneratorCommon,
  conferenceMessageReceivedGeneratorCommon,
} from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/CommonCaseGenerators';
import {
  conferencePrivateMessageReceivedGeneratorStage,
  conferenceTrackAddedGeneratorStage,
  conferenceTrackRemovedGeneratorStage,
} from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/StageCaseGenerators';
import { LoggerType, LogMessage } from '@src/CustomHooks/LoggerHelper';

/**
 * @description The EventChannel where we put all conference listeners for the STAGE ROLE
 * @param jitsiConference
 * @returns {any}
 */
export const stageChannel = (jitsiConference) => eventChannel((emitter) => {
  const joinSuccess = () => {
    LogMessage(LoggerType.DEBUG, '[TEST]', 'stageChannel: joinSuccess');
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.joinSuccess, data: [] });
  };

  const joinFailed = () => {
    LogMessage(LoggerType.DEBUG, '[TEST]', 'stageChannel: joinFailed', jitsiConference);
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.joinFail, data: [] });
  };

  const leave = () => {
    LogMessage(LoggerType.DEBUG, '[TEST]', 'stageChannel: leave', jitsiConference);
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.leave, data: [] });
  };

  const trackRemoved = (track) => {
    LogMessage(LoggerType.DEBUG, '[TEST]', 'stageChannel: trackRemoved', jitsiConference, track);
    if (track.isLocal()) {
      emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackLocalRemoved, data: [] });
    } else {
      emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackRemoved, data: [track] });
    }
  };

  const trackAdded = (track) => {
    LogMessage(LoggerType.DEBUG, '[TEST]', 'stageChannel: trackAdded', jitsiConference, track);
    if (track.isLocal()) {
      emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackLocalAdded, data: [] });
    } else {
      emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackAdded, data: [track] });
    }
  };

  /**
   * @description Listens for private received message
   * @param {Number} from
   * @param {String} message
   * @returns {void}
   */
  const privateMessageReceived = (from, message) => {
    const adaptMessage = {
      message,
      type: 'received',
      userId: from,
      sentAt: Moment().format('hh:mm:ss A'),
      read: false,
    };
    emitter(
      { type: CONFERENCE_LISTENERS_CASES_TYPES.privateMessageReceived, data: [adaptMessage] },
    );
  };

  const messageReceived = (from, message) => {
    const adaptMessage = {
      message,
      type: 'received',
      userId: from,
      sentAt: Moment().format('hh:mm:ss A'),
      read: false,
    };

    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.messageReceived, data: [adaptMessage] });
  };

  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceJoined, joinSuccess);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceJoinFailed, joinFailed);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceLeft, leave);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceTrackRemoved, trackRemoved);
  jitsiConference.addListener(
    JITSI_CONFERENCE_EVENTS.conferencePrivateMessageReceived, privateMessageReceived,
  );
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceMessageReceived, messageReceived);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceTrackAdded, trackAdded);

  return () => {
    LogMessage(LoggerType.DEBUG, '[TEST]', 'stageChannel: END', jitsiConference);
    jitsiConference.removeConferenceListeners();
  };
});

/**
 * @description Generator function with SWITCH inside, which depending on the
 * conference event which was "caught" dispatches actions
 * @description Explanation:
 * (On the channel above, for the successful conference joining event,
 * we are executing "joinSuccess" function listener,
 * inside that listener we are emitting object with "type: 'JoinSuccess'" and "data: []"
 * in the switch statement below for the 'JoinSuccess' case we are dispatching two actions)
 * @param channelResult
 * @param jitsiConference
 * @returns {any}
 */
export function* stageSwitch(channelResult, jitsiConference) {
  switch (channelResult.type) {
    case CONFERENCE_LISTENERS_CASES_TYPES.joinSuccess:
      yield conferenceJoinSuccessGeneratorCommon();
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.joinFail:
      yield conferenceJoinFailGeneratorCommon();
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.leave:
      yield conferenceLeaveGeneratorCommon(jitsiConference);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackLocalRemoved:
      yield put(changingLocalTrack());
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackLocalAdded:
      yield put(changingLocalTrackSuccess());
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.privateMessageReceived:
      yield conferencePrivateMessageReceivedGeneratorStage(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.messageReceived:
      yield conferenceMessageReceivedGeneratorCommon(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackAdded:
      yield conferenceTrackAddedGeneratorStage(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackRemoved:
      yield conferenceTrackRemovedGeneratorStage(channelResult.data[0]);
      break;
    default:
      break;
  }
}
