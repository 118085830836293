import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import SpeakersTracks from '@routes/UserView/SpeakersTracks';
import { Col, Row, Button } from 'antd';
import DevicesSelectors from '@components/audi/DevicesSelectors/DevicesSelectors';
import VideoTrack from '@components/audi/VideoTrack/VideoTrack';
import { useNotificationHook } from '../../CustomHooks/useNotificationHook';
import { useConferenceCleanupHook } from '../../CustomHooks/useConferenceCleanupHook';
import { useLocalTracksInConferenceHook } from '../../CustomHooks/useLocalTracksInConferenceHook';
import { useHasMutedLocalAudioTrackHook } from '../../CustomHooks/useHasMutedLocalAudioTrackHook';
import { useHasMutedLocalVideoTrackHook } from '../../CustomHooks/useHasMutedLocalVideoTrackHook';
import {openMinimalInterfaceWindow} from "@jitsi/helper";

const Presenter = (props) => {
  const {
    intl,
    isConnected,
    isInConference,
    loggedUserLang,
    loggedUserDatabaseId,
    loggedUserDefaultAudioId,
    loggedUserDefaultVideoId,
    loggedUserDefaultSpeakerId,
  } = props;

  const { localTracks } = useLocalTracksInConferenceHook();

  useNotificationHook(intl);

  useConferenceCleanupHook();

  useHasMutedLocalAudioTrackHook(localTracks);

  useHasMutedLocalVideoTrackHook(localTracks);

  return (
    <div>
      {(isConnected && isInConference)
        ? (
          <Row justify="center" align="middle" className="presenterView">
            <Col
              className="presenterView__localVideoTrackWrapper"
              xs={24}
              sm={24}
              md={16}
            >
              <VideoTrack tracks={localTracks} classesArr={[]} width={70} />
            </Col>
            <Col
              className="presenterView__deviceSelectorsWrapper"
              xs={24}
              sm={24}
              md={16}
              lg={12}
            >
              <DevicesSelectors
                withVideoDeviceSelect
                withAudioDeviceSelect
                withSpeakersDeviceSelect
                withVideoDeviceMuteButton
                withAudioDeviceMuteButton
              />
            </Col>
            <SpeakersTracks />
            <Col xs={24} style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                shape="round"
                size="medium"
                onClick={() => openMinimalInterfaceWindow(
                  `${window.location.origin}/minimal-interface?lang=${loggedUserLang}&skipDatabaseId=${loggedUserDatabaseId}&audioDeviceId=${loggedUserDefaultAudioId}&videoDeviceId=${loggedUserDefaultVideoId}&speakerDeviceId=${loggedUserDefaultSpeakerId}`,
                  window,
                  1280,
                  720,
                )}
                style={{ marginTop: '50px', lineHeight: '20px' }}
              >
                { intl.formatMessage({ id: 'minimalInterface' }) }
              </Button>
            </Col>
          </Row>
        )
        : null}
    </div>
  );
};

Presenter.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isConnected: PropTypes.bool.isRequired,
  isInConference: PropTypes.bool.isRequired,
  loggedUserLang: PropTypes.string.isRequired,
  loggedUserDatabaseId: PropTypes.number,
  loggedUserDefaultAudioId: PropTypes.string,
  loggedUserDefaultVideoId: PropTypes.string,
  loggedUserDefaultSpeakerId: PropTypes.string,
};

const mapStateToProps = ({ loggedUser }) => ({
  isConnected: loggedUser.isConnected,
  isInConference: loggedUser.isInConference,
  loggedUserLang: loggedUser.lang,
  loggedUserDatabaseId: loggedUser.databaseId,
  loggedUserDefaultAudioId: loggedUser.defaultAudioId,
  loggedUserDefaultVideoId: loggedUser.defaultVideoId,
  loggedUserDefaultSpeakerId: loggedUser.defaultSpeakerId,
});

export default connect(mapStateToProps, null)(injectIntl(React.memo(Presenter)));
