import {
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  FETCH_ALL_EVENTS_REQUEST,
  FETCH_ALL_EVENTS_SUCCESS,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  SET_SELECTED_EVENT,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_REQUEST_SUCCESS,
} from '@constants/ActionTypes';

/**
 * @description Fetch all events list request
 * @returns {Object}
 */
export const onFetchEvents = () => {
  return {
    type: FETCH_ALL_EVENTS_REQUEST,
  };
};

/**
 * @description Fetch events list success
 * @param {Object} data
 */
export const onFetchEventsSuccess = (data) => {
  return {
    type: FETCH_ALL_EVENTS_SUCCESS,
    payload: data,
  };
};

/**
 * @description Fetch single event request
 * @param {Number} eventId
 * @returns {Object}
 */
export const onFetchEvent = (eventId) => {
  return {
    type: FETCH_EVENT_REQUEST,
    eventId,
  };
};

/**
 * @description Fetch single event success
 * @param {Object} payload
 * @returns {Object}
 */
export const onFetchEventSuccess = (payload) => {
  return {
    type: FETCH_EVENT_SUCCESS,
    payload,
  };
};

export const onUpdateEvent = (eventId, eventData) => ({
  type: UPDATE_EVENT_REQUEST,
  eventId,
  eventData,
});

export const onUpdateEventSuccess = () => ({
  type: UPDATE_EVENT_REQUEST_SUCCESS,
});

export const addEvent = (eventData) => ({
  type: ADD_EVENT_REQUEST,
  eventData,
});

export const addEventSuccess = () => ({
  type: ADD_EVENT_SUCCESS,
});

export const setSelectedEvent = (eventId) => ({
  type: SET_SELECTED_EVENT,
  eventId,
});
