import {
  CHANGE_LANGUAGE,
  CHANGING_LOCAL_TRACK,
  CHANGING_LOCAL_TRACK_FAIL,
  CHANGING_LOCAL_TRACK_SUCCESS,
  CHAT_SOUND_NOTIFICATION,
  FETCH_LOGGED_USER_DATA,
  FETCH_LOGGED_USER_DATA_SUCCESS,
  FETCH_LOGGED_USER_JOIN_DATA_SUCCESS,
  FETCH_LOGGED_USER_SLOT_DATA_SUCCESS,
  SET_BEING_PREVIEWED,
  SET_BEING_SPEAKER,
  SET_HAS_CAMERA_DEVICE,
  SET_HAS_MICROPHONE_DEVICE,
  SET_HAS_MUTED_LOCAL_AUDIO_TRACK,
  SET_HAS_MUTED_LOCAL_VIDEO_TRACK,
  SET_HAS_RAISED_HAND,
  SET_IS_ON_FULLSCREEN,
  SET_LOGGED_USER_BROWSER,
  SET_LOGGED_USER_CONVERSATION,
  SET_LOGGED_USER_DEFAULT_AUDIO_DEVICE_ID,
  SET_LOGGED_USER_DEFAULT_SPEAKER_DEVICE_ID,
  SET_LOGGED_USER_DEFAULT_VIDEO_DEVICE_ID,
  SET_LOGGED_USER_DEVICE_TYPE,
  SET_LOGGED_USER_DEVICES,
  SET_LOGGED_USER_IS_CONNECTED,
  SET_LOGGED_USER_IS_IN_CONFERENCE,
  SET_LOGGED_USER_IS_KICKED,
  UPDATE_MESSAGE_AS_READ,
  TOGGLE_STREAM_TYPE,
} from '@constants/ActionTypes';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import {
  DEVICES_TYPES,
  USER_ROLES,
  SUPPORTED_STREAM_TYPES,
} from '@constants/Settings';
import {
  getNameInitials,
  startAudioVideoToBool,
} from '@jitsi/helper';
import { addModeratorIdToStorage } from '../../../CustomHooks/HelperFuncs';
import JitsiConference from '@jitsi/JitsiConference';

const INIT_STATE = {
  databaseId: null,
  displayName: null,
  userGroup: null,
  uid: null,
  last_update: null,
  disabled: false,
  company: null,
  last_login: null,
  hasMicrophoneDevice: false,
  hasCameraDevice: false,
  browserName: null,
  userRole: null,
  roomName: null,
  lang: null,
  initials: null,
  encodedDisplayName: null,
  isConnected: false,
  isInConference: false,
  devices: {},
  conversation: {},
  defaultVideoId: undefined,
  defaultAudioId: undefined,
  defaultSpeakerId: undefined,
  changingLocalTrack: false,
  deviceType: DEVICES_TYPES.desktop,
  isOnFullScreen: false,
  hasRaisedHand: false,
  hasMutedLocalVideoTrack: false,
  hasMutedLocalAudioTrack: false,
  beingPreviewed: {
    beingPreviewed: null,
  },
  beingSpeaker: {
    withAudio: false,
    withVideo: false,
  },
  chatSoundNotification: false,
  slotMedia: null,
  isKicked: false,
};

const onFetchLoggedUserDataSuccess = (state, userData) => {
  const newState = _.cloneDeep(state);

  newState.databaseId = userData.user_id;
  newState.displayName = `${userData.first_name} ${userData.last_name}`;
  newState.user_group = userData.user_group;
  newState.uid = userData.uid;
  newState.last_update = userData.last_update;
  newState.lang = sessionStorage.getItem('userLang') ? sessionStorage.getItem('userLang') : userData.language;
  newState.disabled = userData.disabled;
  newState.company = userData.company;
  newState.last_login = userData.last_login;
  newState.initials = getNameInitials(newState.displayName);

  if (sessionStorage.getItem('hasMutedLocalAudioTrack')) {
    newState.hasMutedLocalAudioTrack = startAudioVideoToBool(sessionStorage.getItem('hasMutedLocalAudioTrack'));
  }
  if (sessionStorage.getItem('hasMutedLocalVideoTrack')) {
    newState.hasMutedLocalVideoTrack = startAudioVideoToBool(sessionStorage.getItem('hasMutedLocalVideoTrack'));
  }
  if (window.innerWidth <= 990 && state.deviceType !== DEVICES_TYPES.mobile) {
    newState.deviceType = DEVICES_TYPES.mobile;
  } else if (window.innerWidth > 990 && state.deviceType !== DEVICES_TYPES.desktop) {
    newState.deviceType = DEVICES_TYPES.desktop;
  }

  return { ...state, ...newState };
};

const onFetchLoggedUserJoinDataSuccess = (state, joinData) => {
  const newState = _.cloneDeep(state);
  newState.slotMedia = joinData.slotMedia;
  newState.userRole = joinData.user_role.toUpperCase();

  if (newState.userRole !== USER_ROLES.visitor) {
    newState.hasMutedLocalAudioTrack = false;
    newState.hasMutedLocalVideoTrack = false;
  } else {
    if (!sessionStorage.getItem('hasMutedLocalAudioTrack')) {
      sessionStorage.setItem('hasMutedLocalAudioTrack', 'true');
      newState.hasMutedLocalAudioTrack = true;
    }
    if (!sessionStorage.getItem('hasMutedLocalVideoTrack')) {
      sessionStorage.setItem('hasMutedLocalVideoTrack', 'true');
      newState.hasMutedLocalVideoTrack = true;
    }
  }

  if (!sessionStorage.getItem('userRole')) {
    sessionStorage.setItem('userRole', joinData.user_role.toUpperCase());
  }

  const decodedTokenData = jwtDecode(joinData.jitsi_token || joinData.token);
  newState.encodedDisplayName = decodedTokenData.context.user.name;

  newState.roomName = decodedTokenData.context.user.slotUid;

  if (joinData.user_role === USER_ROLES.translator) {
    newState.beingSpeaker.withAudio = true;
    newState.beingSpeaker.withVideo = false;
  } else if (joinData.user_role === USER_ROLES.stage) {
    newState.beingSpeaker.withAudio = true;
    newState.beingSpeaker.withVideo = true;
  }

  return { ...state, ...newState };
};

const onFetchLoggedUserSlotDataSuccess = (state, slotData) => {
  const newState = _.cloneDeep(state);

  newState.streamTypes = slotData.stream_types;

  return { ...state, ...newState };
}

const onSetHasMutedLocalTrack = (state, hasMutedLocalTrackValue, trackType) => {
  const newState = _.cloneDeep(state);

  if (trackType === 'video') {
    newState.hasMutedLocalVideoTrack = hasMutedLocalTrackValue;
    sessionStorage.setItem('hasMutedLocalVideoTrack', hasMutedLocalTrackValue.toString());
  } else {
    newState.hasMutedLocalAudioTrack = hasMutedLocalTrackValue;
    sessionStorage.setItem('hasMutedLocalAudioTrack', hasMutedLocalTrackValue.toString());
  }

  return { ...state, ...newState };
};

const onSetDefaultSpeakerId = (state, devices) => {
  const groupedDevices = _.groupBy(_.cloneDeep(devices), 'kind');

  if (_.isEmpty(groupedDevices)) {
    return { ...state };
  }

  const speakersDevices = groupedDevices['audiooutput'];

  if (_.isEmpty(speakersDevices)) {
    return { ...state };
  }

  return {
    ...state,
    defaultSpeakerId: speakersDevices[0].deviceId,
  };
};

function onSetLoggedUserInConference(state, isInConference) {
  const newState = _.cloneDeep(state);

  if (newState.userRole === USER_ROLES.moderator) {
    addModeratorIdToStorage(JitsiConference.getLocalUserConferenceId());
  }
  newState.isInConference = isInConference;

  return { ...state, ...newState };
}

function onSetBeingSpeaker(state, beingSpeaker, beingSpeakerWithVideo) {
  const newState = _.cloneDeep(state);
  newState.beingSpeaker = {
    withAudio: beingSpeaker,
    withVideo: beingSpeakerWithVideo,
  };

  if (!beingSpeaker && !beingSpeakerWithVideo) {
    newState.beingPreviewed = {
      beingPreviewed: false,
    };
  }

  return { ...state, ...newState };
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_LOGGED_USER_DATA: {
      return {
        ...state,
      };
    }

    case FETCH_LOGGED_USER_DATA_SUCCESS: {
      return onFetchLoggedUserDataSuccess(state, action.result);
    }

    case FETCH_LOGGED_USER_JOIN_DATA_SUCCESS: {
      return onFetchLoggedUserJoinDataSuccess(state, action.result);
    }

    case FETCH_LOGGED_USER_SLOT_DATA_SUCCESS: {
      return onFetchLoggedUserSlotDataSuccess(state, action.result);
    }

    case SET_LOGGED_USER_DEVICES: {
      return {
        ...state,
        devices: _.groupBy(_.cloneDeep(action.devices), 'kind'),
      };
    }

    case SET_LOGGED_USER_DEFAULT_VIDEO_DEVICE_ID: {
      return {
        ...state,
        defaultVideoId: action.deviceId,
      };
    }

    case SET_LOGGED_USER_DEFAULT_AUDIO_DEVICE_ID: {
      return {
        ...state,
        defaultAudioId: action.deviceId,
      };
    }

    case SET_LOGGED_USER_DEFAULT_SPEAKER_DEVICE_ID: {
      return {
        ...state,
        defaultSpeakerId: action.deviceId,
      };
    }

    case SET_LOGGED_USER_IS_CONNECTED: {
      return {
        ...state,
        isConnected: action.bool,
      };
    }

    case SET_LOGGED_USER_IS_KICKED: {
      return {
        ...state,
        isKicked: action.bool,
      };
    }

    case SET_LOGGED_USER_IS_IN_CONFERENCE: {
      return onSetLoggedUserInConference(state, action.bool);
    }

    case CHANGING_LOCAL_TRACK: {
      return {
        ...state,
        changingLocalTrack: true,
      };
    }

    case CHANGING_LOCAL_TRACK_SUCCESS: {
      return {
        ...state,
        changingLocalTrack: false,
      };
    }

    case CHANGING_LOCAL_TRACK_FAIL: {
      return {
        ...state,
        changingLocalTrack: false,
      };
    }

    case SET_LOGGED_USER_CONVERSATION: {
      return {
        ...state,
        conversation: action.message,
      };
    }

    case SET_LOGGED_USER_DEVICE_TYPE: {
      return {
        ...state,
        deviceType: action.deviceType,
      };
    }

    case SET_IS_ON_FULLSCREEN: {
      return {
        ...state,
        isOnFullScreen: action.isOnFullScreen,
      };
    }

    case SET_HAS_RAISED_HAND: {
      return {
        ...state,
        hasRaisedHand: action.hasRaisedHand,
      };
    }

    case SET_HAS_MUTED_LOCAL_VIDEO_TRACK: {
      return onSetHasMutedLocalTrack(state, action.hasMutedLocalVideoTrack, 'video');
    }

    case SET_HAS_MUTED_LOCAL_AUDIO_TRACK: {
      return onSetHasMutedLocalTrack(state, action.hasMutedLocalAudioTrack, 'audio');
    }

    case UPDATE_MESSAGE_AS_READ: {
      return {
        ...state,
        conversation: action.conversation,
      };
    }

    case SET_BEING_PREVIEWED: {
      return {
        ...state,
        beingPreviewed: { beingPreviewed: action.beingPreviewed },
      };
    }

    case SET_BEING_SPEAKER: {
      return onSetBeingSpeaker(state, action.beingSpeaker, action.beingSpeakerWithVideo);
    }

    case SET_HAS_MICROPHONE_DEVICE: {
      return {
        ...state,
        hasMicrophoneDevice: action.hasMicrophoneDevice,
      };
    }

    case SET_HAS_CAMERA_DEVICE: {
      return {
        ...state,
        hasCameraDevice: action.hasCameraDevice,
      };
    }

    case CHANGE_LANGUAGE: {
      return {
        ...state,
        lang: action.newLanguage,
      };
    }

    case CHAT_SOUND_NOTIFICATION: {
      return {
        ...state,
        chatSoundNotification: action.payload,
      };
    }

    case SET_LOGGED_USER_BROWSER: {
      return {
        ...state,
        browserName: action.browserName,
      };
    }

    case TOGGLE_STREAM_TYPE: {
      return {
        ...state,
        slotMedia: action.streamType,
      };
    }

    default:
      return state;
  }
};
