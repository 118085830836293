import {
  GET_ALL_QUESTIONS_REQUEST,
  GET_ALL_QUESTIONS_SUCCESS,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  ADD_QUESTION_REQUEST,
  ADD_QUESTION_SUCCESS,
} from '@constants/ActionTypes';

const INIT_STATE = {
  questions: [],
  loading: false,
};

/**
 * @description Questions reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case GET_ALL_QUESTIONS_REQUEST:
      newState = { loading: true };
      return { ...state, ...newState };

    case GET_ALL_QUESTIONS_SUCCESS:
      newState = { loading: false, questions: action.questions };
      return { ...state, ...newState };

    case ADD_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ADD_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DELETE_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
