import React, {useEffect, useState} from 'react';
import {Layout, Modal} from 'antd';
import {useLocation, useRouteMatch, useHistory} from 'react-router-dom';
import {footerText} from 'util/config';
import App from 'routes/index';
import {useSelector} from 'react-redux';
import Sidebar from '../Sidebar/index';
import HorizontalDefault from '../Topbar/HorizontalDefault/index';
import HorizontalDark from '../Topbar/HorizontalDark/index';
import InsideHeader from '../Topbar/InsideHeader/index';
import AboveHeader from '../Topbar/AboveHeader/index';
import BelowHeader from '../Topbar/BelowHeader/index';
import IntlMessages from '../../util/IntlMessages';

import Topbar from '../Topbar/index';
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import NoHeaderNotification from '../Topbar/NoHeaderNotification/index';

const { Content, Footer } = Layout;

const MainApp = () => {
  const { width, navStyle } = useSelector(({ settings }) => settings);
  const [expiredTokenModal, setExpiredTokenModal] = useState(false); 
  const match = useRouteMatch();
  const history = useHistory();
  const userRole = useSelector((state) => state.loggedUser.userRole);

  /**
   * To check if we are on the 'UserView' route, so we hide the footer.
   */
  const location = useLocation();

  const expiredTokenModalHandler = () => {
    sessionStorage.removeItem('token');
    setExpiredTokenModal(true);
  };

  useEffect(() => {
    const eventRef = window.addEventListener('EXPIRED_TOKEN', expiredTokenModalHandler);

    return () => {
      window.removeEventListener(eventRef, expiredTokenModalHandler);
    };
  }, []);

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return 'gx-container-wrap';
      case NAV_STYLE_BELOW_HEADER:
        return 'gx-container-wrap';
      case NAV_STYLE_ABOVE_HEADER:
        return 'gx-container-wrap';
      default:
        return '';
    }
  };
  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return null;
    }
  };

  const getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };

  return (
    <Layout className="gx-app-layout">
      {getSidebar(navStyle, width)}
      <Layout>
        {getNavStyles(navStyle)}
        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
          {expiredTokenModal && <Modal
            title="Alert"
            visible={true}
            onOk={() => { history.replace('/login') }}
            closable={false}
            cancelButtonProps={{ style: { display: 'none' } }}
            >
              <p>
              <IntlMessages id="notification.expiredToken" />
              </p>
          </Modal>}
          <App match={match} />
          {
            location.pathname !== '/user' && userRole
              ? (
                <Footer>
                  <div className="gx-layout-footer-content">
                    {footerText}
                    <small className="gx-float-right footer-version">
                      v
                      {process.env.REACT_APP_VERSION}
                    </small>
                  </div>
                </Footer>
              )
              : null
          }
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainApp;
