import {
  all, call, fork, put, select, take, takeEvery,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { START_JITSI_CONNECTION } from '@constants/ActionTypes';
import JitsiConnection from '@jitsi/JitsiConnection';
import {
  jitsiConnectionFail,
  jitsiConnectionSuccess,
  jitsiDisconnectSuccess,
} from '@actions/jitsiConnection/JitsiConnectionActions';
import { JITSI_CONNECTION_EVENTS, USER_ROLES } from '@constants/Settings';
import {
  fetchLoggedUser,
  setLoggedUserIsConnected,
  setLoggedUserIsInConference,
} from '@actions/loggedUser/LoggedUserActions';
import { joinJitsiConference } from '@actions/jitsiConference/JitsiConferenceActions';
import { hqAudioConfig, isFatalJitsiConnectionError } from '@jitsi/helper';
import { LoggerType, LogMessage } from '@src/CustomHooks/LoggerHelper';

/**
 * @description EventChannel which is used to add listeners to the real jitsi connection object
 * @param jitsiConnectionCustomObj
 * @returns {any}
 */
function connectionChannel(jitsiConnectionCustomObj) {
  return eventChannel((emitter) => {
    const connected = () => {
      emitter({ type: 'ConnectSuccess', data: [] });
    };
    const notConnected = (error) => {
      emitter({ type: 'ConnectFail', data: [], error });
    };
    const disconnected = () => {
      emitter({ type: 'Disconnect', data: [] });
    };

    jitsiConnectionCustomObj.addListener(JITSI_CONNECTION_EVENTS.connectionEstablished, connected);
    jitsiConnectionCustomObj.addListener(JITSI_CONNECTION_EVENTS.connectionFailed, notConnected);
    jitsiConnectionCustomObj.addListener(
      JITSI_CONNECTION_EVENTS.connectionDisconnect,
      disconnected,
    );

    return () => {
      jitsiConnectionCustomObj.removeConnectionListeners();
    };
  });
}

/**
 * @description Generator to create connection with the JITSI server
 * @returns void
 */
export function* doJitsiConnection() {
  const jitsiConnection = yield new JitsiConnection(sessionStorage.getItem('jitsiToken'));
  const loggedUserData = yield select((state) => state.loggedUser);
  const { userRole } = loggedUserData;

  const connectionConfig = userRole === USER_ROLES.stage ? hqAudioConfig() : null;

  yield jitsiConnection.initConnection(connectionConfig);
  const channel = yield call(connectionChannel, jitsiConnection);
  yield jitsiConnection.connect();

  while (true) {
    const loggedUserChannel = yield take(channel);
    let isInConference = false;

    switch (loggedUserChannel.type) {
      case 'ConnectSuccess':
        LogMessage(LoggerType.DEBUG, '[TEST]', 'ConnectSuccess', window.audi.connection?.getJid());
        yield put(jitsiConnectionSuccess());
        yield put(setLoggedUserIsConnected(true));
        isInConference = yield select((state) => state.loggedUser.isInConference);
        if (!isInConference) {
          yield put(joinJitsiConference());
        }
        break;
      case 'ConnectFail':
        LogMessage(LoggerType.DEBUG, '[TEST]', 'ConnectFail', loggedUserChannel.error, window.audi.connection?.getJid());
        yield put(jitsiConnectionFail());
        yield put(setLoggedUserIsConnected(false));
        if (isFatalJitsiConnectionError(loggedUserChannel.error)) {
          yield put(setLoggedUserIsInConference(false));
          yield jitsiConnection.removeConnectionListeners();
          yield put(fetchLoggedUser());
        }
        break;
      case 'Disconnect':
        LogMessage(LoggerType.DEBUG, '[TEST]', 'Disconnect', window.audi.connection?.getJid());
        yield put(jitsiDisconnectSuccess());
        yield put(setLoggedUserIsConnected(false));
        yield jitsiConnection.removeConnectionListeners();
        break;
      default:
        break;
    }
  }
}

export function* actionsWatcher() {
  yield takeEvery(START_JITSI_CONNECTION, doJitsiConnection);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
