import {
  ADD_CONVERSATION,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_TO_GROUP,
  ADD_USER_TO_GROUP_SUCCESS,
  BAN_PARTICIPANT,
  BAN_PARTICIPANT_FAIL,
  BAN_PARTICIPANT_SUCCESS,
  CHECK_MESSAGE_AS_READ,
  CHECK_PARTICIPANT_SUCCESS,
  CLEAR_LIVE_PARTICIPANTS,
  CLEAR_LIVE_PARTICIPANTS_SUCCESS,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FILTER_SEARCH_PARTICIPANTS_SUCCESS,
  KICK_PARTICIPANT,
  KICK_PARTICIPANT_FAIL,
  KICK_PARTICIPANT_SUCCESS,
  MUTE_PARTICIPANTS_SUCCESS,
  RESET_OR_LEAVE_CURRENT_PARTICIPANT_STATUS,
  SELECT_PARTICIPANTS_SUCCESS,
  SET_PARTICIPANT_LIVE_AUDIO_SUCCESS,
  SET_PARTICIPANT_LIVE_VIDEO_SUCCESS,
  SET_USERS_FROM_STORAGE,
  TOGGLE_PARTICIPANT_CHAT,
  UNSELECT_PARTICIPANTS_SUCCESS,
  USER_CONN_STATUS_HAS_CHANGED,
  USER_HAS_JOINED,
  USER_HAS_LEFT,
  USER_LOCAL_TRACK_MUTE_CHANGE,
  USER_RAISE_HAND_CHANGE,
  USER_TRACK_MUTE_CHANGED,
  USER_TRACK_WAS_ADDED,
  USER_TRACK_WAS_REMOVED,
} from '@constants/ActionTypes';

import _ from 'lodash';
import {
  JITSI_PARTICIPANT_CONNECTION_STATUSES,
  PARTICIPANTS_SELECT_VALUES,
  PARTICIPANTS_TABLE,
  USER_ROLES, CHAT_STATUS
} from '@constants/Settings';
import { decodeDisplayName } from '@jitsi/helper';
import {
  cloneDeepUsersAndTableData,
  getUserIndexFromUsersAndTableDataByUserUid,
  getUserIndexFromUsersAndTableDataByUserDatabaseId,
  participantConfPropsOnTrackAdded,
  participantConfPropsOnTrackRemoved,
  getUserIndexFromUsersAndTableDataByUserConfId,
  addModeratorIdToStorage,
  chatObjectToArray,
} from '../../../CustomHooks/HelperFuncs';
import {
  LoggerHeaders,
  LoggerMessage,
  LoggerType,
  LogMessage,
} from "../../../CustomHooks/LoggerHelper";

const INIT_STATE = {
  users: [],
  participantsPage: {
    tableData: [],
    total: '',
    selection: '',
    searchValue: '',
    filterValue: '',
  },
  loading: false,
  conferenceModeratorId: null,
};

const defaultConferenceProps = {
  checked: false,
  chatOpened: false,
  confProps: {
    userConfId: null,
    userRole: null,
    userConfLang: null,
    broadcaster: false,
    status: 'unknown',
    microphone: false,
    camera: false,
    liveAV: false,
    liveAudio: false,
    handRaised: false,
    conversation: {},
    consent: false,
    connStatus: JITSI_PARTICIPANT_CONNECTION_STATUSES.INACTIVE,
  },
};

const addDefaultConfProps = (participants) => {
  const newParticipants = _.cloneDeep(participants);

  newParticipants.map((p) => {
    const userData = { ...p.user, ...JSON.parse(JSON.stringify(defaultConferenceProps)) };

    return Object.assign(p.user, userData);
  });

  return newParticipants;
};

const onJoinedUser = (state, joinedUser) => {
  const { tableData, users } = cloneDeepUsersAndTableData(state);
  const decodedDisplayName = decodeDisplayName(joinedUser.getDisplayName());

  LogMessage(LoggerType.INFO, LoggerHeaders.USER_JOINED, LoggerMessage.userJoined(decodedDisplayName, joinedUser.getId()));

  if (decodedDisplayName.confRole === USER_ROLES.moderator) {
    addModeratorIdToStorage(joinedUser.getId());
  }

  const { tableDataIndex, userIndex } = getUserIndexFromUsersAndTableDataByUserDatabaseId(decodedDisplayName, users, tableData);

  if (tableDataIndex !== -1) {
    tableData[tableDataIndex] = {
      ...tableData[tableDataIndex],
      confProps: {
        ..._.cloneDeep(tableData[tableDataIndex].confProps),
        broadcaster: decodedDisplayName.confRole === USER_ROLES.stage,
        userRole: decodedDisplayName.confRole.toUpperCase(),
        userConfId: joinedUser.getId(),
        userConfLang: decodedDisplayName.lang,
        status: PARTICIPANTS_TABLE.rowStatusClasses.online,
        connStatus: JITSI_PARTICIPANT_CONNECTION_STATUSES.ACTIVE,
      },
    };
  }

  if (userIndex !== -1) {
    users[userIndex] = {
      ...users[userIndex],
      confProps: {
        ..._.cloneDeep(users[userIndex].confProps),
        broadcaster: decodedDisplayName.confRole === USER_ROLES.stage,
        userRole: decodedDisplayName.confRole.toUpperCase(),
        userConfId: joinedUser.getId(),
        userConfLang: decodedDisplayName.lang,
        status: PARTICIPANTS_TABLE.rowStatusClasses.online,
        connStatus: JITSI_PARTICIPANT_CONNECTION_STATUSES.ACTIVE,
      },
    };
  }

  const newState = {
    users: [
      ...users,
    ],
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
    },
  };

  return { ...state, ...newState };
};

const onUserTrackAdded = (state, track) => {
  const userId = track.ownerEndpointId;
  const user = window.audi.room.getParticipants().find((p) => p.getId() === userId);

  if (user) {
    const { tableData, users } = cloneDeepUsersAndTableData(state);
    const decodedDisplayName = decodeDisplayName(user.getDisplayName());
    const { tableDataIndex, userIndex } = getUserIndexFromUsersAndTableDataByUserDatabaseId(decodedDisplayName, users, tableData);

    LogMessage(LoggerType.INFO, LoggerHeaders.USER_TRACK_ADDED, LoggerMessage.userTrackAdded(track, decodedDisplayName));

    const isTheUserStageOrTranslator = decodedDisplayName.confRole === USER_ROLES.translator || decodedDisplayName.confRole === USER_ROLES.stage;
    const confPropsAdditionObj = participantConfPropsOnTrackAdded(users, decodedDisplayName, userIndex, track);

    if (tableDataIndex !== -1) {
      tableData[tableDataIndex] = {
        ...tableData[tableDataIndex],
        confProps: {
          ...tableData[tableDataIndex].confProps,
          ...isTheUserStageOrTranslator ? confPropsAdditionObj : {},
        },
      };
    }

    if (userIndex !== -1) {
      users[userIndex] = {
        ...users[userIndex],
        confProps: {
          ...users[userIndex].confProps,
          ...isTheUserStageOrTranslator ? confPropsAdditionObj : {},
        },
      };
    }

    const newState = {
      users: [
        ...users,
      ],
      participantsPage: {
        ..._.cloneDeep(state.participantsPage),
        tableData,
      },
    };

    return { ...state, ...newState };
  }

  return { ...state };
};

const onUserTrackRemoved = (state, track) => {
  const userId = track.ownerEndpointId;
  const user = window.audi.room.getParticipants().find((p) => p.getId() === userId);

  if (user) {
    const { tableData, users } = cloneDeepUsersAndTableData(state);
    const decodedDisplayName = decodeDisplayName(user.getDisplayName());
    const { tableDataIndex, userIndex } = getUserIndexFromUsersAndTableDataByUserDatabaseId(decodedDisplayName, users, tableData);

    LogMessage(LoggerType.INFO, LoggerHeaders.USER_TRACK_REMOVED, LoggerMessage.userTrackRemoved(track, decodedDisplayName));
    const confPropsAdditionObj = participantConfPropsOnTrackRemoved(users, decodedDisplayName, userIndex, track);

    if (tableDataIndex !== -1) {
      tableData[tableDataIndex] = {
        ...tableData[tableDataIndex],
        confProps: {
          ...tableData[tableDataIndex].confProps,
          ...confPropsAdditionObj,
        },
      };
    }

    if (userIndex !== -1) {
      users[userIndex] = {
        ...users[userIndex],
        confProps: {
          ...users[userIndex].confProps,
          ...confPropsAdditionObj,
        },
      };
    }

    const newState = {
      users: [
        ...users,
      ],
      participantsPage: {
        ..._.cloneDeep(state.participantsPage),
        tableData,
      },
    };

    return { ...state, ...newState };
  }

  return { ...state };
}

const onUserTrackMuteChanged = (state, track) => {
  const userId = track.ownerEndpointId;
  const user = window.audi.room.getParticipants().find((p) => p.getId() === userId);

  if (user) {
    const decodedDisplayName = decodeDisplayName(user.getDisplayName());
    const { users, tableData } = cloneDeepUsersAndTableData(state);
    const { userIndex, tableDataIndex } = getUserIndexFromUsersAndTableDataByUserDatabaseId(decodedDisplayName, users, tableData);
    
    if (userIndex === -1) {
      LogMessage(LoggerType.ERROR, LoggerHeaders.USER_TRACK_MUTE_STATUS_CHANGED, LoggerMessage.userIndexWasNotFoundInTheUsersList(userId));
    }

    if (tableDataIndex !== -1) {
      tableData[tableDataIndex] = {
        ...tableData[tableDataIndex],
      };
    }

    if (userIndex !== -1) {
      users[userIndex] = {
        ...users[userIndex],
      };
    }

    const newState = {
      users: [
        ...users,
      ],
      participantsPage: {
        ..._.cloneDeep(state.participantsPage),
        tableData,
      },
    };

    return { ...state, ...newState };
  }

  return { ...state };
}

const onUserLeft = (state, userId) => {
  const { tableData, users } = cloneDeepUsersAndTableData(state);
  const { userIndex, tableDataIndex } = getUserIndexFromUsersAndTableDataByUserConfId(userId, users, tableData);

  LogMessage(LoggerType.INFO, LoggerHeaders.USER_LEFT, LoggerMessage.userLeft(userId));

  if (tableDataIndex !== -1) {
    tableData[tableDataIndex] = { ...tableData[tableDataIndex], ...JSON.parse(JSON.stringify(defaultConferenceProps)) };
    tableData[tableDataIndex].confProps.status = PARTICIPANTS_TABLE.rowStatusClasses.offline;
    tableData[tableDataIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.INACTIVE;
  }

  if (userIndex !== -1) {
    const userConversation = _.cloneDeep(users[userIndex].confProps.conversation);

    users[userIndex] = { ...users[userIndex], ...JSON.parse(JSON.stringify(defaultConferenceProps)) };
    users[userIndex].confProps.status = PARTICIPANTS_TABLE.rowStatusClasses.offline;
    users[userIndex].confProps.conversation = userConversation;
    users[userIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.INACTIVE;
  }

  const newState = {
    users: [
      ...users,
    ],
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
    },
  };

  return { ...state, ...newState };
};

const onSetParticipantLiveVideo = (state, record, liveVideoValue) => {
  const { users, tableData } = cloneDeepUsersAndTableData(state);
  const { userIndex, tableDataIndex } = getUserIndexFromUsersAndTableDataByUserConfId(record.userConfId, users, tableData);

  if (tableDataIndex !== -1) {
    if (!liveVideoValue) {
      tableData[tableDataIndex].confProps.liveAV = liveVideoValue;
    } else {
      tableData[tableDataIndex].confProps.liveAV = liveVideoValue;
      tableData[tableDataIndex].confProps.liveAudio = liveVideoValue;
    }
  }
  if (userIndex !== -1) {
    if (!liveVideoValue) {
      users[userIndex].confProps.liveAV = liveVideoValue;
    } else {
      users[userIndex].confProps.liveAV = liveVideoValue;
      users[userIndex].confProps.liveAudio = liveVideoValue;
    }
  }

  const newState = {
    users: [
      ...users,
    ],
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
    },
    loading: false,
  };

  return { ...state, ...newState };
};

const onSetParticipantLiveAudio = (state, record, isLiveAudio) => {
  const { users, tableData } = cloneDeepUsersAndTableData(state);
  const { userIndex, tableDataIndex } = getUserIndexFromUsersAndTableDataByUserConfId(record.userConfId, users, tableData);

  if (tableDataIndex !== -1) {
    tableData[tableDataIndex].confProps.liveAudio = isLiveAudio;
  }
  if (userIndex !== -1) {
    users[userIndex].confProps.liveAudio = isLiveAudio;
  }

  const newState = {
    users: [
      ...users,
    ],
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
    },
    loading: false,
  };

  return { ...state, ...newState };
};

const onUserRaiseHand = (state, user, raiseHandValue) => {
  const { users, tableData } = cloneDeepUsersAndTableData(state);
  const { userIndex, tableDataIndex } = getUserIndexFromUsersAndTableDataByUserConfId(user.getId(), users, tableData);

  if (userIndex === -1) {
    LogMessage(LoggerType.ERROR, LoggerHeaders.USER_RAISE_HAND, LoggerMessage.userIndexWasNotFoundInTheUsersList(user.getId()));
  }
  if (tableDataIndex !== -1) {
    tableData[tableDataIndex].confProps.handRaised = raiseHandValue;

    (raiseHandValue)
      ? tableData[tableDataIndex].confProps.status = PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised
      : tableData[tableDataIndex].confProps.status = PARTICIPANTS_TABLE.rowStatusClasses.online;
  }
  if (userIndex !== -1) {
    users[userIndex].confProps.handRaised = raiseHandValue;

    (raiseHandValue)
      ? users[userIndex].confProps.status = PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised
      : users[userIndex].confProps.status = PARTICIPANTS_TABLE.rowStatusClasses.online;
  }

  const newState = {
    users: [
      ...users,
    ],
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
    },
  };

  return { ...state, ...newState };
};

const onUserLocalTrackMuteChange = (state, user, localTrackMuteValue, trackType) => {
  const { users, tableData } = cloneDeepUsersAndTableData(state);
  const { userIndex, tableDataIndex } = getUserIndexFromUsersAndTableDataByUserConfId(user.getId(), users, tableData);

  const confPropsAdditionObj = (trType, trMuteValue) => {
    if (trType === 'video') {
      return {
        camera: !trMuteValue,
      };
    }
    return {
      microphone: !trMuteValue,
    };
  };

  if (tableDataIndex !== -1) {
    tableData[tableDataIndex] = {
      ...tableData[tableDataIndex],
      confProps: {
        ...tableData[tableDataIndex].confProps,
        ...confPropsAdditionObj(trackType, localTrackMuteValue),
      },
    };
  }

  if (userIndex !== -1) {
    users[userIndex] = {
      ...users[userIndex],
      confProps: {
        ...users[userIndex].confProps,
        ...confPropsAdditionObj(trackType, localTrackMuteValue),
      },
    };
  }

  const newState = {
    users: [
      ...users,
    ],
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
    },
  };

  return { ...state, ...newState };
};

/**
 * Filter and/or search participants state array
 * @param {object} state
 * @param {string} filter
 * @param {string} searchValue
 * @returns Object
 */
const onFilterAndSearchParticipants = (state, filter, searchValue) => {
  let participantsData = _.cloneDeep(state.users);

  if (filter) {
    if (filter === CHAT_STATUS.online) {
      participantsData = participantsData.filter((p) => p.confProps.status === CHAT_STATUS.online || p.confProps.status === CHAT_STATUS.onlineRaised);
    } else {
      participantsData = participantsData.filter((p) => p.confProps.status === filter);
    }
  }

  if (searchValue) {
    participantsData = participantsData.filter((p) => {
      const stringsToSearchIn = [
        p.first_name || '',
        p.last_name || '',
        p.company || '',
        p.user_group || '',
        p.language || '',
      ];

      return _.some(stringsToSearchIn, (el) => _.includes(el.toLowerCase(), searchValue.toLowerCase()));
    });
  }

  const newState = {
    ..._.cloneDeep(state),
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData: participantsData,
      selection: '',
      filterValue: filter,
      searchValue,
    },
    loading: false,
  };

  return { ...state, ...newState };
};

/**
 * Set participants property "checked" to true IF they meet the requirement "selectionType"
 * so in the table the participant is checked
 * @param {object} state
 * @param {string} selectionType
 * @returns object
 */
const onSelectParticipants = (state, selectionType) => {
  const tableData = _.cloneDeep(state.participantsPage.tableData);

  tableData.map((participant, index) => {
    const modifiedParticipant = { ...participant };
    modifiedParticipant.checked = selectionType.toLowerCase() === `${modifiedParticipant.confProps.status}`
      || selectionType === PARTICIPANTS_SELECT_VALUES.all;

    tableData[index] = modifiedParticipant;
  });

  const newState = {
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
      selection: selectionType,
    },
    loading: false,
  };

  return { ...state, ...newState };
};

/**
 * Set participants property "checked" to false so all users gets unchecked
 * @param {object} state
 * @returns object
 */
const onUnselectParticipants = (state) => {
  const tableData = _.cloneDeep(state.participantsPage.tableData);

  tableData.map((participant, index) => {
    const modifiedParticipant = _.cloneDeep(participant);
    modifiedParticipant.checked = false;
    tableData[index] = modifiedParticipant;
  });

  const newState = {
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
      selection: '',
    },
  };

  return { ...state, ...newState };
};

/**
 * Set specific participant property "checked" to true,
 * so he gets checked on the table
 * @param {object} state
 * @param {number} recordId - represents the table record key
 * @returns object
 */
const doCheckParticipant = (state, recordId) => {
  const tableData = _.cloneDeep(state.participantsPage.tableData);
  const index = _.findIndex(tableData, (p) => p.user_id === recordId, 0);
  tableData[index] = {
    ...tableData[index],
    checked: !tableData[index].checked,
  };

  const newState = {
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
    },
  };

  return { ...state, ...newState };
};

/**
 * @description Set is chat opened or not
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object}
 */
const toggleParticipantChat = (state, payload) => {
  const usersList = _.cloneDeep(state.users);
  const index = _.findIndex(usersList, (p) => p.user_id === payload, 0);
  const tableData = _.cloneDeep(state.participantsPage.tableData);
  const tableIndex = _.findIndex(tableData, (p) => p.user_id === payload, 0);

  for (let i = 0; i < usersList.length; i += 1) {
    if (i === index) {
      usersList[i] = {
        ...usersList[i],
        chatOpened: !usersList[i].chatOpened,
      };
    } else {
      usersList[i] = {
        ...usersList[i],
        chatOpened: false,
      };
    }
  }

  for (let i = 0; i < tableData.length; i += 1) {
    if (i === tableIndex) {
      tableData[i] = {
        ...tableData[i],
        chatOpened: !tableData[i].chatOpened,
      };
    } else {
      tableData[i] = {
        ...tableData[i],
        chatOpened: false,
      };
    }
  }

  const newState = {
    users: usersList,
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
    },
  };

  return { ...state, ...newState };
};

/**
 * @description Reset OR Leave the participant status
 * @description When the participant leave the conference we set his status to OFFLINE from another action and
 * start a 20 secs timer. When the timer expires we dispatch the 'RESET_OR_LEAVE_PARTICIPANT_CURRENT_STATUS' action
 * and we reset his status to the default one (unknown)
 * @description BUT IF the participant comes again online before the timer expires we dispatch again the
 * 'RESET_OR_LEAVE_PARTICIPANT_CURRENT_STATUS' action BUT inside the function below we check if his status ATM
 * is online or online&raisedHand, if so we dont change anything
 */
const doResetOrLeaveParticipantStatus = (state, userDisplayName, status) => {
  const tableData = _.cloneDeep(state.participantsPage.tableData);
  const users = _.cloneDeep(state.users);
  const decodedDisplayName = decodeDisplayName(userDisplayName);

  const tableDataIndex = _.findIndex(tableData, (p) => p.user_id === parseInt(decodedDisplayName.databaseId), 0);
  const usersIndex = _.findIndex(users, (p) => p.user_id === parseInt(decodedDisplayName.databaseId), 0);

  if (tableDataIndex !== -1
    && !_.includes([
      PARTICIPANTS_TABLE.rowStatusClasses.online,
      PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised,
    ], tableData[tableDataIndex].confProps.status)) {

    tableData[tableDataIndex] = {
      ...tableData[tableDataIndex],
      confProps: {
        ..._.cloneDeep(tableData[tableDataIndex].confProps),
        status,
      },
    };
  }
  if (usersIndex !== -1
    && !_.includes([
      PARTICIPANTS_TABLE.rowStatusClasses.online,
      PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised,
    ], users[usersIndex].confProps.status)) {

    users[usersIndex] = {
      ...users[usersIndex],
      confProps: {
        ..._.cloneDeep(users[usersIndex].confProps),
        status,
      },
    };
  }

  const newState = {
    users: [
      ...users,
    ],
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
    },
  };

  return { ...state, ...newState };
};

/**
 * @description Adapts participants data from the backend
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object}
 */
function onFetchUsersList(state, payload) {
  const usersList = [];

  const participantsWithFakeData = addDefaultConfProps(payload);
  let newState = {};

  if (participantsWithFakeData && participantsWithFakeData.length) {
    participantsWithFakeData.forEach((participant) => {
      usersList.push(participant.user);
    });
  }

  newState = {
    loading: false,
    users: usersList,
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData: _.cloneDeep(usersList),
      total: participantsWithFakeData.length,
    },
  };

  return { ...state, ...newState };
}

/**
 * @description Adds new message in chat conversation
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object}
 */
function onAddMessage(state, payload) {
  let newState;

  const findUser = state.users.find((user) => user.uid === payload.userId);

  if (findUser) {
    const updatedConversation = [...chatObjectToArray(findUser.confProps.conversation), payload];
    const { users } = cloneDeepUsersAndTableData(state);
    const { userIndex } = getUserIndexFromUsersAndTableDataByUserUid(payload.userId, state.users, null);

    if (userIndex !== -1) {
      users[userIndex].confProps.conversation = updatedConversation;
    }

    newState = {
      loading: false,
      users,
    };
  } else if (!findUser && payload.type === 'sent') {
    newState = {
      loading: false,
      users: state.users.map((user) => ({
        ...user,
        confProps: {
          ...user.confProps,
          conversation: (user.confProps.userConfId
            || user.role.toUpperCase() === USER_ROLES.visitor)
            ? [...user.confProps.conversation, payload]
            : user.confProps.conversation,
        },
      })),
    };
  } else {
    newState = {
      loading: false,
    };
  }

  return { ...state, ...newState };
}

/**
 * @description Updates conversation on read missed messages
 * @param {Object} state
 * @param {Object} payload
 * @returns {Object}
 */
function onCheckMessageAsRead(state, payload) {
  let newState = {};

  const { users } = state;

  if (payload) {
    const findUser = users.find((user) => {
      const firstReceivedMessage = chatObjectToArray(user.confProps.conversation).find((message) => message.type === 'received');
      if (firstReceivedMessage) {
        return user.user_id === firstReceivedMessage.dataBaseId;
      }
    });

    if (findUser) {
      const updatedConversation = payload;
      const firstReceivedMessage = payload.find((message) => message.type === 'received');
      if (firstReceivedMessage) {
        newState = {
          loading: false,
          users: state.users.map((user) => (user.user_id === firstReceivedMessage.dataBaseId
            ? {
              ...user,
              confProps: {
                ...user.confProps,
                conversation: updatedConversation,
              },
            } : user)),
        };
      }
    }
  }

  return { ...state, ...newState };
}

function onUserConnectionStatusChange(state, userConfId, status) {
  const { users, tableData } = cloneDeepUsersAndTableData(state);
  const { userIndex, tableDataIndex } = getUserIndexFromUsersAndTableDataByUserConfId(userConfId, users, tableData);

  if (tableDataIndex !== -1) {
    switch (status) {
      case JITSI_PARTICIPANT_CONNECTION_STATUSES.INTERRUPTED:
        tableData[tableDataIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.INTERRUPTED;
        break;
      case JITSI_PARTICIPANT_CONNECTION_STATUSES.INACTIVE:
        tableData[tableDataIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.INACTIVE;
        tableData[tableDataIndex].confProps.connStatus = PARTICIPANTS_TABLE.rowStatusClasses.unknown;
        break;
      case JITSI_PARTICIPANT_CONNECTION_STATUSES.ACTIVE:
        tableData[tableDataIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.ACTIVE;
        tableData[tableDataIndex].confProps.status = tableData[tableDataIndex].confProps.handRaised
          ? PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised
          : PARTICIPANTS_TABLE.rowStatusClasses.online;
        break;
      case JITSI_PARTICIPANT_CONNECTION_STATUSES.RESTORING:
        tableData[tableDataIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.RESTORING;
        break;
      default:
        tableData[tableDataIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.UNKNOWN;
        break;
    }
  }
  if (userIndex !== -1) {
    switch (status) {
      case JITSI_PARTICIPANT_CONNECTION_STATUSES.INTERRUPTED:
        users[userIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.INTERRUPTED;
        break;
      case JITSI_PARTICIPANT_CONNECTION_STATUSES.INACTIVE:
        users[userIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.INACTIVE;
        users[userIndex].confProps.connStatus = PARTICIPANTS_TABLE.rowStatusClasses.unknown;
        break;
      case JITSI_PARTICIPANT_CONNECTION_STATUSES.ACTIVE:
        users[userIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.ACTIVE;
        users[userIndex].confProps.status = users[userIndex].confProps.handRaised
          ? PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised
          : PARTICIPANTS_TABLE.rowStatusClasses.online;
        break;
      case JITSI_PARTICIPANT_CONNECTION_STATUSES.RESTORING:
        users[userIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.RESTORING;
        break;
      default:
        users[userIndex].confProps.connStatus = JITSI_PARTICIPANT_CONNECTION_STATUSES.UNKNOWN;
        break;
    }
  }

  const newState = {
    users: [
      ...users,
    ],
    participantsPage: {
      ..._.cloneDeep(state.participantsPage),
      tableData,
    },
  };

  return { ...state, ...newState };
}

/**
 * @description Users reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case FETCH_USERS_REQUEST:
      newState = { loading: true };
      return { ...state, ...newState };

    case FETCH_USERS_SUCCESS:
      return onFetchUsersList(state, action.payload);

    case FILTER_SEARCH_PARTICIPANTS_SUCCESS: {
      return onFilterAndSearchParticipants(state, action.filter, action.searchValue);
    }

    case SET_PARTICIPANT_LIVE_VIDEO_SUCCESS: {
      return onSetParticipantLiveVideo(state, action.record, action.liveVideoValue);
    }

    case SET_PARTICIPANT_LIVE_AUDIO_SUCCESS: {
      return onSetParticipantLiveAudio(state, action.record, action.liveAudioValue);
    }

    case SELECT_PARTICIPANTS_SUCCESS: {
      return onSelectParticipants(state, action.selectionType);
    }

    case UNSELECT_PARTICIPANTS_SUCCESS: {
      return onUnselectParticipants(state);
    }

    case CHECK_PARTICIPANT_SUCCESS: {
      return doCheckParticipant(state, action.recordId);
    }

    case MUTE_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case RESET_OR_LEAVE_CURRENT_PARTICIPANT_STATUS: {
      return doResetOrLeaveParticipantStatus(state, action.user, action.status);
    }

    case KICK_PARTICIPANT: {
      return {
        ..._.cloneDeep(state),
        loading: true,
      };
    }

    case KICK_PARTICIPANT_SUCCESS: {
      return {
        ..._.cloneDeep(state),
        loading: false,
      };
    }

    case KICK_PARTICIPANT_FAIL: {
      return {
        ..._.cloneDeep(state),
        loading: false,
      };
    }

    case BAN_PARTICIPANT: {
      return {
        ..._.cloneDeep(state),
        loading: true,
      };
    }

    case BAN_PARTICIPANT_SUCCESS: {
      return {
        ..._.cloneDeep(state),
        loading: false,
      };
    }

    case BAN_PARTICIPANT_FAIL: {
      return {
        ..._.cloneDeep(state),
        loading: false,
      };
    }

    case CLEAR_LIVE_PARTICIPANTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLEAR_LIVE_PARTICIPANTS_SUCCESS: {
      return {
        ...state,
        participantsPage: {
          ..._.cloneDeep(state.participantsPage),
          tableData: [...action.result],
        },
        loading: false,
      };
    }

    case ADD_USER: {
      return {
        ...state,
      };
    }

    case ADD_USER_SUCCESS: {
      return {
        ...state,
      };
    }

    case ADD_USER_TO_GROUP: {
      return {
        ...state,
      };
    }

    case ADD_USER_TO_GROUP_SUCCESS: {
      return {
        ...state,
      };
    }

    case USER_HAS_JOINED: {
      return onJoinedUser(state, action.user);
    }

    case USER_HAS_LEFT: {
      return onUserLeft(state, action.userId);
    }

    case USER_CONN_STATUS_HAS_CHANGED: {
      return onUserConnectionStatusChange(state, action.userConfId, action.status);
    }

    case USER_RAISE_HAND_CHANGE: {
      return onUserRaiseHand(state, action.user, action.raiseHandValue);
    }

    case USER_LOCAL_TRACK_MUTE_CHANGE: {
      return onUserLocalTrackMuteChange(state, action.user, action.localTrackMuteValue, action.trackType);
    }

    case ADD_CONVERSATION: {
      return onAddMessage(state, action.message);
    }

    case CHECK_MESSAGE_AS_READ: {
      return onCheckMessageAsRead(state, action.conversation);
    }

    case SET_USERS_FROM_STORAGE: {
      return {
        ...state,
        users: action.users,
      };
    }

    case USER_TRACK_WAS_ADDED: {
      return onUserTrackAdded(state, action.track);
    }

    case USER_TRACK_WAS_REMOVED: {
      return onUserTrackRemoved(state, action.track);
    }

    case USER_TRACK_MUTE_CHANGED: {
      return onUserTrackMuteChanged(state, action.track);
    }

    case TOGGLE_PARTICIPANT_CHAT: {
      return toggleParticipantChat(state, action.payload);
    }

    default:
      return state;
  }
};
