import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { ReactComponent as CameraIcon } from '@assets/images/actions-icons/camera_icon_white.svg';
import { ReactComponent as CameraIconThin } from '@assets/images/actions-icons/camera_icon_white-thin.svg';
import { ReactComponent as CameraIconMuted } from '@assets/images/actions-icons/camera_icon_white-muted.svg';
import { ReactComponent as CameraIconMutedThin } from '@assets/images/actions-icons/camera_icon_white-muted-thin.svg';
import { ReactComponent as MicrophoneIcon } from '@assets/images/actions-icons/microphone_icon_white.svg';
import { ReactComponent as MicrophoneIconThin } from '@assets/images/actions-icons/microphone_icon_white-thin.svg';
import { ReactComponent as MicrophoneIconMuted } from '@assets/images/actions-icons/microphone_icon_white-muted.svg';
import { ReactComponent as MicrophoneIconMutedThin } from '@assets/images/actions-icons/microphone_icon_white-muted-thin.svg';
import { DEVICES_TYPES } from '@constants/Settings';
import { useIdleTimer } from 'react-idle-timer';
import { useDevicesButtonsActionsHook } from '../../../../CustomHooks/useDevicesButtonsActionsHook';

const MicCamActionsButtons = (props) => {
  const {
    deviceType,
    intl,
  } = props;

  const [fadeInOut, setFadeInOut] = useState('fadeIn');

  const hasCameraDevice = useSelector((state) => state.loggedUser.hasCameraDevice);
  const hasRaisedHand = useSelector((state) => state.loggedUser.hasRaisedHand);
  const hasMicrophoneDevice = useSelector((state) => state.loggedUser.hasMicrophoneDevice);
  const isOnFullscreen = useSelector((state) => state.loggedUser.isOnFullScreen);
  const hasMutedLocalAudioTrack = useSelector((state) => state.loggedUser.hasMutedLocalAudioTrack);
  const hasMutedLocalVideoTrack = useSelector((state) => state.loggedUser.hasMutedLocalVideoTrack);

  const {
    toggleMicrophone,
    toggleCamera,
    cameraTooltip,
    microphoneTooltip,
  } = useDevicesButtonsActionsHook(
    intl,
    hasRaisedHand,
    hasMutedLocalAudioTrack,
    hasMutedLocalVideoTrack,
    hasCameraDevice,
    hasMicrophoneDevice,
  );

  /**
   * Function to get the correct camera icon depending on
   * the user device type (mobile|desktop) and the state of
   * the camera (muted|notMuted)
   *
   * @returns {JSX.Element}
   */
  const getCameraIcon = () => {
    if (deviceType === DEVICES_TYPES.desktop) {
      return (hasMutedLocalVideoTrack || !hasCameraDevice)
        ? <CameraIconMuted onClick={() => toggleCamera()} />
        : <CameraIcon onClick={() => toggleCamera()} />;
    }

    return (hasMutedLocalVideoTrack || !hasCameraDevice)
      ? <CameraIconMutedThin onClick={() => toggleCamera()} />
      : <CameraIconThin onClick={() => toggleCamera()} />;
  };

  /**
   * Function to get the correct microphone icon depending on
   * the user device type (mobile|desktop) and the state of
   * the microphone (muted|notMuted)
   *
   * @returns {JSX.Element}
   */
  const getMicrophoneIcon = () => {
    if (deviceType === DEVICES_TYPES.desktop) {
      return (hasMutedLocalAudioTrack || !hasMicrophoneDevice)
        ? <MicrophoneIconMuted onClick={() => toggleMicrophone()} />
        : <MicrophoneIcon onClick={() => toggleMicrophone()} />;
    }

    return (hasMutedLocalAudioTrack || !hasMicrophoneDevice)
      ? <MicrophoneIconMutedThin onClick={() => toggleMicrophone()} />
      : <MicrophoneIconThin onClick={() => toggleMicrophone()} />;
  };

  /**
   * @description Set 'fadeOut' class name to actions div
   * @returns {void}
   */
  const handleOnIdle = () => {
    setFadeInOut('fadeOut');
  };

  /**
   * @description Set 'fadeIn' class name to actions div
   * @returns {void}
   */
  const handleOnActive = () => {
    setFadeInOut('fadeIn');
  };

  /**
   * @description Use idleTimer hook to catch user activity
   * @returns {void}
   */
  const checkUserIdle = useIdleTimer({
    timeout: 2000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
  });

  return (
    <div className={`micCamActionsButtons micCamActionsButtons--${deviceType} ${fadeInOut}`}>
      <Tooltip title={cameraTooltip}>
        <div className="userViewActionButton">
          { getCameraIcon() }
        </div>
      </Tooltip>
      <Tooltip title={microphoneTooltip}>
        <div className="userViewActionButton">
          { getMicrophoneIcon() }
        </div>
      </Tooltip>
    </div>
  );
};

MicCamActionsButtons.propTypes = {
  deviceType: PropTypes.string,
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default (injectIntl(MicCamActionsButtons));
