import React from 'react';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MicCamActionsButtons from '@routes/UserView/Fullscreen/Mobile/MicCamActionsButtons';
import PropTypes from 'prop-types';
import { CompressOutlined, ExpandOutlined } from '@ant-design/icons';
import { DEVICES_TYPES } from '@constants/Settings';
import { setLoggedUserIsOnFullScreen } from '@actions/loggedUser/LoggedUserActions';
import { toggleFullscreenHelper } from '../../CustomHooks/HelperFuncs';

const NoPresenter = (props) => {
  const { isOnFullScreen, deviceType, onSetFullscreen } = props;

  /**
   * Function to toggle the fullscreen
   */
  const toggleFullscreen = (isItOnFullscreen) => toggleFullscreenHelper(isItOnFullscreen, onSetFullscreen, true, 'ios');

  return (
    <div className={`userViewNoPresenter userViewNoPresenter${isOnFullScreen ? '--fullscreen' : ''}`}>
      <FontAwesomeIcon icon={faCamera} />
      {
        isOnFullScreen
          ? (
            <CompressOutlined
              onClick={() => toggleFullscreen(!isOnFullScreen)}
              className={`userViewVideoTracks__videoFullScreenIcon ${isOnFullScreen ? 'userViewRemoteTracks__videoFullScreenIcon--fullscreen' : ''}`}
            />
          )
          : (
            <ExpandOutlined
              className="userViewVideoTracks__videoFullScreenIcon"
              onClick={() => toggleFullscreen(!isOnFullScreen)}
            />
          )
      }
      {
        (isOnFullScreen || deviceType === DEVICES_TYPES.desktop)
          ? <MicCamActionsButtons deviceType={deviceType} />
          : null
      }
    </div>
  );
};

NoPresenter.propTypes = {
  isOnFullScreen: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  onSetFullscreen: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSetFullscreen: (isOnFullScreen) => dispatch(setLoggedUserIsOnFullScreen(isOnFullScreen)),
});

export default connect(null, mapDispatchToProps)(NoPresenter);

