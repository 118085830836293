import {
  CREATE_STROPHE_CONNECTION,
  CREATE_STROPHE_CONNECTION_FAIL,
  CREATE_STROPHE_CONNECTION_SUCCESS,
  LEAVE_MUC_ROOM,
  SET_JOINED_MUCS,
  SET_NICKNAME_UNIQUE_ID,
  STROPHE_CONNECTION_DISCONNECT,
  STROPHE_JOIN_MUCS,
  STROPHE_MUC_MESSAGE_RECEIVED,
  STROPHE_MUC_MESSAGE_SEND,
  CHAT_IS_CONNECTED,
} from '@constants/ActionTypes';

export const createStropheConnection = (jitsiToken) => ({
  type: CREATE_STROPHE_CONNECTION,
  jitsiToken,
});

export const createStropheConnectionSuccess = () => ({
  type: CREATE_STROPHE_CONNECTION_SUCCESS,
});

export const createStropheConnectionFail = () => ({
  type: CREATE_STROPHE_CONNECTION_FAIL,
});

export const stropheConnectionDisconnect = () => ({
  type: STROPHE_CONNECTION_DISCONNECT,
});

export const stropheJoinMucs = () => ({
  type: STROPHE_JOIN_MUCS,
});

export const stropheMucMessageReceived = (adaptMessage) => ({
  type: STROPHE_MUC_MESSAGE_RECEIVED,
  adaptMessage,
});

export const setNicknameUniqueId = (nicknameUniqueId) => ({
  type: SET_NICKNAME_UNIQUE_ID,
  nicknameUniqueId,
});

export const stropheMucMessageSend = (mucRoomName, message) => ({
  type: STROPHE_MUC_MESSAGE_SEND,
  mucRoomName,
  message,
});

export const stropheSetJoinedMucs = (joinedMucs) => ({
  type: SET_JOINED_MUCS,
  joinedMucs,
});

export const stropheLeaveMucRoom = (mucRoomName, nicknameUsedInTheRoom) => ({
  type: LEAVE_MUC_ROOM,
  mucRoomName,
  nicknameUsedInTheRoom,
});

export const chatConnectionEstablished = (isConnected) => ({
  type: CHAT_IS_CONNECTED,
  payload: isConnected,
});
