import { all, call, fork, select, take } from 'redux-saga/effects';
import { JOIN_JITSI_CONFERENCE } from '@constants/ActionTypes';
import { JITSI_PARTICIPANT_CUSTOM_PROPS, USER_ROLES } from '@constants/Settings';
import JitsiConference from '@jitsi/JitsiConference';
import { stageChannel, stageSwitch } from '@sagas/jitsiConference/StageConferenceListeners';
import { moderatorChannel, moderatorSwitch } from '@sagas/jitsiConference/ModeratorConferenceListeners';
import { visitorChannel, visitorSwitch } from '@sagas/jitsiConference/VisitorConferenceListeners';
import { translatorChannel, translatorSwitch } from '@sagas/jitsiConference/TranslatorConferenceListeners';
import { takeEvery } from '@redux-saga/core/effects';
import { hqAudioConfig } from '@jitsi/helper';

/**
 * Method used to choose what listeners to set depending on the logged user ROLE
 * @param {object} jitsiConference
 * @param {string} userRole
 * @returns {any}
 */
function* conferenceChannel(jitsiConference, userRole) {
  switch (userRole) {
    case USER_ROLES.stage:
      return yield stageChannel(jitsiConference);
    case USER_ROLES.visitor:
      return visitorChannel(jitsiConference);
    case USER_ROLES.moderator:
      return yield moderatorChannel(jitsiConference);
    case USER_ROLES.translator:
      return yield translatorChannel(jitsiConference);
    default:
      break;
  }

  return false;
}

/**
 * Generator to do join/create a conference
 * @returns void
 */
export function* doJitsiConference() {
  const loggedUserData = yield select((state) => state.loggedUser);
  const { roomName, userRole, browserName } = loggedUserData;

  const selectedGroupUid = yield select((state) => state.groupsReducer.selectedGroup.uid);

  const jitsiConference = roomName
    ? yield new JitsiConference(roomName)
    : yield new JitsiConference(selectedGroupUid);

  const conferenceConfig = (userRole === USER_ROLES.stage || userRole === USER_ROLES.translator)
    ? hqAudioConfig() : null;
  yield jitsiConference.initConference(conferenceConfig);
  yield jitsiConference.setDisplayName(loggedUserData.encodedDisplayName);
  yield jitsiConference.setCustomProperty(JITSI_PARTICIPANT_CUSTOM_PROPS.raiseHand, false);
  yield jitsiConference.setCustomProperty(JITSI_PARTICIPANT_CUSTOM_PROPS.browserName, browserName);

  if (!sessionStorage.getItem('hasMutedLocalAudioTrack')) {
    sessionStorage.setItem('hasMutedLocalAudioTrack', 'true');
    yield jitsiConference.setCustomProperty(JITSI_PARTICIPANT_CUSTOM_PROPS.hasMutedLocalAudioTrack, 'true');
  } else {
    yield jitsiConference.setCustomProperty(JITSI_PARTICIPANT_CUSTOM_PROPS.hasMutedLocalAudioTrack, sessionStorage.getItem('hasMutedLocalAudioTrack'));
  }
  if (!sessionStorage.getItem('hasMutedLocalVideoTrack')) {
    sessionStorage.setItem('hasMutedLocalVideoTrack', 'true');
    yield jitsiConference.setCustomProperty(JITSI_PARTICIPANT_CUSTOM_PROPS.hasMutedLocalVideoTrack, 'true');
  } else {
    yield jitsiConference.setCustomProperty(JITSI_PARTICIPANT_CUSTOM_PROPS.hasMutedLocalVideoTrack, sessionStorage.getItem('hasMutedLocalVideoTrack'));
  }

  const channel = yield call(conferenceChannel, jitsiConference, userRole);

  yield jitsiConference.join();

  while (true) {
    const channelResult = yield take(channel);

    switch (userRole) {
      case USER_ROLES.stage:
        yield fork(stageSwitch, channelResult, jitsiConference);
        break;

      case USER_ROLES.visitor:
        yield fork(visitorSwitch, channelResult, jitsiConference);
        break;

      case USER_ROLES.moderator:
        yield fork(moderatorSwitch, channelResult, jitsiConference);
        break;

      case USER_ROLES.translator:
        yield fork(translatorSwitch, channelResult, jitsiConference);
        break;

      default:
        break;
    }
  }
}

export function* actionsWatcher() {
  yield takeEvery(JOIN_JITSI_CONFERENCE, doJitsiConference);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
