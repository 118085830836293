import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Moment from 'moment';
import CustomScrollbars from '@util/CustomScrollbars';
import Conversation from '@components/chat/Conversation';
import {
  setLoggedUserConversation,
  setLoggedUserIsOnFullScreen,
  updateReadMessages
} from '@actions/loggedUser/LoggedUserActions';
import {connect} from 'react-redux';
import {Badge, Col, Input, Row, Tooltip,} from 'antd';
import {ReactComponent as HandIcon} from '@assets/images/actions-icons/hand_icon_white.svg';
import {ReactComponent as HandIconSelected} from '@assets/images/actions-icons/hand_icon_black.svg';
import {ReactComponent as ChatIcon} from '@assets/images/actions-icons/chat_icon_black.svg';
import {ReactComponent as SendMessageIcon} from '@assets/images/actions-icons/sendMessage_icon_black.svg';
import {injectIntl} from 'react-intl';
import JitsiConference from '@jitsi/JitsiConference';
import IntlMessages from '../../util/IntlMessages';
import {useDevicesButtonsActionsHook} from '../../CustomHooks/useDevicesButtonsActionsHook';
import {
  constructMucRoomName,
  toggleFullscreenHelper,
} from '../../CustomHooks/HelperFuncs';
import { stropheMucMessageSend } from '@actions/mucs/MucsActions';
import { chatObjectToArray, chatArrayToObject } from '../../CustomHooks/HelperFuncs';

const { TextArea } = Input;

const ChatComponent = (props) => {
  const {
    conversationList,
    conferenceModeratorsIds,
    setLoggedUserConversation,
    deviceType,
    intl,
    updateReadMessages,
    countUnreadMessages,
    unreadMessages,
    hasRaisedHand,
    isOnFullScreen,
    onSetFullscreen,
    hasMutedLocalAudioTrack,
    hasMutedLocalVideoTrack,
    hasMicrophoneDevice,
    browserName,
    uid,
    onSendMessage,
    slotUid,
    chatConnectionEstablished,
  } = props;

  const [message, setMessage] = useState('');
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
  /**
   * Checking if there are messages (for the user) in the storage.
   * (Used on situation where the user refresh the page while chatting)
   */
  const conversation = _.isEmpty(JSON.parse(sessionStorage.getItem('visitorConversation')))
    ? {}
    : JSON.parse(sessionStorage.getItem('visitorConversation')).conversation;
  const [messagesList, setMessagesList] = useState(conversation);
  const inputRef = useRef();

  const {
    toggleRaiseHand,
    raiseHandTooltip,
  } = useDevicesButtonsActionsHook(
    intl,
    hasRaisedHand,
    hasMutedLocalAudioTrack,
    hasMutedLocalVideoTrack,
    null,
    hasMicrophoneDevice,
  );

  useEffect(() => {
    setLoggedUserConversation(messagesList);

    setTimeout(() => {
      const scrollableChatNode = document.querySelector('.gx-chat-list-scroll > div');

      if (scrollableChatNode) {
        scrollableChatNode.scrollTop = scrollableChatNode.scrollHeight;
      }
    }, 100);
  }, [messagesList]);

  /**
   * Function to send the message to the moderator
   */
  const sendMessage = () => {
    const roomName = constructMucRoomName(slotUid, uid);
    onSendMessage(roomName, message);
    setMessage('');
  };

  /**
   * Function to handle message sending from the button click
   */
  const handleMessageSendingFromButton = () => {
    sendMessage();
  };

  /**
   * Handle any key press on the chat.
   * If the key is 'enter' WITHOUT 'shift' and there is a moderator we send it
   */
  const handleKeyPress = (e) => {
    e.persist();
    e.preventDefault();

    if (e.which === 13 && !e.shiftKey) { // checking if ENTER is pressed WITHOUT shift
      sendMessage();
    }
  };

  /**
   * Update the message in the state of the component
   */
  const updateMessageValue = (event) => {
    setMessage(event.target.value);
  };

  /**
   * @description Updates conversation list. When chat textarea is focused,
   * change received messages as read
   * @returns {void}
   */
  const checkReadMessages = () => {
    let copyConversation = conversationList;
    if (isTextAreaFocused && copyConversation) {
      copyConversation = copyConversation.map((msg) => {
        const modifiedMessage = { ...msg, read: true };

        return modifiedMessage;
      });
    }

    if (copyConversation && copyConversation.length > 0) {
      updateReadMessages(chatArrayToObject(copyConversation));
    }
  };

  useEffect(() => {
    checkReadMessages();
  }, [isTextAreaFocused]);

  /**
   * @description Updates state on focus text area
   * @returns {void}
   */
  const onFocusTextArea = () => {
    setIsTextAreaFocused(true);
  };

  /**
   * Function to toggle the fullscreen
   */
  const toggleFullscreen = (isItOnFullscreen) => toggleFullscreenHelper(isItOnFullscreen, onSetFullscreen);

  /**
   * @description Updates state on blur text area
   * @returns {void}
   */
  const onBlurTextArea = () => {
    setIsTextAreaFocused(false);
  };

  /**
   * @description Counter for unread messages
   * @returns {Number}
   */
  const unReadMessagesCount = () => {
    let counter = 0;
    if (conversationList) {
      conversationList.forEach((msg) => {
        if (!msg.read) {
          counter += 1;
        }
      });
    }

    return counter;
  };

  useEffect(() => {
    const unReadMessages = unReadMessagesCount();
    countUnreadMessages(unReadMessages);
  });

  const isHLS = JSON.parse(sessionStorage.getItem('streamTypeHLS'));
  return (
    <div className="userViewChat">
      <div className={`userViewChat--${deviceType}`}>
        <Row className="userViewChat__desktopButtons">
          <div className="userViewChat__desktopButtonsWrapper">

            {!isHLS && <Tooltip
              placement="topRight"
              title={raiseHandTooltip}
            >
              <div
                className={`userViewActionButton userViewActionButton--${deviceType} ${hasRaisedHand ? 'userViewActionButton--selected' : ''}`}
                onClick={() => toggleRaiseHand()}
              >
                { hasRaisedHand ? <HandIconSelected /> : <HandIcon /> }
              </div>
            </Tooltip>
            }
            <Badge
              count={unreadMessages > 0 ? unreadMessages : null}
              className="userViewActionButton__iconBadge"
              size="small"
            >
              <div
                className={`userViewActionButton userViewActionButton--${deviceType} userViewActionButton--selected`}
              >
                <ChatIcon onClick={() => toggleFullscreen(!isOnFullScreen)} />
              </div>
            </Badge>
          </div>
        </Row>
        <Row className="userViewChat__chatHeading">
          <span>
            <IntlMessages id="chat.users.heading" />
          </span>
        </Row>
      </div>
      <CustomScrollbars
        className={
          `gx-chat-list-scroll
           gx-chat-list-scroll--${deviceType}--${browserName}
           gx-chat-list-scroll--${deviceType}`
        }
        devicetype={deviceType}
      >
        <Conversation conversation={conversationList} />
      </CustomScrollbars>
      <div className={`gx-chat-main-footer userViewChat__input userViewChat__input--${deviceType}`}>
        <div>
          <Row align="middle" justify="space-around" gutter={5}>
            <Col xs={22} sm={22} md={19} lg={19} xl={22}>
              <TextArea
                autoSize={{
                  minRows: 1,
                  maxRows: 1,
                }}
                ref={inputRef}
                onKeyUp={handleKeyPress}
                onChange={updateMessageValue}
                value={message}
                placeholder={chatConnectionEstablished ? intl.formatMessage({ id: 'userView.textarea.placeholder' }) : intl.formatMessage({ id: 'userView.textarea.tryingToConnect' })}
                onFocus={onFocusTextArea}
                onBlur={onBlurTextArea}
                className="userViewChat__textarea"
                disabled={!chatConnectionEstablished}
              />
            </Col>
            <Col span={2} offset={2}>
              <div className={`userViewChat__sendMessageButtonWrapper${!chatConnectionEstablished ? '--disabled' : ''}`} role="presentation" onClick={() => handleMessageSendingFromButton()}>
                <SendMessageIcon className="userViewChat__sendMessageIcon" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
ChatComponent.propTypes = {
  conferenceModeratorsIds: PropTypes.arrayOf(PropTypes.string),
  hasMutedLocalAudioTrack: PropTypes.bool.isRequired,
  hasMutedLocalVideoTrack: PropTypes.bool.isRequired,
  isRaisedHand: PropTypes.bool,
  loggedUser: PropTypes.object,
  setLoggedUserConversation: PropTypes.func,
  conversationList: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updateReadMessages: PropTypes.func,
  hasMicrophoneDevice: PropTypes.bool.isRequired,
  browserName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSetFullscreen: PropTypes.func.isRequired,
  uid: PropTypes.string,
  onSendMessage: PropTypes.func.isRequired,
  slotUid: PropTypes.string,
  chatConnectionEstablished: PropTypes.bool
};

const mapStateToProps = ({ loggedUser, conferenceReducer, mucsReducer }) => ({
  conferenceModeratorsIds: conferenceReducer.moderatorsIds,
  conversationList: chatObjectToArray(loggedUser.conversation),
  hasRaisedHand: loggedUser.hasRaisedHand,
  uid: loggedUser.uid,
  hasMutedLocalAudioTrack: loggedUser.hasMutedLocalAudioTrack,
  hasMutedLocalVideoTrack: loggedUser.hasMutedLocalVideoTrack,
  hasMicrophoneDevice: loggedUser.hasMicrophoneDevice,
  slotUid: loggedUser.roomName,
  chatConnectionEstablished: mucsReducer.chatConnectionEstablished,
});

const mapDispatchToProps = (dispatch) => ({
  setLoggedUserConversation: (message) => dispatch(setLoggedUserConversation(message)),
  updateReadMessages: (conversation) => dispatch(updateReadMessages(conversation)),
  onSetFullscreen: (isOnFullScreen) => dispatch(setLoggedUserIsOnFullScreen(isOnFullScreen)),
  onSendMessage: (mucRoomName, message) => dispatch(stropheMucMessageSend(mucRoomName, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(React.memo(ChatComponent)));
