import {
  CREATE_STROPHE_CONNECTION,
  LEAVE_MUC_ROOM,
  STROPHE_JOIN_MUCS,
  STROPHE_MUC_MESSAGE_RECEIVED,
  STROPHE_MUC_MESSAGE_SEND,
} from '@constants/ActionTypes';
import { v4 as uuidv4 } from 'uuid';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import {
  MUC_PROPERTIES,
  StropheConnectionStatuses,
  USER_ROLES,
} from '@constants/Settings';
import { eventChannel } from 'redux-saga';
import {
  createStropheConnectionFail,
  createStropheConnectionSuccess,
  setNicknameUniqueId,
  stropheConnectionDisconnect,
  stropheJoinMucs,
  stropheMucMessageReceived,
  stropheSetJoinedMucs,
  chatConnectionEstablished,
} from '@actions/mucs/MucsActions';
import { conferencePrivateMessageReceivedGeneratorVisitor } from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/VisitorCaseGenerators';
import Moment from 'moment';
import { conferencePrivateMessageReceivedGeneratorModerator } from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/ModeratorCaseGenerators';
import { setLoggedUserConversation, fetchLoggedUser } from '@actions/loggedUser/LoggedUserActions';
import {
  LoggerHeaders,
  LoggerMessage,
  LoggerType,
  LogMessage,
} from '../../../CustomHooks/LoggerHelper';
import {
  constructMucRoomName,
  constructTheNicknameForMucRoom,
  getPartsOfMucRoomName,
} from '../../../CustomHooks/HelperFuncs';

/**
 * Function to get an array with 'strings' which represent the name of the MUC rooms
 * which the logged user have to join
 *
 * @param loggedUserReducer
 * @param usersReducer
 * @returns {*[]|*}
 */
function getMucsWhichHaveToJoin(loggedUserReducer, usersReducer) {
  const { userRole } = loggedUserReducer;
  const slotUid = loggedUserReducer.roomName;
  const { users } = usersReducer;
  const mucsFullNames = [];

  if (userRole === USER_ROLES.moderator && users) {
    users
      .filter((u) => u.role.toUpperCase() === USER_ROLES.visitor)
      .map((u) => u.uid)
      .forEach((uid) => mucsFullNames.push(constructMucRoomName(slotUid, uid)));
  } if (userRole === USER_ROLES.visitor) {
    const { uid } = loggedUserReducer;

    mucsFullNames.push(constructMucRoomName(slotUid, uid));
  }

  return mucsFullNames;
}

/**
 * @param url
 * @returns {any}
 */
function stropheConnectionChannel(url) {
  window.stropheConn = new window.Strophe.Connection(url, { protocol: 'wss' });

  return eventChannel((emitter) => {
    const stropheConnStatusCallback = (status) => {
      switch (status) {
        case window.Strophe.Status.CONNECTING: {
          LogMessage(LoggerType.INFO, LoggerHeaders.STROPHE_CONNECTION_CONNECTING,
            LoggerMessage.stropheConnectionConnecting(), window.stropheConn?.jid);
          emitter(StropheConnectionStatuses.CONNECTING);
          break;
        }
        case window.Strophe.Status.CONNECTED: {
          LogMessage(LoggerType.INFO, LoggerHeaders.STROPHE_CONNECTION_CONNECTED,
            LoggerMessage.stropheConnectionConnected(), window.stropheConn?.jid);
          emitter(StropheConnectionStatuses.CONNECTED);
          break;
        }
        case window.Strophe.Status.CONNFAIL: {
          LogMessage(LoggerType.ERROR, LoggerHeaders.STROPHE_CONNECTION_CONNECTION_FAILED,
            LoggerMessage.stropheConnectionConnectionFailed(), window.stropheConn?.jid);
          emitter(StropheConnectionStatuses.CONNFAIL);
          break;
        }
        case window.Strophe.Status.DISCONNECTING: {
          LogMessage(LoggerType.INFO, LoggerHeaders.STROPHE_CONNECTION_DISCONNECTING,
            LoggerMessage.stropheConnectionDisconnecting(), window.stropheConn?.jid);
          emitter(StropheConnectionStatuses.DISCONNECTING);
          break;
        }
        case window.Strophe.Status.DISCONNECTED: {
          LogMessage(LoggerType.INFO, LoggerHeaders.STROPHE_CONNECTION_DISCONNECTED,
            LoggerMessage.stropheConnectionDisconnected(), window.stropheConn?.jid);
          emitter(StropheConnectionStatuses.DISCONNECTED);
          break;
        }
        case window.Strophe.Status.AUTHENTICATING: {
          LogMessage(LoggerType.INFO, LoggerHeaders.STROPHE_CONNECTION_AUTHENTICATING,
            LoggerMessage.stropheConnectionAuthenticating(), window.stropheConn?.jid);
          emitter(StropheConnectionStatuses.AUTHENTICATING);
          break;
        }
        case window.Strophe.Status.AUTHFAIL: {
          LogMessage(LoggerType.ERROR, LoggerHeaders.STROPHE_CONNECTION_AUTHFAIL,
            LoggerMessage.stropheConnectionAuthFail(), window.stropheConn?.jid);
          emitter(StropheConnectionStatuses.AUTHFAIL);
          break;
        }
        default: break;
      }
    };

    /* eslint-disable no-undef */
    window.stropheConn.connect(JitsiDomains.domain, null, stropheConnStatusCallback);

    return () => {
    };
  });
}

let kaTimeout = 0;

function cancelKeepAlive() {
  clearTimeout(kaTimeout);
  kaTimeout = 0;
}

// Websocet connection from Strophejs gets disconnection timeout after 60 sec if
// there is no data transfer so we send a ping request to keep the chat from disconnecting
function keepConnectionAlive() {
  if (kaTimeout) {
    cancelKeepAlive();
  }
  kaTimeout = setTimeout(() => {
    if (window.stropheConn?.connected && !window.stropheConn?.disconnecting) {
      window.stropheConn.ping.ping();
      kaTimeout = 0;
      keepConnectionAlive();
    }
  }, 50000);
}

function* onCreateStropheConnection({ jitsiToken }) {
  const url = yield MUC_PROPERTIES.WSS_SERVICE(jitsiToken);
  const stropheConnStatusCallbackChannel = yield call(stropheConnectionChannel, url);
  while (true) {
    const status = yield take(stropheConnStatusCallbackChannel);
    let isInConference = false;

    switch (status) {
      case StropheConnectionStatuses.CONNFAIL:
      case StropheConnectionStatuses.AUTHFAIL:
        yield put(createStropheConnectionFail());
        cancelKeepAlive();
        break;
      case StropheConnectionStatuses.DISCONNECTED:
        yield put(stropheConnectionDisconnect());
        yield put(chatConnectionEstablished(false));
        cancelKeepAlive();
        isInConference = yield select((state) => state.loggedUser.isInConference);
        if (isInConference) {
          yield put(fetchLoggedUser());
        }
        break;
      case StropheConnectionStatuses.CONNECTED:
        yield window.stropheConn.muc.init(window.stropheConn);
        keepConnectionAlive();
        yield put(createStropheConnectionSuccess());
        yield put(setNicknameUniqueId(uuidv4()));
        yield put(stropheJoinMucs());
        yield put(chatConnectionEstablished(true));
        break;
      default: break;
    }
  }
}

/**
 * @param xmlResponse
 * @param emitter
 * @returns {Promise<unknown>}
 */
const MucMessageHandler = (xmlResponse, emitter) => {
  /**
   * To WHO is the message
   * @type {string}
   */
  const to = xmlResponse.getAttribute('to');

  /**
   * from WHO is the message
   * @type {string} - the structure is {ROOM_NAME}@conference.{SERVER_NAME}/{USER_NICKNAME}
   */
  const from = xmlResponse.getAttribute('from');

  /**
   * The type of the message
   * @type {string} - 'chat' (private message) OR 'groupchat' (public message)
   */
  const type = xmlResponse.getAttribute('type');

  /**
   * The ID of the message (if there is any)
   * @type {string}
   */
  const id = xmlResponse.getAttribute('id');

  /**
   * The body of the XML response
   */
  const body = xmlResponse.getElementsByTagName('body');

  /**
   * The message XML tag from the body
   * @type {*}
   */
  let message = window.Strophe.getText(body[0]);

  /**
   * The node of the 'from' string
   * (visitor8room@conference.dev.digitalcon.center/visitor@_@someUniqueId)
   * the node is "visitor8room"
   * @type {null}
   */
  const node = window.Strophe.getNodeFromJid(from);

  /**
   * The resource of the 'from' string
   * (visitor8room@conference.dev.digitalcon.center/visitor@_@someUniqueId)
   * the node is "visitor@_@someUniqueId"
   * @type {null}
   */
  const resource = window.Strophe.getResourceFromJid(from);

  if (!message) {
    return Promise.resolve(null);
  }

  message = window.Strophe.xmlunescape(message);

  return Promise.resolve(emitter({
    to,
    from,
    type,
    id,
    message,
    node,
    resource,
  }));
};

/**
 *
 * @returns {any}
 * @param mucsToJoin
 * @param nickNameForMucs
 */
function stropheMucsChannel(mucsToJoin, nickNameForMucs) {
  return eventChannel((emitter) => {
    mucsToJoin.forEach((mucRoomFullName) => {
      window.stropheConn.muc.join(mucRoomFullName, nickNameForMucs,
        (xmlResponse) => MucMessageHandler(xmlResponse, emitter), null);
    });

    return () => {
      mucsToJoin.forEach((mucRoomFullName) => {
        window.stropheConn.muc.leave(mucRoomFullName, nickNameForMucs);
      });
    };
  });
}

function* onStropheMucMessageReceived({ adaptMessage }) {
  const loggedUserRole = yield select((state) => state.loggedUser.userRole);

  if (loggedUserRole === USER_ROLES.moderator) {
    yield conferencePrivateMessageReceivedGeneratorModerator(adaptMessage);
  } else {
    yield conferencePrivateMessageReceivedGeneratorVisitor(adaptMessage, false);
  }
}

function* onStropheJoinMucs() {
  const loggedUserReducer = yield select((state) => state.loggedUser);
  const usersReducer = yield select((state) => state.usersReducer);
  const mucsReducer = yield select((state) => state.mucsReducer);

  const mucsToJoin = getMucsWhichHaveToJoin(loggedUserReducer, usersReducer);
  const role = loggedUserReducer.userRole;
  const { nicknameUniqueId } = mucsReducer;
  const nickName = constructTheNicknameForMucRoom(role, nicknameUniqueId);

  yield put(stropheSetJoinedMucs(mucsToJoin));
  const channel = yield call(stropheMucsChannel, mucsToJoin, nickName);

  while (true) {
    const messageReceivedStanza = yield take(channel);

    if (!messageReceivedStanza) {
      return;
    }

    const resourceParts = yield messageReceivedStanza.resource.split('@_@');
    const senderNicknameUniqueId = resourceParts[1];
    const senderRole = resourceParts[0];

    const messageType = yield senderRole.toUpperCase() === loggedUserReducer.userRole.toUpperCase() || senderNicknameUniqueId === mucsReducer.nicknameUniqueId ? 'sent' : 'received';
    const { userUid } = getPartsOfMucRoomName(messageReceivedStanza.node);

    const adaptMessage = {
      message: messageReceivedStanza.message,
      type: messageType,
      userId: userUid,
      sentAt: Moment().format('hh:mm:ss A'),
      messageId: messageReceivedStanza.id,
      read: messageType !== 'received',
    };

    yield put(stropheMucMessageReceived(adaptMessage));
  }
}

function* onStropheMucMessageSend({ mucRoomName, message }) {
  yield window.stropheConn.muc.groupchat(mucRoomName, message, null, `messageId_${uuidv4()}`);
}

function* onStropheLeaveMucRoom({ mucRoomName, nicknameUsedInTheRoom }) {
  yield window.stropheConn.muc.leave(mucRoomName, nicknameUsedInTheRoom, null);
  yield put(setLoggedUserConversation({}));
}

export function* actionsWatcher() {
  yield takeEvery(CREATE_STROPHE_CONNECTION, onCreateStropheConnection);
  yield takeEvery(STROPHE_JOIN_MUCS, onStropheJoinMucs);
  yield takeEvery(STROPHE_MUC_MESSAGE_RECEIVED, onStropheMucMessageReceived);
  yield takeEvery(STROPHE_MUC_MESSAGE_SEND, onStropheMucMessageSend);
  yield takeEvery(LEAVE_MUC_ROOM, onStropheLeaveMucRoom);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
