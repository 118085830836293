import { useEffect } from 'react';
import JitsiConnection from '@jitsi/JitsiConnection';
import JitsiConference from '@jitsi/JitsiConference';

/**
 * Custom react hook to disconnect from jitsi.
 * So when page closed/refreshed, we will
 * be sure that we first disconnect from jitsi.
 */
export function useConferenceCleanupHook() {
  /**
   * @description Effect which is used WHEN the page closed/refreshed, to
   * disconnect from the jitsi server
   *
   * @description So when the page is reloaded we have the authentication data in sessionStorage.
   * So we can just disconnect on component dismount or page refresh,
   * then the app will take care to get us back
   */
  useEffect(() => {
    const destroyJitsiConnection = () => {
      JitsiConnection.disconnect()
        .then(
          () => {},
          (error) => console.log(error),
        );
    };

    window.addEventListener('beforeunload', destroyJitsiConnection);

    return () => {
      window.removeEventListener('beforeunload', destroyJitsiConnection);
    };
  }, []);
}
