import _ from 'lodash';
import {createLocalTracksOptions} from '@jitsi/helper';
import {USER_ROLES} from '@constants/Settings';

class JitsiTrack {
  /**
   * @description Create the local tracks
   * @returns Promise
   */
  static createLocalTracks(options = null) {
    const creatLocalTracksOptions = options || {
      devices: ['audio', 'video'],
    };

    return window.JitsiMeetJS.createLocalTracks(creatLocalTracksOptions)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch(() => {
        return Promise.reject('notification.localTrackCreationError');
      });
  }

  /**
   * Add a track to the room (JitsiConference)
   * @param track
   */
  static addTrackToRoom(track) {
    window.audi.room.addTrack(track);
  }

  /**
   * Function to replace a track (alternative to the removeTrack or addTrack methods)
   *
   * @param oldTrack
   * @param newTrack
   * @return {*}
   */
  static replaceTrack(oldTrack, newTrack) {
    return window.audi.room.replaceTrack(oldTrack, newTrack);
  }

  /**
   * Function to check if we have local track
   * with particular type in the conference
   *
   * @param trackType
   * @return {boolean}
   */
  static checkIfLocalTrackTypeAlreadyInConference(trackType) {
    const currentTracks = window.audi.room.getLocalTracks();

    return currentTracks.findIndex((tr) => tr.type === trackType) === -1;
  }

  /**
   * @description Helper function to add tracks to the room (JitsiConference)
   * @param tracks
   */
  static addLocalTracksToTheConference(tracks) {
    for (const track of tracks) {
      if (this.checkIfLocalTrackTypeAlreadyInConference(track.type)) {
        this.replaceTrack(null, track)
          .then(() => '', (error) => '');
      }
    }
  }

  /**
   * @description Function which gets all our computer devices (microphones, speakers, cameras)
   * @returns {Promise<unknown>}
   */
  static enumerateDevices() {
    return new Promise((resolve, reject) => {
      window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
        resolve(
          devices,
        );
      });
    });
  }

  /**
   * @description Helper function the return our tracks used in the conference
   * @returns {Promise<unknown>}
   */
  static getLocalTracksFromTheConferenceWindowObject() {
    return new Promise((resolve, reject) => {
      resolve(window.audi.room.getLocalTracks());
    });
  }

  /**
   * @description Function which we use when the user change a device
   *
   * Explanation:
   * 1. When the user change his camera, we get our tracks (video, audio) used in the conference
   * 2. We check what type of a track the user want to change (video, audio)
   * 3. We stop and then remove our current (video/audio) track from the conference
   * 4. We call the createLocalTracks() function to make new local tracks which will be attached
   * to the conference
   *
   * @param deviceID - the new device ID
   * @param deviceType - the device type we want to change
   *
   * @returns Promise
   * @throws Will throw error if there is error with tracks creation OR with track adding
   */
  static changeLocalTrackOnTheConferenceWindowObject(deviceID, deviceType) {
    const tracks = window.audi.room.getLocalTracks();
    const trackForChange = tracks.find((tr) => (tr.getType() === deviceType.type));

    if (trackForChange) {
      const options = {
        devices: [deviceType.type],
      };

      if (deviceType.type === 'video') {
        Object.assign(options, { cameraDeviceId: deviceID });
      } else if (deviceType.type === 'audio') {
        Object.assign(options, { micDeviceId: deviceID });
      }

      return this.createLocalTracks(options)
        .then((createdLocalTracks) => {
          JitsiTrack.removeLocalTrack(trackForChange)
            .then(() => {
              this.addLocalTracksToTheConference(createdLocalTracks);
            })
            .catch(() => {
              throw new Error('notification.localTrackChangeError');
            });
        })
        .catch(() => {
          return Promise.reject('notification.localTrackChangeError');
        });
    }

    return Promise.reject('notification.localTrackChangeError');
  }

  /**
   * @description Function which we use to clear all our tracks from the conference
   * Used usually when we leave or disconnects from the server.
   *
   * @description <b>Note:</b> the difference between "track.dispose()" and the "removeTrack(track)"
   * is that 'dispose' is going to remove the track and also make it 'unusable'. So for example
   * if we try to add the same track again in the conference, it wont work.
   */
  static clearLocalTracksFromTheConference() {
    if (!_.isEmpty(window.audi.room)) {
      const tracks = window.audi.room.getLocalTracks();

      tracks.forEach((tr) => {
        tr.dispose();
      });
    }
  }

  /**
   * @description Function to remove ALL local tracks from the conference
   */
  static removeLocalTracks() {
    if (!_.isEmpty(window.audi.room)) {
      const tracks = window.audi.room.getLocalTracks();

      tracks.forEach((tr) => {
        JitsiTrack.removeLocalTrack(tr);
      });
    }
  }

  /**
   * @description Function to remove ONE local track from the conference
   * @param track
   */
  static removeLocalTrack(track) {
    if (!_.isEmpty(window.audi.room)) {
      return window.audi.room.removeTrack(track);
    }

    throw new Error('There is no conference object');
  }

  /**
   * Function to remove ONE local track by given type ('audio', 'video')
   * @param {string} type - audio or video
   * @return Promise
   */
  static removeLocalTrackByType(type) {
    if (!_.isEmpty(window.audi.room)) {
      const tracks = window.audi.room.getLocalTracks();

      const track = tracks.find((tr) => tr.getType() === type);
      if (track) {
        return this.replaceTrack(track, null);
      }

      return Promise.reject(`Remote track with ${type} not found`);
    }

    return Promise.reject(`Not in conference`);
  }

  /**
   * Function to remove the local tracks from the conference and
   * after that to make new track locally (WITHOUT adding them to the coference)
   * @param trackType
   * @return {Promise<Promise>}
   */
  static async removeAndRecreateVisitorTrack(trackType) {
    const localTracksOptions = createLocalTracksOptions(true, true, USER_ROLES.visitor);

    try {
      await JitsiTrack.removeLocalTrackByType(trackType);

      return JitsiTrack.createLocalTracks(localTracksOptions);
    } catch (e) {
      return Promise.reject('Error on removing/creating tracks');
    }
  }

  /**
   * @description Function to check if a participant has a track by given type
   * @param {string} participantId - id of the participant in the conference
   * @param {string} trackType - the track type [audio, video]
   * @returns {boolean}
   */
  static checkIfParticipantHasParticularTrack(participantId, trackType) {
    if (!_.isEmpty(window.audi.room)) {
      const participants = window.audi.room.getParticipants();
      const participant = participants.find((p) => p.getId() === participantId);

      if (!participant) {
        return false;
      }
      const tracks = participant.getTracks();

      if (tracks.length === 0) {
        return false;
      }

      return tracks.find((tr) => tr.getType() === trackType);
    }

    return false;
  }

  /**
   * Function to check if user track is muted
   * @param userId
   * @param trackType - video, audio
   * @returns {boolean|*}
   */
  static checkUserTrackMutedByUserId(userId, trackType) {
    const participant = window.audi.room.getParticipants()
      .find((p) => p.getId() === userId);

    if (!participant) {
      return true;
    }
    const tracks = participant.getTracksByMediaType(trackType);

    if (tracks.length === 0) {
      return true;
    }

    return tracks[0].isMuted();
  }
}

export default JitsiTrack;
