import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {
  fetchSlotFailure,
  fetchSlotSuccess,
  onFetchSlotsByEventSuccess,
  updateSlotSuccess,
} from '@actions/groups/GroupsActions';
import {FETCH_GROUPS_BY_EVENT_REQUEST, FETCH_SLOT_REQUEST, UPDATE_SLOT_REQUEST,} from '@constants/ActionTypes';
import {ENDPOINTS} from '@constants/Endpoints';
import {fetchError} from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * @description Request groups data
 * @param {Number} id
 * @returns {Object}
 */
const doFetchEventGroupsData = async (id) => {
  return await RestManager.request(
    `${ENDPOINTS.getGroups}/${id}/slots`,
    'GET',
    [{ name: 'Authorization', value: `Bearer ${sessionStorage.getItem('startToken')}` }]
  );
};

/**
 * @description Get groups data
 * @param {Number} param
 * @returns {Object}
 */
function* fetchEventGroupsList({ id }) {
  try {
    const groupsData = yield call(doFetchEventGroupsData, id);
    if (groupsData) {
      yield put(onFetchSlotsByEventSuccess(groupsData));
    }
  } catch (error) {
    yield put(fetchSlotFailure());
    yield put(fetchError(error));
  }
}

/**
 * @description Get slot by id
 * @param {Number} slotId
 * @returns {Object}
 */
const fetchSlotById = async (slotId) => {
  return await RestManager.request(`${ENDPOINTS.getSlots}/${slotId}`, 'GET', [{ name: 'Authorization', value: `Bearer ${sessionStorage.getItem('startToken')}` }]);
};

/**
 * @description Get single slot data
 * @param {Number} param
 * @returns {Object}
 */
function* doFetchSlotById({ slotId }) {
  try {
    const fetchedSlot = yield call(fetchSlotById, slotId);

    if (fetchedSlot) {
      yield put(fetchSlotSuccess(fetchedSlot));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Triggers endpoint for update slot
 * @param {Number} slotId
 * @param {Object} data
 * @returns {Object}
 */
const updateSlot = async (slotId, data) => {
  return await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.updateSlot}/${slotId}`,
    'PATCH',
    data,
  );
};

/**
 * @description Updates slot
 * @param {Number} slotId
 * @param {Object} data
 * @returns {Object}
 */
function* doUpdateSlot({ slotId, data }) {
  try {
    const updatedData = yield call(updateSlot, slotId, data);
    if (updatedData) {
      yield put(updateSlotSuccess(updatedData));
      const eventId = data.event_id;
      yield call(fetchEventGroupsList, { id: eventId });
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_GROUPS_BY_EVENT_REQUEST, fetchEventGroupsList);
  yield takeEvery(FETCH_SLOT_REQUEST, doFetchSlotById);
  yield takeEvery(UPDATE_SLOT_REQUEST, doUpdateSlot);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
