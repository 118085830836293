import React from 'react';
import PropTypes from 'prop-types';
import ReceivedMessageCell from './ReceivedMessageCell/index';
import SentMessageCell from './SentMessageCell/index';

const Conversation = ({
  conversation, selectedUser, intl,
}) => (
  <div className="gx-chat-main-content">
    {conversation ? conversation.map((message, index) => (message.type === 'sent'
      ? (
        <SentMessageCell
          key={index}
          conversation={message}
        />
      )
      : (
        <ReceivedMessageCell
          key={index}
          conversation={message}
          user={selectedUser}
          intl={intl}
        />
      ))) : null}
  </div>
);

Conversation.propTypes = {
  conversation: PropTypes.array,
  selectedUser: PropTypes.object,
  intl: PropTypes.oneOfType([PropTypes.object]),
};

export default Conversation;
