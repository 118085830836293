import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Avatar, Button, Checkbox, Drawer, Input,} from 'antd';
import CustomScrollbars from 'util/CustomScrollbars';
import Moment from 'moment';

import ChatUserList from '@components/chat/ChatUserList';
import Conversation from '@components/chat/Conversation/index';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@components/CircularProgress/index';
import PredefinedMessages from './PredefinedMessages';
import {
  addMessageToConversation,
  checkMessageAsRead,
  onFetchUsers,
  setUsersFromStorage,
  toggleParticipantChat,
} from '@actions/users/UsersActions';
import {
  addPredefinedMessage,
  deletePredefinedMessage,
  onFetchPredefinedMessages,
  savePredefinedMessage,
} from '@actions/predefinedMessages/PredefinedMessagesActions';
import {onFetchEvent} from '@actions/events/EventsActions';
import {fetchSlot} from '@actions/groups/GroupsActions';
import LanguageFilter from './LanguageFilter';
import {CHAT_STATUS, USER_ROLES} from '@constants/Settings';
import JitsiConference from '@jitsi/JitsiConference';
import { stropheMucMessageSend } from '@actions/mucs/MucsActions';
import { constructMucRoomName, chatObjectToArray } from '../../CustomHooks/HelperFuncs';


const { TextArea } = Input;

/**
 * @description Renders chat in moderator view
 */
class Chat extends Component {
  /**
   * @description Get name initials of selected user
   * @param {Object} user
   * @returns {String}
   */
  getNameInitials = (user) => {
    const firstNameInitial = user.first_name.charAt(0);
    const lastNameInitial = user.last_name.charAt(0);

    return firstNameInitial + lastNameInitial;
  };

  /**
   * @description Shows communication view
   * @returns {JSX}
   */
  Communication = () => {
    const { message, selectedUser } = this.state;
    const { users, intl } = this.props;
    const currentUser = users.find((user) => user.user_id === selectedUser.user_id);

    return <div className="gx-chat-main">
      <div className="gx-chat-main-header">
        <span className="gx-d-block gx-d-lg-none gx-chat-btn">
          <i
            className="gx-icon-btn icon icon-chat"
            onClick={this.onToggleDrawer.bind(this)}
          />
        </span>
        <div className="gx-chat-main-header-info">
          <div className="gx-chat-avatar gx-mr-2">
            <div className="gx-status-pos">
              <Avatar
                src={selectedUser.thumb}
                className="gx-rounded-circle gx-size-60"
                alt=""
              >
                {this.getNameInitials(selectedUser)}
              </Avatar>
              <span className={`gx-status gx-${selectedUser.confProps.status}`}/>
            </div>
          </div>

          <div className="gx-chat-contact-name">
            <span>{selectedUser.first_name} {' '}</span>
            <span>{selectedUser.last_name}</span>
          </div>
        </div>

      </div>

      <CustomScrollbars className="gx-chat-list-scroll gx-chat-list-scroll--desktop">
        <Conversation
          conversation={chatObjectToArray(currentUser.confProps.conversation)}
          selectedUser={selectedUser}
          intl={intl}
        />
      </CustomScrollbars>

      <div className="gx-chat-main-footer">
        <div className="gx-flex-row gx-align-items-center" style={{ maxHeight: 51 }}>
          <div className="gx-col">
            <TextArea
              id="required"
              className="gx-border-0 ant-input gx-chat-textarea"
              onKeyUp={this._handleKeyPress.bind(this)}
              onChange={this.updateMessageValue.bind(this)}
              value={message}
              placeholder={intl.formatMessage({ id: 'chat.textarea.enabled' })}
              onFocus={this.textAreaFocus.bind(this)}
              onBlur={this.textAreaBlur.bind(this)}
            />
          </div>
          <i
            className="gx-icon-btn icon icon-sent"
            onClick={this.submitComment.bind(this)}
          />
        </div>
      </div>
    </div>
  };

  /**
   * @description Renders app users info
   * @returns {JSX}
   */
  AppUsersInfo = () => {
    return <div className="gx-chat-sidenav-main">
      <div className="gx-bg-grey-light gx-chat-sidenav-header">

        <div className="gx-chat-user-hd gx-mb-0">
          <i className="gx-icon-btn icon icon-arrow-left" onClick={() => {
            this.setState({userState: 1});
          }}/>

        </div>
        <div className="gx-chat-user gx-chat-user-center">
          <div className="gx-chat-avatar gx-mx-auto">
            <Avatar src={"https://via.placeholder.com/150x150"}
                    className="gx-size-60" alt="John Doe"/>
          </div>

          <div className="gx-user-name h4 gx-my-2">Robert Johnson</div>

        </div>
      </div>
      <div className="gx-chat-sidenav-content">

        <CustomScrollbars className="gx-chat-sidenav-scroll">
          <div className="gx-p-4">
            <form>
              <div className="gx-form-group gx-mt-4">
                <label>Mood</label>

                <Input
                  fullWidth
                  id="exampleTextarea"
                  multiline
                  rows={3}
                  onKeyUp={this._handleKeyPress.bind(this)}
                  onChange={this.updateMessageValue.bind(this)}
                  defaultValue="it's a status....not your diary..."
                  placeholder="Status"
                  margin="none"/>

              </div>
            </form>
          </div>
        </CustomScrollbars>

      </div>
    </div>
  };

  /**
   * @description Renders users list
   * @returns {JSX}
   */
  ChatUsers = () => {
    const {
      isAllUsersChecked,
      chatUsers,
      isTextAreaFocused,
      selectedUser,
      userNotFound,
    } = this.state;

    const { users, onSendMessage } = this.props;

    return (
      <div className="gx-chat-sidenav-main">
        <div className="gx-chat-sidenav-content">
          <div>
            <LanguageFilter
              users={users}
              filterUsersByLanguage={this.filterUsersByLanguage}
            />
          </div>
          <CustomScrollbars className="gx-chat-sidenav-scroll-tab">
            {chatUsers.length === 0
              ? <div className="gx-p-5">{userNotFound}</div>
              : (
                <div className="chat-sidebar">
                  <ChatUserList
                    chatUsers={chatUsers}
                    selectedSectionId={this.state.selectedSectionId}
                    onSelectUser={this.onSelectUser.bind(this)}
                    props={this.props}
                    isTextAreaFocused={isTextAreaFocused}
                    selectedUser={selectedUser}
                  />
                </div>
              )}
          </CustomScrollbars>
          <div className="all-users-checkbox">
            <Checkbox
              checked={isAllUsersChecked}
              onChange={this.toggleCheckBox}
            >
              <IntlMessages id="usersList.allUsers" />
            </Checkbox>
          </div>
        </div>
      </div>
    );
  };

  /**
   * @description Submits message on enter click
   * @param {Object} e
   * @returns {void}
   */
  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.submitComment();
    }
  };

  /**
   * @description Toggles 'All users' checkbox
   * @param {Object} e
   * @returns {void}
   */
  toggleCheckBox = (e) => {
    this.setState({ isAllUsersChecked: e.target.checked });
  }

  /**
   * @description Filters users by their language
   * @param {String} language
   * @returns {void}
   */
  filterUsersByLanguage = (language) => {
    const { users } = this.props;
    const filteredAllVisitors = users.filter(
      (user) => (user.confProps.userRole === USER_ROLES.visitor || user.confProps.userRole === null));
    const filteredByLanguage = filteredAllVisitors.filter((user) => user.language === language);
    this.sortUsers(filteredByLanguage);
    this.sortUsers(filteredAllVisitors);

    language === 'all'
      ? this.setState({ chatUsers: filteredAllVisitors })
      : this.setState({ chatUsers: filteredByLanguage });
  };

  /**
   * @description Selects user from chat list
   * @param {Object} user
   * @returns {void}
   */
  onSelectUser = (user) => {
    const { history, onToggleParticipantChat } = this.props;
    const getInfoFromStorage = JSON.parse(sessionStorage.getItem('usersInfo'));
    const getUserFromStore = this.props.users.find((u) => u.user_id === user.user_id);
    if (getUserFromStore) {
      this.setState({
        loader: true,
        selectedSectionId: getUserFromStore.user_id,
        drawerState: this.props.drawerState,
        selectedUser: getUserFromStore,
      });
    }
    onToggleParticipantChat(user.user_id);
    history.push({ pathname: `/chat/${user.user_id}`});
    setTimeout(() => {
      this.setState({loader: false});
    }, 1500);
  };

  /**
   * @description Renders communication view
   * @returns {void}
   */
  showCommunication = () => {
    return (
      <div className="gx-chat-box">
        {this.state.selectedUser === null ?
          <div className="gx-comment-box">
            <div className="gx-fs-80"><i className="icon icon-chat gx-text-muted"/></div>
            <h1 className="gx-text-muted">{<IntlMessages id="chat.selectUserChat"/>}</h1>
            <Button className="gx-d-block gx-d-lg-none" type="primary"
                    onClick={this.onToggleDrawer.bind(this)}>{<IntlMessages
              id="chat.selectContactChat"/>}</Button>

          </div>
          : this.Communication()}
      </div>)
  };

  constructor() {
    super();
    this.state = {
      loader: false,
      drawerState: false,
      userState: 1,
      selectedUser: null,
      message: '',
      chatUsers: [],
      isAllUsersChecked: false,
      predefinedMessages: [],
      isTextAreaFocused: false,
    };
    this.scrollRef = React.createRef();
  }

  /**
   * @description Updates local state, fetch events and slots on reload
   * @returns {void}
   */
  componentDidMount() {
    const {
      selectedGroup,
      users,
      predefinedMessages,
      onFetchPredefinedMessages,
    } = this.props;

    if (Object.keys(selectedGroup).length !== 0) {
      const filteredVisitors = users.filter((user) =>
        (user.confProps.userRole === USER_ROLES.visitor || user.confProps.userRole === null));

      this.sortUsers(filteredVisitors);
      this.setState({ chatUsers: filteredVisitors });
    }

    onFetchPredefinedMessages();
    this.setState({ predefinedMessages });
  }

  /**
   * @description Sorts users array in descending order by last message
   * @param {Array} users
   * @returns {Array}
   */
  sortUsers = (users) => {
    users.sort((user1, user2) => {
      const u1ConversationLength = user1.confProps.conversation
        ? user1.confProps.conversation.length : 0;
      const u2ConversationLength = user2.confProps.conversation
        ? user2.confProps.conversation.length : 0;

      const u1LastMessageTime = u1ConversationLength ? user1.confProps.conversation[u1ConversationLength - 1].sentAt : '00:00:00';
      const u2LastMessageTime = u2ConversationLength ? user2.confProps.conversation[u2ConversationLength - 1].sentAt : '00:00:00';

      return u2LastMessageTime.localeCompare(u1LastMessageTime);
    });
  }

  /**
   * @description Updates local state on change, updates communication on new message
   * @param {Object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const { users, predefinedMessages } = this.props;
    /*const getInfoFromStorage = JSON.parse(sessionStorage.getItem('usersInfo'));
    const chatUsersFromStorage = (getInfoFromStorage && getInfoFromStorage.users) || [];*/
    const filteredVisitors = users.filter((user) =>
        (user.confProps.userRole === USER_ROLES.visitor || user.confProps.userRole === null));

    this.showCommunication();

    prevProps.users.forEach((user) => {
      filteredVisitors.forEach((u) => {
        if (user.user_id === u.user_id) {
          if (JSON.stringify(user.confProps) !== JSON.stringify(u.confProps)) {
            this.sortUsers(filteredVisitors);
            this.setState({ chatUsers: filteredVisitors });
          }
        }
      });
    });

    /*if (prevProps.users.length === 0 && users.length > 0) {
      const filteredUsersFromStorage = chatUsersFromStorage.filter(
         (user) => (user.confProps.userRole === USER_ROLES.visitor || user.confProps.userRole === null));
      this.setState({ chatUsers: filteredUsersFromStorage });
    }
*/
    if (prevProps.predefinedMessages.length === 0 && predefinedMessages.length > 0) {
      this.setState({ predefinedMessages });
    }

    if (prevProps.predefinedMessages.length !== predefinedMessages.length) {
      this.setState({ predefinedMessages });
    }
  }

  /**
   * @description Toggles drawer
   * @returns {void}
   */
  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }

  /**
   * @description Updates conversation list. When chat textarea is focused,
   * change received messages as read
   * @returns {void}
   */
  checkReadMessages = () => {
    const { selectedUser } = this.state;
    const { checkMessageAsRead, users } = this.props;
    const currentUser = users.find((user) => user.user_id === selectedUser.user_id);

    let conversationCopy = chatObjectToArray(currentUser.confProps.conversation);
    if (conversationCopy) {
       conversationCopy = conversationCopy.map((message) => {
        const modifiedMessage = { ...message, read: true };

        return modifiedMessage;
      });
    }

    if (conversationCopy && conversationCopy.length > 0) {
      checkMessageAsRead(conversationCopy);
    }
  }

  /**
   * @description Focus textarea in chat
   * @returns {void}
   */
  textAreaFocus() {
    const { selectedUser } = this.state;

    const getUserFromStorage = this.props.users.find((u) => u.user_id === selectedUser.user_id);

    this.setState({
      isTextAreaFocused: true,
      selectedUser: getUserFromStorage,
    });

    this.checkReadMessages();
  }

  /**
   * @description Change state on blur
   * @returns {void}
   */
  textAreaBlur() {
    this.setState({ isTextAreaFocused: false });
  }

  /**
   * @description Adds predefined message in textarea
   * @param {String} message
   * @returns {void}
   */
  addPredefinedMessageToTextArea = (message) => {
    this.setState({ message });
  };


  /**
   * @description Gets online participants from all users list
   * @returns {Array}
   */
  getOnlineParticipants = () => {
    const { users } = this.props;
    const onlineParticipants = users.filter((user) => user.confProps.userConfId
      && (user.confProps.status === CHAT_STATUS.online
      || user.confProps.status === CHAT_STATUS.onlineRaised));

    return onlineParticipants;
  }

  /**
   * @description Sends message to all participants in the conference
   * @param {Array} participants
   * @returns {void}
   */
  submitCommentToAll(participants) {
    const { message } = this.state;

    if (message.trim() !== '') {
      const { joinedMucs, onSendMessage } = this.props;

      joinedMucs.forEach((mucRoomName) => {
        onSendMessage(mucRoomName, message);
      });

      this.setState({ message: '' });
    }
  }

  /**
   * @description Sends P2P message in conference
   * @param {Object} currentUser
   * @param {Object} participant
   * @param {Number} userIdFromStorage
   * @returns {void}
   */
  submitP2PComment(currentUser, participant) {
    const { message } = this.state;

    if (message.trim() !== '') {
      const { onSendMessage, slotUid } = this.props;
      onSendMessage(constructMucRoomName(slotUid, participant.uid), message);

      this.setState((prevState) => ({
        selectedUser: {
          ...prevState.selectedUser,
          confProps: {
            ...prevState.selectedUser.confProps,
            conversation: [...chatObjectToArray(currentUser.confProps.conversation)]
          },
        },
        message: '',
      }));
    }
  }

  /**
   * @description Submits comment in moderator chat
   * @returns {void}
   */
  submitComment() {
    const { message, selectedUser, isAllUsersChecked } = this.state;
    if (message !== '') {
      const onlineParticipants = this.getOnlineParticipants();
      const participant = this.props.users.find(
        (p) => p.uid === selectedUser.uid);


      if (!isAllUsersChecked) {
        this.submitP2PComment(selectedUser, participant);
      } else {
        this.submitCommentToAll(onlineParticipants);
      }
    }
  }

  /**
   * @description Updates message value
   * @param {Object} evt
   * @returns {void}
   */
  updateMessageValue(evt) {
    this.setState({
      message: evt.target.value,
    });
  }

  render() {
    const {
      loader,
      userState,
      drawerState,
      predefinedMessages,
      selectedUser,
    } = this.state;

    return (
      <div className="gx-main-content">
        <div className="gx-app-module gx-chat-module">
          <div className="gx-chat-module-box">
            <div className="gx-d-block gx-d-lg-none">
              <Drawer
                placement="left"
                closable={false}
                visible={drawerState}
                onClose={this.onToggleDrawer.bind(this)}
              >
                {userState === 1 ? this.ChatUsers() : this.AppUsersInfo()}
              </Drawer>
            </div>
            <div className="gx-chat-sidenav gx-d-none gx-d-lg-flex">
              {userState === 1 ? this.ChatUsers() : this.AppUsersInfo()}
            </div>
            {loader
              ? (
                <div className="gx-loader-view">
                  <CircularProgress />
                </div>
              )
              : this.showCommunication()}
            <div className="predefined-messages-wrapper">
              <PredefinedMessages
                props={this.props}
                messages={predefinedMessages}
                selectedUser={selectedUser}
                addPredefinedMessageToTextArea={this.addPredefinedMessageToTextArea}
              />
            </div>

          </div>
        </div>
      </div>
    );
  }
}

Chat.propTypes = {
  onFetchChatUsersList: PropTypes.func,
  addPredefinedMessage: PropTypes.func,
  deletePredefinedMessage: PropTypes.func,
  addMessageToConversation: PropTypes.func,
  setUsersFromStorage: PropTypes.func,
  onFetchPredefinedMessages: PropTypes.func,
  savePredefinedMessage: PropTypes.func,
  checkMessageAsRead: PropTypes.func,
  onToggleParticipantChat: PropTypes.func,
  onSendMessage: PropTypes.func,
  joinedMucs: PropTypes.arrayOf(PropTypes.string),
  slotUid: PropTypes.string,
};

const mapStateToProps = ({
  predefinedMessagesReducer,
  groupsReducer,
  usersReducer,
  mucsReducer,
  loggedUser,
}) => {
  const { users } = usersReducer || { users: [] };
  const { predefinedMessages } = predefinedMessagesReducer || { predefinedMessages: [] };
  const selectedGroup = groupsReducer.selectedGroup;
  const { joinedMucs } = mucsReducer;
  const slotUid = loggedUser.roomName;

  return {
    users,
    predefinedMessages,
    selectedGroup,
    joinedMucs,
    slotUid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchUsers: (slotId) => dispatch(onFetchUsers(slotId)),
    addPredefinedMessage: (message) => dispatch(addPredefinedMessage(message)),
    deletePredefinedMessage: (messageId) => dispatch(deletePredefinedMessage(messageId)),
    addMessageToConversation: (message) => dispatch(addMessageToConversation(message)),
    onFetchEvent: (eventId) => dispatch(onFetchEvent(eventId)),
    fetchSlot: (slotId) => dispatch(fetchSlot(slotId)),
    setUsersFromStorage: (usersList) => dispatch(setUsersFromStorage(usersList)),
    onFetchPredefinedMessages: () => dispatch(onFetchPredefinedMessages()),
    savePredefinedMessage: (messageId, message) => dispatch(savePredefinedMessage(messageId, message)),
    checkMessageAsRead: (conversation) => dispatch(checkMessageAsRead(conversation)),
    onToggleParticipantChat: (userId) => dispatch(toggleParticipantChat(userId)),
    onSendMessage: (mucRoomName, message) => dispatch(stropheMucMessageSend(mucRoomName, message)),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(Chat)));
