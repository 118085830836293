import Logger from 'js-logger';

export const LoggerType = {
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
  DEBUG: 'DEBUG',
};

export const LoggerHeaders = {
  USER_JOINED: 'USER_JOINED',
  USER_LEFT: 'USER_LEFT',
  USER_KICK: 'USER_KICK',
  USER_TRACK_ADDED: 'USER_TRACK_ADDED',
  USER_TRACK_REMOVED: 'USER_TRACK_REMOVED',
  USER_RAISE_HAND: 'USER_RAISE_HAND',
  USER_TRACK_MUTE_STATUS_CHANGED: 'USER_TRACK_MUTE_STATUS_CHANGED',
  PRIVATE_MESSAGE_RECEIVED: 'PRIVATE_MESSAGE_RECEIVED',
  STROPHE_CONNECTION_CONNECTING: 'STROPHE_CONNECTION_CONNECTING',
  STROPHE_CONNECTION_CONNECTED: 'STROPHE_CONNECTION_CONNECTED',
  STROPHE_CONNECTION_CONNECTION_FAILED: 'STROPHE_CONNECTION_FAILED',
  STROPHE_CONNECTION_DISCONNECTING: 'STROPHE_CONNECTION_DISCONNECTING',
  STROPHE_CONNECTION_DISCONNECTED: 'STROPHE_CONNECTION_DISCONNECTED',
  STROPHE_CONNECTION_AUTHENTICATING: 'STROPHE_CONNECTION_AUTHENTICATING',
  STROPHE_CONNECTION_AUTHFAIL: 'STROPHE_CONNECTION_AUTHFAIL',

};

/**
 * Show logger message depending on the type
 *
 * @param messageType
 * @param messageHeader
 * @param message
 * @param params
 * @constructor
 */
export const LogMessage = (messageType, messageHeader, message, ...params) => {
  switch (messageType) {
    case 'WARN':
      Logger.warn(`-- LOGGER -- ${messageHeader} -> ${message}`, ...params);
      break;
    case 'INFO':
      Logger.info(`-- LOGGER -- ${messageHeader} -> ${message}`, ...params);
      break;
    case 'ERROR':
      Logger.error(`-- LOGGER -- ${messageHeader} -> ${message}`, ...params);
      break;
    case 'DEBUG':
      Logger.debug(`-- LOGGER -- ${messageHeader} -> ${message}`, ...params);
      break;
    default:
      break;
  }
};

/**
 * Object containing different logger messages
 */
export const LoggerMessage = {
  /**
   *
   * @param decodedDisplayName
   * @param userConfId
   * @returns {String} 'User "${string}"(confID: ${string}) has joined'
   */
  userJoined: (decodedDisplayName, userConfId) => `User "${decodedDisplayName.displayName}"(confID: ${userConfId}) has joined`,

  /**
   * @param userConfID
   * @returns {String} 'User with conference id "${string}" has left'
   */
  userLeft: (userConfID) => `User with conference id "${userConfID}" has left`,

  /**
   * @param track
   * @param decodedDisplayName
   * @returns {String } 'User "${string}" ${*} was added'
   */
  userTrackAdded: (track, decodedDisplayName) => {
    const trackType = track.getType();

    return `User "${decodedDisplayName.displayName}" ${trackType} was added`;
  },

  /**
   * @param track
   * @param decodedDisplayName
   * @returns {String} 'User "${string}" ${*} was removed'
   */
  userTrackRemoved: (track, decodedDisplayName) => {
    const trackType = track.getType();

    return `User "${decodedDisplayName.displayName}" ${trackType} was removed`;
  },

  userIndexWasNotFoundInTheUsersList: (userIdentifier) => `User ${userIdentifier} index was not found in the users list`,
  stropheConnectionConnecting: () => 'Strophe connection connecting',
  stropheConnectionConnected: () => 'Strophe connection success',
  stropheConnectionConnectionFailed: () => 'Strophe connection failed',
  stropheConnectionDisconnecting: () => 'Strophe connection disconnecting',
  stropheConnectionDisconnected: () => 'Strophe connection disconnected',
  stropheConnectionAuthenticating: () => 'Strophe connection authenticating',
  stropheConnectionAuthFail: () => 'Strophe connection auth failed',
  userWillBeKicked: (firstName, lastName) => `${firstName} ${lastName} will be kicked.`,
  userIsKicked: (firstName, lastName) => `${firstName} ${lastName} is kicked successfully.`,
  userIsNotKicked: (firstName, lastName) => `${firstName} ${lastName} was NOT kicked successfully.`,
};
