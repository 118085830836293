import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {Badge, Button, Menu, Modal,} from 'antd';
import PropTypes from 'prop-types';
import DataProtection from '@containers/PolicyModals/DataProtection';
import CookieGuidelines from '@containers/PolicyModals/CookieGuidelines';
import TermsOfUse from '@containers/PolicyModals/TermsOfUse';
import LegalNotice from '@containers/PolicyModals/LegalNotice';
import {USER_ROLES} from '@constants/Settings';
import {checkParticipantsTable} from '@actions/conference/ConferenceActions';
import UserSettings from '@routes/UserView/UserSettings';
import IntlMessages from '../../util/IntlMessages';
import { chatObjectToArray } from '../../CustomHooks/HelperFuncs';

const HorizontalNav = (props) => {
  const {
    users, loggedUserRole, isVertical, onCheckParticipantsTable, checkIsSeen,
  } = props;
  const location = useLocation();

  const [termsOfUseModal, setTermsOfUseModal] = useState(false);
  const [legalNoticeModal, setLegalNoticeModal] = useState(false);
  const [dataProtectionModal, setDataProtectionModal] = useState(false);
  const [cookieGuidelines, setCookieGuidelines] = useState(false);
  const [chatNotification, setChatNotification] = useState(false);
  const [visitorSettings, setVisitorSettings] = useState(false);

  const pathname = useSelector(({ settings }) => settings.pathname);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const getCurrentMenuItem = (menuItem) => (selectedKeys.indexOf(menuItem) !== -1 ? 'menu-item-link-selected' : 'menu-item-link');

  const role = loggedUserRole || null;

  /**
   * @description Shows data protection modal
   * @returns {void}
   */
  const showDataProtection = () => {
    setDataProtectionModal(true);
  };

  /**
   * @description Close data protection modal
   * @returns {void}
   */
  const closeDataProtection = () => {
    setDataProtectionModal(false);
  };

  /**
   * @description Shows revocation of consent modal
   * @returns {void}
   */
  const showRevocationOfConsent = () => {
    setCookieGuidelines(true);
  };

  /**
   * @description Close revocation of consent modal
   * @returns {void}
   */
  const closeRevocationOfConsent = () => {
    setCookieGuidelines(false);
  };

  /**
   * @description Shows terms of use modal
   * @returns {void}
   */
  const showTermsOfUse = () => {
    setTermsOfUseModal(true);
  };

  /**
   * @description Close terms of use modal
   * @return {void}
   */
  const closeTermsOfUse = () => {
    setTermsOfUseModal(false);
  };

  /**
   * @description Shows legal notice modal
   * @returns {void}
   */
  const showLegalNotice = () => {
    setLegalNoticeModal(true);
  };

  /**
   * @description Close legal notice modal
   * @return {void}
   */
  const closeLegalNotice = () => {
    setLegalNoticeModal(false);
  };

  /**
   * @description Check for unread messages in some of the users
   * @returns {void}
   */
  const checkForUnreadChat = () => {
    if (users) {
      const check = users.some((user) => (user.confProps.conversation
        ? chatObjectToArray(user.confProps.conversation).some((message) => message.read === false)
        : false));
      setChatNotification(check);
    }
  };

  useEffect(() => {
    if (location.pathname.substring(0, 5) === '/chat') {
      setChatNotification(false);
    } else {
      checkForUnreadChat();
    }
  }, [users, location]);

  useEffect(() => {
    if (location.pathname === '/participants') {
      onCheckParticipantsTable(true);
    }
  });

  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode={isVertical ? 'vertical' : 'horizontal'}
    >
      {role === USER_ROLES.moderator ? (
        <>
          <Menu.Item key="schedule">
            <Link to="/schedule" className={getCurrentMenuItem('schedule')}>
              <IntlMessages id="horizontalNav.schedule" />
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Badge
              dot={!checkIsSeen && location.pathname !== '/participants'}
              offset={[10, 0]}
            >
              <Link to="/participants" className={getCurrentMenuItem('participants')}>
                <IntlMessages id="horizontalNav.participants" />
              </Link>
            </Badge>
          </Menu.Item>
          <Menu.Item>
            <Badge
              dot={chatNotification}
              offset={[10, 0]}
            >
              <Link to="/chat" className={getCurrentMenuItem('chat')}>
                <IntlMessages id="horizontalNav.chat" />
              </Link>
            </Badge>
          </Menu.Item>
         {/* <Menu.Item> --------- HIDDEN ELEMENTS FOR NOW -----------
            <Link to="/translate" className={getCurrentMenuItem('translate')}>
              <IntlMessages id="horizontalNav.translate" />
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/settings" className={getCurrentMenuItem('settings')}>
              <IntlMessages id="horizontalNav.settings" />
            </Link>
          </Menu.Item>*/}
        </>
      ) : null}
      {role === USER_ROLES.visitor ? (
        <>
          <Menu.Item className="user-menu-item">
            <Button onClick={showDataProtection} className="user-menu-button">
              <IntlMessages id="user.menu.dataProtection" />
            </Button>
            <Modal
              title={<IntlMessages id="user.menu.dataProtection.header" />}
              visible={dataProtectionModal}
              closable={false}
              bodyStyle={{
                height: '300px',
                overflowY: 'scroll',
              }}
              footer={[
                <Button key="dataProtection" onClick={closeDataProtection}>
                  <IntlMessages id="user.menu.modal.done" />
                </Button>,
              ]}
            >
              <DataProtection />
            </Modal>
          </Menu.Item>
          <Menu.Item className="user-menu-item">
            <Button onClick={showRevocationOfConsent} className="user-menu-button">
              <IntlMessages id="user.menu.cookieGuidelines" />
            </Button>
            <Modal
              title={<IntlMessages id="user.menu.cookieGuidelines" />}
              visible={cookieGuidelines}
              closable={false}
              bodyStyle={{
                height: '300px',
                overflowY: 'scroll',
              }}
              footer={[
                <Button key="revocationOfConsent" onClick={closeRevocationOfConsent}>
                  <IntlMessages id="user.menu.modal.done" />
                </Button>,
              ]}
            >
              <CookieGuidelines />
            </Modal>
          </Menu.Item>
          <Menu.Item className="user-menu-item">
            <Button onClick={showTermsOfUse} className="user-menu-button">
              <IntlMessages id="user.menu.termsOfUse" />
            </Button>
            <Modal
              title={<IntlMessages id="user.menu.termsOfUse" />}
              visible={termsOfUseModal}
              closable={false}
              footer={[
                <Button key="termsOfUse" onClick={closeTermsOfUse}>
                  <IntlMessages id="user.menu.modal.done" />
                </Button>,
              ]}
            >
              <TermsOfUse />
            </Modal>
          </Menu.Item>
          <Menu.Item className="user-menu-item">
            <Button onClick={showLegalNotice} className="user-menu-button">
              <IntlMessages id="user.menu.legalNotice" />
            </Button>
            <Modal
              title={<IntlMessages id="user.menu.legalNotice" />}
              visible={legalNoticeModal}
              closable={false}
              bodyStyle={{
                maxHeight: '600px',
                overflowY: 'scroll',
              }}
              footer={[
                <Button key="legalNotice" onClick={closeLegalNotice}>
                  <IntlMessages id="user.menu.modal.done" />
                </Button>,
              ]}
            >
              <LegalNotice />
            </Modal>
          </Menu.Item>
          <Menu.Item className="user-menu-item">
            <Button onClick={() => setVisitorSettings(true)} className="user-menu-button">
              <IntlMessages id="user.menu.modal.settings" />
            </Button>
            <Modal
              title={<IntlMessages id="user.menu.modal.settings" />}
              visible={visitorSettings}
              closable={false}
              bodyStyle={{
                height: '210px',
              }}
              style={{ top: '15px' }}
              footer={[
                <Button key="settings" onClick={() => setVisitorSettings(false)}>
                  <IntlMessages id="user.menu.modal.done" />
                </Button>,
              ]}
            >
              <UserSettings />
            </Modal>
          </Menu.Item>
        </>
      ) : null}
    </Menu>
  );
};

HorizontalNav.propTypes = {
  users: PropTypes.array,
  loggedUserRole: PropTypes.string,
  isVertical: PropTypes.bool,
  checkIsSeen: PropTypes.bool,
  onCheckParticipantsTable: PropTypes.func,
};

const mapStateToProps = ({ usersReducer, loggedUser, conferenceReducer }) => ({
  users: usersReducer.users,
  loggedUserRole: loggedUser.userRole,
  checkIsSeen: conferenceReducer.checkIsSeen,
});

const mapDispatchToProps = (dispatch) => ({
  onCheckParticipantsTable: (bool) => (dispatch(checkParticipantsTable(bool))),
});

export default connect(mapStateToProps, mapDispatchToProps)(HorizontalNav);
