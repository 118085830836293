import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { addEvent } from '@actions/events/EventsActions';
import { v4 as uuidv4 } from 'uuid';
import languages from '../../../assets/vendors/languages.json';
import IntlMessages from '../../../util/IntlMessages';

const { RangePicker } = DatePicker;
const langs = JSON.parse(JSON.stringify(languages));
const COLUMNS_CLASS = 'eventUsersTable__column';
const { Option } = Select;

const ScheduleCreate = (props) => {
  const { intl, onSaveSchedule } = props;
  const format = 'DD.MM.YYYY HH:mm';
  const [groupsCount, setGroupsCount] = useState(0);
  const [form] = Form.useForm();
  const { Column } = Table;

  const [submitted, setSubmitted] = useState(false);

  /**
   * @description Helper function to add a row in the group users table
   * when "Add group" button is clicked
   * @param {object} users
   * @returns {object}
   */
  const setDefaultRow = (users) => {
    if (users.length === 0) {
      users.push({
        fieldKey: 0,
        isListField: true,
        key: 0,
        name: 0,
      });
    }

    return users;
  };

  /**
   * @description Helper function to set which hours in the RangePicker should be disabled
   *
   * Explanation:
   * -- IF the user choose the today date and at the moment is "14:00", we will disable
   * all hours until "14" the function will return array [0...14]
   * -- IF the user choose tomorrow day will win not disable anything
   *
   * @param {object} current
   * @returns {array}
   */
  const makeDisabledHours = (current) => {
    if (moment(current).day() === moment().day()) {
      return _.range(0, moment().hour());
    }

    return [];
  };

  /**
   * @description Helper function to set which minutes in the RangePicker should be disabled
   *
   * Explanation:
   * -- IF the user choose the today date and at the moment is "14:34", we will disable
   * all minutes until "34" the function will return array [0...34]
   * -- IF the user choose tomorrow day will win not disable anything
   *
   * @param {object} current
   * @returns {array}
   */
  const makeDisabledMinutes = (current) => {
    if (moment(current).day() === moment().day()) {
      return _.range(0, moment().minutes());
    }

    return [];
  };

  /**
   * @description Helper function to set the disabled hours and minutes
   *
   * @param {object} date
   * @param {string} partial - represents in which 'section" in
   * the range picker the user is (start, end)
   * @returns {object}
   */
  const setDisabledTime = (date, partial) => {
    if (partial === 'start') {
      return {
        disabledHours: () => makeDisabledHours(date),
        disabledMinutes: () => (moment(date).hour() === moment().hour()
          ? makeDisabledMinutes(date)
          : []),
      };
    }

    return {
      disabledHours: () => makeDisabledHours(date),
      disabledMinutes: () => (moment(date).hour() === moment().hour()
        ? makeDisabledMinutes(date)
        : []),
    };
  };

  /**
   * @description Helper function to add UID to an object
   * @param {array<object>}formUsersArr
   * @returns {[]}
   */
  const addUIDtoUsers = (formUsersArr) => {
    const newUsers = [];

    formUsersArr.forEach((user) => {
      const newUser = { ...user, uid: uuidv4() };
      newUsers.push(newUser);
    });

    return newUsers;
  };

  /**
   * @description Function to format the FormEvent data for the backend requirements
   * @param {object} eventFormData
   * @returns {{events: [], users: []}}
   */
  const formatEventDataForRequest = (eventFormData) => {
    const eventData = _.cloneDeep(eventFormData);

    const result = {
      events: [],
      users: [],
    };

    const eventObj = {
      name: eventData.event,
      title: eventData.event,
      slots: [],
    };

    eventData.groups.forEach((group) => {
      const slotObj = {
        time_start: moment(group.groupDate[0]).format(),
        time_end: moment(group.groupDate[1]).format(),
        room: group.groupName,
        participants: [],
      };

      const usersWithUID = addUIDtoUsers(group.users);
      result.users.push(...usersWithUID);

      usersWithUID.forEach((user) => slotObj.participants.push({ user_uid: user.uid }));

      eventObj.slots.push(slotObj);
    });

    result.events.push(eventObj);

    return result;
  };

  /**
   * @description Function to submit the new Event data
   * @param {object} eventFormData
   */
  const saveEventHandler = (eventFormData) => {
    if (!submitted) {
      onSaveSchedule(formatEventDataForRequest(eventFormData));
    }
  };

  return (
    <Form
      name="schedule-crete-form"
      form={form}
      initialValues={{
        language: 'English',
      }}
      onFinish={(values) => {
        setSubmitted(true);
        saveEventHandler(values);
      }}
      autoComplete="off"
      className="schedule-createForm"
    >
      <Form.Item
        label={intl.formatMessage({ id: 'schedule.create.eventName' })}
        name="event"
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'schedule.create.eventName.errorRequired',
            }),
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={intl.formatMessage({ id: 'schedule.create.eventName' })}
        />
      </Form.Item>

      <Form.List name="groups">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Card key={field.key}>
                <div className="schedule-createForm__groupWrapper">
                  <Row justify="space-between" style={{ minHeight: '56px' }}>
                    <Col>
                      <Form.Item
                        {...field}
                        name={[field.name, 'groupDate']}
                        fieldKey={[field.fieldKey, 'groupDate']}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'schedule.create.groupDate.errorRequired',
                            }),
                          },
                        ]}
                        label={intl.formatMessage({
                          id: 'schedule.create.groupDate',
                        })}
                      >
                        <RangePicker
                          className="schedule-createForm__groupDate"
                          format={format}
                          showTime={{
                            hideDisabledOptions: true,
                            minuteStep: 5,
                          }}
                          disabledDate={(current) => current < moment().add(-1, 'hours')}
                          disabledTime={(date, partial) => setDisabledTime(date, partial)}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        {...field}
                        name={[field.name, 'groupName']}
                        fieldKey={[field.fieldKey, 'groupName']}
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'schedule.create.groupName.errorRequired',
                            }),
                            whitespace: true,
                          },
                        ]}
                        label={intl.formatMessage({
                          id: 'schedule.create.groupName',
                        })}
                      >
                        <Input
                          placeholder={intl.formatMessage({
                            id: 'schedule.create.groupName',
                          })}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Popconfirm
                        title={intl.formatMessage({
                          id: 'schedule.create.removeGroupConfirm',
                        })}
                        onConfirm={() => {
                          remove(field.name);
                          setGroupsCount(groupsCount - 1);
                        }}
                      >
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'schedule.create.removeGroupPopup',
                          })}
                        >
                          <Button
                            shape="circle"
                            icon={<DeleteOutlined />}
                            className={[
                              'schedule-createForm__btn',
                              'schedule-createForm__btn--red',
                            ].join(' ')}
                          />
                        </Tooltip>
                      </Popconfirm>
                    </Col>
                  </Row>
                  <Divider />

                  <Form.List name={[field.name, 'users']}>
                    {(users, { add, remove }) => (
                      <div style={{ marginTop: '20px' }}>
                        <Table
                          className="eventUsersTable"
                          dataSource={setDefaultRow(users)}
                          rowClassName={() => 'editable-row'}
                          bordered
                          pagination={false}
                          rowKey="key"
                          scroll={{ x: true }}
                        >
                          <Column
                            dataIndex="first_name"
                            key="first_name"
                            title={intl.formatMessage({
                              id: 'schedule.create.tableFirstName',
                            })}
                            className={COLUMNS_CLASS}
                            render={(text, record, index) => (
                              <Form.Item
                                name={[index, 'first_name']}
                                fieldKey={[index, 'first_name']}
                                rules={[
                                  {
                                    required: true,
                                    message: intl.formatMessage({
                                      id:
                                        'schedule.create.tableFirstName.errorRequired',
                                    }),
                                    whitespace: true,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={intl.formatMessage({
                                    id: 'schedule.create.tableFirstName',
                                  })}
                                />
                              </Form.Item>
                            )}
                          />

                          <Column
                            dataIndex="last_name"
                            key="last_name"
                            title={intl.formatMessage({
                              id: 'schedule.create.tableLastName',
                            })}
                            className={COLUMNS_CLASS}
                            render={(text, record, index) => (
                              <Form.Item
                                name={[index, 'last_name']}
                                fieldKey={[index, 'last_name']}
                                rules={[
                                  {
                                    required: true,
                                    message: intl.formatMessage({
                                      id:
                                        'schedule.create.tableLastName.errorRequired',
                                    }),
                                    whitespace: true,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={intl.formatMessage({
                                    id: 'schedule.create.tableLastName',
                                  })}
                                />
                              </Form.Item>
                            )}
                          />

                          <Column
                            dataIndex="company"
                            key="company"
                            title={intl.formatMessage({
                              id: 'schedule.create.tableCompany',
                            })}
                            className={COLUMNS_CLASS}
                            render={(text, record, index) => (
                              <Form.Item
                                name={[index, 'company']}
                                fieldKey={[index, 'company']}
                                rules={[
                                  {
                                    required: true,
                                    message: intl.formatMessage({
                                      id:
                                        'schedule.create.tableCompany.errorRequired',
                                    }),
                                    whitespace: true,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={intl.formatMessage({
                                    id: 'schedule.create.tableCompany',
                                  })}
                                />
                              </Form.Item>
                            )}
                          />

                          <Column
                            dataIndex="user_group"
                            key="user_group"
                            title={intl.formatMessage({
                              id: 'schedule.create.tableUserGroup',
                            })}
                            className={COLUMNS_CLASS}
                            render={(text, record, index) => (
                              <Form.Item
                                name={[index, 'user_group']}
                                fieldKey={[index, 'user_group']}
                                rules={[
                                  {
                                    required: true,
                                    message: intl.formatMessage({
                                      id:
                                        'schedule.create.tableUserGroup.errorRequired',
                                    }),
                                    whitespace: true,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={intl.formatMessage({
                                    id: 'schedule.create.tableUserGroup',
                                  })}
                                />
                              </Form.Item>
                            )}
                          />

                          <Column
                            dataIndex="language"
                            key="language"
                            title={intl.formatMessage({
                              id: 'schedule.create.tableLanguage',
                            })}
                            className={COLUMNS_CLASS}
                            render={(text, record, index) => (
                              <Form.Item
                                name={[index, 'language']}
                                rules={[
                                  {
                                    required: true,
                                    message: intl.formatMessage({
                                      id:
                                        'schedule.create.tableLanguage.errorRequired',
                                    }),
                                    whitespace: true,
                                  },
                                ]}
                                initialValue="EN"
                              >
                                <Select className="eventUsersTable__languages-select">
                                  {Object.keys(langs).map((langKey) => (
                                    <Option
                                      key={langs[langKey].name}
                                      value={langKey.toUpperCase()}
                                    >
                                      {langs[langKey].name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}
                          />

                          <Column
                            dataIndex="actions"
                            key="actions"
                            title={intl.formatMessage({
                              id: 'schedule.create.tableActions',
                            })}
                            className={COLUMNS_CLASS}
                            render={(text, record, index) => (
                              <>
                                <Tooltip
                                  title={props.intl.formatMessage({
                                    id: 'schedule.create.tableAddRow',
                                  })}
                                >
                                  <Button
                                    shape="circle"
                                    icon={<PlusOutlined />}
                                    className={[
                                      'eventUsersTable__rowActionBtn',
                                      'eventUsersTable__rowActionBtn--green',
                                    ].join(' ')}
                                    size="small"
                                    onClick={() => {
                                      add(null, index + 1);
                                    }}
                                  />
                                </Tooltip>
                                <Popconfirm
                                  title={props.intl.formatMessage({
                                    id: 'schedule.create.tableRemoveRowConfirm',
                                  })}
                                  onConfirm={() => {
                                    if (users.length === 1) {
                                      return;
                                    }
                                    remove(index);
                                  }}
                                >
                                  <Tooltip
                                    title={props.intl.formatMessage({
                                      id: 'schedule.create.tableRemoveRow',
                                    })}
                                  >
                                    <Button
                                      type="danger"
                                      shape="circle"
                                      icon={<DeleteOutlined />}
                                      className="eventUsersTable__rowActionBtn"
                                      size="small"
                                    />
                                  </Tooltip>
                                </Popconfirm>
                              </>
                            )}
                          />
                        </Table>
                      </div>
                    )}
                  </Form.List>
                </div>
              </Card>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                  setGroupsCount(groupsCount + 1);
                }}
                block
                icon={<PlusOutlined />}
              >
                {' '}
                <IntlMessages id="schedule.create.addGroupBtn" />
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {groupsCount > 0 ? (
        <Button
          type="primary"
          htmlType="submit"
          className="schedule-createForm__submitBtn"
          disabled={submitted}
        >
          <IntlMessages id="schedule.create.save" />
        </Button>
      ) : null}
    </Form>
  );
};

ScheduleCreate.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onSaveSchedule: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSaveSchedule: (scheduleData) => dispatch(addEvent(scheduleData)),
});

export default connect(
  null,
  mapDispatchToProps,
)(injectIntl(withRouter(ScheduleCreate)));
