import {
  CHECK_PARTICIPANTS_TABLE,
  FETCH_CHOSEN_LANGUAGE_SPEAKERS,
  FETCH_CHOSEN_LANGUAGE_SPEAKERS_FAIL,
  FETCH_CHOSEN_LANGUAGE_SPEAKERS_SUCCESS,
  FETCH_CONFERENCE_SLOT_LANGUAGES,
  FETCH_CONFERENCE_SLOT_LANGUAGES_SUCCESS,
  FETCH_LANGUAGES_ON_MODERATOR,
  FETCH_LANGUAGES_ON_MODERATOR_SUCCESS,
  MODERATOR_HAS_JOINED,
  MODERATOR_HAS_LEFT,
  PRESENTER_HAS_JOINED,
  PRESENTER_HAS_LEFT,
  SET_CONFERENCE_VOLUME,
  SET_HAS_CONFERENCE_AUDIO_TRACK,
  SET_HAS_PRESENTER_VIDEO_TRACK,
  SET_HAS_SPEAKER_VIDEO_TRACK,
  SET_HAS_TO_FETCH_SPEAKERS_AUDIO_TRACKS,
  SET_SELECTED_LANGUAGE,
  SET_VISITORS_SPEAKERS,
} from '@constants/ActionTypes';

export const presenterHasJoined = (presenterId) => ({
  type: PRESENTER_HAS_JOINED,
  presenterId,
});

export const moderatorHasJoined = (moderatorId) => ({
  type: MODERATOR_HAS_JOINED,
  moderatorId,
});

export const presenterHasLeft = () => ({
  type: PRESENTER_HAS_LEFT,
});

export const moderatorHasLeft = (moderatorId) => ({
  type: MODERATOR_HAS_LEFT,
  moderatorId,
});

export const setHasConferenceAudioTrack = (hasConferenceAudioTrack) => ({
  type: SET_HAS_CONFERENCE_AUDIO_TRACK,
  hasConferenceAudioTrack,
});

export const setHasPresenterVideoTrack = (hasPresenterVideoTrack) => ({
  type: SET_HAS_PRESENTER_VIDEO_TRACK,
  hasPresenterVideoTrack,
});

export const fetchConferenceSlotLanguages = () => ({
  type: FETCH_CONFERENCE_SLOT_LANGUAGES,
});

export const fetchConferenceSlotLanguagesSuccess = (result) => ({
  type: FETCH_CONFERENCE_SLOT_LANGUAGES_SUCCESS,
  result,
});

export const setSelectedLanguage = (language) => ({
  type: SET_SELECTED_LANGUAGE,
  language,
});

export const fetchChosenLanguageSpeakers = (language) => ({
  type: FETCH_CHOSEN_LANGUAGE_SPEAKERS,
  language,
});

export const fetchChosenLanguageSpeakersSuccess = (result) => ({
  type: FETCH_CHOSEN_LANGUAGE_SPEAKERS_SUCCESS,
  result,
});

export const fetchChosenLanguageSpeakersFail = () => ({
  type: FETCH_CHOSEN_LANGUAGE_SPEAKERS_FAIL,
});

export const setHasToFetchSpeakersAudioTracks = (hasToFetch) => ({
  type: SET_HAS_TO_FETCH_SPEAKERS_AUDIO_TRACKS,
  hasToFetch,
});

export const setVisitorsSpeakers = (visitorsIds) => ({
  type: SET_VISITORS_SPEAKERS,
  visitorsIds,
});

export const setHasSpeakerVideoTrack = (hasSpeakerVideoTrack) => ({
  type: SET_HAS_SPEAKER_VIDEO_TRACK,
  hasSpeakerVideoTrack,
});

export const fetchLanguagesOnModerator = () => ({
  type: FETCH_LANGUAGES_ON_MODERATOR,
});

export const fetchLanguagesOnModeratorSuccess = (result) => ({
  type: FETCH_LANGUAGES_ON_MODERATOR_SUCCESS,
  result,
});

export const checkParticipantsTable = (bool) => ({
  type: CHECK_PARTICIPANTS_TABLE,
  bool,
});

export const setConferenceVolume = (volumeValue) => ({
  type: SET_CONFERENCE_VOLUME,
  volumeValue,
});
