import {
  FETCH_GROUPS_BY_EVENT_REQUEST,
  FETCH_GROUPS_BY_EVENT_SUCCESS,
  FETCH_SLOT_FAILURE,
  FETCH_SLOT_REQUEST,
  FETCH_SLOT_SUCCESS,
  SET_SELECTED_GROUP,
} from '@constants/ActionTypes';
import _ from 'lodash';

const INIT_STATE = {
  groups: [],
  selectedGroup: {},
  loading: false,
};

/**
 * Function to set the 'selectedGroup'
 * @param state
 * @param groupId
 * @returns {*}
 */
const onSetSelectedGroup = (state, groupId) => {
  const newState = _.cloneDeep(state);
  const selectedGroup = newState.groups.find((g) => g.slot_id === groupId);

  if (selectedGroup) {
    newState.selectedGroup = selectedGroup;
  }

  return { ...state, ...newState };
};

/**
 * @description Groups reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {

    case FETCH_GROUPS_BY_EVENT_REQUEST:
      newState = { loading: true };
      return { ...state, ...newState };

    case FETCH_GROUPS_BY_EVENT_SUCCESS:
      const groups = Object.keys(action.payload).map((key) => action.payload[key]).sort((a,b) => { return new Date(a.slot_start_time)- new Date(b.slot_start_time)});
      newState = { loading: false, groups };
      return { ...state, ...newState };

    case FETCH_SLOT_REQUEST:
      return Object.assign(state, {
        loading: true,
        selectedGroupId: null,
      });

    case FETCH_SLOT_SUCCESS:
      newState = { loading: false, selectedGroup: action.payload };
      return { ...state, ...newState };

    case FETCH_SLOT_FAILURE:
      newState = { loading: false, groups: [] };
      return { ...state, ...newState };

    case SET_SELECTED_GROUP:
      return onSetSelectedGroup(state, action.groupId);

    default:
      return state;
  }
};
