export const BASE_URL = '/api';
export const ENDPOINTS = {
  getChatUsers: '/chatUsers',
  addChatMessage: '/chatUsers',
  getEvents: `${BASE_URL}/events`,
  updateEvent: (eventId) => `${BASE_URL}/events/${eventId}`,
  getGroups: `${BASE_URL}/events`,
  getSlots: `${BASE_URL}/slots`,
  addUser: `${BASE_URL}/users`,
  slotLanguages: `${BASE_URL}/slots/mine/languages`,
  languagesBySlot: (slotId) => `${BASE_URL}/slots/${slotId}/languages`,
  addUserToSlot: (slotId) => `${BASE_URL}/slots/${slotId}/participants`,
  updateSlot: `${BASE_URL}/slots`,
  getScheduleUsers: `${BASE_URL}/slots`,
  importEvent: `${BASE_URL}/import`,
  userAuthentication: (slotId, userId, userRole) => `${BASE_URL}/slots/${slotId}/join-info?userId=${userId}&userRole=${userRole}&languageId=deu`,
  questions: `${BASE_URL}/slots`,
  predefinedMessages: `${BASE_URL}/messages`,
  deletePredefinedMessage: `${BASE_URL}/messages`,
  userData: `${BASE_URL}/users/me`,

  login: `${BASE_URL}/login`,
  join: `${BASE_URL}/slot-pick`,
  loginLP: `${BASE_URL}/login/sso`,
  updateUser: (userId) => `${BASE_URL}/users/${userId}`,
  userSlotData: `${BASE_URL}/slots/mine`,
  userJoinData: (langIsoCode, media) => `${BASE_URL}/slots/mine/media/${media}/join-info?isoCode=${langIsoCode}`, //endpoint for VISITOR, TRANSLATOR, STAGE roles
  userModeratorJoinData: (slotId, media) => `${BASE_URL}/slots/${slotId}/media/${media}/join-info`, //endpoint for MODERATOR role
  chosenLanguageSpeakers: (chosenLangIsoCode) => `${BASE_URL}/slots/mine/languages/${chosenLangIsoCode}/speakers`,
  addUserAsSpeaker: (slotId, langIsoCode, userId, userRole) => `${BASE_URL}/slots/${slotId}/languages/${langIsoCode}/speakers/${userId}:${userRole}`,
  removeUserAsSpeaker: (slotId, langIsoCode, userId, userRole) => `${BASE_URL}/slots/${slotId}/languages/${langIsoCode}/speakers/${userId}:${userRole}`,
};
