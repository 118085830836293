import React from 'react';

const LegalNotice = () => (
  <div>

    <p><strong>AUDI AG</strong></p>
    <p><strong>Mailing Address</strong><br />
      AUDI AG<br />
      Ettinger Strasse 70<br />
      85057 Ingolstadt<br />
      Germany</p>
    <p><strong>Phone:<br /></strong>+49 (0)841 89-0</p>
    <p><strong>Fax:<br /></strong>+49 (0)841 89-32524</p>
    <p><strong>E-mail:<br /></strong><a href=
                                        "mailto:imprint@audi.de">imprint@audi.de<br />
      <br /></a> AUDI AG is a stock corporation under German law, the
      head office is located in Ingolstadt.</p>
    <p><strong>Chairman of the Supervisory Board:</strong><br />
      Dr. Herbert Diess</p>
    <p><strong>Managing board</strong><br />
      Markus Duesmann (Chairman)<br />
      Dirk Große-Loheide<br />
      Oliver Hoffmann<br />
      Peter Kössler<br />
      Sabine Maaßen<br />
      Jürgen Rittersberger<br />
      Hildegard Wortmann</p>
    <p>AUDI AG is registered in the commercial register of the
      Amtsgericht (local court) Ingolstadt under the number HR B 1.</p>
    <p>The VAT registration number of AUDI AG is DE811115368.</p>
    <p>AUDI AG does not commit to nor is it obliged to participate in
      the alternative dispute resolution for consumer disputes in front
      of a consumer dispute resolution entity.</p>
    <p>&nbsp;</p>
    <p>European Commission official website for Online Dispute
      Resolution:<br />
        <a href=
             "http://www.ec.europa.eu/consumers/odr">http://www.ec.europa.eu/consumers/odr</a></p>
  </div>
);

export default LegalNotice;
