import {
  delay,
  put,
  select,
} from 'redux-saga/effects';
import {
  checkIfUserIsModeratorByUserId,
  checkIfUserIsWithParticularRole,
  checkIfUserWhoHaveJoinedIsInTheListOfSpeakers,
} from '@jitsi/helper';
import _ from 'lodash';
import {
  DEVICES_TYPES,
  JITSI_MUTE_MESSAGES,
  JITSI_OTHER_MESSAGES,
  JITSI_PARTICIPANT_VISITOR_PREVIEW_MESSAGES,
  JITSI_SPEAKER_MESSAGES,
  USER_ROLES,
  VIDEO_QUALITY,
} from '@constants/Settings';
import {
  setBeingPreviewed,
  setBeingSpeaker,
  setHasMutedLocalAudioTrack,
  setLoggedUserConversation,
  setLoggedUserIsOnFullScreen,
} from '@actions/loggedUser/LoggedUserActions';
import {
  hideNotification,
  showNotification,
} from '@actions/Common';
import {
  moderatorHasJoined,
  moderatorHasLeft,
  presenterHasJoined,
  presenterHasLeft,
  setHasToFetchSpeakersAudioTracks,
  setVisitorsSpeakers,
} from '@actions/conference/ConferenceActions';
import JitsiConference from '@jitsi/JitsiConference';
import { conferenceMessageReceivedGeneratorCommon } from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/CommonCaseGenerators';

/**
 * Generator to keep DRY principle and to handle a message on the visitor interface which we have to display
 * (doesnt matter if the message which the user has received is private or not)
 * @param adaptedMessage
 * @returns {Generator<*, void, *>}
 */
export function* conferenceMessageReceivedVisitorHandler(adaptedMessage) {
  const { message, messageId, read, sentAt, type, userId } = adaptedMessage;
  const reduxConversation = yield select((state) => state.loggedUser.conversation);

  const finalConversation = yield _.cloneDeep(reduxConversation);
  finalConversation[messageId] = { message, read, sentAt, type, userId };

  const loggedUserDeviceType = yield select((state) => state.loggedUser.deviceType);

  yield put(setLoggedUserConversation(finalConversation));

  /**
   * When the user receive a new message and he is using desktop device, toggle the Chat Component so it becomes visible
   *
   * (we are using the action for fullscreen toggling to show the Chat, because on the other components we are using helper function to toggle the fullscreen
   * "toggleFullscreenHelper" which checks if we have to toggle the fullscreen with the browser fullscreen functionality OR just to show the Chat)
   */
  if (loggedUserDeviceType === DEVICES_TYPES.desktop) {
    yield put(setLoggedUserIsOnFullScreen(false));
  }
  // scroll the user chat to bottom
  yield delay(200);
  const scrollableChatNode = yield document.querySelector('.gx-chat-list-scroll > div');

  if (scrollableChatNode) {
    yield scrollableChatNode.scrollTop = scrollableChatNode.scrollHeight;
  }
}

/**
 * VISITOR ROLE Generator function which we execute when the visitor receive private message
 * @param adaptedMessage
 * @param isTheMessageFromOriginalJitsiChat - a flag to set if the message is comming from the original JITSI chat, NOT from
 * our custom MUC chat
 */
export function* conferencePrivateMessageReceivedGeneratorVisitor(adaptedMessage, isTheMessageFromOriginalJitsiChat = true) {
  const currentModeratorsIds = yield select((state) => state.conferenceReducer.moderatorsIds);
  const isTheSenderModerator = currentModeratorsIds.find((id) => id === adaptedMessage.userId);

  if (isTheSenderModerator && isTheMessageFromOriginalJitsiChat) {
    switch (adaptedMessage.message) {
      case JITSI_PARTICIPANT_VISITOR_PREVIEW_MESSAGES.startPreview:
        yield put(setBeingPreviewed(true));
        break;
      case JITSI_SPEAKER_MESSAGES.startSpeaker:
        yield put(setBeingSpeaker(true, false));
        yield put(setVisitorsSpeakers([]));
        break;
      case JITSI_SPEAKER_MESSAGES.endSpeaker:
        yield put(setBeingSpeaker(false, false));
        yield put(setVisitorsSpeakers([]));
        break;
      case JITSI_SPEAKER_MESSAGES.startSpeakerWithVideo:
        yield put(setBeingSpeaker(true, true));
        yield put(setVisitorsSpeakers([]));
        break;
      case JITSI_MUTE_MESSAGES.muteAudio:
        yield put(setHasMutedLocalAudioTrack(true));
        break;
      case JITSI_OTHER_MESSAGES.alreadyInConference:
        yield put(showNotification('warning', 'notification.alreadyInConference', 0)); // using intl id for the message
        yield put(hideNotification());
        break;
      case JITSI_OTHER_MESSAGES.pingMessageToVisitor:
        yield JitsiConference.sendPrivateSystemMessage(JITSI_OTHER_MESSAGES.pingMessageReceivedResponseToModerator, adaptedMessage.userId);
        break;
      default:
        yield conferenceMessageReceivedVisitorHandler(adaptedMessage, true);
        break;
    }
  } else if (!isTheMessageFromOriginalJitsiChat) {
    yield conferenceMessageReceivedVisitorHandler(adaptedMessage, true);
  }
}

/**
 * VISITOR ROLE Generator function which we execute when the visitor receive a message which is NOT private
 * @param adaptedMessage
 */
export function* conferenceMessageReceivedGeneratorVisitor(adaptedMessage) {
  const isTheSenderModerator = yield checkIfUserIsModeratorByUserId(adaptedMessage.userId);
  const splitMessage = yield adaptedMessage.message.split('::');

  yield conferenceMessageReceivedGeneratorCommon(adaptedMessage);

  if (splitMessage.length === 1 && isTheSenderModerator && splitMessage[0] !== JITSI_SPEAKER_MESSAGES.removeSpeakerVideo) {
    yield conferenceMessageReceivedVisitorHandler(adaptedMessage);
  }
}

/**
 * VISITOR Generator function which we execute when some user has left the conference
 * @param user
 */
export function* conferenceUserLeftGeneratorVisitor(user) {
  const currentVisitorsSpeakers = yield select((state) => state.conferenceReducer.visitorsSpeakers);

  if (checkIfUserIsWithParticularRole(user, USER_ROLES.stage)) {
    /**
     * The IF below is used on the situation where the presenter account joins
     * the conference for second time
     */
    if (!JitsiConference.checkIfThereIsAPresenterInTheConference()) {
      yield put(presenterHasLeft());
    }
  } else if (checkIfUserIsWithParticularRole(user, USER_ROLES.moderator)) {
    yield put(moderatorHasLeft(user.getId()));
  } else if (currentVisitorsSpeakers.find((s) => s === user.getId())) {
    yield put(setVisitorsSpeakers([]));
  }
}

/**
 * VISITOR Generator function which we execute when user joins the conference
 * @param joinedUser
 */
export function* conferenceUserJoinedGeneratorVisitor(joinedUser) {
  const speakers = yield select((state) => state.conferenceReducer.chosenLanguageSpeakers);

  if (checkIfUserIsWithParticularRole(joinedUser, USER_ROLES.stage)) {
    yield put(presenterHasJoined(joinedUser.getId()));
    yield JitsiConference.setReceiverVideoConstraint(VIDEO_QUALITY.Hd);
  } else if (checkIfUserIsModeratorByUserId(joinedUser.getId())) {
    yield put(moderatorHasJoined(joinedUser.getId()));
  } else if (checkIfUserWhoHaveJoinedIsInTheListOfSpeakers(joinedUser, speakers)
    && !checkIfUserIsWithParticularRole(joinedUser, USER_ROLES.visitor)) {
    yield delay(700);
    yield put(setHasToFetchSpeakersAudioTracks(true));
  }
}
