import React, {useCallback, useState} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Button, Col, Dropdown, Menu, Popconfirm, Row, Space,} from 'antd';
import {injectIntl} from 'react-intl';
import {DownOutlined, FilterFilled, FilterOutlined} from '@ant-design/icons';
import SearchBox from '@components/SearchBox';
import IntlMessages from '@util/IntlMessages';
import {connect} from 'react-redux';
import {
  banParticipant,
  filterAndSearchParticipantsSuccess,
  kickParticipant,
  muteParticipants,
  selectParticipantsSuccess,
  unselectParticipantsSuccess,
} from '@actions/users/UsersActions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckSquare, faMinusSquare, faSquare} from '@fortawesome/free-regular-svg-icons';
import {PARTICIPANTS_SELECT_VALUES, PARTICIPANTS_TABLE} from '@constants/Settings';

const ActionsTop = (props) => {
  const {
    intl, participants, selectionType, users, onKickParticipants, onBanParticipants,
    onFilterParticipants, onSearchParticipants,
  } = props;

  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);

  const filterMenuItems = [
    [intl.formatMessage({ id: 'participants.actionsTop.filterSelectOnline' }), PARTICIPANTS_TABLE.rowStatusClasses.online],
    [intl.formatMessage({ id: 'participants.actionsTop.filterSelectOnlineRaised' }), PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised],
    [intl.formatMessage({ id: 'participants.actionsTop.filterSelectSignedOnLanding' }), PARTICIPANTS_TABLE.rowStatusClasses.signed],
    [intl.formatMessage({ id: 'participants.actionsTop.filterSelectOffline' }), PARTICIPANTS_TABLE.rowStatusClasses.offline],
    [intl.formatMessage({ id: 'participants.actionsTop.filterSelectUnknown' }), PARTICIPANTS_TABLE.rowStatusClasses.unknown],
  ];
  const selectMenuItems = [
    [intl.formatMessage({ id: 'participants.actionsTop.dropdownSelectAll' }), PARTICIPANTS_SELECT_VALUES.all],
    [intl.formatMessage({ id: 'participants.actionsTop.dropdownSelectOnline' }), PARTICIPANTS_SELECT_VALUES.online],
    [intl.formatMessage({ id: 'participants.actionsTop.dropdownSelectLive' }), PARTICIPANTS_SELECT_VALUES.live],
    [intl.formatMessage({ id: 'participants.actionsTop.dropdownSelectOffline' }), PARTICIPANTS_SELECT_VALUES.offline],
  ];

  /**
   * Function to "debounce" the search query trigger when we type in the search field
   */
  const delayedSearchQuery = useCallback(_.debounce((filterValue, searchInputValue) => onSearchParticipants(filterValue, searchInputValue), 600),
    []);

  /**
   * Function when we choose a filter
   */
  const onFilterChange = (menuItem) => {
    const itemKey = parseInt(menuItem.key);
    const newFilter = filter === filterMenuItems[itemKey][1] ? '' : filterMenuItems[itemKey][1];

    setFilter(newFilter);
    onFilterParticipants(newFilter, search);
  };

  /**
   * Function when we choose a type of selection from the dropdown
   */
  const onSelectChange = (menuItem) => {
    const itemKey = parseInt(menuItem.key);

    if (selectionType !== selectMenuItems[itemKey]) {
      props.onSelectParticipants(selectMenuItems[itemKey][1]);
    }
  };

  /**
   * Function when we start typing in the search field
   */
  const onSearchChange = (e) => {
    const searchInputValue = e.target.value.trimLeft();

    if (search !== searchInputValue) {
      setSearch(searchInputValue);

      if ((searchInputValue.length >= 3 || searchInputValue <= 0)) {
        delayedSearchQuery(filter, searchInputValue, users);
      }
    }
  };

  /**
   * Function to check IF there are 'checked: true' and 'checked: false' users
   * The function is used to check the correct select menu icon
   * @param {array<object>} tableData
   * @returns {boolean}
   */
  const hasCheckedAndUncheckedUsers = (tableData) => _.some(tableData, { checked: true })
      && _.some(tableData, { checked: false });

  /**
   * Function to check IF all users are 'checked: true'
   * The function is used to check the correct select menu icon
   * @param {array<object>} tableData
   * @returns {boolean}
   */
  const hasCheckedUsersOnly = (tableData) => _.every(tableData, { checked: true });

  /**
   * Function the handle the user click on the select menu icon
   * IF there are any 'checked: true' users, the function will trigger to uncheck them
   * IF there are NOT any 'checked: true' users, the function will trigger to CHECK all users
   */
  const selectMenuIconClickHandler = () => {
    if (_.some(participants.tableData, { checked: true })) {
      props.onUnselectParticipants();
    } else {
      props.onSelectParticipants(PARTICIPANTS_SELECT_VALUES.all);
    }
  };

  /**
   * Helper function to choose the correct "Select Menu" icon
   * @returns {JSX.Element}
   */
  const chooseSelectMenuIcon = () => {
    let icon = <FontAwesomeIcon className="actionsWrapper__icon" icon={faSquare} onClick={() => selectMenuIconClickHandler()} />;

    if (hasCheckedAndUncheckedUsers(participants.tableData)) {
      icon = <FontAwesomeIcon className="actionsWrapper__icon" icon={faMinusSquare} onClick={() => selectMenuIconClickHandler()} />;
    } else if (hasCheckedUsersOnly(participants.tableData)) {
      icon = <FontAwesomeIcon className="actionsWrapper__icon" icon={faCheckSquare} onClick={() => selectMenuIconClickHandler()} />;
    }

    return icon;
  };

  const selectMenu = (
    <Menu onClick={(item) => onSelectChange(item)}>
      {selectMenuItems.map((item, index) => (
        <Menu.Item key={index}>
          {item[0]}
        </Menu.Item>
      ))}
    </Menu>
  );

  // TODO: change filter to use Intl
  const filterMenu = (
    <Menu onClick={(item) => onFilterChange(item)}>
      {filterMenuItems.map((item, index) => (
        <Menu.Item key={index} className={item[1] === filter ? 'actionsWrapper__menuItemActive' : null}>
          {item[0]}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Row className="actionsWrapper" gutter={[0, 30]} justify="space-between" align="middle">
      <Col lg={16} md={24}>
        <Row justify="flex-start" gutter={[20, 60]} className="actionsWrapper__actionsSubRow">
          <Col>
            <Dropdown overlay={selectMenu}>
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                <Space align="center" size={2}>
                  {chooseSelectMenuIcon()}
                  {' '}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </Col>

          <Col>
            <Popconfirm
              title={intl.formatMessage({ id: 'participants.actionsTop.mute.confirm' })}
              onConfirm={() => props.onMuteParticipants(participants.tableData)}
            >
              <Button className="actionsWrapper__button" type="primary" ghost size="small"><IntlMessages id="participants.actionsTop.mute" /></Button>
            </Popconfirm>
          </Col>
          <Col>
            <Popconfirm
              title={intl.formatMessage({ id: 'participants.actionsTop.kick.confirm' })}
              onConfirm={() => onKickParticipants()}
            >
              <Button className="actionsWrapper__button" type="primary" ghost size="small"><IntlMessages id="participants.actionsTop.kick" /></Button>
            </Popconfirm>
          </Col>
          <Col>
            <Popconfirm
              title={intl.formatMessage({ id: 'participants.actionsTop.ban.confirm' })}
              onConfirm={() => onBanParticipants()}
            >
              <Button className="actionsWrapper__button" type="primary" ghost size="small"><IntlMessages id="participants.actionsTop.ban" /></Button>
            </Popconfirm>
          </Col>
       {/*   <Col>  -------- HIDDEN ELEMENTS FOR NOW --------
            <Button className="actionsWrapper__button" type="primary" ghost size="small"><IntlMessages id="participants.actionsTop.live" /></Button>
          </Col>
          <Col>
            <Button className="actionsWrapper__button" type="primary" ghost size="small"><IntlMessages id="participants.actionsTop.preview" /></Button>
          </Col>
          <Col>
            <Button className="actionsWrapper__button" type="primary" ghost size="small"><IntlMessages id="participants.actionsTop.token" /></Button>
          </Col>*/}

          <Col>
            <Dropdown overlay={filterMenu}>
              <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                <Space align="center" size={2}>
                  {filter ? <FilterFilled className="actionsWrapper__icon" /> : <FilterOutlined className="actionsWrapper__icon" />}
                  {' '}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </Col>
        </Row>
      </Col>

      <Col lg={6} md={12} sm={24}>
        <SearchBox placeholder={intl.formatMessage({ id: 'participants.actionsTop.search' })} value={search} onChange={(e) => onSearchChange(e)} />
      </Col>
    </Row>
  );
};

ActionsTop.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  participants: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onFilterParticipants: PropTypes.func.isRequired,
  onSearchParticipants: PropTypes.func.isRequired,
  onSelectParticipants: PropTypes.func.isRequired,
  onUnselectParticipants: PropTypes.func.isRequired,
  onMuteParticipants: PropTypes.func.isRequired,
  onKickParticipants: PropTypes.func.isRequired,
  onBanParticipants: PropTypes.func.isRequired,
  selectionType: PropTypes.string.isRequired,
};

const mapStateToProps = ({ usersReducer }) => ({
  users: usersReducer.users,
  participants: usersReducer.participantsPage,
  selectionType: usersReducer.participantsPage.selection,
});

const mapDispatchToProps = (dispatch) => ({
  onFilterParticipants:
    (filter, searchValue) => dispatch(filterAndSearchParticipantsSuccess(filter, searchValue)),
  onSearchParticipants:
    (filter, searchValue) => dispatch(filterAndSearchParticipantsSuccess(filter, searchValue)),
  onSelectParticipants: (selectionType) => dispatch(selectParticipantsSuccess(selectionType)),
  onUnselectParticipants: () => dispatch(unselectParticipantsSuccess()),
  onMuteParticipants: (data) => dispatch(muteParticipants(data)),
  onKickParticipants: () => dispatch(kickParticipant()),
  onBanParticipants: () => dispatch(banParticipant()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ActionsTop));
