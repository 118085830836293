import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';
import {injectIntl} from 'react-intl';
import {
  setLoggedUserConversation,
  setLoggedUserDefaultAudioDeviceId,
  setLoggedUserDefaultVideoDeviceId,
} from '@actions/loggedUser/LoggedUserActions';
import UserViewVideoTracks from '@routes/UserView/UserViewVideoTracks';
import {Col, Row} from 'antd';
import NoPresenter from '@routes/UserView/NoPresenter';
import MobileActionsButtons from '@routes/UserView/MobileActionsButtons';
import ChatComponent from '@routes/UserView/ChatComponent';
import HLSPlayer from '@routes/UserView/HLSPlayer';
import JitsiTrack from '@jitsi/JitsiTrack';
import {DEVICES_TYPES, SUPPORTED_STREAM_TYPES} from '@constants/Settings';
import VerticalActionsButtons from '@routes/UserView/Fullscreen/Mobile/VerticalActionsButtons';
import SpeakersTracks from '@routes/UserView/SpeakersTracks';
import Topbar from '@routes/UserView/Fullscreen/Desktop/Topbar';
import {useNotificationHook} from '../../CustomHooks/useNotificationHook';
import {useConferenceCleanupHook} from '../../CustomHooks/useConferenceCleanupHook';
import {useCreateLocalTracksHook} from '../../CustomHooks/useCreateLocalTracksHook';
import {useHasMutedLocalAudioTrackHook} from '../../CustomHooks/useHasMutedLocalAudioTrackHook';
import {useHasMutedLocalVideoTrackHook} from '../../CustomHooks/useHasMutedLocalVideoTrackHook';
import {useFirstRender} from '../../CustomHooks/useFirstRender';
import {isSafariBrowser, isStreamTypeSupportedByBrowser} from '../../CustomHooks/HelperFuncs';

const UserView = (props) => {
  const {
    isConnected,
    isInConference,
    setLoggedUserConversation,
    presenterId,
    hasPresenterVideoTrack,
    roomName,
    deviceType,
    isOnFullScreen,
    isBeingPreviewed,
    intl,
    isBeingSpeaker,
    browserName,
    onSetDefaultAudioDeviceId,
    onSetDefaultVideoDeviceId,
    slotMedia,
    isKicked,
  } = props;

  const [unreadMessages, setUnreadMessages] = useState(0);

  const { localTracks, setLocalTracks } = useCreateLocalTracksHook();

  useNotificationHook(intl);

  useConferenceCleanupHook();

  useHasMutedLocalAudioTrackHook(localTracks);

  useHasMutedLocalVideoTrackHook(localTracks);

  const isFirstRender = useFirstRender();

  useEffect(() => {
    if (!_.isEmpty(sessionStorage.getItem('visitorConversation'))) {
      const { conversation } = JSON.parse(sessionStorage.getItem('visitorConversation'));
      setLoggedUserConversation(conversation);
    }
  }, []);

  /**
   * @description Effect which executes when 'isBeingPreviewed' property is changed.
   *
   * @descriptionSo when the visitor receive the private message to be previewed, we set the value to 'true'.
   * If the value is 'true' we add the visitor local VIDEO track to the conference
   */
  useEffect(() => {
    if (isBeingPreviewed.beingPreviewed) {
      JitsiTrack.addLocalTracksToTheConference(localTracks);
    }
  }, [isBeingPreviewed.beingPreviewed]);

  const setDevicesIds = useCallback((tracks) => {
    for (const track of tracks) {
      if (track.getType() === 'video') {
        onSetDefaultVideoDeviceId(track.deviceId);
      }
      if (track.getType() === 'audio') {
        onSetDefaultAudioDeviceId(track.deviceId);
      }
    }
  }, [localTracks]);

  /**
   * @description Effect which executes when 'isBeingSpeaker.withAudio' property is changed.
   *
   * @description So when the visitor receive the private message to be a speaker, the message
   * contains information if we have to add the visitor video AND audio or ONLY the audio.
   *
   * The object looks like that:
   * {
   *   withVideo: bool,
   *   withAudio: bool
   * }
   *
   * when withAudio property is true, we add the audio local track to the conference
   */
  useEffect(() => {
    if (isBeingSpeaker.withAudio) {
      JitsiTrack.addLocalTracksToTheConference(localTracks.filter((tr) => tr.getType() === 'audio'));
    } else if (!isBeingSpeaker.withAudio && !isFirstRender && !isSafariBrowser(browserName)) {
      JitsiTrack.removeAndRecreateVisitorTrack('audio')
        .then((result) => {
          setLocalTracks(result);
          setDevicesIds(result);
        })
        .catch(() => {
          console.log("CREATE TRACKS ERROR");
        });
    }
  }, [isBeingSpeaker.withAudio]);

  /**
   * @description Effect which executes when 'isBeingSpeaker.withVideo' property is changed.
   *
   * @description So when the visitor receive the private message to be a speaker, the message
   * contains information if we have to add the visitor video AND audio or ONLY the audio.
   *
   * The object looks like that:
   * {
   *   withVideo: bool,
   *   withAudio: bool
   * }
   *
   * when withVideo property is true, we add the video local track to the conference
   */
  useEffect(() => {
    if (isBeingSpeaker.withVideo) {
      JitsiTrack.addLocalTracksToTheConference(localTracks.filter((tr) => tr.getType() === 'video'));
    } else if (!isBeingSpeaker.withVideo && !isFirstRender && !isSafariBrowser(browserName)) {
      JitsiTrack.removeAndRecreateVisitorTrack('video')
        .then((result) => {
          setLocalTracks(result);
          setDevicesIds(result);
        })
        .catch(() => {
          console.log("CREATE TRACKS ERROR");
        });
    }
  }, [isBeingSpeaker.withVideo]);

  /**
   * Choose how many column of the ant design grid to take for the video container
   * (different values if we are on 'mobile' or 'desktop' devices
   * and when are on 'full' or 'non-full' screen)
   * @returns {number}
   */
  const getColumnSpanForVideoContainer = () => {
    if (deviceType === DEVICES_TYPES.mobile) {
      if (isOnFullScreen) {
        return 21;
      }
      return 24;
    }

    if (isOnFullScreen) {
      return 24;
    }

    return 18;
  };

  /**
   * @description Get unread message count from child component
   * @param {Number} count
   * @returns {void}
   */
  const countUnreadMessages = useCallback((count) => {
    setUnreadMessages(count);
  }, []);

  if (slotMedia === SUPPORTED_STREAM_TYPES.HLS) {
    return (
      <div className={`userView userView--${deviceType}${isOnFullScreen ? '--fullscreen' : ''}`}>
        <Row style={{ flex: '1' }}>
          <Col
            span={getColumnSpanForVideoContainer()}
            className={
              `userView__videoAndActionsWrapper
               userView__videoAndActionsWrapper--${deviceType}
               userView__videoAndActionsWrapper--${deviceType}${isOnFullScreen ? '--fullscreen' : ''}
              `
            }
          >
            {!isOnFullScreen ? <div className={`userView__title userView__title--${deviceType}`} /> : null}

            {isOnFullScreen && deviceType === DEVICES_TYPES.desktop ?
              (<Topbar unreadMessages={unreadMessages} deviceType={deviceType} roomName={roomName} isOnFullScreen={isOnFullScreen} />)
              : null}
            {!isKicked ? <HLSPlayer playlistURL={sessionStorage.getItem('url')} /> : <div className="userView__userKickedPlaceholder"></div>}
          </Col>
          <Col
            style={isOnFullScreen ? { display: 'none' } : {}}
            span={deviceType === DEVICES_TYPES.mobile ? 24 : 6}
            className={`userView__chatComponentWrapper userView__chatComponentWrapper--${deviceType}`}
          >
            <ChatComponent
              deviceType={deviceType}
              countUnreadMessages={countUnreadMessages}
              unreadMessages={unreadMessages}
              isOnFullScreen={isOnFullScreen}
              browserName={browserName}
            />
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className={`userView userView--${deviceType}${isOnFullScreen ? '--fullscreen' : ''}`}>
      <Row style={{ flex: '1' }}>
        <Col
          span={getColumnSpanForVideoContainer()}
          className={
            `userView__videoAndActionsWrapper
             userView__videoAndActionsWrapper--${deviceType}
             userView__videoAndActionsWrapper--${deviceType}${isOnFullScreen ? '--fullscreen' : ''}
            `
          }
        >
          {!isOnFullScreen ? <div className={`userView__title userView__title--${deviceType}`} /> : null}

          {isOnFullScreen && deviceType === DEVICES_TYPES.desktop ?
            (<Topbar unreadMessages={unreadMessages} deviceType={deviceType} roomName={roomName} isOnFullScreen={isOnFullScreen} />)
            : null}

          {(isConnected && isInConference && hasPresenterVideoTrack)
            ? (
              <>
                <UserViewVideoTracks
                  presenterId={presenterId}
                  hasPresenterVideoTrack={hasPresenterVideoTrack}
                  deviceType={deviceType}
                  browserName={browserName}
                  isOnFullScreen={isOnFullScreen}
                  localTracks={localTracks}
                  intl={intl}
                />
              </>
            )
            : (
              <NoPresenter isOnFullScreen={isOnFullScreen} deviceType={deviceType} />
            )}
          {(isConnected && isInConference) ? (<SpeakersTracks />) : null}

          {(!isOnFullScreen && deviceType === DEVICES_TYPES.desktop)
            ? <div style={{ height: '50px', background: 'black', borderRight: '1px solid #3d3d3dcf' }} />
            : null}

          {!isOnFullScreen ? (
            <MobileActionsButtons
              deviceType={deviceType}
              unreadMessages={unreadMessages}
            />
          ) : null}
        </Col>
        <Col
          style={isOnFullScreen ? { display: 'none' } : {}}
          span={deviceType === DEVICES_TYPES.mobile ? 24 : 6}
          className={`userView__chatComponentWrapper userView__chatComponentWrapper--${deviceType}`}
        >
          <ChatComponent
            deviceType={deviceType}
            countUnreadMessages={countUnreadMessages}
            unreadMessages={unreadMessages}
            isOnFullScreen={isOnFullScreen}
            browserName={browserName}
          />
        </Col>
        <Col
          style={(!isOnFullScreen || (deviceType === DEVICES_TYPES.desktop)) ? { display: 'none' } : {}}
          span={3}
        >
          <VerticalActionsButtons
            unreadMessages={unreadMessages}
            isOnFullScreen={isOnFullScreen}
          />
        </Col>
      </Row>
    </div>
  );
};

UserView.propTypes = {
  isConnected: PropTypes.bool.isRequired,
  isInConference: PropTypes.bool.isRequired,
  presenterId: PropTypes.string,
  hasPresenterVideoTrack: PropTypes.bool.isRequired,
  setLoggedUserConversation: PropTypes.func.isRequired,
  roomName: PropTypes.string,
  deviceType: PropTypes.string.isRequired,
  isOnFullScreen: PropTypes.bool.isRequired,
  isBeingPreviewed: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  isBeingSpeaker: PropTypes.oneOfType([PropTypes.object]).isRequired,
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  browserName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSetDefaultAudioDeviceId: PropTypes.func.isRequired,
  onSetDefaultVideoDeviceId: PropTypes.func.isRequired,
  moderatorId: PropTypes.PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  slotMedia: PropTypes.string,
};

const mapStateToProps = ({ loggedUser, conferenceReducer }) => ({
  isConnected: loggedUser.isConnected,
  isInConference: loggedUser.isInConference,
  presenterId: conferenceReducer.presenterId,
  hasPresenterVideoTrack: conferenceReducer.hasPresenterVideoTrack,
  hasConferenceAudioTrack: conferenceReducer.hasConferenceAudioTrack,
  roomName: loggedUser.roomName,
  deviceType: loggedUser.deviceType,
  isOnFullScreen: loggedUser.isOnFullScreen,
  isBeingPreviewed: loggedUser.beingPreviewed,
  isBeingSpeaker: loggedUser.beingSpeaker,
  browserName: loggedUser.browserName,
  moderatorId: conferenceReducer.moderatorId,
  slotMedia: loggedUser.slotMedia,
  isKicked: loggedUser.isKicked,
});

const mapDispatchToProps = (dispatch) => ({
  setLoggedUserConversation: (message) => dispatch(setLoggedUserConversation(message)),
  onSetDefaultAudioDeviceId: (deviceId) => dispatch(setLoggedUserDefaultAudioDeviceId(deviceId)),
  onSetDefaultVideoDeviceId: (deviceId) => dispatch(setLoggedUserDefaultVideoDeviceId(deviceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(React.memo(UserView)));
