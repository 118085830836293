import React, { useEffect, useState } from 'react';
import {
  Button, Form, Input, Modal, Select,
} from 'antd';
import IntlMessages from '@util/IntlMessages';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addUser } from '@actions/users/UsersActions';
import { injectIntl } from 'react-intl';
import languages from '../../../assets/vendors/languages.json';

const { Option } = Select;

const AddUserModal = (props) => {
  const {
    intl, visible, hideModal, selectedGroup,
  } = props;

  const [submitted, setSubmitted] = useState(false);

  /**
   * Effect which set "submitted" state to false if the modal is visible and
   * to true otherwise.
   * So on submitted = true we disable the submit button, otherwise enable it,
   * so we prevent the "multiple submit clicks"
   */
  useEffect(() => {
    setSubmitted(!visible);
  }, [visible]);

  const addUserHandler = (values) => {
    if (!submitted) {
      props.onAddUser(values, selectedGroup.slot_id);
    }
  };

  return (
    <Modal
      visible={visible}
      title={intl.formatMessage({ id: 'participants.actionBottom.modal.title' })}
      centered
      wrapClassName="addUserModalWrapper"
      onCancel={hideModal}
      bodyStyle={{
        height: '550px', display: 'flex', flexDirection: 'column', justifyContent: 'center',
      }}
      footer={[
        <Button key="back" onClick={hideModal}>
          <IntlMessages id="participants.addUserForm.cancelText" />
        </Button>,
        <Button key="submit" type="primary" form="addUserForm" htmlType="submit" disabled={submitted}>
          <IntlMessages id="participants.addUserForm.submitText" />
        </Button>,
      ]}
    >
      <Form
        id="addUserForm"
        name="addUser"
        layout="vertical"
        className="addUserModalWrapper__form"
        onFinish={(values) => {
          setSubmitted(true);
          hideModal();
          addUserHandler(values);
        }}
      >
        <Form.Item
          label={intl.formatMessage({ id: 'participants.addUserForm.field.firstName.label' })}
          name="first_name"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'participants.addUserForm.field.firstName.errorRequired' }),
            },
            {
              whitespace: true,
              message: intl.formatMessage({ id: 'participants.addUserForm.field.firstName.errorBlankChars' }),
            }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage({ id: 'participants.addUserForm.field.lastName.label' })}
          name="last_name"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'participants.addUserForm.field.lastName.errorRequired' }),
            },
            {
              whitespace: true,
              message: intl.formatMessage({ id: 'participants.addUserForm.field.lastName.errorBlankChars' }),
            }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage({ id: 'participants.addUserForm.field.userCompany.label' })}
          name="company"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'participants.addUserForm.field.userCompany.errorRequired' }),
            },
            {
              whitespace: true,
              message: intl.formatMessage({ id: 'participants.addUserForm.field.userCompany.errorBlankChars' }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage({ id: 'participants.addUserForm.field.userGroup.label' })}
          name="user_group"
          rules={[{
            required: true,
            message: intl.formatMessage({ id: 'participants.addUserForm.field.userGroup.errorRequired' }),
          }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage({ id: 'participants.addUserForm.field.userLanguage.label' })}
          name="language"
          rules={[{
            required: true,
            message: intl.formatMessage({ id: 'participants.addUserForm.field.userLanguage.errorRequired' }),
          }]}
        >
          <Select>
            {Object.keys(languages).map((langKey) => (
              <Option key={languages[langKey].name} value={langKey.toUpperCase()}>
                {languages[langKey].name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddUserModal.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onAddUser: PropTypes.func.isRequired,
  selectedGroup: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = ({ groupsReducer }) => ({
  selectedGroup: groupsReducer.selectedGroup,
});

const mapDispatchToProps = (dispatch) => ({
  onAddUser: (userData, slotId) => dispatch(addUser(userData, slotId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddUserModal));
