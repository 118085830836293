import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  Dropdown,
  Menu,
} from 'antd';
import {
  DownCircleOutlined,
  UpCircleFilled,
} from '@ant-design/icons';
import { setSelectedLanguage } from '@actions/conference/ConferenceActions';
import {
  changeLanguage,
  fetchLoggedUserJoinData,
  setBeingSpeaker, setLoggedUserIsInConference,
} from '@actions/loggedUser/LoggedUserActions';
import JitsiConference from '@jitsi/JitsiConference';
import { COUNTRY_LANGUAGE_MAPPING } from '@constants/Settings';
import JitsiConnection from '../../JITSI/JitsiConnection';
import { constructMucRoomName } from '../../CustomHooks/HelperFuncs';
import { stropheLeaveMucRoom } from '@actions/mucs/MucsActions';

const convert3To1 = require('iso-639-3-to-1');

const SlotLanguagesMenu = (props) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const {
    slotLanguages,
    loggedUserLanguage,
    selectedLanguage,
    onSetSelectedLanguage,
    onFetchLoggedUserJitsiData,
    onChangeLanguage,
    beingSpeaker,
    onSetBeingSpeaker,
    loggedUserUid,
    mucNickname,
    onLeaveMucRoom,
    slotUid,
    onSetLoggedUserNotInConference,
  } = props;

  const handleMenuVisibilityChange = (visible) => {
    setIsMenuVisible(visible);
  };

  /**
   * Function where we check if the logged user language exists in all languages for the slot,
   * if so we set it to be the selected language, if it is not found the first language from the array
   * will be set
   * @returns {unknown}
   */
  const findLoggedUserLanguageOrDefault = () => {
    const loggedUserLanguageInSlotLanguages = slotLanguages.find(
      (lang) => lang.iso_code === loggedUserLanguage,
    );

    if (!loggedUserLanguageInSlotLanguages) {
      return slotLanguages[0];
    }

    return loggedUserLanguageInSlotLanguages;
  };

  /**
   * @description User leaves the conference then receives
   * new token with selected language and join again in the conference
   * @param {Object} languageObject - { key: iso_code }
   * @returns {void}
   */
  const changeSelectedLanguage = async (languageObject) => {
    setIsMenuVisible(false);
    const findTheChosenLanguage = slotLanguages.find((lang) => lang.iso_code === languageObject.key);

    try {
      await JitsiConnection.disconnect();
      await onLeaveMucRoom(constructMucRoomName(slotUid, loggedUserUid), mucNickname);
      onSetLoggedUserNotInConference();
      await onSetBeingSpeaker(false, false);

      await sessionStorage.setItem('userLang', findTheChosenLanguage.iso_code);
      await onChangeLanguage(findTheChosenLanguage.iso_code);
      await onFetchLoggedUserJitsiData();

      if (findTheChosenLanguage) {
        await onSetSelectedLanguage(findTheChosenLanguage);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Effect where when the slotLanguages is changed (when we fetch them),
   * we set the selected language
   */
  useEffect(() => {
    if (slotLanguages.length > 0) {
      onSetSelectedLanguage(findLoggedUserLanguageOrDefault());
    }
  }, [slotLanguages]);

  /**
   * @description Gets country code by its official language code
   * @param {String} langCode
   * @returns {void}
   */
  const getCountryCode = (langCode) => Object.keys(COUNTRY_LANGUAGE_MAPPING)
    .find((key) => COUNTRY_LANGUAGE_MAPPING[key] === convert3To1(langCode));

  /**
   * @description Renders language menu items
   * @returns {JSX}
   */
  const languagesMenuItems = () => (
    <Menu onClick={(item) => changeSelectedLanguage(item)}>
      {slotLanguages
        .filter((lang) => lang.iso_code !== selectedLanguage.iso_code)
        .map((lang) => (
          <Menu.Item
            key={lang.iso_code}
            className="userViewSlotLanguagesMenu__languageMenuItem"
            disabled={beingSpeaker.withAudio}
          >
            {/* <i className={`flag flag-24 gx-mr-2 flag-${getCountryCode(lang.iso_code)}`} /> */}
            <span className="gx-language-text">
            {lang.language_name}
          </span>
            <Avatar
              className="userViewSlotLanguagesMenu__languageIsoCode"
              size="small"
            >
              {convert3To1(lang.iso_code).toUpperCase()}
            </Avatar>
          </Menu.Item>
        ))}
    </Menu>
  );

  /**
   * @descriptions Shows language selector depending on slot languages count
   * @returns {JSX}
   */
  const showLanguageSelector = () => {
    let JSXToReturn = null;
    if (slotLanguages.length > 2) {
      JSXToReturn = (
        <Dropdown
          overlay={languagesMenuItems}
          trigger={['click']}
          arrow
          className="userViewSlotLanguagesMenu__dropdown"
          onVisibleChange={(isVisible) => handleMenuVisibilityChange(isVisible)}
        >
          {
            isMenuVisible
              ? <UpCircleFilled className="userViewSlotLanguagesMenu__icon" />
              : <DownCircleOutlined className="userViewSlotLanguagesMenu__icon" />
          }
        </Dropdown>
      );
    }

    if (slotLanguages.length === 2) {
      if (selectedLanguage) {
        const getNotSelectedLang = slotLanguages.filter(
          (lang) => lang.iso_code.toUpperCase() !== selectedLanguage.iso_code.toUpperCase(),
        );

        JSXToReturn = (
          <Avatar
            className="userViewSlotLanguagesMenu__languageCircleNotSelected"
            size="small"
            onClick={() => changeSelectedLanguage({ key: getNotSelectedLang[0].iso_code })}
          >
            {getNotSelectedLang[0].iso_code.toUpperCase()}
          </Avatar>
        );
      }
    }

    return JSXToReturn;
  };

  return (
    <div className="userViewSlotLanguagesMenu">
      {selectedLanguage
        ? (
          <Avatar
            className="userViewSlotLanguagesMenu__languageCircle"
            size="small"
          >
            {selectedLanguage.iso_code.toUpperCase()}
          </Avatar>
        )
        : null}

      {showLanguageSelector()}
    </div>
  );
};

SlotLanguagesMenu.propTypes = {
  slotLanguages: PropTypes.arrayOf(PropTypes.shape).isRequired,
  loggedUserLanguage: PropTypes.string,
  selectedLanguage: PropTypes.object,
  onSetSelectedLanguage: PropTypes.func.isRequired,
  onFetchLoggedUserJitsiData: PropTypes.func,
  onChangeLanguage: PropTypes.func,
  onSetBeingSpeaker: PropTypes.func.isRequired,
  onSetLoggedUserNotInConference: PropTypes.func.isRequired,
  beingSpeaker: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loggedUserUid: PropTypes.string.isRequired,
  mucNickname: PropTypes.string,
  onLeaveMucRoom: PropTypes.func.isRequired,
  slotUid: PropTypes.string,
};

const mapStateToProps = ({ loggedUser, conferenceReducer, mucsReducer }) => ({
  slotLanguages: conferenceReducer.slotLanguages,
  loggedUserLanguage: loggedUser.lang,
  selectedLanguage: conferenceReducer.selectedLanguage,
  beingSpeaker: loggedUser.beingSpeaker,
  loggedUserUid: loggedUser.uid,
  mucNickname: mucsReducer.nicknameUniqueId,
  slotUid: loggedUser.roomName,
});

const mapDispatchToProps = (dispatch) => ({
  onSetSelectedLanguage: (language) => dispatch(setSelectedLanguage(language)),
  onFetchLoggedUserJitsiData: () => dispatch(fetchLoggedUserJoinData()),
  onChangeLanguage: (language) => dispatch(changeLanguage(language)),
  onSetLoggedUserNotInConference: () => dispatch(setLoggedUserIsInConference(false)),
  onSetBeingSpeaker: (withAudioValue, withVideoValue) => dispatch(setBeingSpeaker(withAudioValue, withVideoValue)),
  onLeaveMucRoom: (mucRoomName, nicknameUsedInTheRoom) => dispatch(stropheLeaveMucRoom(mucRoomName, nicknameUsedInTheRoom)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlotLanguagesMenu);
