import {
  call,
  delay,
  put,
  select,
} from 'redux-saga/effects';
import {
  addMessageToConversation,
  filterAndSearchParticipantsSuccess,
  resetOrLeaveCurrentParticipantStatus,
  userConnStatusHasChanged,
  userHasJoined,
  userHasLeft,
  userRaiseHandChange,
} from '@actions/users/UsersActions';
import { checkParticipantsTable } from '@actions/conference/ConferenceActions';
import {
  JITSI_OTHER_MESSAGES,
  PARTICIPANTS_TABLE,
  USER_ROLES,
} from '@constants/Settings';
import JitsiConference from '@jitsi/JitsiConference';
import soundFile from '../../../../assets/sounds/chat-notification.mp3';
import {
  hideNotification,
  showNotification,
} from "@actions";
import {
  LoggerHeaders,
  LoggerMessage,
  LoggerType,
  LogMessage,
} from '../../../../CustomHooks/LoggerHelper';
import { isPublicSystemMessage } from '../../../../CustomHooks/HelperFuncs';
import { checkIfUserIsModeratorByUserId } from '@jitsi/helper';

const audio = new Audio(soundFile);

const addSoundOnNewMessage = (users, senderId, moderatorChatSoundPermission) => {
  const findOpenedChatUser = users.find((user) => user.chatOpened);
  const isAudioPlaying = audio.currentTime > 0 && !audio.paused
      && audio.ended && audio.readyState > audio.HAVE_CURRENT_DATA;
  if (findOpenedChatUser) {
    if (findOpenedChatUser.user_id !== senderId.dataBaseId) {
      if (!isAudioPlaying && moderatorChatSoundPermission) {
        audio.play();
      }
    }
  } else if (!isAudioPlaying && moderatorChatSoundPermission) {
    audio.play();
  }
};

/**
 * MODERATOR ROLE Generator function which we execute when user join the conference
 * @param joinedUser
 */
export function* conferenceUserJoinedGeneratorModerator(joinedUser) {
  const usersWithSameIdInTheConference = yield JitsiConference.checkIfJoinedUserIsAlreadyInTheConference(joinedUser);
  const currentParticipantsFilterValue = yield select((state) => state.usersReducer.participantsPage.filterValue);
  const currentParticipantsSearchValue = yield select((state) => state.usersReducer.participantsPage.searchValue);

  if (usersWithSameIdInTheConference.length > 1 && usersWithSameIdInTheConference[0].userRole === USER_ROLES.visitor) {
    const userIdWhichHasToBeKicked = usersWithSameIdInTheConference[0].getId();

    yield JitsiConference.sendPrivateSystemMessage(JITSI_OTHER_MESSAGES.alreadyInConference, userIdWhichHasToBeKicked);
    yield JitsiConference.kickUserFromConference(userIdWhichHasToBeKicked);
  }

  yield put(userHasJoined(joinedUser));
  yield put(checkParticipantsTable(false));
  yield put(filterAndSearchParticipantsSuccess(currentParticipantsFilterValue, currentParticipantsSearchValue));
}

/**
 * MODERATOR ROLE Generator function which we execute when user have left the conference
 * @param userId
 * @param user
 */
export function* conferenceUserLeftGeneratorModerator(userId, user) {
  const currentParticipantsFilterValue = yield select((state) => state.usersReducer.participantsPage.filterValue);
  const currentParticipantsSearchValue = yield select((state) => state.usersReducer.participantsPage.searchValue);

  yield put(userHasLeft(userId));
  yield put(filterAndSearchParticipantsSuccess(currentParticipantsFilterValue, currentParticipantsSearchValue));
  yield delay(20000);
  yield put(resetOrLeaveCurrentParticipantStatus(
    user.getDisplayName(), PARTICIPANTS_TABLE.rowStatusClasses.unknown,
  ));
  yield put(checkParticipantsTable(false));

  const participantsFilterValueAfterTimeout = yield select((state) => state.usersReducer.participantsPage.filterValue);
  const participantsSearchValueAfterTimeout = yield select((state) => state.usersReducer.participantsPage.searchValue);
  yield put(filterAndSearchParticipantsSuccess(participantsFilterValueAfterTimeout, participantsSearchValueAfterTimeout));
}

/**
 * MODERATOR ROLE Generator function which we execute when user has changed his 'raiseHand' property
 * @param user
 * @param raiseHandValue
 */
export function* conferenceUserRaiseHandGeneratorModerator(user, raiseHandValue) {
  const currentParticipantsFilterValue = yield select((state) => state.usersReducer.participantsPage.filterValue);
  const currentParticipantsSearchValue = yield select((state) => state.usersReducer.participantsPage.searchValue);

  yield put(userRaiseHandChange(user, raiseHandValue));
  yield put(checkParticipantsTable(false));
  yield put(filterAndSearchParticipantsSuccess(currentParticipantsFilterValue, currentParticipantsSearchValue));
}

/**
 * MODERATOR ROLE Generator function which we execute when the moderator receives private message
 * @param {Object} adaptedMessage
 */
export function* conferencePrivateMessageReceivedGeneratorModerator(adaptedMessage) {
  const users = yield select((state) => state.usersReducer.users);
  const moderatorChatSoundPermission = yield select((state) => state.loggedUser.chatSoundNotification);
  const sender = users.find(
    (user) => user.uid === adaptedMessage.userId || user.confProps.userConfId === adaptedMessage.userId,
  );

  if (!sender) {
    LogMessage(LoggerType.ERROR, LoggerHeaders.PRIVATE_MESSAGE_RECEIVED, LoggerMessage.userIndexWasNotFoundInTheUsersList(adaptedMessage.userId));
  }

  if (adaptedMessage.message === JITSI_OTHER_MESSAGES.pingMessageReceivedResponseToModerator && sender) {
    if (sessionStorage.getItem('pingedUser') === adaptedMessage.userId) {
      yield put(showNotification('success', 'notification.participantIsReceivingMessages'));
      yield put(hideNotification());
      sessionStorage.removeItem('pingedUser');
    }
  } else if (sender) {
    const senderId = { dataBaseId: sender.user_id };
    const modifiedMessageObject = { ...adaptedMessage, ...senderId };

    yield call(addSoundOnNewMessage, users, senderId, moderatorChatSoundPermission);

    yield put(addMessageToConversation(modifiedMessageObject));
  }
}


/**
 * MODERATOR ROLE Generator function which we execute when the moderator receives a public message from the JITSI chat
 * @param adaptedMessage
 */
export function* conferenceMessageReceivedGeneratorModerator(adaptedMessage) {
  const senderId = { dataBaseId: null };
  const modifiedMessageObject = { ...adaptedMessage, ...senderId };
  const isTheSenderModerator = yield checkIfUserIsModeratorByUserId(adaptedMessage.userId);

  if (!isPublicSystemMessage(adaptedMessage?.message) && isTheSenderModerator) {
    yield put(addMessageToConversation(modifiedMessageObject));
  }
}

/**
 * MODERATOR ROLE Generator function which we execute when some of the user connection statsu has changed
 * @param userId
 * @param connStatusValue
 */
export function* conferenceUserConnectionStatusChangedGeneratorModerator(userId, connStatusValue) {
  yield put(userConnStatusHasChanged(userId, connStatusValue));
}
