import React from 'react';

const DataProtection = () => {
  return (
    <div>
      <p>In this Data Protection Notice (Privacy Policy) according to Articles
        13 and 14 of the EU GDPR, we inform you about the processing of your
        personal data by AUDI AG, Auto-Union-Straße 1, 85057 Ingolstadt,
        Germany, (“we”) in the context of implementing digital events of Audi
        Communications and the corresponding participant management tool for the
        dissemination of content from Audi Communications. International media
        representatives who would like to participate in AUDI AG digital events
        can register via the participant management platform. For registration,
        you will receive an e-mail that you can use to define your own access
        password. Participation in the event itself takes place via the Digital
        Conference Tool for Events platform (DCT).</p>
      <p>During the digital event, you have the ability to ask questions (via
        email or audio/video signal) yourself. As part of the digital event, a
        summary of the presentation will be made available in the Audi Media
        Center afterwards.</p>
      <p><strong>Personal data</strong> means all information relating to an
        identified or identifiable natural person. An identifiable natural
        person is one who can be identified directly or indirectly, in
        particular by reference to an identifier such as a name, an
        identification number, location data, an online identifier, or one or
        more factors specific to the physical, physiological, genetic,
        psychological, economic, cultural, or social identity of that natural
        person. <strong>Processing</strong> means any operation or set of
        operations performed with or without the aid of automated processes in
        connection with personal data such as collection, recording,
        organization, structuring, storage, adaptation or alteration, retrieval,
        consultation, use, disclosure by transmission, dissemination, or
        otherwise making available, alignment or combination, restriction,
        erasure, or destruction.</p>
      <h1 id="who-is-responsible-for-data-processing-and-whom-can-i-contact">Who
        is responsible for data processing and whom can I contact?</h1>
      <p><strong>The data controller responsible</strong> for the processing
        of your personal data is:</p>
      <p>AUDI AG</p>
      <p>Auto-Union-Str. 1</p>
      <p>85057 Ingolstadt, Germany.</p>
      <p>If you have concerns about data protection, you can also contact our
        <strong>company’s Data Protection Officer</strong>:
      </p>
      <p>AUDI AG</p>
      <p>Datenschutzbeauftragter</p>
      <p>85045 Ingolstadt, Germany</p>
      <p>E-mail: <a href="mailto:datenschutz@audi.de">datenschutz@audi.de</a></p>
      <p>If you wish to assert your <strong>data protection rights</strong>,
        please use the contact option at</p>
      <p><a href="http://www.gdpr.audi.com">www.gdpr.audi.com</a></p>
      <p>You will find further information there on how you can assert your
        data protection rights. You can also contact us by mail at the following
        address:</p>
      <p>AUDI AG</p>
      <p>DSGVO-Betroffenenrechte</p>
      <p>85045 Ingolstadt, Germany</p>
      <p>Should you have general questions about this Data Protection Notice
        or about the processing of your personal data by AUDI AG, please use the
        following contact options: <a href="mailto:media-events@audi.de">media-events@audi.de</a> </p>
      <h1 id="what-kind-of-data-do-we-process-and-what-are-their-sources">What
        kind of data do we process and what are their sources?</h1>
      <h2 id="use-of-the-website">Use of the website</h2>
      <p>In general, you can only access the website for login via link. You
        will receive this link with a personal invitation via e-mail. However,
        registration for the event requires the entry of personal data (see
        section 2.2).</p>
      <p>Each time you access the website, your Internet browser automatically
        transmits certain information, which we store in so-called log
        files.</p>
      <p>In particular, the following information is transmitted
        automatically:</p>
      <ul>
        <li>
          <p>IP address (Internet protocol address) of the terminal from which
            the online service is accessed;</p>
        </li>
        <li>
          <p>Internet address of the website from which the online service was
            accessed (the so-called “source URL” or “referrer URL”);</p>
        </li>
        <li>
          <p>Name of the files or information retrieved;</p>
        </li>
        <li>
          <p>Date and time as well as duration of the retrieval;</p>
        </li>
        <li>
          <p>Transferred data volume;</p>
        </li>
        <li>
          <p>Operating system and information about the Internet browser used,
            including installed add-ons</p>
        </li>
        <li>
          <p>http status code (for example, “request succeeded” or “requested
            file not found”).</p>
        </li>
        <li>
          <p>The IP address is stored in the log files in anonymized form,
            with the last portion of the IP address being replaced by a random
            value.</p>
          <ol type={1}>
            <li>
              <h2 id="use-of-the-website-data-provided-by-you"><strong>Use of the
                website / data provided by you</strong></h2>
            </li>
          </ol>
        </li>
      </ul>
      <p>We process personal data that we receive from you as part of
        registration as well as for participation in digital events of Audi
        Communications.</p>
      <p>The relevant personal data include:</p>
      <ul>
        <li>
          <p><strong>contact / organizational data (professional)</strong>
            (e.g. professional address, e-mail and telephone number of company,
            department)</p>
        </li>
        <li>
          <p><strong>IT usage data</strong></p>
        </li>
        <li>
          <p><strong>recordings</strong> (video, picture, sound)</p>
        </li>
      </ul>
      <h2 id="feedback-function">Feedback function</h2>
      <p>Immediately following the digital event, you can give an anonymous
        rating of our website via a pop-up window. As part of this, your IP
        address will be anonymized and stored, with the last portion of the IP
        address being replaced by a random value.</p>
      <h1 id="for-what-purposes-do-we-process-your-data-and-on-which-legal-basis">For
        what purposes do we process your data and on which legal
        basis?</h1>
      <p>We process your personal data for various purposes in accordance with
        the provisions of the General Data Protection Regulation (GDPR) and the
        German Federal Data Protection Act [<em>Bundesdatenschutzgesetz</em>
        (“BDSG”)].</p>
      <p>The processing of your personal data must be supported by one of the
        following legal bases:</p>
      <ul>
        <li>
          <blockquote>
            <p>You have given your consent (Art. 6 (1) (1) (a) of the GDPR);</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Processing is necessary to perform a contract with you, or in order
              to take precontractual measures at your request (Art. 6 (1) (1) (b) of
              the GDPR);</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Processing is necessary for compliance with a legal obligation under
              EU law or under the law of a member state to which we are subject (Art.
              6 (1) (1) (c) of the GDPR);</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Processing is necessary to protect your vital interests or the vital
              interests of another person (Art. 6 (1) (1) (d) of the GDPR);</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Processing is necessary for the performance of a task carried out in
              the public interest or in the exercise of official authority that has
              been vested in us (Art. 6 (1) (1) (e) of the GDPR);</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Processing is necessary for the purposes of the legitimate interests
              pursued by the AUDI AG or of a third party, except where these interests
              are overridden by your interests or fundamental rights and freedoms that
              require protection of personal data, particularly where this concerns a
              child (Art. 6 (1) (1) (f) of the GDPR).</p>
          </blockquote>
        </li>
      </ul>
      <p>If, in exceptional cases, we process special categories of personal
        data (data revealing racial or ethnic origin, political opinions,
        religious or philosophical beliefs, or trade union membership, as well
        as the processing of genetic data, biometric data for the purpose of
        uniquely identifying a natural person, data concerning health or data
        concerning a natural person’s sex life or sexual orientation) pertaining
        to you, one of the following legal bases must also apply:</p>
      <ul>
        <li>
          <blockquote>
            <p>You have given explicit consent (Art. 9 (2) (a) of the GDPR);</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Processing is necessary to protect your vital interests or those of
              another person where the data subject is physically or legally incapable
              of giving consent (Art. 9 (2) (c) of the GDPR);</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Processing relates to personal data that you manifestly made public
              (Art. 9 (2) (e) of the GDPR);</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Processing is necessary for the establishment, exercise or defense of
              legal claims (Art. 9 (2) (f) of the GDPR);</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Processing is necessary for reasons of substantial public interest,
              on the basis of EU law or the law of an EU member state which shall be
              proportionate to the aim pursued, respect the essence of the right to
              data protection and provide for suitable and specific measures to
              safeguard the fundamental rights and the interests of the data subject
              (Art. 9(2g) GDPR).</p>
          </blockquote>
        </li>
      </ul>
      <blockquote>
        <p>Following on from the above, we process your personal data on the
          basis of the following legal bases for the following purposes:</p>
      </blockquote>
      <table>
        <colgroup>
          <col style={{ width: '28%' }} />
          <col style={{ width: '22%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '23%' }} />
        </colgroup>
        <thead>
          <tr className="header">
            <th><strong>Purpose</strong></th>
            <th><strong>Legal basis</strong></th>
            <th><strong>Legitimate interest in balancing of interests</strong></th>
            <th><strong>Data category</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr className="odd">
            <td>Provision of the website to register for and carry out digital
              events for international media representatives</td>
            <td>Performance of contract</td>
            <td />
            <td>Title, first name, last name, medium/company, email address,
              telephone number, IP address</td>
          </tr>
          <tr className="even">
            <td>Protection of legal interests, e.g.: confidentiality, including
              communication in advance of the event</td>
            <td>Contract</td>
            <td />
            <td>Secrecy agreements, declaration of blocking periods, and the like
              with (digital) signature</td>
          </tr>
          <tr className="odd">
            <td>Recordings (video, picture, sound) to document the event</td>
            <td>Consent</td>
            <td />
            <td>
              <p>Recordings</p>
              <p>(video, picture, sound)</p>
            </td>
          </tr>
          <tr className="even">
            <td>Determination of disruptions and guaranteeing system security
              including uncovering and tracking impermissible access attempts and
              access to our web servers</td>
            <td>Satisfaction of our legal obligations with respect to data security
              and balancing of interests</td>
            <td>We have a legitimate interest in eliminating disruptions, ensuring
              system security, and detecting and tracking unauthorized access and/or
              unauthorized access attempts.</td>
            <td>Title, first name, last name, medium/company, IP address</td>
          </tr>
          <tr className="odd">
            <td>Disclosure related to official/court actions for purposes of
              collecting evidence, criminal prosecution, and enforcement of civil law
              claims</td>
            <td>Fulfillment of legal obligations, balancing of interests</td>
            <td>Compliance with statutory and official requirements</td>
            <td>Title, first name, last name, medium/company, or<br />
              IP address</td>
          </tr>
          <tr className="even">
            <td>
              <blockquote>
                <p>Audits and special inspections, internal investigations</p>
              </blockquote>
            </td>
            <td>
              <blockquote>
                <p>Balancing of interests</p>
              </blockquote>
            </td>
            <td>
              <blockquote>
                <p>Verification of compliance with contractual and statutory obligations
                  by AUDI AG, its employees and its sales partners, suppliers, etc., if
                  necessary using the vehicle identification number</p>
              </blockquote>
            </td>
            <td>Title, first name, last name, medium/company, or<br />
              IP address</td>
          </tr>
          <tr className="odd">
            <td>
              <blockquote>
                <p>Assertion of legal claims and defense</p>
                <p>in legal disputes</p>
              </blockquote>
            </td>
            <td>
              <blockquote>
                <p>Balancing of interests</p>
              </blockquote>
            </td>
            <td>
              <blockquote>
                <p>We have a legitimate interest in the assertion, exercise or defense
                  of legal claims.</p>
              </blockquote>
            </td>
            <td>Title, first name, last name, medium/company, or<br />
              IP address</td>
          </tr>
        </tbody>
      </table>
      <p>Please observe your rights of objection when processing data for the
        purpose of direct marketing or for personal reasons and your right to
        withdraw consent (see the section titled “What rights do you have?” and
        the section titled “Information about your rights of objection”).</p>
      <h2 id="is-there-an-obligation-to-provide-personal-data">Is
        there an obligation to provide personal data?</h2>
      <p>In the context of <strong><u>digital events</u></strong>, we process
        personal data for the purposes of registration at the event and for the
        implementation of the digital events, or if we are required by law to
        collect this data. If you do not provide this data, we are unable to
        allow you to take part in a digital event.</p>
      <h1 id="who-receives-my-data">Who receives my
        data?</h1>
      <p>Due to the volume and complexity of data processing undertaken by
        AUDI AG, it is not possible to list every recipient of your personal
        data individually in this privacy notice. As a general rule, therefore,
        we specify only categories of recipients.</p>
      <p>Within AUDI AG, your data is provided to the respective departments
        that need such data for data processing (e.g., Audi Communications,
        IT).</p>
      <p>Third-party service providers engaged by us and working on our order
        to support data processing (so-called contract processors) may also
        receive data for these purposes. We use only contract processors in
        Germany. For example, your e-mail address can be passed on to a service
        provider so that they can deliver an ordered newsletter to you. Service
        providers can also be commissioned to provide server capacity. These
        include:</p>
      <ul>
        <li>
          <p>Participant management</p>
        </li>
        <li>
          <p>Event agency</p>
        </li>
        <li>
          <p>Hosting providers and support service providers</p>
        </li>
        <li>
          <p>Live stream service providers</p>
        </li>
      </ul>
      <p>Your personal data will be disclosed by us to third parties only if
        this is necessary for the fulfillment of the contract, if we or the
        third party have a legitimate interest in the disclosure, or if you have
        given your consent. In addition, data may be transferred to third
        parties if we are obligated to do so to comply with statutory provisions
        or an enforceable regulatory or judicial order. Third parties to whom we
        may transfer your personal data, irrespective of the services we
        provide, include</p>
      <ul>
        <li>
          <p>Project-related service providers (e.g., participant
            administration, event agency, technology support)</p>
        </li>
        <li>
          <p>Group companies located at the event venue</p>
        </li>
        <li>
          <p>Authorities within their jurisdiction (e.g., tax office, police,
            public prosecutor’s office)</p>
        </li>
        <li>
          <p>Courts</p>
        </li>
        <li>
          <p>Other third parties, if you instruct us to pass on data or give
            your consent</p>
        </li>
      </ul>
      <h1 id="is-my-data-transmitted-to-third-countries">Is my data
        transmitted to third countries?</h1>
      <p>We process your data in Germany. As a rule, we do not transfer your
        data to other countries or third countries (countries that are neither
        members of the European Union nor of the European Economic Area) or to
        international organizations.</p>
      <p>A transfer of data to other countries or third countries (i.e.,
        countries that are neither members of the European Union nor of the
        European Economic Area) may occur in exceptional cases to the extent
        necessary to perform services for you, if required by law, or where you
        have given us your consent.</p>
      <p>Please note that not all third countries have a level of data privacy
        deemed to be adequate by the European Commission. For data transfers to
        third countries where there is no adequate level of data protection, we
        ensure that, prior to disclosure, the recipient has either an adequate
        level of data protection (e.g., adequacy decision of the EU Commission
        or agreement of so-called EU Standard Contractual Clauses of the
        European Union with the recipient) or we have obtained express consent
        from our users. Please use the contact information listed above.</p>
      <p>You can obtain from us a copy of the specific applicable or
        agreed-upon provisions to ensure an adequate level of data protection.
        To do so, please use the information in the “Contact” section.</p>
      <h1 id="how-long-will-my-data-be-stored">How long will my data
        be stored?</h1>
      <p>We store your data as long as necessary for the implementation of the
        digital event or as long as we have a legitimate interest in the
        continued storage. For instance, in order to perform participant
        management, the service provider stores the personal data for a maximum
        of 30 days following the event. AUDI AG stores the data – to the extent
        this is relevant for tax purposes – pursuant to statutory
        requirements.</p>
      <p>This could result in retention and documentation requirements
        pursuant to, <em>inter alia</em>, the German Commercial Code
        (<em>Handelsgesetzbuch</em> – “HGB”) and the Tax Code
        (<em>Abgabenordnung</em> – “AO”). The retention and documentation
        periods specified therein last up to 10 years. Finally, the storage
        period is also governed by the statutory limitation periods, which can
        be up to 30 years, for example, pursuant to sections 195 <em>et
          seq.</em> of the German Civil Code (<em>Bürgerliches Gesetzbuch</em> –
        “BGB”), whereby the general limitation period is three years.</p>
      <blockquote>
        <p>Under certain circumstances, your data may need to be kept longer,
          e.g. if a <em>legal hold</em> or <em>litigation hold</em> (i.e. a ban on
          deleting data for the duration of the proceedings) is ordered in
          connection with official or judicial proceedings.</p>
      </blockquote>
      <h1 id="which-rights-do-i-have">Which rights do I
        have?</h1>
      <p>As the data subject, you are entitled to the following data
        protection rights:</p>
      <table>
        <colgroup>
          <col style={{ width: '24%' }} />
          <col style={{ width: '75%' }} />
        </colgroup>
        <thead>
          <tr className="header">
            <th><strong>Access:</strong></th>
            <th>You have the right to request access to personal data related to you
              and stored at AUDI AG and about the scope of data processing and data
              transfer performed by AUDI AG and to obtain a copy of your stored
              personal data.</th>
          </tr>
        </thead>
        <tbody>
          <tr className="odd">
            <td><strong>Rectification:</strong></td>
            <td>With respect to your personal data stored at AUDI AG, you have the
              right to demand the immediate rectification of incorrect personal data
              and you have the right to have incomplete personal data completed.</td>
          </tr>
          <tr className="even">
            <td><strong>Erasure:</strong></td>
            <td>
              <p>You have the right to demand the immediate deletion or erasure of
                your personal data stored by AUDI AG, if the legal requirements are
                satisfied.</p>
              <p>This is the case, in particular, if</p>
              <ul>
                <li>
                  <p>your personal data is no longer needed for the purposes for which
                    it was collected;</p>
                </li>
                <li>
                  <p>the sole legal basis for processing such data was your consent,
                    and you have withdrawn such consent;</p>
                </li>
                <li>
                  <p>you have objected to processing on the legal grounds relating to
                    your particular situation, and we cannot prove that there are overriding
                    legitimate grounds for processing;</p>
                </li>
                <li>
                  <p>your personal data were processed unlawfully; or</p>
                </li>
                <li>
                  <p>your personal data must be erased in order to comply with legal
                    requirements.</p>
                </li>
              </ul>
              <p>If we have transmitted your data to third parties, we will inform
                them about the erasure to the extent required by law.</p>
              <p>Please note that your right to erasure is subject to certain
                limitations. For example, we may not and/or must not erase data that we
                are still required to retain in line with statutory retention
                obligations. In addition, your right of erasure does not extend to data
                that we need in order to assert, exercise, or defend against legal
                claims.</p>
            </td>
          </tr>
          <tr className="odd">
            <td><strong>Restriction to the processing:</strong></td>
            <td>
              <p>Under certain conditions, you have the right to request that
                processing be limited (i.e., the marking of stored personal data with
                the aim of limiting its processing in the future). The requirements
                are:</p>
              <ul>
                <li>
                  <p>The accuracy of your personal data is contested by you and AUDI
                    AG must verify the accuracy of the personal data;</p>
                </li>
                <li>
                  <p>the processing is unlawful, but you oppose the erasure of the
                    personal data and request the restriction of their use instead;</p>
                </li>
                <li>
                  <p>AUDI AG no longer needs the personal data for the purposes of
                    processing, but you require the data to establish, exercise, or defend
                    your legal claims;</p>
                </li>
                <li>
                  <p>you have objected to processing pending the verification of
                    whether the legitimate grounds of AUDI AG override your legitimate
                    grounds.</p>
                </li>
              </ul>
              <p>Where processing has been restricted, such data will be marked
                accordingly and, with the exception of storage, will be processed only
                with your consent or for the establishment, exercise or defense of legal
                claims or for the protection of the rights of another natural or legal
                person or for reasons of important public interest of the EU or an EU
                member state.</p>
            </td>
          </tr>
          <tr className="even">
            <td><strong>Data portability:</strong></td>
            <td>To the extent that we automatically process your personal data that
              you have provided to us based on your consent or any contract with you
              (including your employment contract), you have the right to receive such
              data in a structured, commonly used, and machine-readable format and
              have the right to transmit those data to another controller without
              hindrance from AUDI AG. You also have the right to have the personal
              data transmitted directly from AUDI AG to another controller where
              technically feasible, provided that such transmission does not adversely
              affect the rights and freedoms of others.</td>
          </tr>
          <tr className="odd">
            <td><strong>Objection:</strong></td>
            <td>If we process your personal data on grounds of legitimate interests
              or in the public interest, then you have the right to object to the
              processing of your personal data on grounds relating to your particular
              situation. In addition, you have an unrestricted right to object if we
              process your data for our direct-marketing purposes. Please see our
              separate note in the section titled “Information about your right to
              object.”</td>
          </tr>
          <tr className="even">
            <td><strong>Withdrawal of consent:</strong></td>
            <td>If you have given consent to the processing of your personal data,
              then you can withdraw such consent at any time. Please note that the
              withdrawal applies prospectively only. Processing that occurred before
              the withdrawal of consent is unaffected.</td>
          </tr>
          <tr className="odd">
            <td><strong>Complaint:</strong></td>
            <td>
              <p>Furthermore, you have a right to file a complaint with a data
                protection authority, if you believe that the processing of your
                personal data is unlawful. The right to file a complaint is without
                prejudice to any other administrative or judicial remedies. The address
                for the data protection supervisory body that is competent for AUDI AG
                is:</p>
              <p><strong>Bayerisches Landesamt für Datenschutzaufsicht</strong></p>
              <p>Promenade 18</p>
              <p>91522 Ansbach</p>
              <p>Germany</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h1 id="information-about-your-right-to-object">Information
        about your right to object</h1>
      <h2 id="right-to-object-for-personal-reasons" className="list-paragraph">Right to object for
        personal
        reasons</h2>
      <p>You have the right to object to the processing of your personal data
        on grounds relating to your particular situation. The prerequisite for
        this is that the data processing takes place in the public interest or
        on the basis of a balancing of interests. This also applies to
        profiling.</p>
      <p>Insofar as we base the processing of your personal data on a
        balancing of interests, we generally assume that we can demonstrate
        compelling legitimate grounds but will, of course, examine each
        individual case.</p>
      <p>In the event of an objection, we will no longer process your personal
        data, unless:</p>
      <ul>
        <li>
          <p>we can demonstrate compelling legitimate grounds for the
            processing of these data that override your interests, rights, and
            freedoms, or</p>
        </li>
        <li>
          <p>your personal data serves the establishment, exercise, or defense
            of legal claims.</p>
        </li>
      </ul>
      <h2 id="exercising-the-right-to-object" className="list-paragraph">Exercising the right to
        object</h2>
      <p>Objection can be made without form and should preferably be made to
        the contact data listed in this privacy notice.</p>
    </div>
  );
};

export default DataProtection;
