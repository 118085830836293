import { useEffect } from 'react';
import { notification } from 'antd';
import { useSelector } from 'react-redux';

/**
 * Custom react hook to show notification in every component we want
 * @param intl
 */
export function useNotificationHook(intl) {
  const notificationMessage = useSelector((state) => state.common.notificationMessage);
  const notificationType = useSelector((state) => state.common.notificationType);
  const notificationDuration = useSelector((state) => state.common.notificationDuration);

  /**
   * Notification
   *
   * @param type - type of the notification (warning, success...)
   * @param description - description
   * @param title - the title of the notification
   */
  const openNotification = (type, description, title) => {
    notification[type]({
      message: title,
      description,
      style: { width: 320 },
      duration: notificationDuration,
    });
  };

  /**
   * Effect where we watch if a 'notificationMessage' from redux store is not empty.
   *
   * For example if moderator has went offline, in the visitorListeners saga, we dispatch action to
   * showNotification(notificationType: ['success', 'warning', 'error' or 'info'], notificationMessage)
   *
   * <b>NOTE:</b> the notification message is a ID from the en_US.json file, so it can be translated for the user.
   */
  useEffect(() => {
    if (notificationMessage !== '') {
      const title = intl.formatMessage({ id: `notification.${notificationType}` });
      const description = intl.formatMessage({ id: notificationMessage });

      openNotification(notificationType, description, title);
    }
  }, [notificationMessage]);
}
