import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Slider, Switch } from 'antd';
import { setConferenceVolume } from '@actions/conference/ConferenceActions';
import { toggleStreamType, fetchLoggedUserJoinData, fetchLoggedUser } from '@actions/loggedUser/LoggedUserActions';
import IntlMessages from '@util/IntlMessages';
import { SUPPORTED_STREAM_TYPES } from '@constants/Settings';

const UserSettings = (props) => {
  const {
    conferenceVolume,
    onSetConferenceVolume,
    slotMedia,
    onChangeStreamType,
    onSwitchStreamType,
  } = props;

  const sliderFormatter = (value) => `${value}%`;

  const switchStreamTypeHandler = () => {
    const streamType = slotMedia === SUPPORTED_STREAM_TYPES.WEBRTC ? SUPPORTED_STREAM_TYPES.HLS : SUPPORTED_STREAM_TYPES.WEBRTC;
    const strophieConn = window.stropheConn;
    strophieConn.flush();
    strophieConn.disconnect();
    onChangeStreamType(streamType);
    onSwitchStreamType();
  };

  const isHLS = JSON.parse(sessionStorage.getItem('streamTypeHLS'));
  const isSwitchableStreamType = JSON.parse(sessionStorage.getItem('switchableStreamType'));

  return (
    <div>
      <h2>
        <IntlMessages id="user.menu.modal.settings.volume" />
      </h2>
      <Slider
        tipFormatter={sliderFormatter}
        tooltipVisible="true"
        step={5}
        defaultValue={100}
        value={conferenceVolume * 100}
        onChange={(value) => onSetConferenceVolume(value * 0.01)}
        disabled={isHLS}
      />
      {isSwitchableStreamType
        && (
          <>
            <h2>Stream type</h2>
            <div className="switch-media-type">
              <h5>WebRTC</h5>
              <Switch onClick={switchStreamTypeHandler} checked={slotMedia === SUPPORTED_STREAM_TYPES.HLS} />
              <h5>HLS</h5>
            </div>
          </>
        )}
    </div>
  );
};

UserSettings.propTypes = {
  conferenceVolume: PropTypes.number.isRequired,
  onSetConferenceVolume: PropTypes.func.isRequired,
  slotMedia: PropTypes.string.isRequired,
  onChangeStreamType: PropTypes.func.isRequired,
  onSwitchStreamType: PropTypes.func.isRequired,
};

const mapStateToProps = ({ conferenceReducer, loggedUser }) => ({
  conferenceVolume: conferenceReducer.conferenceVolume,
  slotMedia: loggedUser.slotMedia,
});

const mapDispatchToProps = (dispatch) => ({
  onSetConferenceVolume: (volume) => dispatch(setConferenceVolume(volume)),
  onChangeStreamType: (streamType) => dispatch(toggleStreamType(streamType)),
  onSwitchStreamType: ()=>dispatch(fetchLoggedUserJoinData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserSettings));
