import {
  CREATE_STROPHE_CONNECTION_SUCCESS,
  LEAVE_MUC_ROOM,
  SET_JOINED_MUCS,
  SET_NICKNAME_UNIQUE_ID,
  CHAT_IS_CONNECTED,
} from '@constants/ActionTypes';
import _ from 'lodash';

const INIT_STATE = {
  stropheConnection: false,
  nicknameUniqueId: null,
  joinedMucs: [],
  chatConnectionEstablished: false,
};

const onCreateStropheConnectionSuccess = (state) => {
  const newState = _.cloneDeep(state);
  newState.stropheConnection = true;

  return newState;
};

const onSetNicknameUniqueId = (state, nicknameUniqueId) => {
  const newState = _.cloneDeep(state);
  newState.nicknameUniqueId = nicknameUniqueId;

  return newState;
};

function onSetJoinedMucs(state, joinedMucs) {
  const newState = _.cloneDeep(state);
  newState.joinedMucs = joinedMucs;

  return { ...state, ...newState };
}

function onLeaveMucRoom(state, mucRoomName) {
  const newState = _.cloneDeep(state);
  newState.joinedMucs = newState.joinedMucs.filter((room) => room !== mucRoomName);

  return { ...state, ...newState };
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_STROPHE_CONNECTION_SUCCESS:
      return onCreateStropheConnectionSuccess(state);
    case SET_NICKNAME_UNIQUE_ID:
      return onSetNicknameUniqueId(state, action.nicknameUniqueId);
    case SET_JOINED_MUCS:
      return onSetJoinedMucs(state, action.joinedMucs);
    case LEAVE_MUC_ROOM:
      return onLeaveMucRoom(state, action.mucRoomName);
    case CHAT_IS_CONNECTED:
      return { ...state, chatConnectionEstablished: action.payload };
    default:
      return state;
  }
};
