import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomScrollbars from 'util/CustomScrollbars';
import {
  Menu,
  Popover,
} from 'antd';
import { COUNTRY_LANGUAGE_MAPPING } from '@constants/Settings';
import IntlMessages from '../../util/IntlMessages';
import { useNotificationHook } from "../../CustomHooks/useNotificationHook";
import { injectIntl } from "react-intl";

const convert3To1 = require('iso-639-3-to-1');

/**
 * @description Renders Language filter
 * @param {Array} users
 * @param {Function} filterUsersByLanguage
 * @returns {JSX}
 */
const LanguageFilter = ({ users, filterUsersByLanguage, intl }) => {
  const [defaultFlag, setDefaultFlag] = useState('all');

  useNotificationHook(intl);

  /**
   * @description Get country code by its official language code
   * @param {String} language
   * @returns {void}
   */
  const getCountryCode = (language) => Object.keys(COUNTRY_LANGUAGE_MAPPING)
    .find((key) => COUNTRY_LANGUAGE_MAPPING[key] === convert3To1(language));

  const changeFilterFlag = (item) => {
    setDefaultFlag(item.key);
  };
  /**
   * @description All users languages menu
   * @returns {JSX}
   */
  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <Menu
        className="gx-sub-popover"
        onClick={(item) => changeFilterFlag(item)}
      >
        <Menu.Item
          className="chat-language-filter"
          key="all"
          onClick={() => filterUsersByLanguage('all')}
        >
          <span className="gx-language-text">All</span>
        </Menu.Item>
        {Array.from(new Set(users.map((user) => user.language))).map((language) => {
          return (
            <Menu.Item
              className="chat-language-filter"
              key={language}
              onClick={() => filterUsersByLanguage(language)}
            >
              <i className={`flag flag-24 gx-mr-2 flag-${getCountryCode(language)}`} />
              <span className="gx-language-text">{language.toUpperCase()}</span>
            </Menu.Item>
          );
        })}
      </Menu>
    </CustomScrollbars>
  );

  /**
   * @description Renders current filter flag
   * @returns {JSX}
   */
  const renderFilteredFlag = () => {
    if (defaultFlag && defaultFlag !== 'all') {
      return (
        <i className={`flag flag-24 flag-${getCountryCode(defaultFlag)}`} />
      );
    } if (defaultFlag === 'all') {
      return (
        <span>
          <IntlMessages id="common.all" />
        </span>
      );
    }
    return null;
  };

  return (
    <div className="language-filter-wrapper">
      <span>
        <IntlMessages id="chat.users.list.filter" />
      </span>
      <span>
        <Popover
          overlayClassName="gx-popover-horizantal"
          placement="bottomRight"
          content={languageMenu}
          trigger="click"
        >
          <span className="gx-pointer gx-flex-row gx-align-items-center">
            {renderFilteredFlag()}
            <i className="icon icon-chevron-down gx-pl-2" />
          </span>
        </Popover>
      </span>
    </div>
  );
};

LanguageFilter.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  users: PropTypes.oneOfType(PropTypes.array),
  filterUsersByLanguage: PropTypes.func,
};

export default (injectIntl(LanguageFilter));
