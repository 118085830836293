import React, {useState} from 'react';
import {Avatar, Drawer, Layout, Menu, Popover,} from 'antd';
import {connect} from 'react-redux';
import CustomScrollbars from 'util/CustomScrollbars';
import {useLocation} from 'react-router-dom';
import UserInfo from 'components/UserInfo';
import SlotLanguagesMenu from '@routes/UserView/SlotLanguagesMenu';
import {COUNTRY_LANGUAGE_MAPPING, DEVICES_TYPES, USER_ROLES} from '@constants/Settings';
import VisitorMobileMenu from '@routes/UserView/Fullscreen/Mobile/VisitorMobileMenu';
import logoVisitor from 'assets/images/logo/Audi_Rings_Medium_bl-RGB.svg';
import logo from 'assets/images/logo/Audi_Rings_Medium_wh-RGB.svg';
import HorizontalNav from '../HorizontalNav';
import HorizontalNavDropdown from '../HorizontalNavDropdown';
import {switchLanguage, toggleCollapsedSideNav} from '../../../appRedux/actions/Setting';
import IntlMessages from '../../../util/IntlMessages';
import logoAudiRingsBl from 'assets/images/logo/Audi_Rings_Medium_bl-RGB.svg';
import logoAudiRingsWh from 'assets/images/logo/Audi_Rings_Medium_wh-RGB.svg';

const { Header } = Layout;
const convert3To1 = require('iso-639-3-to-1');

const InsideHeader = (props) => {
  const {
    userRole, isOnFullScreen, slotLanguages, deviceType, browserName, lang
  } = props;
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [defaultFlag, setDefaultFlag] = useState('eng');

  const userRoleFromStorage = sessionStorage.getItem('userRole');

  /**
   * Used to check if are on the 'UserView' so we display only the slot languages dropdown
   */
  const location = useLocation();

  /**
   * @description Should change language
   * @param {Object} item
   * @returns {void}
   */
  // TODO: Changing languages should be implemented when we have i18n
  const changeLanguage = (item) => {
    setDefaultFlag(item.key);
  };

  /**
   * @description Gets country code by its official language code
   * @param {String} langCode
   * @returns {void}
   */
  const getCountryCode = (langCode) => Object.keys(COUNTRY_LANGUAGE_MAPPING)
    .find((key) => COUNTRY_LANGUAGE_MAPPING[key] === convert3To1(langCode));

  /**
   * @description Renders language menu
   * @returns {JSX}
   */
  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <Menu
        className="gx-sub-popover moderator-languages-menu"
        onClick={(item) => changeLanguage(item)}
      >
        {slotLanguages && slotLanguages.map((language) => (
          <Menu.Item
            className="moderator-languages-menu-item"
            key={language.iso_code}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${getCountryCode(language.iso_code)}`} />
            <span className="gx-language-text">{language.language_name}</span>
          </Menu.Item>
        ))}
      </Menu>
    </CustomScrollbars>
  );

  /**
   * @description Renders hamburger menu content depending on logged user role
   * @returns {JSX}
   */
  const renderHamburgerMenu = () => {
    if (userRole === USER_ROLES.moderator) {
      return (
        <Drawer
          title={<IntlMessages id="sidebar.navigation.menu" />}
          placement="left"
          closable
          visible={isMenuVisible}
          onClose={() => setIsMenuVisible(false)}
          className="hamburger-menu-moderator"
        >
          <HorizontalNav
            isVertical={isMenuVisible}
          />
        </Drawer>
      );
    }

    if (userRole === USER_ROLES.visitor) {
      return (
        <VisitorMobileMenu
          isMenuVisible={isMenuVisible}
          onHideMenu={() => setIsMenuVisible(false)}
          browserName={browserName}
        />
      );
    }

    return null;
  };

  const makeToUpperCase = (language) => (language ? language.toUpperCase() : null);

  /**
   * @description Renders InsideHeader menu
   * @returns {JSX}
   */
  const result = (
    <div
      className={`gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal ${userRole === USER_ROLES.visitor ? `visitor-header visitor-header--${deviceType}` : null}`}
      style={((isOnFullScreen && deviceType !== DEVICES_TYPES.desktop) || location.pathname === '/preview') || !userRole ? { display: 'none' } : {}}
    >
      <div className="gx-header-horizontal-top">
        <div className="gx-container">
          <div className="gx-header-horizontal-top-flex">
            <div className="gx-header-horizontal-top-left">
              <HorizontalNavDropdown />
            </div>
          </div>
        </div>
      </div>
      <div className="gx-header-horizontal-top main-header-wrapper">
        <div className={`gx-container ${userRole === USER_ROLES.visitor ? 'gx-container-visitor' : null}`}>
          <div className="gx-header-horizontal-top-flex">
            <Header
              className="gx-header-horizontal-main"
            >
              <div className={`gx-container header-container-${userRole === USER_ROLES.visitor ? 'visitor' : 'moderator'} ${deviceType === DEVICES_TYPES.mobile ? 'header-container-visitor--mobile' : ''}` }>
                <div className="gx-header-horizontal-main-flex">
                  <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
                    <i
                      className={`gx-icon-btn icon icon-menu ${userRole === USER_ROLES.visitor ? 'hamburger-icon-visitor' : 'hamburger-icon-moderator'}`}
                      onClick={() => setIsMenuVisible(true)}
                    />
                  </div>
                  <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
                    {renderHamburgerMenu()}
                  </div>
                  <div className={`visitorLogoWrapper visitorLogoWrapper--${deviceType}`}>
                    {
                      userRole === USER_ROLES.visitor
                        ? <img alt="logo" src={logoAudiRingsBl} className="gx-d-lg-block gx-mr-xs-5 gx-logo"/>
                        : <img alt="logo" src={logoAudiRingsWh} className="gx-d-lg-block gx-mr-xs-5 gx-logo"/>
                    }
                  </div>
                  <div className={`flex-container ${userRole === USER_ROLES.visitor ? `flex-container--visitor flex-container--visitor--${deviceType}` : ''} `}>
                    <div className={`gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-lg-block ${deviceType === DEVICES_TYPES.mobile ? 'gx-d-none' : ''}`}>
                      <HorizontalNav />
                    </div>
                    <ul className="gx-header-notifications gx-ml-auto" style={{ display: userRole !== USER_ROLES.moderator ? 'none' : 'flex' }}>
                      <li className="gx-language">
                        <Popover
                          overlayClassName="gx-popover-horizantal languages-moderator-popover"
                          placement="bottomRight"
                          content={languageMenu()}
                          trigger="click"
                          className="languages-moderator-popover"
                        >
                          <span className="gx-pointer gx-flex-row gx-align-items-center">
                            <i className={`flag flag-24 flag-${getCountryCode(defaultFlag)}`} />
                          </span>
                        </Popover>
                      </li>
                      <li className="gx-user-nav"><UserInfo /></li>
                    </ul>
                    <span style={{ display: userRole === USER_ROLES.translator || userRole === USER_ROLES.stage ? 'flex' : 'none' }}>
                      <i className={`flag flag-24 flag-${getCountryCode(lang)}`} />
                      <Avatar
                        size="small"
                        className="stage-translator-language"
                      >
                        {makeToUpperCase(lang)}
                      </Avatar>
                    </span>
                    {userRole === USER_ROLES.visitor
                      ? (
                        <div className="flex-container" style={{ marginLeft: 'auto' }}>
                          <SlotLanguagesMenu />
                        </div>
                      ) : null}
                  </div>
                </div>
              </div>
            </Header>
          </div>
        </div>
      </div>
    </div>
  );

  return (userRole || userRoleFromStorage) ? result : null;
};

const mapStateToProps = ({ settings, loggedUser, conferenceReducer }) => {
  const { userRole, isOnFullScreen, deviceType, lang, browserName } = loggedUser;
  const { locale, navCollapsed } = settings;
  const { slotLanguages } = conferenceReducer;

  return {
    locale,
    navCollapsed,
    userRole,
    isOnFullScreen,
    deviceType,
    slotLanguages,
    lang,
    browserName,
  };
};
export default connect(mapStateToProps, { toggleCollapsedSideNav, switchLanguage })(InsideHeader);
