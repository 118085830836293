import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import EventsReducer from './events/EventsReducer';
import GroupsReducer from './groups/GroupsReducer';
import Settings from './Settings';
import Auth from './Auth';
import Notes from './Notes';
import Common from './Common';
import UsersReducer from './users/UsersReducer';
import LoggedUserReducer from './loggedUser/LoggedUserReducer';
import QuestionsReducer from './questions/QuestionsReducer';
import PredefinedMessagesReducer from './predefinedMessages/PredefinedMessagesReducer';
import ConferenceReducer from './conference/ConferenceReducer';
import MucsReducer from '@reducers/mucs/MucsReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  common: Common,
  eventsReducer: EventsReducer,
  groupsReducer: GroupsReducer,
  usersReducer: UsersReducer,
  loggedUser: LoggedUserReducer,
  questionsReducer: QuestionsReducer,
  predefinedMessagesReducer: PredefinedMessagesReducer,
  conferenceReducer: ConferenceReducer,
  mucsReducer: MucsReducer,
});

export default createRootReducer;
