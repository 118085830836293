import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  HIDE_NOTIFICATION,
  SHOW_MESSAGE,
  SHOW_NOTIFICATION,
} from "../../constants/ActionTypes";


export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

/**
 * <b>Note:</b> for notifaciton message we use ID from the <b>en_US.json</b> file
 */
export const showNotification = (notificationType, notificationMessage, notificationDuration = 4.5) => {
  return {
    type: SHOW_NOTIFICATION,
    notificationType,
    notificationMessage,
    notificationDuration,
  }
};

export const hideNotification = () => {
  return {
    type: HIDE_NOTIFICATION,
  }
};
