import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {
  addEventSuccess,
  onFetchEventsSuccess,
  onFetchEventSuccess,
  onUpdateEventSuccess,
} from '@actions/events/EventsActions';
import {
  ADD_EVENT_REQUEST,
  FETCH_ALL_EVENTS_REQUEST,
  FETCH_EVENT_REQUEST,
  UPDATE_EVENT_REQUEST,
} from '@constants/ActionTypes';
import {ENDPOINTS} from '@constants/Endpoints';
import {fetchError} from '@actions/Common';
import RestManager from '@util/RestManager';

/**
 * @description Request events data
 * @returns {Object}
 */
const doFetchEventsData = async () => {
  return await RestManager.request(`${ENDPOINTS.getEvents}`, 'GET', [{ name: 'Authorization', value: `Bearer ${sessionStorage.getItem('startToken')}` }]);
};

/**
 * @description Get events data
 * @returns {Object}
 */
function* fetchEventsList() {
  try {
    const eventsData = yield call(doFetchEventsData);
    if (eventsData) {
      yield put(onFetchEventsSuccess({ ...eventsData }));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request single event data
 * @param {Number} eventId
 * @returns {Object}
 */
const doFetchEventById = async (eventId) => {
  return await RestManager.request(`${ENDPOINTS.getEvents}/${eventId}`, 'GET', [{ name: 'Authorization', value: `Bearer ${sessionStorage.getItem('startToken')}` }]);
};

/**
 * @description Get single event data
 * @param {Number} eventId
 * @returns {Object}
 */
function* fetchEventById({ eventId }) {
  try {
    const fetchedEvent = yield call(doFetchEventById, eventId);
    if (fetchedEvent) {
      yield put(onFetchEventSuccess(fetchedEvent));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request to add an event
 * @param {object} eventData
 * @returns {Promise<*>}
 */
const addEvent = async (eventData) =>
  await RestManager.requestWithoutQueryParams(
    `${ENDPOINTS.importEvent}`,
    'POST',
    eventData
  );

/**
 * Add an event
 * @param {object} eventData
 */
function* doAddEvent({ eventData }) {
  try {
    const addEventResult = yield call(addEvent, eventData);

    if (addEventResult) {
      yield put(addEventSuccess());
      yield put(push('/schedule'));
      yield call(fetchEventsList);
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Request to update an event
 * @param {number} eventId
 * @param {object} newEventData
 * @returns {Promise<*>}
 */
const doUpdateEvent = async (eventId, newEventData) => await RestManager.requestWithoutQueryParams(`${ENDPOINTS.updateEvent(eventId)}`, 'PATCH', newEventData);

/**
 * @description Generator to update an event when specific action is triggered
 * @param {number} eventId
 * @param {object} eventData
 * @returns {any}
 */
function* updateEvent({ eventId, eventData }) {
  try {
    const updateEventResult = yield call(doUpdateEvent, eventId, eventData);

    if (updateEventResult) {
      yield put(onUpdateEventSuccess());
      yield call(fetchEventsList);
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_ALL_EVENTS_REQUEST, fetchEventsList);
  yield takeEvery(FETCH_EVENT_REQUEST, fetchEventById);
  yield takeEvery(UPDATE_EVENT_REQUEST, updateEvent);
  yield takeEvery(ADD_EVENT_REQUEST, doAddEvent);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
