import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ReactComponent as HandIcon } from '@assets/images/actions-icons/hand_icon_white-thin.svg';
import { ReactComponent as HandIconSelected } from '@assets/images/actions-icons/hand_icon_black-thin.svg';
import { MenuOutlined } from '@ant-design/icons';
import { ReactComponent as ChatIcon } from '@assets/images/actions-icons/chat_icon_white-thin.svg';
import { setLoggedUserIsOnFullScreen } from '@actions/loggedUser/LoggedUserActions';
import { Badge, Tooltip } from 'antd';
import VisitorMobileFullscreenMenu from '@routes/UserView/Fullscreen/Mobile/VisitorMobileMenu';
import { useDevicesButtonsActionsHook } from '../../../../CustomHooks/useDevicesButtonsActionsHook';
import { toggleFullscreenHelper } from '../../../../CustomHooks/HelperFuncs';

const VerticalActionsButtons = (props) => {
  const {
    intl, onSetFullscreen,
    unreadMessages, isOnFullScreen,
  } = props;

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const hasRaisedHand = useSelector((state) => state.loggedUser.hasRaisedHand);
  const hasMutedLocalAudioTrack = useSelector((state) => state.loggedUser.hasMutedLocalAudioTrack);
  const hasMutedLocalVideoTrack = useSelector((state) => state.loggedUser.hasMutedLocalVideoTrack);
  const hasMicrophoneDevice = useSelector((state) => state.loggedUser.hasMicrophoneDevice);

  const { toggleRaiseHand, raiseHandTooltip } = useDevicesButtonsActionsHook(
    intl,
    hasRaisedHand,
    hasMutedLocalAudioTrack,
    hasMutedLocalVideoTrack,
    null,
    hasMicrophoneDevice,
  );

  /**
   * Function to toggle the fullscreen
   */
  const toggleFullscreen = (isItOnFullscreen) => toggleFullscreenHelper(isItOnFullscreen, onSetFullscreen, true);

  return (
    <div className="userViewVerticalActionsButtons">
      <div className="userViewVerticalActionsButtons__hamburgerMenuIconWrapper">
        <MenuOutlined
          className="userViewVerticalActionsButtons__icon"
          onClick={() => setIsMenuVisible(true)}
        />
      </div>
      <VisitorMobileFullscreenMenu
        isMenuVisible={isMenuVisible}
        isOnFullScreen={isOnFullScreen}
        onHideMenu={() => setIsMenuVisible(false)}
      />
      <div className="userViewVerticalActionsButtons__otherIconsWrapper">
        <Tooltip placement="topRight" title={raiseHandTooltip} trigger={['click', 'hover']}>
          <div
            className={`userViewActionButton userViewActionButton--mobile ${hasRaisedHand ? 'userViewActionButton--selected' : ''}`}
            onClick={() => toggleRaiseHand()}
          >
            { hasRaisedHand ? <HandIconSelected /> : <HandIcon /> }
          </div>
        </Tooltip>
        <Badge
          count={unreadMessages > 0 ? unreadMessages : null}
          showZero
          size="small"
          className="userViewActionButton__iconBadge"
        >
          <div className="userViewActionButton userViewActionButton--mobile" onClick={() => toggleFullscreen()}>
            <ChatIcon />
          </div>
        </Badge>
      </div>
    </div>
  );
};

VerticalActionsButtons.propTypes = {
  isOnFullScreen: PropTypes.bool.isRequired,
  onSetFullscreen: PropTypes.func.isRequired,
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  unreadMessages: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSetFullscreen: () => dispatch(setLoggedUserIsOnFullScreen(false)),
});

export default connect(null, mapDispatchToProps)(injectIntl(React.memo(VerticalActionsButtons)));
