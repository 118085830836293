import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { userSignOut } from 'appRedux/actions/Auth';
import IntlMessages from '../../util/IntlMessages';

/**
 * @description Renders UsersInfo component
 * @param {String} displayName
 * @returns {JSX}
 */
const UserInfo = ({ displayName }) => {
  const dispatch = useDispatch();

  /**
   * @description Renders logged user menu
   * @returns {JSX}
   */
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => dispatch(userSignOut())}
      >
        <IntlMessages id="app.logout" />
      </li>
    </ul>
  );

  /**
   * @description Get initials of logged user
   * @param {String} fullName
   * @returns {String}
   */
  const getLoggedUserInitials = (fullName) => {
    let avatarInitials;
    if (fullName) {
      const names = fullName.split(' ');
      const firstName = names[0].charAt(0);
      const lastName = names[1].charAt(0);

      avatarInitials = firstName + lastName;
    }

    return avatarInitials;
  };

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <Avatar
        className="gx-avatar gx-pointer"
        alt=""
      >
        {getLoggedUserInitials(displayName)}
      </Avatar>
    </Popover>
  );
};

UserInfo.propTypes = {
  displayName: PropTypes.string,
};

const mapStateToProps = ({ loggedUser }) => ({
  displayName: loggedUser.displayName,
});

export default connect(mapStateToProps, null)(UserInfo);
