import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Table } from 'antd';
import { onFetchUsers } from '@actions/users/UsersActions';
import ScheduleHeader from '@routes/Schedule/ScheduleHeader';

/**
 * @description Renders Users table in Schedule view
 */
class ScheduleGroups extends React.Component {
  constructor(props) {
    super(props);

    const { intl } = this.props;

    this.columns = [];

    this.tableKeys = [
      'first_name',
      'last_name',
      'user_group',
      'company',
      'language',
      'user_group',
    ];

    this.tableKeys.forEach((value) => {
      this.columns.push({
        title: intl.formatMessage({ id: `schedule.table.users.${value}` }),
        minWidth: 200,
        dataIndex: value,
        render: (content) => content,
      });
    });
  }

  /**
   * @description Updates component on componentDidMount
   * @returns {void}
   */
  componentDidMount() {
    const { onFetchUsers, match } = this.props;
    const { params } = match;
    const { slotId } = params;
    const currentSlotId = slotId;

    onFetchUsers(currentSlotId);
  }

  /**
   * @description Adapts the data
   * @param {Array<Object>} data
   * @returns {Object}
   */
  dataAdapter = (data = []) => {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        first_name: value.first_name,
        last_name: value.last_name,
        company: value.company,
        user_group: value.user_group,
        language: value.language,
      });
    });

    return adaptedData;
  };

  render() {
    const { users, loading } = this.props;

    return (
      <div>
        <ScheduleHeader />
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24} className="schedule-details-col">
            <div className="audi-table">
              <Table
                bordered
                pagination={false}
                columns={this.columns}
                dataSource={this.dataAdapter(users)}
                loading={loading}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ScheduleGroups.propTypes = {
  intl: PropTypes.object,
  users: PropTypes.array,
  match: PropTypes.object,
  onFetchUsers: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ usersReducer }) => {
  const { users } = usersReducer || { users: [] };
  const { loading } = usersReducer;

  return { users, loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchUsers: (eventId, slotId) => dispatch(onFetchUsers(eventId, slotId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(ScheduleGroups)));
