import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { addQuestionRequest, deleteQuestionRequest } from '@actions/questions/QuestionsActions';
import { USER_ROLES } from '@constants/Settings';
import logo from 'assets/images/logo/Audi_Rings_bl-RGB.svg';

const ReceivedMessageCell = ({
  conversation,
  user,
  userRole,
  slotId,
  addQuestion,
  deleteQuestion,
  questions,
}) => {
  const [isTagClicked, setIsTagClicked] = useState(false);
  const [tagId, setTagId] = useState(null);


  /**
   * @description Gets id of the messages that will be added/deleted from questions
   * @param {Object} e
   * @returns {void}
   */
  const getTagId = (e) => {
    const getTaggedMessageId = e.target.parentNode.getAttribute('data-messageid');
    if (getTaggedMessageId === conversation.messageId) {
      setTagId(getTaggedMessageId);
    }
  };

  /**
   * @description Toggle tag button and sends request depending of tag status
   * in case of tag has already been clicked deleteQuestion is executed,
   * if the tag is not clicked function for add question is going to be executed
   * @returns {void}
   */
  const toggleTagClick = (e) => {
    setIsTagClicked(!isTagClicked);
    if (!isTagClicked) {
      const questionToAdd = {
        user_id: user.user_id,
        disabled: true,
        question_body: conversation.message,
        message_id: conversation.messageId,
      };

      addQuestion(slotId, questionToAdd);
      getTagId(e);
    } else {
      const { messageId } = conversation;
      const questionToDelete = questions.find((question) => question.message_id === messageId);
      const questionId = questionToDelete?.id;

      deleteQuestion(slotId, questionId);
      getTagId(e);
    }
  };

  /**
   * @description Get name initials, in case names are more than two,
   * get initials of first two names
   * @returns {String}
   */
  const getNameInitials = () => {
    let initials = null;

    if (user.first_name && user.last_name) {
      const firstNameInitial = user.first_name.charAt(0);
      const lastNameInitial = user.last_name.charAt(0);

      initials = firstNameInitial + lastNameInitial;
    }

    return initials;
  };

  return (
    <div className="gx-chat-item">
      {userRole !== USER_ROLES.moderator
        ? (
          <Avatar
            className="gx-size-40 gx-align-self-end moderator-logo"
            src={logo}
            alt={conversation.name}
          />
        )
        : (
          <Avatar
            className="gx-size-40 gx-align-self-end user-avatar"
            alt={conversation.name}
          >
            {getNameInitials()}
          </Avatar>
        )}
      <div className="gx-bubble-block">
        <div className="gx-bubble received-message-cell">
          <div>
            <div className="gx-message">{conversation.message}</div>
          </div>
          {
            userRole === USER_ROLES.moderator
              ? (
                <div>
                  <Button
                    className="tag-button"
                    onClick={(e) => toggleTagClick(e)}
                  >
                    <FontAwesomeIcon className={isTagClicked ? 'tag-clicked' : 'tag-unclicked'} icon={faTag} data-messageid={conversation.messageId} />
                  </Button>
                </div>
              ) : null
          }
        </div>
      </div>

    </div>
  );
};

ReceivedMessageCell.propTypes = {
  conversation: PropTypes.oneOfType([PropTypes.object]).isRequired,
  userRole: PropTypes.string.isRequired,
  user: PropTypes.oneOfType([PropTypes.object]),
  slotId: PropTypes.number,
  addQuestion: PropTypes.func,
  deleteQuestion: PropTypes.func,
  questions: PropTypes.array,
  displayName: PropTypes.string.isRequired,
  notificationType: PropTypes.string.isRequired,
  notificationMessage: PropTypes.string.isRequired,
  intl: PropTypes.oneOfType([PropTypes.object]),
};

const mapStateToProps = ({
  loggedUser, groupsReducer, questionsReducer, common,
}) => ({
  userRole: loggedUser.userRole,
  slotId: groupsReducer.selectedGroup.slot_id,
  questions: questionsReducer.questions,
  displayName: loggedUser.displayName,
  notificationType: common.notificationType,
  notificationMessage: common.notificationMessage,
});

const mapDispatchToProps = (dispatch) => ({
  addQuestion: (slotId, question) => dispatch(addQuestionRequest(slotId, question)),
  deleteQuestion: (slotId, questionId) => dispatch(deleteQuestionRequest(slotId, questionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceivedMessageCell);
