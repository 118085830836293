import {
  GET_ALL_QUESTIONS_REQUEST,
  GET_ALL_QUESTIONS_SUCCESS,
  GET_QUESTION_BY_ID,
  GET_QUESTION_BY_ID_SUCCESS,
  DELETE_QUESTION_REQUEST,
  DELETE_QUESTION_SUCCESS,
  UPDATE_QUESTION_REQUEST,
  UPDATE_QUESTION_SUCCESS,
  ADD_QUESTION_REQUEST,
  ADD_QUESTION_SUCCESS,
} from '@constants/ActionTypes';

/**
 * @description Fetch all questions request
 * @param {Number} slotId
 * @returns {Object}
 */
export const onFetchQuestions = (slotId) => ({
  type: GET_ALL_QUESTIONS_REQUEST,
  slotId,
});

/**
 * @description Fetch all questions success
 * @param {Array} questions
 * @returns {Object}
 */
export const onFetchQuestionsSuccess = (questions) => ({
  type: GET_ALL_QUESTIONS_SUCCESS,
  questions,
});

/**
 * @description Handles fetch question by id request
 * @param {Number} slotId
 * @param {Number} questionId
 * @returns {Object}
 */
export const onFetchQuestionById = (slotId, questionId) => ({
  type: GET_QUESTION_BY_ID,
  slotId,
  questionId,
});

/**
 * @description Handles fetch question by id success
 * @param {Object} payload
 * @returns {Object}
 */
export const onFetchQuestionByIdSuccess = (payload) => ({
  type: GET_QUESTION_BY_ID_SUCCESS,
  payload,
});

/**
 * @description Handles delete question request
 * @param {Number} slotId
 * @param {Number} questionId
 * @returns {Promise}
 */
export const deleteQuestionRequest = (slotId, questionId) => ({
  type: DELETE_QUESTION_REQUEST,
  slotId,
  questionId,
});

/**
 * @description Handles delete question success
 * @param {Object} payload
 * @returns {Object}
 */
export const deleteQuestionSuccess = (payload) => ({
  type: DELETE_QUESTION_SUCCESS,
  payload,
});

/**
 * @description Handles update question request
 * @param {Number} slotId
 * @param {Number} questionId
 * @returns {Object}
 */
export const updateQuestion = (slotId, questionId) => ({
  type: UPDATE_QUESTION_REQUEST,
  slotId,
  questionId,
});

/**
 * @description Handles update question success
 * @param {Object} payload
 * @returns {Object}
 */
export const updateQuestionSuccess = (payload) => ({
  type: UPDATE_QUESTION_SUCCESS,
  payload,
});

/**
 * @description Handles add question request action
 * @param {Number} slotId
 * @param {Object} question
 * @returns {Object}
 */
export const addQuestionRequest = (slotId, question) => ({
  type: ADD_QUESTION_REQUEST,
  slotId,
  question,
});

/**
 * @description Handles add question success action
 * @param {Object} payload
 * @returns {Object}
 */
export const addQuestionSuccess = (payload) => ({
  type: ADD_QUESTION_SUCCESS,
  payload,
});
