import _ from 'lodash';
import {
  JITSI_PARTICIPANT_CONNECTION_STATUSES,
  JITSI_PARTICIPANT_CUSTOM_PROPS,
  USER_ROLES,
  VIDEO_QUALITY,
} from '@constants/Settings';
import { decodeDisplayName } from '@jitsi/helper';

/* eslint-disable no-undef */
const config = JitsiConfig;

class JitsiConference {
  /**
   * @description The constructor required the roomName which will be joined
   * (for visitors and others it is included in the token context)
   * (for moderator it will be included when EVENT and EVENT_GROUP are selected)
   * @param {string} roomName
   */
  constructor(roomName) {
    this.room = roomName; // the room name
    this.attachedListeners = [];
  }

  /**
   * Function used to send a chat message (to all participants)
   * @param message
   */
  static sendMessage(message) {
    window.audi.room.sendMessage(message);
  }

  static sendPublicSystemMessage(message) {
    window.audi.room.sendMessage(message);
  }

  /**
   * Function used to send a private 'system' message to particular user (for example the message that he is
   * being previewed, so he has to add his video track in the conference)
   *
   * @param message
   * @param participantId
   */
  static sendPrivateSystemMessage(message, participantId) {
    window.audi.room.sendMessage(message, participantId);
  }

  /**
   * @description Add a command listener
   * @param command
   * @param handler
   */
  static addCommandListener(command, handler) {
    if (!_.isEmpty(window.audi.room)) {
      window.audi.room.addCommandListener(command, handler);
    }
  }

  /**
   * @description Function to kick a user from the conference
   * @param participantId - user conference id
   */
  static kickUserFromConference(participantId) {
    try {
      window.audi.room.kickParticipant(participantId);
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * @description Function to get the Presenter of the conference
   * @param presenterId - the id of the presenter from the redux store
   * @returns {object | null} - its JitsiParticipant object
   */
  static getConferencePresenterParticipant(presenterId) {
    const participants = window.audi.room.getParticipants();

    if (!_.isEmpty(participants)) {
      const filteredParticipants = participants.filter((p) => p.getId() === presenterId);

      if (filteredParticipants.length > 0) {
        return filteredParticipants[0];
      }

      return null;
    }

    return null;
  }

  /**
   * Funciton to check if there are user with 'STAGE' role in the conference
   *
   * (used for situations where presenter account joins the conference
   * for second time)
   * @return {boolean|*}
   */
  static checkIfThereIsAPresenterInTheConference() {
    const participants = window.audi.room.getParticipants();

    if (_.isEmpty(participants)) {
      return false;
    }

    return participants.find((p) => {
      const participantDecodedName = decodeDisplayName(p.getDisplayName());

      return participantDecodedName.confRole === USER_ROLES.stage;
    });
  }

  /**
   * @description Function to get the our speakers from the conference
   * @param speakers - the object returned from the endpoint: "/slots/mine/languages/${chosenLangIsoCode}/speakers"
   * @returns {null|*}
   */
  static getConferenceSpeakers(speakers) {
    const participants = window.audi.room.getParticipants();
    const transformedSpeakers = speakers.map((s) => {
      const speakerParts = s.id.split(':');

      return {
        id: speakerParts[0],
        role: speakerParts[1],
      };
    });

    if (!_.isEmpty(participants)) {
      const filteredParticipants = participants.filter((p) => {
        const decodedParticipantName = decodeDisplayName(p.getDisplayName());
        const participantDatabaseId = decodedParticipantName.databaseId;

        return _.some(transformedSpeakers, ['id', participantDatabaseId]);
      });

      if (filteredParticipants.length > 0) {
        return filteredParticipants;
      }

      return null;
    }

    return null;
  }

  /**
   * Function to raise hand
   * @param handValue
   */
  static raiseHand(handValue) {
    window.audi.room.setLocalParticipantProperty(
      JITSI_PARTICIPANT_CUSTOM_PROPS.raiseHand,
      handValue,
    );
  }

  /**
   * Function to change the 'hasMutedLocalAudioTrack' property
   * @param hasMutedLocalAudioTrack
   */
  static changeHasMutedLocalAudioTrack(hasMutedLocalAudioTrack) {
    window.audi.room.setLocalParticipantProperty(
      JITSI_PARTICIPANT_CUSTOM_PROPS.hasMutedLocalAudioTrack,
      hasMutedLocalAudioTrack,
    );
  }

  /**
   * Function to change the 'hasMutedLocalVideoTrack' property
   * @param hasMutedLocalVideoTrack
   */
  static changeHasMutedLocalVideoTrack(hasMutedLocalVideoTrack) {
    window.audi.room.setLocalParticipantProperty(
      JITSI_PARTICIPANT_CUSTOM_PROPS.hasMutedLocalVideoTrack,
      hasMutedLocalVideoTrack,
    );
  }

  /**
   * Leave jitsi conference static method
   *
   * @return Promise
   */
  static leave() {
    return window.audi.room.leave();
  }

  /**
   * Check if participant is in the conference and if its connection status is active
   * @param participantId
   * @returns {boolean|*}
   */
  static checkIfParticipantIsOnlineAndActiveConnStatus(participantId) {
    if (_.isEmpty(window.audi.room)) {
      return false;
    }

    const participant = window.audi.room.getParticipants()
      .find((p) => p.getId() === participantId);

    if (!participant) {
      return false;
    }

    return participant._connectionStatus === JITSI_PARTICIPANT_CONNECTION_STATUSES.ACTIVE;
  }

  /**
   * Get participant display name by his conference id
   * @param participantId
   * @returns {string|boolean}
   */
  static getParticipantDisplayNameById(participantId) {
    if (_.isEmpty(window.audi.room)) {
      return false;
    }

    const participant = window.audi.room.getParticipants()
      .find((p) => p.getId() === participantId);

    if (!participant) {
      return false;
    }

    return participant.getDisplayName();
  }

  /**
   * Select participant to always receive high quality video from him
   *
   * <b>NOTE:</b> Should be used WHEN WE already have the media stream received
   * @param participantId
   */
  static setReceiverConstraints([participantId]) {
    if (!_.isEmpty(window.audi.room)) {
      // window.audi.room.selectParticipant(participantId);
      window.audi.room.setReceiverConstraints({
        colibriClass: 'ReceiverVideoConstraints',
        lastN: -1,
        selectedSources: [participantId],
        onStageSources: [participantId],
        defaultConstraints: { maxHeight: -1, maxFrameRate: 60 },
        constraints: {
          [participantId]: { maxHeight: -1 },
        },
      });
    }
  }

  /**
   * Select participants to always receive high quality video from
   * these participants
   *
   * @param participantsIds
   */
  static selectParticipants(participantsIds) {
    if (!_.isEmpty(window.audi.room)) {
      window.audi.room.getParticipants(participantsIds);
    }
  }

  /**
   * Set what resolution video should we RECEIVE from the conference
   * @param resolution - '1080', '720', '480' etc...
   */
  static setReceiverVideoConstraint(resolution) {
    if (!_.isEmpty(window.audi.room)) {
      switch (resolution) {
        case VIDEO_QUALITY.fullHd:
          window.audi.room.setReceiverVideoConstraint(VIDEO_QUALITY.fullHd);
          break;
        case VIDEO_QUALITY.hd:
          window.audi.room.setReceiverVideoConstraint(VIDEO_QUALITY.hd);
          break;
        case VIDEO_QUALITY.medium:
          window.audi.room.setReceiverVideoConstraint(VIDEO_QUALITY.medium);
          break;
        case VIDEO_QUALITY.low:
          window.audi.room.setReceiverVideoConstraint(VIDEO_QUALITY.low);
          break;
        case VIDEO_QUALITY.veryLow:
          window.audi.room.setReceiverVideoConstraint(VIDEO_QUALITY.veryLow);
          break;
        default:
          window.audi.room.setReceiverVideoConstraint(VIDEO_QUALITY.fullHd);
          break;
      }
    }
  }

  static checkIfJoinedUserIsAlreadyInTheConference(joinedUser) {
    if (_.isEmpty(window.audi.room)) {
      return false;
    }

    const usersInTheConferenceAtm = window.audi.room.getParticipants();
    const joinedUserDecodedDisplayName = decodeDisplayName(joinedUser.getDisplayName());

    const userWhoAreInTheConferenceWithSameDatabaseId = usersInTheConferenceAtm.filter((u) => {
      const decodedDisplayName = decodeDisplayName(u.getDisplayName());

      return decodedDisplayName.databaseId === joinedUserDecodedDisplayName.databaseId && joinedUserDecodedDisplayName.databaseId;
    });

    return userWhoAreInTheConferenceWithSameDatabaseId;
  }

  static getLocalUserConferenceId() {
    if (window.audi.room) {
      return window.audi.room.myUserId();
    }

    return null;
  }

  /**
   * Function to make a JitsiConference object
   * some handler if something with the object creation went wrong
   * @returns {JitsiConference}
   */
  initConference(configOverride = null) {
    const conf = {
      ..._.cloneDeep(config),
      ..._.cloneDeep(configOverride),
    };

    window.audi.room = window.audi.connection.initJitsiConference(
      this.room,
      configOverride ? conf : config,
    );

    return this;
  }

  /**
   * Function which we use when we are adding events to the the real JitsiConference object
   * @param jitsiEvent
   * @param listener
   * @returns {JitsiConference}
   */
  addListener(jitsiEvent, listener) {
    if (!_.isEmpty(window.audi.room)) {
      window.audi.room.addEventListener(
        jitsiEvent,
        listener,
      );
      this.attachedListeners.push([jitsiEvent, listener]);
    }

    return this;
  }

  /**
   * @description Function which remove all attached listeners
   */
  removeConferenceListeners() {
    this.attachedListeners.forEach((listener) => {
      window.audi.room.removeEventListener(
        listener[0], // represents the JITSI official event
        listener[1], // represents our listener function attached to the event
      );
    });

    this.attachedListeners = [];

    return this;
  }

  /**
   * @description Returns all participants in the conference atm
   * @returns {*[]|*}
   */
  getConferenceParticipants() {
    if (!_.isEmpty(window.audi.room)) {
      return window.audi.room.getParticipants();
    }

    return [];
  }

  /**
   * @description Set the display name for the localParticipant
   * (used mainly BEFORE joining the conference)
   * @param {string} displayName
   */
  setDisplayName(displayName) {
    window.audi.room.setDisplayName(displayName);
  }

  /**
   * @description Add custom property for the localParticipant
   * example: 'raiseHand'
   * @param {string} property
   * @param {any} propertyValue
   */
  setCustomProperty(property, propertyValue) {
    window.audi.room.setLocalParticipantProperty(property, propertyValue);
  }

  /**
   * @description Join/Create the conference
   */
  join() {
    if (!_.isEmpty(window.audi.room)) {
      window.audi.room.join();
    }
  }

  /**
   * @description Leave the conference
   */
  leave() {
    window.audi.room.leave()
      .then(
        () => {
          window.audi.room = {};
        },
        (error) => {
          console.log('Conf already left');
        },
      );
  }
}

export default JitsiConference;
