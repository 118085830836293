import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  HIDE_NOTIFICATION,
  SHOW_MESSAGE,
  SHOW_NOTIFICATION,
} from '@constants/ActionTypes';

const INIT_STATE = {
  error: '',
  message: '',
  loading: false,
  notificationType: '',
  notificationMessage: '',
  notificationDuration: 4.5,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state, error: '', message: '', loading: true,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state, error: '', message: '', loading: false,
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state, error: '', message: action.payload, loading: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state, loading: false, error: '', message: '',
      };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload };
    }
    case SHOW_NOTIFICATION: {
      return {
        ...state,
        notificationType: action.notificationType,
        notificationMessage: action.notificationMessage,
        notificationDuration: action.notificationDuration,
      };
    }
    case HIDE_NOTIFICATION: {
      return { ...state, notificationType: '', notificationMessage: ''}
    }
    default:
      return state;
  }
};
