import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import { DEVICES_TYPES } from '@constants/Settings';
import { setLoggedUserDeviceType } from '@actions/loggedUser/LoggedUserActions';
import { updateWindowWidth } from '@actions/Setting';
import { hot } from 'react-hot-loader';
import configureStore, { history } from './appRedux/store';
import App from './containers/App/index';

const { detect } = require('detect-browser');

const store = configureStore();
window.addEventListener('resize', () => {
  store.dispatch(updateWindowWidth(window.innerWidth));
});
/**
 * Set the logged user device type when the app is loaded
 */
if (isMobileOnly) {
  store.dispatch(setLoggedUserDeviceType(DEVICES_TYPES.mobile));
}

const browser = detect();

if (browser) {
  sessionStorage.setItem('browserName', browser.name);
}

const NextApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default hot(module)(NextApp);
