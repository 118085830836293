import {eventChannel} from 'redux-saga';
import {CONFERENCE_LISTENERS_CASES_TYPES, JITSI_CONFERENCE_EVENTS} from '@constants/Settings';
import {put} from 'redux-saga/effects';
import Moment from 'moment';
import {fetchConferenceSlotLanguages,} from '@actions/conference/ConferenceActions';
import {
  conferenceJoinFailGeneratorCommon,
  conferenceJoinSuccessGeneratorCommon,
  conferenceKickedGeneratorCommon,
  conferenceLeaveGeneratorCommon,
  conferenceMessageReceivedGeneratorCommon,
  conferenceTrackAddedGeneratorCommon,
  conferenceTrackRemovedGeneratorCommon
} from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/CommonCaseGenerators';
import {
  conferencePrivateMessageReceivedGeneratorTranslator,
  conferenceUserJoinedGeneratorTranslator,
  conferenceUserLeftGeneratorTranslator,
} from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/TranslatorCaseGenerators';
import {changingLocalTrack, changingLocalTrackSuccess} from "@actions/loggedUser/LoggedUserActions";

/**
 * @description The EventChannel where we put all conference listeners for the VISITOR ROLE
 * @param jitsiConference
 * @returns {any}
 */
export const translatorChannel = (jitsiConference) => eventChannel((emitter) => {
  /**
   * @description Listens for conference join success
   * @returns {void}
   */
  const joinSuccess = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.joinSuccess, data: [] });
  };

  /**
   * @description Listens for join failed
   * @returns {void}
   */
  const joinFailed = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.joinFail, data: [] });
  };

  /**
   * @description Listens for conference left
   * @returns {void}
   */
  const leave = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.leave, data: [] });
  };

  /**
   * @description Listens for user join
   * @param {Number} userId
   * @param {Object} user
   * @returns {void}
   */
  const userJoined = (userId, user) => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.userJoined, data: [user] });
  };

  /**
   * @description Listens for user left
   * @param {Number} userId
   * @param {Object} user
   * @returns {void}
   */
  const userLeft = (userId, user) => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.userLeft, data: [user] });
  };

  /**
   * @description Listens for any track which has been removed from the conference
   * @param {object} track
   */
  const trackRemoved = (track) => {
    if (track.isLocal()) {
      emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackLocalRemoved, data: [] });
    } else {
      emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackRemoved, data: [track] });
    }
  };

  /**
   * @description Listens for any track which has been added in the conference
   * @param {object} track
   */
  const trackAdded = (track) => {
    if (track.isLocal()) {
      emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackLocalAdded, data: [] });
    } else {
      emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackAdded, data: [track] });
    }
  };

  /**
   * @description Listens for private received message
   * @param {Number} from
   * @param {String} message
   * @returns {void}
   */
  const privateMessageReceived = (from, message) => {
    const adaptMessage = {
      message,
      type: 'received',
      userId: from,
      sentAt: Moment().format('hh:mm:ss A'),
      read: false,
    };
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.privateMessageReceived, data: [adaptMessage] });
  };

  const messageReceived = (from, message) => {
    const adaptMessage = {
      message,
      type: 'received',
      userId: from,
      sentAt: Moment().format('hh:mm:ss A'),
      read: false,
    };

    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.messageReceived, data: [adaptMessage] });
  };

  /**
   * Listens if we have been kicked from the conference
   */
  const kickedFromConference = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.kickedFromConference, data: [] });
  };

  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceJoined, joinSuccess);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceJoinFailed, joinFailed);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceLeft, leave);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceUserJoined, userJoined);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceUserLeft, userLeft);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceTrackAdded, trackAdded);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceTrackRemoved, trackRemoved);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferencePrivateMessageReceived, privateMessageReceived);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceMessageReceived, messageReceived);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceKicked, kickedFromConference);

  return () => {
    jitsiConference.removeConferenceListeners();
  };
});

/**
 * @description Generator function with SWITCH inside, which depending on the
 * conference event which was "caught" dispatches actions
 * @description Explanation:
 * (On the channel above, for the successful conference joining event, we are executing "joinSuccess" function listener,
 * inside that listener we are emitting object with "type: 'JoinSuccess'" and "data: []"
 * in the switch statement below for the 'JoinSuccess' case we are dispatching two actions)
 * @param channelResult
 * @param jitsiConference
 * @returns {any}
 */
export function* translatorSwitch(channelResult, jitsiConference) {
  switch (channelResult.type) {
    case CONFERENCE_LISTENERS_CASES_TYPES.joinSuccess:
      yield conferenceJoinSuccessGeneratorCommon();
      /**
       * When we join the conference successfully, we get the languages available for the slot
       * we have joined
       */
      yield put(fetchConferenceSlotLanguages(sessionStorage.getItem('slotId')));
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.joinFail:
      yield conferenceJoinFailGeneratorCommon();
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.leave:
      yield conferenceLeaveGeneratorCommon(jitsiConference);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.userJoined:
      yield conferenceUserJoinedGeneratorTranslator(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.userLeft:
      yield conferenceUserLeftGeneratorTranslator(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackAdded:
      yield conferenceTrackAddedGeneratorCommon(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackRemoved:
      yield conferenceTrackRemovedGeneratorCommon(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackLocalRemoved:
      yield put(changingLocalTrack());
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackLocalAdded:
      yield put(changingLocalTrackSuccess());
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.privateMessageReceived:
      yield conferencePrivateMessageReceivedGeneratorTranslator(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.messageReceived:
      yield conferenceMessageReceivedGeneratorCommon(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.kickedFromConference:
      yield conferenceKickedGeneratorCommon();
      break;
    default:
      break;
  }
}
