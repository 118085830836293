import { all, call, fork, put, select, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import { FETCH_LOGGED_USER_DATA, FETCH_LOGGED_USER_JOIN_DATA, WINDOW_WIDTH } from '@constants/ActionTypes';
import { ENDPOINTS } from '@constants/Endpoints';
import { fetchError } from '@actions/Common';
import _ from 'lodash';
import { push } from 'react-router-redux';
import RestManager from '@util/RestManager';
import {
  fetchLoggedUserJoinData,
  fetchLoggedUserJoinDataSuccess,
  fetchLoggedUserSlotData,
  fetchLoggedUserSlotDataSuccess,
  fetchLoggedUserSuccess,
  setLoggedUserBrowser,
  setLoggedUserDefaultSpeakerDeviceId,
  setLoggedUserDevices,
  setLoggedUserDeviceType,
  fetchLoggedUser,
} from '@actions/loggedUser/LoggedUserActions';
import {
  DEVICES_TYPES, USER_ROLES, SUPPORTED_STREAM_TYPES, CHAT_RECONNECTION_DELAY,
} from '@constants/Settings';
import { startJitsiConnection } from '@actions/jitsiConnection/JitsiConnectionActions';
import { onFetchEvents, setSelectedEvent } from '@actions/events/EventsActions';
import { fetchSlot, onFetchSlotsByEvent } from '@actions/groups/GroupsActions';
import { onFetchUsers } from '@actions/users/UsersActions';
import JitsiTrack from '@jitsi/JitsiTrack';
import { getTheDefaultSpeakerId, hqAudioConfig } from '@jitsi/helper';


import { createStropheConnection } from '@actions/mucs/MucsActions';
import { addModeratorIdToStorage, isStreamTypeSupportedByBrowser } from '../../../CustomHooks/HelperFuncs';
import JitsiConference from '../../../JITSI/JitsiConference';

/* eslint-disable no-undef */
const config = JitsiConfig;

const { detect } = require('detect-browser');

/**
 * @description Request to get the user data
 * for authentication (token, user role, Jitsi URL, isModerator)
 * @returns {Object}
 */
const doFetchLoggedUserData = async () => await RestManager.request(ENDPOINTS.userData);

/**
 * Request to fetch the logged userData for jitsi (jitsiToken, role etc.)
 *
 * @description <b>NOTE:</b> ONLY IF THE ROLE IS <b>(VISITOR, TRANSLATOR OR STAGE)</b>
 * @returns {Promise<*>}
 */
const doFetchLoggedUserSlotData = async () => await RestManager.request(ENDPOINTS.userSlotData);

/**
 * Request to fetch the logged userData for jitsi (jitsiToken, role etc.)
 *
 * @description <b>NOTE:</b> ONLY IF THE ROLE IS <b>(VISITOR, TRANSLATOR OR STAGE)</b>
 * @returns {Promise<*>}
 */
const doFetchLoggedUserJoinData = async (lang, media) => {
  return await RestManager.request(
    ENDPOINTS.userJoinData(lang, media),
  );
}

const doSetHLSCookies = async (cookieURL) => await RestManager.request(
  cookieURL,
);

/**
 * Request to fetch the logged userData for jitsi (jitsiToken, role etc.)
 *
 * @description <b>NOTE:</b> ONLY IF THE ROLE IS <b>MODERATOR</b>
 * @param {number} slotId
 * @returns {Promise<*>}
 */
const doFetchLoggedUserModeratorJoinData =
  async (slotId, media) => await RestManager.request(ENDPOINTS.userModeratorJoinData(slotId, media));

/**
 * The code below reacts on the situation when a moderator/stage/translator refresh his page
 *
 * When page is refreshed our state is lost, but we have a sessionStorage.
 * We check if the 'groups' array in the redux is empty (it wont be empty if we do the normal login flow,
 * so we check it here because if we dont, when we login normally we will fetch events, and groups twice).
 *
 * After that we fetch all events, after that we fetch the slot we have already been,
 * in the result of the fetched slot is the event it belongs to. We get that event id, and fetch all
 * its groups so we have the groups in the dropdown menu in the header.
 */
function* fetchEventsAndSlotsOnPageRefresh(groups) {
  if (_.isEmpty(groups.selectedGroup)) {
    yield put(onFetchEvents());
    yield put(fetchSlot(parseInt(sessionStorage.getItem('slot_id'))));
    yield delay(1500);
    const selectedGroup = yield select((state) => state.groupsReducer.selectedGroup);

    yield put(setSelectedEvent(selectedGroup.event.id));
    yield put(onFetchSlotsByEvent(selectedGroup.event.id));
  }
}

/**
 * @description Fetch logged user data
 * @returns {Object}
 */
function* onFetchLoggedUserData() {
  try {
    const authenticationData = yield call(doFetchLoggedUserData);

    if (authenticationData) {
      const browser = detect();
      const userDevices = yield JitsiTrack.enumerateDevices();
      yield put(setLoggedUserDefaultSpeakerDeviceId(getTheDefaultSpeakerId(userDevices)));
      yield put(setLoggedUserDevices(userDevices));

      yield put(fetchLoggedUserSuccess(authenticationData));
      if (browser) {
        yield put(setLoggedUserBrowser(browser.name));
      }

      const isInConference = yield select((state) => state.loggedUser.isInConference);
      const chatConnectionEstablished = yield select(
        (state) => state.mucsReducer.chatConnectionEstablished,
      );
      if (isInConference && !chatConnectionEstablished && window.stropheConn
        && sessionStorage.getItem('jitsiToken')) {
        const conferenceReducer = yield select((state) => state.conferenceReducer);
        const { presenterId } = conferenceReducer;
        const conferencePresenter = JitsiConference.getConferencePresenterParticipant(presenterId);
        yield JitsiConference.setReceiverConstraints(conferencePresenter.getId());
        yield put(createStropheConnection(sessionStorage.getItem('jitsiToken')));
        console.log('[TEST][RECONNECT] onFetchLoggedUserData - RETURN');
        return;
      }

      yield put(fetchLoggedUserJoinData());
    }
  } catch (error) {
    yield put(fetchError(error));
    yield delay(CHAT_RECONNECTION_DELAY);
    yield put(fetchLoggedUser());
  }
}

/**
 * Generator to fetch loggedUser Join Data
 */
function* onFetchLoggedUserJoinData() {
  try {
    const slotData = yield call(doFetchLoggedUserSlotData);

    if (!slotData || !slotData.stream_types) {
      const event = new Event('NO_ACTIVE_STREAMS');
      window.dispatchEvent(event);
      return;
    }

    const loggedUserLang = yield select((state) => state.loggedUser.lang);
    const userLang = sessionStorage.getItem('userLang')
      ? sessionStorage.getItem('userLang')
      : loggedUserLang;

    // determine media
    let slotMedia = yield select((state) => state.loggedUser.slotMedia);

    if (!slotMedia) {
      slotData.stream_types.every((streamType) => {
        const sst = SUPPORTED_STREAM_TYPES[streamType];
        const isbb = isStreamTypeSupportedByBrowser(streamType);
        if (sst && isbb) {
          slotMedia = streamType;
          return false; // returning false equals break
        }

        return true;
      });
    }

    if (!slotMedia) {
      throw new Error('Cannot find supported stream type');
    }

    const joinData = sessionStorage.getItem('userRole') === USER_ROLES.moderator
      ? yield call(doFetchLoggedUserModeratorJoinData, parseInt(sessionStorage.getItem('slot_id')), slotMedia.toLowerCase())
      : yield call(doFetchLoggedUserJoinData, userLang, slotMedia.toLowerCase());

    if (joinData) {
      joinData.slotMedia = slotMedia;
      yield put(fetchLoggedUserJoinDataSuccess(joinData));
      yield put(createStropheConnection(joinData.jitsi_token || joinData.token));
      yield sessionStorage.setItem('url', joinData.url);
      yield sessionStorage.setItem('moderator', joinData.moderator);

      if (slotMedia === SUPPORTED_STREAM_TYPES.HLS) {
        yield call(renewCookie, userLang, slotMedia, joinData);
      } else if (slotMedia === SUPPORTED_STREAM_TYPES.WEBRTC) {
        yield sessionStorage.setItem('jitsiToken', joinData.jitsi_token || joinData.token);

      }

      const userRole = sessionStorage.getItem('userRole');
      const connectionConfig = (userRole === USER_ROLES.stage || userRole === USER_ROLES.translator) ? hqAudioConfig() : config;
      window.JitsiMeetJS.init(connectionConfig);

      yield put(startJitsiConnection(joinData.jitsi_token || joinData.token));

      if (sessionStorage.getItem('inPreview') !== 'yes') {
        const groups = yield select((state) => state.groupsReducer);

        if (joinData.user_role === USER_ROLES.stage) {
          yield put(push('/presenter'));
          yield fetchEventsAndSlotsOnPageRefresh(groups);
        } else if (joinData.user_role === USER_ROLES.visitor) {
          yield put(push('/user'));
        } else if (joinData.user_role === USER_ROLES.moderator) {
          yield put(onFetchUsers(parseInt(sessionStorage.getItem('slot_id'))));
          yield fetchEventsAndSlotsOnPageRefresh(groups);
          yield put(push('/participants'));
        } else if (joinData.user_role === USER_ROLES.translator) {
          yield fetchEventsAndSlotsOnPageRefresh(groups);
          yield put(push('/translator'));
        } else {
          yield put(push('/user'));
        }
      }
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

let renewCookieTimeout;
async function renewCookie(userLang, slotMedia, currJoinData) {
  const joinData = currJoinData ?
    currJoinData : await doFetchLoggedUserJoinData(userLang, slotMedia.toLowerCase());
  sessionStorage.setItem('url', joinData.media_url);
  sessionStorage.setItem('hlsToken', joinData.token);
  sessionStorage.setItem('jitsiToken', joinData.jitsi_token || joinData.token);
  sessionStorage.setItem('setCookieURL', joinData.set_cookie_url);
  sessionStorage.setItem('cookieExpires', joinData.expires);
  try {
    await doSetHLSCookies(joinData.set_cookie_url);
  } catch (e) {
    console.warn('[ERROR] renewCookie: doSetHLSCookies', e);
  }
  const cookieExpiresAfter = (joinData.expires * 1000 - new Date().getTime()) - 60 * 1000;

  if (renewCookieTimeout) {
    clearTimeout(renewCookieTimeout);
  }
  // renew the cookie 1 minute before it expires or NOW if already expired
  renewCookieTimeout = setTimeout(() => {
    renewCookie(userLang, slotMedia);
  }, cookieExpiresAfter > 0 ? cookieExpiresAfter : 0);
};

function* onWindowWidthChange({ width }) {
  const loggedUserState = yield select((state) => state.loggedUser);
  const currentDeviceType = loggedUserState.deviceType;

  if (width <= 990 && currentDeviceType !== DEVICES_TYPES.mobile) {
    yield put(setLoggedUserDeviceType(DEVICES_TYPES.mobile));
  } else if (width >= 990 && currentDeviceType !== DEVICES_TYPES.desktop) {
    yield put(setLoggedUserDeviceType(DEVICES_TYPES.desktop));
  }
}

export function* actionsWatcher() {
  yield takeEvery(FETCH_LOGGED_USER_DATA, onFetchLoggedUserData);
  yield takeEvery(FETCH_LOGGED_USER_JOIN_DATA, onFetchLoggedUserJoinData);
  yield takeLatest(WINDOW_WIDTH, onWindowWidthChange);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
