/**
 * Helper function the "decode" the displayName from the token
 * @param displayName
 * @returns {object}
 */
import { USER_ROLES } from '@constants/Settings';
import _ from 'lodash';

import { getModeratorsIdsFromStorage } from '../CustomHooks/HelperFuncs';

/* eslint-disable no-undef */
const config = JitsiConfig;

export const decodeDisplayName = (displayName) => {
  const result = {
    displayName: null,
    databaseId: null,
    confRole: null,
    lang: null,
  };

  if (displayName) {
    const splitNameParts = displayName.split(' ');

    result.databaseId = splitNameParts[0];
    result.lang = splitNameParts[1];
    result.confRole = splitNameParts[2];
    result.displayName = splitNameParts.slice(4, splitNameParts.length).join(' ');
  }

  return result;
};

/**
 * Helper function the check if user (JitsiParticipant object) is with particular role
 * @param user
 * @param particularRole
 * @returns {boolean}
 */
export const checkIfUserIsWithParticularRole = (user, particularRole) => {
  const decodedDisplayName = decodeDisplayName(user.getDisplayName());

  return decodedDisplayName.confRole === particularRole;
};

/**
 * Helper function to check if a user who have joined the conference is in our speakers list
 * @param user - jitsi participant object
 * @param speakersList - speakers list (the one from redux: conferenceReducer.chosenLanguageSpeakers)
 * @returns {*}
 */
export const checkIfUserWhoHaveJoinedIsInTheListOfSpeakers = (user, speakersList) => {
  const decodedDisplayName = decodeDisplayName(user.getDisplayName());
  const speakers = speakersList.map((s) => {
    const parts = s.id.split(':');

    return {
      id: parts[0],
      role: parts[1],
    };
  });

  return speakers.find((s) => s.role === decodedDisplayName.confRole);
};

/**
 * @description Helper function to check if a track is a track of one of our speakers
 *
 * @description (Used in the situations where for example a presenter change his microphone device.
 * First he remove his track and then add it again with different device, then we need to handle
 * that new track of one of our speakers is added to play it again)
 */
export const checkIfTrackIsATrackOfOneOfOurSpeakers = (trackOwnerId, speakersList) => {
  const trackOwner = window.audi.room.getParticipants().find((p) => p.getId() === trackOwnerId);

  const speakers = speakersList.map((s) => {
    const parts = s.id.split(':');

    return {
      id: parts[0],
      role: parts[1],
    };
  });

  if (trackOwner) {
    const trackOwnerDecodedName = decodeDisplayName(trackOwner.getDisplayName());

    return speakers.find((s) => s.id === trackOwnerDecodedName.databaseId);
  }

  return false;
};

/**
 * Check if user is with particular role (check by userId (conference id))
 * @param userId
 * @param particularRole
 * @returns {boolean}
 */
export const checkIfUserIsWithParticularRoleByUserId = (userId, particularRole) => {
  const participants = window.audi.room.getParticipants();
  const filteredParticipants = participants.filter((p) => p.getId() === userId);

  if (filteredParticipants.length === 0) {
    return false;
  }

  const decodedDisplayName = decodeDisplayName(filteredParticipants[0].getDisplayName());
  return decodedDisplayName.confRole.toUpperCase() === particularRole.toUpperCase();
};

/**
 * By DatabaseID check the a user is with particular role
 *
 * @param userDatabaseId
 * @param particularRole
 * @return {boolean}
 */
export const checkIfUserIsWithParticularRoleByUserDatabaseId = (userDatabaseId, particularRole) => {
  const participants = window.audi.room.getParticipants();
  const filteredParticipants = participants.filter((p) => {
    const decodedDisplayName = decodeDisplayName(p.getDisplayName());

    return userDatabaseId === decodedDisplayName.databaseId
      && particularRole.toUpperCase() === decodedDisplayName.confRole.toUpperCase();
  });

  if (filteredParticipants.length === 0) {
    return false;
  }

  const decodedDisplayName = decodeDisplayName(filteredParticipants[0].getDisplayName());
  return decodedDisplayName.confRole.toUpperCase() === particularRole.toUpperCase();
};

export const getUserJitsiParticipantObjectByUserDatabaseId = (userDatabaseId) => {
  const participants = window.audi.room.getParticipants();

  return participants.find((p) => {
    const decodedDisplayName = decodeDisplayName(p.getDisplayName());

    return userDatabaseId === decodedDisplayName.databaseId;
  });
};

/**
 * Check if user is moderator (check by userId)
 */
export const checkIfUserIsModeratorByUserId = (userId) => {
  const participants = window.audi.room.getParticipants();
  const filteredParticipants = participants.filter((p) => p.getId() === userId);

  const moderatorIds = getModeratorsIdsFromStorage();
  const isModeratorId = moderatorIds.find((id) => id === userId);

  if (isModeratorId) {
    return true;
  }
  if (filteredParticipants.length === 0) {
    return false;
  }
  const decodedDisplayName = decodeDisplayName(filteredParticipants[0].getDisplayName());

  return decodedDisplayName.confRole === USER_ROLES.moderator || filteredParticipants[0].isModerator();
};

/**
 * Helper function the make initials from the user displayName
 * @param displayName
 * @returns {string}
 */
export const getNameInitials = (displayName) => {
  const splitNameParts = displayName.split(' ');
  let initials = '';
  const numberRegex = /^\D$/;

  splitNameParts.forEach((partFromName) => {
    const firstLetterFromNamePart = partFromName.substring(0, 1);

    if (numberRegex.test(firstLetterFromNamePart)) {
      initials += firstLetterFromNamePart;
    }
  });

  return initials;
};

/**
 * @description Helper function to check joined user tracks mute statuses
 * @param tracks
 * @returns {{video: boolean, audio: boolean}}
 */
export const checkJoinUserTracksMuteStatus = (tracks) => {
  const video = tracks.find((tr) => tr.type === 'video');
  const audio = tracks.find((tr) => tr.type === 'audio');

  return {
    video: video ? !video.muted : false,
    audio: audio ? !audio.muted : false,
  };
};

/**
 * Helper function to transform the 'startAudioMuted' or 'startVideoMuted' value from the URL query params, to bool
 *
 * @param value
 * @returns {boolean}
 */
export const startAudioVideoToBool = (value) => {
  switch (value) {
    case '0':
    case 0:
    case 'false':
    case false:
    case '':
      return false;
    default:
      return true;
  }
};

/**
 * Get the JITSI high quality audio configs to the stage and translator role
 */
export const hqAudioConfig = () => {
  return {
    ..._.cloneDeep(config),
    p2p: {
      ...config.p2p,
      useStunTurn: false,
    },
    opusMaxAverageBitrate: 192000,
    disableAP: true,
    disableAEC: true,
    disableNS: true,
    disableAGC: true,
    disableHPF: true,
  };
};

/**
 * Helper function to get the default 'audiooutput' device ID
 * @param devices
 */
export const getTheDefaultSpeakerId = (devices) => {
  const groupedDevices = _.groupBy(_.cloneDeep(devices), 'kind');

  if (_.isEmpty(groupedDevices)) {
    return undefined;
  }

  const speakersDevices = groupedDevices['audiooutput'];

  if (_.isEmpty(speakersDevices)) {
    return undefined;
  }

  return speakersDevices[0].deviceId;
};

/**
 * Function which we use to create the options passed to the JitsiConference.createLocalTracks() method
 *
 * { devices: ['video', 'audio'] } - means that we are going the create local tracks for VIDEO and AUDIO
 * { devices: ['video'] } - means that we are going the create local tracks for VIDEO only
 * @returns {{devices: []}}
 */
export const createLocalTracksOptions = (hasMicrophoneDevice, hasCameraDevice, userRole) => {
  const options = {
    devices: [],
  };

  if (hasMicrophoneDevice) {
    options.devices.push('audio');
  }
  if (hasCameraDevice && userRole !== USER_ROLES.translator) {
    options.devices.push('video');
  }

  return options;
};

/**
 * Function which we use to create the options passed to the JitsiConference.createLocalTracks() method
 * but here we specify which devices has to be used for 'microphone' and 'camera'
 *
 * @returns {{devices: []}}
 */
export const createLocalTracksOptionsWithSpecificDevices = (micDeviceId, cameraDeviceId) => {
  const options = {
    devices: [],
  };

  if (micDeviceId) {
    options.devices.push('audio');
    Object.assign(options, { micDeviceId });
  }
  if (cameraDeviceId) {
    options.devices.push('video');
    Object.assign(options, { cameraDeviceId });
  }

  return options;
};

/**
 * @description Function to open the window on the middle of the screen (used for the Preview window)
 * @param url
 * @param win
 * @param w
 * @param h
 */
export const openPreviewWindow = (url, win, w, h) => {
  const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
  const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);

  return win.open(url, '_blank', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
};

/**
 * @description Function to open the window on the middle of the screen (used for the MinimalInterface window)
 * @param url
 * @param win
 * @param w
 * @param h
 */
export const openMinimalInterfaceWindow = (url, win, w, h) => {
  openPreviewWindow(url, win, w, h);
};

export const startMinimalInterface = () => {
  openMinimalInterfaceWindow(
    `${window.location.origin}/minimal-interface`,
    window,
    800,
    600,
  );
};

export const isFatalJitsiConnectionError = (error) => {
  if (typeof error !== 'string') {
    // eslint-disable-next-line no-param-reassign
    error = error?.name;
  }

  return (
    error === 'connection.droppedError'
    || error === 'connection.otherError'
    || error === 'connection.serverError');
};
