import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { onFetchEvent, onFetchEvents } from '@actions/events/EventsActions';
import { fetchSlot, onFetchSlotsByEvent } from '@actions/groups/GroupsActions';
import { onFetchUsers } from '@actions/users/UsersActions';
import { injectIntl } from 'react-intl';
import { USER_ROLES } from '@constants/Settings';
import { stropheLeaveMucRoom } from '@actions/mucs/MucsActions';
import IntlMessages from '../../util/IntlMessages';
import { SIGNIN_USER } from '../../constants/ActionTypes';

const { SubMenu } = Menu;

/**
 * @description Renders dropdown in Horizontal navigation
 */
class HorizontalNavDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventsList: [],
      groupsList: [],
    };
  }

  /**
   * @description Get events list on copmponentDidMount
   * @returns {void}
   */
  componentDidMount() {
    const {
      events,
      groups,
    } = this.props;
    const { eventsList, groupsList } = this.state;

    if (eventsList.length === 0 && events.length) {
      this.setState({ eventsList: events });
    }

    if (groupsList.length === 0 && groups.length) {
      this.setState({ groupsList: groups });
    }
  }

  /**
   * @description Updates component
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    const { events, groups } = this.props;

    if (prevProps.events.length === 0 && events.length > 0) {
      this.setState({ eventsList: events });
    }

    if (prevProps.groups.length === 0 && groups.length > 0) {
      this.setState({ groupsList: groups });
    }
    const getCurrentEventId = groups[0] && groups[0].event_id;
    const getPrevPropEventId = prevProps.groups[0] && prevProps.groups[0].event_id;

    if (getPrevPropEventId !== getCurrentEventId) {
      this.setState({ groupsList: groups });
    }
  }

  /**
   * @description Load list of groups by selected event
   * @param {Number} id
   * @returns {void}
   */
  loadGroupsByCurrentEvent = (id) => {
    const { onFetchSlotsByEvent, onFetchEvent } = this.props;

    onFetchSlotsByEvent(id);
    onFetchEvent(id);
  }

  /**
   * @description Select group from the list
   * @param {Number} id
   * @returns {void}
   */
  selectGroup = (id) => {
    const { fetchSlot, onFetchUsers, onSignInUser } = this.props;
    const { joinedMucs } = this.props;
    const { onLeaveMucRoom } = this.props;
    const { nicknameUniqueId } = this.props;

    joinedMucs
      .forEach((roomName) => onLeaveMucRoom(roomName, nicknameUniqueId));

    try {
      fetchSlot(id);
      onSignInUser({ slot_id: id });
    } catch (error) {
      console.log(error);
    }
  }

  eventsSubmenuJSX = (userRole, selectedEvent, eventsList) => {

    if (
      userRole === USER_ROLES.moderator
      || userRole === USER_ROLES.stage
      || userRole === USER_ROLES.translator) {

      return (
        <SubMenu
          key="events"
          title={(
            <span className="menu-item-label">
              <span>
                {selectedEvent && Object.keys(selectedEvent).length !== 0 ? (
                  <span>{selectedEvent.name}</span>
                ) : (<IntlMessages id="horizontalNav.events" />)}
              </span>
              <i className="icon icon-dropdown" />
            </span>
          )}
          className="menu-item-link"
        >
          {eventsList.map((event) => (
            <Menu.Item className="event-menu-item-ellipsis" key={event.id} onClick={() => this.loadGroupsByCurrentEvent(event.id)}>
              <Tooltip title={event.name}>
                <span>{event.name}</span>
              </Tooltip>
            </Menu.Item>
          ))}
        </SubMenu>
      );
    }

    return null;
  }

  render() {
    const { eventsList, groupsList } = this.state;
    const { selectedEvent, selectedGroup } = this.props;
    const role = sessionStorage.getItem('userRole');

    const slicedEvents = eventsList.slice(0, 15);

    return role !== USER_ROLES.visitor ? (
      <Menu
        mode="horizontal"
      >
        {this.eventsSubmenuJSX(role, selectedEvent, slicedEvents)}
        <SubMenu
          key="groups"
          title={(
            <span className="menu-item-label">
              <span>
                {selectedGroup && Object.keys(selectedGroup).length !== 0 ? (
                  <span>{selectedGroup.room}</span>
                ) : (<IntlMessages id="horizontalNav.groups" />)}
              </span>
              <i className="icon icon-dropdown" />
            </span>
          )}
        >
          {groupsList.length
            ? groupsList.map((group) => {
              if (!group.disabled) {
                return (
                  <Menu.Item className="slot-menu-item-ellipsis" key={group.slot_id} onClick={() => this.selectGroup(group.slot_id)}>
                    <Tooltip title={group.slot_room}>
                      <span>{group.slot_room}</span>
                    </Tooltip>
                  </Menu.Item>
                );
              }
            })
            : (
              <Menu.Item>
                <IntlMessages id="horizontalNav.groups.no_groups" />
              </Menu.Item>
            )}
        </SubMenu>
      </Menu>
    ) : null;
  }
}

HorizontalNavDropdown.propTypes = {
  events: PropTypes.array,
  groups: PropTypes.array,
  location: PropTypes.object,
  selectedEvent: PropTypes.object,
  selectedGroup: PropTypes.object,
  onLeaveMucRoom: PropTypes.func.isRequired,
  nicknameUniqueId: PropTypes.string,
  joinedMucs: PropTypes.arrayOf(PropTypes.string),
  onSignInUser: PropTypes.func.isRequired,
};

const mapStateToProps = ({ eventsReducer, groupsReducer, mucsReducer }) => {
  const { events } = eventsReducer || { events: [] };
  const { groups } = groupsReducer || { groups: [] };
  const { selectedEvent } = eventsReducer || { selectedEvent: {} };
  const { selectedGroup } = groupsReducer || { selectedGroup: {} };
  const { joinedMucs } = mucsReducer;
  const { nicknameUniqueId } = mucsReducer;

  return {
    events,
    groups,
    selectedEvent,
    selectedGroup,
    nicknameUniqueId,
    joinedMucs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchEvents: () => dispatch(onFetchEvents()),
  onFetchEvent: (eventId) => dispatch(onFetchEvent(eventId)),
  fetchSlot: (slotId) => dispatch(fetchSlot(slotId)),
  onFetchSlotsByEvent: (id) => dispatch(onFetchSlotsByEvent(id)),
  onFetchUsers: (slotId) => dispatch(onFetchUsers(slotId)),
  onSignInUser: (values) => dispatch({ type: SIGNIN_USER, values }),
  onLeaveMucRoom: (mucRoomName, nicknameUsedInTheRoom) => dispatch(stropheLeaveMucRoom(mucRoomName, nicknameUsedInTheRoom)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(HorizontalNavDropdown)));
