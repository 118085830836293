import { eventChannel } from 'redux-saga';
import {
  CONFERENCE_LISTENERS_CASES_TYPES,
  JITSI_CONFERENCE_EVENTS,
  JITSI_PARTICIPANT_CUSTOM_PROPS,
} from '@constants/Settings';
import { put } from 'redux-saga/effects';
import Moment from 'moment';
import {
  userLocalTrackMuteChange,
  userTrackMuteChanged,
  userTrackWasAdded,
  userTrackWasRemoved,
} from '@actions/users/UsersActions';
import { v4 as uuidv4 } from 'uuid';
import {
  conferenceJoinFailGeneratorCommon,
  conferenceJoinSuccessGeneratorCommon,
  conferenceLeaveGeneratorCommon,
} from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/CommonCaseGenerators';
import {
  conferenceMessageReceivedGeneratorModerator,
  conferencePrivateMessageReceivedGeneratorModerator,
  conferenceUserConnectionStatusChangedGeneratorModerator,
  conferenceUserJoinedGeneratorModerator,
  conferenceUserLeftGeneratorModerator,
  conferenceUserRaiseHandGeneratorModerator,
} from '@sagas/jitsiConference/GeneratorsForTheSwitchCases/ModeratorCaseGenerators';
import { startAudioVideoToBool } from '@jitsi/helper';

/**
 * @description The EventChannel where we put all conference listeners for the MODERATOR ROLE
 * @param jitsiConference
 * @returns {any}
 */

export const moderatorChannel = (jitsiConference) => eventChannel((emitter) => {
  /**
   * @description Listens for conference join success
   * @returns {void}
   */
  const joinSuccess = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.joinSuccess, data: [] });
  };

  /**
   * @description Listens for join failed
   * @returns {void}
   */
  const joinFailed = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.joinFail, data: [] });
  };

  /**
   * @description Listens for conference left
   * @returns {void}
   */
  const leave = () => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.leave, data: [] });
  };

  /**
   * @description Listens for user join
   * @param {Number} userId
   * @param {Object} user
   * @returns {void}
   */
  const userJoined = (userId, user) => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.userJoined, data: [user] });
  };

  /**
   * @description Listens for user left
   * @param {Number} userId
   * @param {Object} user
   * @returns {void}
   */
  const userLeft = (userId, user) => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.userLeft, data: [userId, user] });
  };

  /**
   * @description Listens for user property changed
   * @param {Object} user
   * @param {String} propertyKey
   * @param {String} oldPropertyValue
   * @param {String} propertyValue
   * @returns {void}
   */
  const userPropertyChanged = (user, propertyKey, oldPropertyValue, propertyValue) => {
    switch (propertyKey) {
      case JITSI_PARTICIPANT_CUSTOM_PROPS.raiseHand:
        if (propertyValue !== oldPropertyValue) {
          emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.userRaiseHand, data: [user, propertyValue] });
        }
        break;
      case JITSI_PARTICIPANT_CUSTOM_PROPS.hasMutedLocalAudioTrack:
        if (propertyValue !== oldPropertyValue) {
          emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.userLocalAudioTrackMutedChanged, data: [user, propertyValue] });
        }
        break;
      case JITSI_PARTICIPANT_CUSTOM_PROPS.hasMutedLocalVideoTrack:
        if (propertyValue !== oldPropertyValue) {
          emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.userLocalVideoTrackMutedChanged, data: [user, propertyValue] });
        }
        break;
      default:
        break;
    }
  };

  /**
   * @description Listens for any track which has been added in the conference
   * @param {object} track
   */
  const trackAdded = (track) => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackAdded, data: [track] });
  };

  /**
   * @description Listens for any track which has been removed from the conference
   * @param {object} track
   */
  const trackRemoved = (track) => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackRemoved, data: [track] });
  };

  /**
   * @description Listens for any track which has changed mute status
   * @param {object} track
   */
  const trackMuteChange = (track) => {
    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.trackMuteChanged, data: [track] });
  };

  /**
   * @description Listen for receive new message
   * @param {Number} to
   * @param {String} message
   * @returns {void}
   */
  const privateMessageReceived = (to, message) => {
    const adaptedMessage = {
      messageId: uuidv4(),
      message,
      type: 'received',
      userId: to,
      sentAt: Moment().format('hh:mm:ss A'),
      read: false,
    };

    emitter({
      type: CONFERENCE_LISTENERS_CASES_TYPES.privateMessageReceived,
      data: [adaptedMessage],
    });
  };

  const messageReceived = (from, message) => {
    const adaptMessage = {
      message,
      type: 'sent',
      userId: from,
      sentAt: Moment().format('hh:mm:ss A'),
      read: true,
    };

    emitter({ type: CONFERENCE_LISTENERS_CASES_TYPES.messageReceived, data: [adaptMessage] });
  };

  const participantConnectionStatusChanged = (id, connStatus) => {
    emitter({
      type: CONFERENCE_LISTENERS_CASES_TYPES.participantConnStatusChanged,
      data: [id, connStatus],
    });
  };

  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceJoined, joinSuccess);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceJoinFailed, joinFailed);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceLeft, leave);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceTrackAdded, trackAdded);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceTrackRemoved, trackRemoved);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceTrackMuteChanged, trackMuteChange);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceUserJoined, userJoined);
  jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceUserLeft, userLeft);
  jitsiConference.addListener(
    JITSI_CONFERENCE_EVENTS.conferenceUserPropertyChanged, userPropertyChanged,
  );
  jitsiConference.addListener(
    JITSI_CONFERENCE_EVENTS.conferencePrivateMessageReceived, privateMessageReceived,
  );
  jitsiConference.addListener(
    JITSI_CONFERENCE_EVENTS.conferenceMessageReceived, messageReceived,
  );
  jitsiConference.addListener(
    JITSI_CONFERENCE_EVENTS.conferenceParticipantConnectionStatusChanged, participantConnectionStatusChanged,
  );

  return () => {
    jitsiConference.removeConferenceListeners();
  };
});

/**
 * @description Generator function with SWITCH inside, which depending on the
 * conference event which was "caught" dispatches actions
 * @description Explanation:
 * (On the channel above, for the successful conference joining event,
 * we are executing "joinSuccess" function listener,
 * inside that listener we are emitting object with "type: 'JoinSuccess'" and "data: []"
 * in the switch statement below for the 'JoinSuccess' case we are dispatching two actions)
 * @param channelResult
 * @param jitsiConference
 * @returns {any}
 */
export function* moderatorSwitch(channelResult, jitsiConference) {
  switch (channelResult.type) {
    case CONFERENCE_LISTENERS_CASES_TYPES.joinSuccess:
      yield conferenceJoinSuccessGeneratorCommon();
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.joinFail:
      yield conferenceJoinFailGeneratorCommon();
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.leave:
      yield conferenceLeaveGeneratorCommon(jitsiConference);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.userJoined:
      yield conferenceUserJoinedGeneratorModerator(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.userLeft:
      yield conferenceUserLeftGeneratorModerator(channelResult.data[0], channelResult.data[1]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.userRaiseHand:
      yield conferenceUserRaiseHandGeneratorModerator(channelResult.data[0], channelResult.data[1]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.userLocalVideoTrackMutedChanged:
      yield put(userLocalTrackMuteChange(channelResult.data[0], startAudioVideoToBool(channelResult.data[1]), 'video'));
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.userLocalAudioTrackMutedChanged:
      yield put(userLocalTrackMuteChange(channelResult.data[0], startAudioVideoToBool(channelResult.data[1]), 'audio'));
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackAdded:
      yield put(userTrackWasAdded(channelResult.data[0]));
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackRemoved:
      yield put(userTrackWasRemoved(channelResult.data[0]));
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.trackMuteChanged:
      yield put(userTrackMuteChanged(channelResult.data[0]));
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.privateMessageReceived:
      yield conferencePrivateMessageReceivedGeneratorModerator(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.messageReceived:
      yield conferenceMessageReceivedGeneratorModerator(channelResult.data[0]);
      break;
    case CONFERENCE_LISTENERS_CASES_TYPES.participantConnStatusChanged:
      yield conferenceUserConnectionStatusChangedGeneratorModerator(
        channelResult.data[0],
        channelResult.data[1],
      );
      break;
    default:
      break;
  }
}
