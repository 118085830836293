import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CustomScrollbars from 'util/CustomScrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Input, Popconfirm, Tooltip, } from 'antd';

/**
 * @description Renders the right side part of moderator chat view
 * @param {Array<Object>} messages
 * @returns {JSX}
 */
const PredefinedMessages = ({
  messages,
  selectedUser,
  props,
  addPredefinedMessageToTextArea,
}) => {
  const { intl } = props;
  const [newMessage, setNewMessage] = useState('');
  const [currMessages, setCurrMessages] = useState([]);
  const inputRefs = useRef([]);
  inputRefs.current = [];

  useEffect(() => {
    setCurrMessages(messages);
  }, [messages]);

  /**
   * @description Add new predefined message in messages list
   * @param {Object} event
   * @returns {void}
   */
  const addMessage = (event) => {
    event.persist();
    event.preventDefault();

    const { addPredefinedMessage } = props;
    const messageToAdd = {
      message_body: newMessage,
      disabled: false,
    };

    addPredefinedMessage(messageToAdd);
    setNewMessage('');
  };

  /**
   * @description Updates value while typing
   * @param {Object} event
   * @returns {void}
   */
  const updateMessageValue = (event) => {
    setNewMessage(event.target.value);
  };

  /**
   * @description Triggers add action when enter key is pressed
   * @param {Object} event
   * @returns {void}
   */
  const handlePressEnterKey = (event) => {
    if (event.key === 'Enter') {
      addMessage(event);
    }
  };

  /**
   * @description Triggers delete action when delete button is clicked
   * @param {Number} id
   * @returns {void}
   */
  const deleteMessage = (messageId) => {
    const { deletePredefinedMessage } = props;

    deletePredefinedMessage(messageId);
  };

  /**
   * @description Add refs on each iteration of map to the Input
   * @param {Object} el
   * @returns {void}
   */
  const addToRefs = (el) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el);
    }
  };

  /**
   * @description Make input focused to be able to edit message
   * @param {Number} id
   * @returns {void}
   */
  const editMessage = (id) => {
    const currRef = inputRefs.current.find((ref) => ref.props.id === id);
    currRef.focus();
  };

  const saveEditedMessage = (event) => {
    if (event.key === 'Enter') {
      const { savePredefinedMessage } = props;
      const messageToUpdate = {
        message_body: event.target.value,
        disabled: false,
      };
      const messageId = Number(event.target.getAttribute('id'));

      savePredefinedMessage(messageId, messageToUpdate);
    }
  };

  /**
   * @description Makes possible to type in input for edit
   * @param {Object} e
   * @param {Number} index
   * @returns {void}
   */
  const handleMessageInput = (e, index) => {
    const newMessages = [...currMessages];
    newMessages[index].message_body = e.target.value;
    setCurrMessages(newMessages);
  };

  /**
   * @description Renders messages list
   * @returns {JSX}
   */
  const renderMessages = () => (
    messages.map((message, index) => (
      <div className="predefined-messages" key={message.id}>
        <span>
          <Input
            id={message.id}
            value={message.message_body}
            name="message"
            type="text"
            className="predefined-messages-input"
            ref={addToRefs}
            onKeyUp={saveEditedMessage}
            onChange={(e) => handleMessageInput(e, index)}
          />
        </span>
        <span>
          <Button
            className="predefined-messages-button"
            onClick={selectedUser
              ? () => addPredefinedMessageToTextArea(message.message_body)
              : null}
          >
            <Tooltip
              title={intl.formatMessage({ id: 'chat.predefinedMessages.tooltip.copy' })}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Tooltip>
          </Button>
          <Button
            className="predefined-messages-button"
            onClick={() => editMessage(message.id)}
          >
            <Tooltip
              title={intl.formatMessage({ id: 'chat.predefinedMessages.tooltip.edit' })}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Tooltip>
          </Button>
          <Popconfirm
            placement="top"
            title={intl.formatMessage({ id: 'chat.predefined.messages.delete' })}
            onConfirm={() => deleteMessage(message.id)}
            okText={intl.formatMessage({ id: 'common.yes' })}
            cancelText={intl.formatMessage({ id: 'common.no' })}
          >
            <Button
              className="predefined-messages-button"
            >
              <Tooltip
                title={intl.formatMessage({ id: 'chat.predefinedMessages.tooltip.delete' })}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Tooltip>
            </Button>
          </Popconfirm>
        </span>
      </div>
    ))
  );

  return (
    <div>
      <div className="chat-sidebar">
        <CustomScrollbars className="gx-module-content-scroll">
          {renderMessages()}
        </CustomScrollbars>
      </div>
      <div className="gx-chat-main-footer">
        <span>
          <Input
            placeholder={intl.formatMessage({ id: 'predefinedMessages.addText' })}
            value={newMessage}
            onKeyUp={handlePressEnterKey}
            onChange={updateMessageValue}
          />
        </span>
      </div>
    </div>
  );
};

PredefinedMessages.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedUser: PropTypes.oneOfType([PropTypes.object]),
  props: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.array]).isRequired,
  addPredefinedMessage: PropTypes.func,
  savePredefinedMessage: PropTypes.func,
  deletePredefinedMessage: PropTypes.func,
  addMessageToConversation: PropTypes.func,
  addPredefinedMessageToTextArea: PropTypes.func,
};

export default injectIntl(PredefinedMessages);
