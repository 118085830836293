import { useEffect } from 'react';
import JitsiConference from '@jitsi/JitsiConference';
import { useSelector } from 'react-redux';

/**
 * Custom hook where we handle the change of the 'hasMutedLocalVideoTrack' property
 *
 * Here the hook depends on the 'localTracks' and 'hasMutedLocalVideoTrack'
 *
 * For example if the user doesn't have Camera and Microphone, we wont have any tracks,
 * so 'localTracks.length > 0' will be false and we wont execute anything
 *
 * @param localTracks - the user tracks
 * @param hasMutedLocalVideoTrack - redux value if the track is muted
 */
export function useHasMutedLocalVideoTrackHook(localTracks) {
  const hasMutedLocalVideoTrack = useSelector((state) => state.loggedUser.hasMutedLocalVideoTrack);

  useEffect(() => {
    if (localTracks.length > 0) {
      const videoTrack = localTracks.find((tr) => tr.getType() === 'video');

      if (videoTrack) {
        if (hasMutedLocalVideoTrack === true) {
          videoTrack.mute();
        } else {
          videoTrack.unmute();
        }

        JitsiConference.changeHasMutedLocalVideoTrack(hasMutedLocalVideoTrack.toString());
      }
    }
  }, [localTracks, hasMutedLocalVideoTrack]);
}
