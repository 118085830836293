import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import CustomScrollbars from '@util/CustomScrollbars';
import Conversation from '@components/chat/Conversation';
import { connect } from 'react-redux';
import { Input, Row, Col } from 'antd';
import { ReactComponent as SendMessageIcon } from '@assets/images/actions-icons/sendMessage_icon_black.svg';
import JitsiConference from '@jitsi/JitsiConference';
import { addMessageToConversation, checkMessageAsRead } from '@actions/users/UsersActions';
import { injectIntl } from 'react-intl';
import { usePrevious } from '../../../CustomHooks/usePrevious';
import { stropheMucMessageSend } from '@actions/mucs/MucsActions';
import { constructMucRoomName } from '../../../CustomHooks/HelperFuncs';

const { TextArea } = Input;

/**
 * @description Renders chat in Participants view
 * @param {Object} props
 * @returns {JSX}
 */
const ParticipantChat = (props) => {
  const {
    conversation,
    userUid,
    onAddMessageToConversation,
    intl,
    onCheckMessageAsRead,
    users,
    onSendMessage,
    slotUid,
  } = props;
  const [message, setMessage] = useState('');
  const [messageObj, setMessageObj] = useState(null);
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
  const [currUser, setCurrUser] = useState({});
  const [isChatOpened, setIsChatOpened] = useState(false);
  const inputRef = useRef();

  let prevConversation = usePrevious(conversation);

  useEffect(() => {
    if (!prevConversation) {
      prevConversation = [];
    }
  }, []);

  useEffect(() => {
    if (messageObj) {
      onAddMessageToConversation(messageObj);
    }
  }, [messageObj]);

  const findCurrUser = () => {
    const selectedUser = users.find((u) => u.uid === userUid);

    setCurrUser(selectedUser);
  };

  useEffect(() => {
    findCurrUser();
  });

  /**
   * @description Use Jitsi function to send message
   * @returns {void}
   */
  const sendMessage = () => {
    if (message.trim() !== '') {
      onSendMessage(constructMucRoomName(slotUid, userUid), message);
      setMessage('');
    }
  };

  /**
   * @description Focus text area
   * @returns {void}
   */
  const onFocusTextArea = () => {
    setIsTextAreaFocused(true);
  };

  /**
   * @description Blur text area
   * @returns {void}
   */
  const onBlurTextArea = () => {
    setIsTextAreaFocused(false);
  };

  /**
   * @description Handles add message on enter click
   * @param {Object} e
   * @returns {void}
   */
  const handleEnterKeyPress = (e) => {
    e.persist();
    e.preventDefault();

    if (e.which === 13 && !e.shiftKey) {
      sendMessage();
      setMessage('');
    }
  };

  /**
   * @description Updates input value
   * @param {Object} event
   * @returns {void}
   */
  const updateMessageValue = (event) => {
    setMessage(event.target.value);
  };

  /**
   * @description Sends message on icon clicked
   * @returns {void}
   */
  const handleSendIconClicked = () => {
    sendMessage();
  };

  /**
   * @description Checks unread messages as read when textarea is focused
   * @returns {void}
   */
  const checkReadMessages = () => {
    let conversationCopy = conversation.slice();
    if ((isChatOpened || isTextAreaFocused) && conversationCopy) {
      conversationCopy = conversationCopy.map((msg) => {
        const modifiedMessage = { ...msg, read: true };

        return modifiedMessage;
      });
    }

    if (conversationCopy && conversationCopy.length > 0) {
      onCheckMessageAsRead(conversationCopy);
    }
  };

  /**
   * @description Check if there is open chat with some user
   * @returns {void}
   */
  const checkForChatOpened = () => {
    const isOpened = users.find((user) => user.chatOpened);
    if (isOpened) {
      setIsChatOpened(true);
    } else {
      setIsChatOpened(false);
    }
  };

  useEffect(() => {
    if (prevConversation.length !== conversation.length) {
      checkForChatOpened();
      checkReadMessages();
    }
  });

  useEffect(() => {
    checkReadMessages();
  }, [isTextAreaFocused]);

  return (
    <div className="participantChatWrapper">
      <CustomScrollbars
        className="gx-chat-list-scroll gx-chat-list-scroll--desktop"
      >
        <Conversation
          conversation={conversation}
          selectedUser={currUser}
        />
      </CustomScrollbars>
      <div className="inputRow">
        <Row>
          <Col span={22}>
            <TextArea
              autoSize={{
                minRows: 1,
                maxRows: 1,
              }}
              ref={inputRef}
              value={message}
              onChange={updateMessageValue}
              onKeyUp={handleEnterKeyPress}
              onFocus={onFocusTextArea}
              onBlur={onBlurTextArea}
              placeholder={intl.formatMessage({ id: 'participants.table.chat' })}
              className="participantChatWrapper_textArea"
            />
          </Col>
          <Col span={2}>
            <div
              className="participantSendMessageIcon"
              onClick={() => handleSendIconClicked()}
            >
              <SendMessageIcon />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

ParticipantChat.propTypes = {
  conversation: PropTypes.array,
  userUid: PropTypes.string,
  onAddMessageToConversation: PropTypes.func,
  intl: PropTypes.object,
  onCheckMessageAsRead: PropTypes.func,
  users: PropTypes.array,
  onSendMessage: PropTypes.func.isRequired,
  slotUid: PropTypes.string,
};

const mapStateToProps = ({ usersReducer, loggedUser }) => ({
  users: usersReducer.users,
  slotUid: loggedUser.roomName,
});

const mapDispatchToProps = (dispatch) => ({
  onAddMessageToConversation: (message) => dispatch(addMessageToConversation(message)),
  onCheckMessageAsRead: (message) => dispatch(checkMessageAsRead(message)),
  onSendMessage: (roomName, message) => dispatch(stropheMucMessageSend(roomName, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ParticipantChat));
