import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const VideoTrack = (props) => {
  const {
    tracks,
    classesArr,
    width,
  } = props;
  const videoRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const track of tracks) {
      if (track.getType() === 'video') {
        track.attach(videoRef.current);
        break;
      }
    }
  }, [tracks]);

  return (
    <video
      autoPlay="1"
      ref={videoRef}
      width={`${width}%`}
      style={{ marginBottom: '30px' }}
      className={classesArr && classesArr.length > 0 ? classesArr.join(' ') : ''}
      playsInline
    />
  );
};

VideoTrack.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.object).isRequired,
  classesArr: PropTypes.arrayOf(PropTypes.string).isRequired,
  width: PropTypes.number.isRequired,
};

export default VideoTrack;
