import { all, call, fork, put, select, takeEvery, takeLatest, } from 'redux-saga/effects';
import {
  addUserAsSpeaker,
  addUserAsSpeakerFail,
  addUserAsSpeakerSuccess,
  addUserSuccess,
  addUserToGroup,
  addUserToGroupSuccess,
  banParticipantFail,
  banParticipantSuccess,
  clearLiveParticipantsSuccess,
  kickParticipant,
  kickParticipantFail,
  kickParticipantSuccess,
  muteParticipantsSuccess,
  onFetchUsersSuccess,
  removeUserAsSpeaker,
  removeUserAsSpeakerFail,
  removeUserAsSpeakerSuccess,
  removeUserVideo,
  removeUserVideoFail,
  removeUserVideoSuccess,
  setParticipantLiveAudioSuccess,
  setParticipantLiveVideoSuccess,
} from '@actions/users/UsersActions';
import {
  ADD_USER,
  ADD_USER_AS_SPEAKER,
  ADD_USER_AS_SPEAKER_FAIL,
  ADD_USER_AS_SPEAKER_SUCCESS,
  ADD_USER_TO_GROUP,
  BAN_PARTICIPANT,
  CLEAR_LIVE_PARTICIPANTS,
  FETCH_USERS_REQUEST,
  KICK_PARTICIPANT,
  MUTE_PARTICIPANTS,
  REMOVE_USER_AS_SPEAKER,
  REMOVE_USER_AS_SPEAKER_FAIL,
  REMOVE_USER_AS_SPEAKER_SUCCESS,
  REMOVE_USER_VIDEO,
  REMOVE_USER_VIDEO_FAIL,
  REMOVE_USER_VIDEO_SUCCESS,
  SET_PARTICIPANT_LIVE_AUDIO,
  SET_PARTICIPANT_LIVE_VIDEO,
} from '@constants/ActionTypes';
import { ENDPOINTS } from '@constants/Endpoints';
import { fetchError, hideNotification, showNotification } from '@actions/Common';
import RestManager from '@util/RestManager';
import JitsiConference from '@jitsi/JitsiConference';
import { JITSI_MUTE_MESSAGES, JITSI_SPEAKER_MESSAGES, PARTICIPANTS_TABLE, USER_ROLES } from '@constants/Settings';
import { delay } from '@redux-saga/core/effects';
import JitsiTrack from '@jitsi/JitsiTrack';
import { LogMessage, LoggerType, LoggerHeaders, LoggerMessage } from '../../../CustomHooks/LoggerHelper';

/**
 * @description Request users data
 * @param {Number} slotId
 * @returns {Object}
 */
const doFetchUsers = async (slotId) => await RestManager.request(
  `${ENDPOINTS.getScheduleUsers}/${slotId}/participants`,
);

/**
 * Request to add an user
 * @param {object} data - the user data from the addUserForm
 * @returns {Promise<*>}
 */
const addUserReq = async (data) => await RestManager.requestWithoutQueryParams(`${ENDPOINTS.addUser}`, 'POST', data);

/**
 * Request to add an user to specific slot
 * @param {object} userDataFromResponse - represents the response when we successfully add an user
 * @param {number} slotId - the slot id
 * @returns {Promise<*>}
 */
const addUserToGroupReq = async (userDataFromResponse, slotId) => await RestManager.requestWithoutQueryParams(`${ENDPOINTS.addUserToSlot(slotId)}`, 'POST', { user_id: userDataFromResponse.user_id });

/**
 * @description Request to add a user as speaker for the language has has chosen
 * @param slotId
 * @param langIsoCode
 * @param userId
 * @param userRole
 * @returns {Promise<*>}
 */
const addUserAsSpeakerReq = async (slotId, langIsoCode, userId, userRole) =>
  await RestManager.requestWithoutQueryParams(`${ENDPOINTS.addUserAsSpeaker(slotId, langIsoCode, userId, userRole)}`, 'PUT');

/**
 * @description Request to remove a user as speaker for the language he has chosen
 * @param slotId
 * @param langIsoCode
 * @param userId
 * @param userRole
 * @returns {Promise<*>}
 */
const removeUserAsSpeakerReq = async (slotId, langIsoCode, userId, userRole) =>
  await RestManager.requestWithoutQueryParams(`${ENDPOINTS.removeUserAsSpeaker(slotId, langIsoCode, userId, userRole)}`, 'DELETE');

/**
 * Request to BAN a user by his ID
 * @param userId
 */
const banUserReq = async (userId) =>
  await RestManager.requestWithoutQueryParams(`${ENDPOINTS.updateUser(userId)}`, 'PATCH', { disabled: true });

/**
 * Generator which we execute when we start adding user as a speaker
 * @param slotId
 * @param record
 * @param withVideo
 */
export function* doAddUserAsSpeaker({ slotId, record, withVideo }) {
  const users = yield select((state) => state.usersReducer.users);

  const alreadyHasSomeVisitorWithLiveAudio = yield users.find((u) => {
    return (u.confProps.status === PARTICIPANTS_TABLE.rowStatusClasses.online
      || u.confProps.status === PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised)
      && (u.confProps.liveAudio === true || u.confProps.liveAV === true)
      && u.confProps.userRole === USER_ROLES.visitor
      && u.confProps.userConfId !== record.userConfId
  });

  if (alreadyHasSomeVisitorWithLiveAudio) {
    yield delay(100);
    yield put(showNotification('warning', 'notification.participantAlreadyHaveAVisitorOnLive'));
    yield put(hideNotification());
  } else {
    try {
      yield call(addUserAsSpeakerReq, slotId, record.language, record.key, record.participantType);
      yield put(addUserAsSpeakerSuccess(record, withVideo));
    } catch (error) {
      yield put(removeUserAsSpeakerFail());
    }
  }
}

/**
 * Generator which we execute when the user is successfully added as speaker on the backend
 * @param {object} record - the record object from the participants table
 * @param withVideo
 * @returns void
 */
export function* doAddUserAsSpeakerSuccess({ record, withVideo }) {
  if (withVideo) {
    yield JitsiConference.sendPublicSystemMessage(`${JITSI_SPEAKER_MESSAGES.newSpeakerAddedWithVideo}::${record.key}`);
    yield JitsiConference.sendPrivateSystemMessage(JITSI_SPEAKER_MESSAGES.startSpeakerWithVideo, record.userConfId);
    yield put(setParticipantLiveVideoSuccess(record, true));
  } else {
    yield JitsiConference.sendPublicSystemMessage(`${JITSI_SPEAKER_MESSAGES.newSpeakerAdded}::${record.key}`);
    yield JitsiConference.sendPrivateSystemMessage(JITSI_SPEAKER_MESSAGES.startSpeaker, record.userConfId);
    yield put(setParticipantLiveAudioSuccess(record, true));
  }
  yield put(showNotification('success', 'notification.participantAddedInSpeakersListSuccess'));
  yield put(hideNotification());
}

/**
 * Generator which we execute when the user adding as speaker on the backend has failed
 */
export function* doAddUserAsSpeakerFail() {
  yield put(showNotification('error', 'notification.participantAddedInSpeakersListFail'));
  yield put(hideNotification());
}

/**
 * Generator which we execute when we start to remove the user as a speaker
 * @param slotId
 * @param {object} record - the record object from the participants table
 * @returns void
 */
export function* doRemoveUserAsSpeaker({ slotId, record }) {
  try {
    yield call(removeUserAsSpeakerReq, slotId, record.language, record.key, record.participantType);
    yield put(removeUserAsSpeakerSuccess(record));
  } catch (error) {
    yield put(removeUserAsSpeakerFail());
  }
}

/**
 * Generator function which we execute when the user removing as a speaker has succeed on the backend
 * @param {object} record - the record object from the participants table
 * @returns void
 */
export function* doRemoveUserAsSpeakerSuccess({ record }) {
  yield JitsiConference.sendPublicSystemMessage(`${JITSI_SPEAKER_MESSAGES.newSpeakerRemoved}::${record.key}`);
  yield JitsiConference.sendPrivateSystemMessage(JITSI_SPEAKER_MESSAGES.endSpeaker, record.userConfId);
  yield put(setParticipantLiveAudioSuccess(record, false));
  yield put(setParticipantLiveVideoSuccess(record, false));
  yield put(showNotification('success', 'notification.participantRemovedFromSpeakersListSuccess'));
  yield put(hideNotification());
}

/**
 * Generator function which we execute when the moderator has send to all visitors that current speaker video is removed
 *
 * @param {object} record - the record object from the participants table
 * @returns void
 */
export function* doRemoveUserVideoSuccess({ record }) {
  yield put(setParticipantLiveVideoSuccess(record, false));
  yield delay(100);
  yield put(showNotification('success', 'notification.participantVideoRemovedSuccess'));
  yield put(hideNotification());
}

/**
 * Generator function which we execute when the moderator message to all visitors that the current speaker video is removed
 * has failed
 *
 * @param {object} record - the record object from the participants table
 * @returns void
 */
export function* doRemoveUserVideoFail() {
  yield put(showNotification('error', 'notification.participantVideoRemovedFail'));
  yield put(hideNotification());
}

/**
 * Generator function which we execute when user removing as a speaker has failed on the backend
 */
export function* doRemoveUserAsSpeakerFail() {
  yield put(showNotification('error', 'notification.participantRemovedFromSpeakersListFail'));
  yield put(hideNotification());
}

export function* doMuteParticipants({ data }) {
  const selectedAndOnlineUsers = data.filter((u) => {
    return u.checked === true
      && (u.confProps.status === PARTICIPANTS_TABLE.rowStatusClasses.online
        || u.confProps.status === PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised)
      && u.confProps.liveAudio === true;
  });

  for (const u of selectedAndOnlineUsers) {
    try {
      yield JitsiConference.sendPrivateSystemMessage(JITSI_MUTE_MESSAGES.muteAudio, u.confProps.userConfId);
      yield delay(2000);

      if (JitsiTrack.checkUserTrackMutedByUserId(u.confProps.userConfId, 'audio')) {
        yield put(showNotification('success', 'notification.participantMuteSuccess'));
        yield put(hideNotification());
      } else {
        yield put(showNotification('error', 'notification.participantMuteFail'));
        yield put(hideNotification());
      }
    } catch (e) {
    }
  }

  yield put(muteParticipantsSuccess(data));
}

export function* doKickParticipants() {
  const currentUsersInConference = yield select((state) => state.usersReducer.participantsPage.tableData);
  let selectedUsers = [];
  const selectedUsersIds = yield currentUsersInConference
    .filter((u) => (u.checked === true)
      && (u.confProps.userConfId)
      && ((u.confProps.status === PARTICIPANTS_TABLE.rowStatusClasses.online)
        || (u.confProps.status === PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised)))
    .map((u) => {
      selectedUsers.push({ firstName: u.first_name, lastName: u.last_name });
      return u.confProps.userConfId;
    });

  if (selectedUsersIds.length > 0) {
    try {
      selectedUsers.forEach((user) => {
        LogMessage(LoggerType.INFO, LoggerHeaders.USER_KICK, LoggerMessage.userWillBeKicked(user.firstName, user.lastName));
      });

      for (const uid of selectedUsersIds) {
        yield JitsiConference.kickUserFromConference(uid);
        yield put(kickParticipantSuccess());
      }

      selectedUsers.forEach((user) => {
        LogMessage(LoggerType.INFO, LoggerHeaders.USER_KICK, LoggerMessage.userIsKicked(user.firstName, user.lastName));
      });
    } catch (error) {
      selectedUsers.forEach((user) => {
        LogMessage(LoggerType.INFO, LoggerHeaders.USER_KICK, LoggerMessage.userIsNotKicked(user.firstName, user.lastName));
      });
      yield put(kickParticipantFail());
    }
  } else {
    yield put(kickParticipantFail());
  }
}

export function* doBanParticipants() {
  const currentUsersInConference = yield select((state) => state.usersReducer.participantsPage.tableData);
  const selectedUsers = yield currentUsersInConference
    .filter((u) => (u.checked === true)
      && (u.confProps.userConfId)
      && ((u.confProps.status === PARTICIPANTS_TABLE.rowStatusClasses.online)
        || (u.confProps.status === PARTICIPANTS_TABLE.rowStatusClasses.onlineRaised)));

  /**
   * Here we first dispatch action to kick the selected participants
   */
  yield put(kickParticipant());

  /**
   * Here we actually ban the selected participants on the backend
   */
  if (selectedUsers.length > 0) {
    try {
      for (const user of selectedUsers) {
        yield call(banUserReq, user.user_id);
        yield put(banParticipantSuccess());
        yield put(showNotification('success', 'notification.participantBanSuccess'));
        yield put(hideNotification());
      }
    } catch (error) {
      yield put(banParticipantFail());
      yield put(showNotification('error', 'notification.participantBanFail'));
      yield put(hideNotification());
    }
  } else {
    yield put(banParticipantFail());
    yield put(showNotification('error', 'notification.participantBanFail'));
    yield put(hideNotification());
  }
}

export function* doLiveVideoChange({ record }) {
  const currentLiveAudioStatus = record.liveAV;

  if (!currentLiveAudioStatus) {
    try {
      yield put(addUserAsSpeaker(sessionStorage.getItem('slot_id'), record, true));
    } catch (e) {
      yield put(addUserAsSpeakerFail());
    }
  } else {
    try {
      yield put(removeUserVideo(sessionStorage.getItem('slot_id'), record));
    } catch (e) {
      yield put(removeUserVideoFail());
    }
  }
}

export function* doRemoveUserVideo({ record }) {
  try {
    yield JitsiConference.sendPublicSystemMessage(`${JITSI_SPEAKER_MESSAGES.removeSpeakerVideo}`);
    yield put(removeUserVideoSuccess(record));
  } catch (error) {
    yield put(removeUserVideoFail());
  }
}

/**
 * Generator to add an user as a speaker for the language he has chosen
 * @param {object} record - the record object from the participants table
 * @returns void
 */
export function* doLiveAudioChange({ record }) {
  const currentLiveAudioStatus = record.liveAudio;

  if (!currentLiveAudioStatus) {
    try {
      yield put(addUserAsSpeaker(sessionStorage.getItem('slot_id'), record));
    } catch (e) {
      yield put(addUserAsSpeakerFail());
    }

    /*   COMMENTED FOR NOW

  yield delay(2000);
    const hasAudioTrack = yield JitsiTrack.checkIfParticipantHasParticularTrack(record.userConfId, 'audio');

    if (hasAudioTrack) {
      yield put(showNotification('success', 'notification.participantAudioAddedSuccess')); //using intl id for the message
      yield put(hideNotification());
    } else {
      yield put(showNotification('error', 'notification.participantAudioAddedFail')); //using intl id for the message
      yield put(hideNotification());
    }
*/
  } else {
    try {
      yield put(removeUserAsSpeaker(sessionStorage.getItem('slot_id'), record));
    } catch (e) {
      yield put(removeUserAsSpeakerFail());
    }

    /* COMMENTED FOR NOW

    yield delay(2000);
    const hasAudioTrack = yield JitsiTrack.checkIfParticipantHasParticularTrack(record.userConfId, 'audio');

    if (!hasAudioTrack) {
      yield put(showNotification('success', 'notification.participantAudioRemovedSuccess')); //using intl id for the message
      yield put(hideNotification());
    } else {
      yield put(showNotification('error', 'notification.participantAudioRemovedFail')); //using intl id for the message
      yield put(hideNotification());
    }*/
  }
}

export function* doClearLive({ data }) {
  // TODO: Request for Live Audio Change
  console.log('Clear Live PARTICIPANTS - code from saga');

  yield put(clearLiveParticipantsSuccess(data));
}

/**
 * Generator to add an user when specific action is triggered
 * @param {object} userData - the user data from the addUserForm
 * @param {number} slotId
 * @returns void
 */
export function* doAddUser({ userData, slotId }) {
  try {
    const response = yield call(addUserReq, userData);

    yield put(addUserSuccess());
    yield put(addUserToGroup(response, slotId));
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * Generator to add an user to specific slot when specific action is triggered
 * @param {object} userDataFromResponse - the response which we get when we add an user successfully
 * @param {number} slotId
 * @returns void
 */
export function* doAddUserToGroup({ userDataFromResponse, slotId }) {
  try {
    yield call(addUserToGroupReq, userDataFromResponse, slotId);
    yield put(addUserToGroupSuccess());
  } catch (error) {
    yield put(fetchError(error));
  }
}

/**
 * @description Get users list data
 * @param {Number} param
 * @returns {Object}
 */
function* fetchUsers({ slotId }) {
  try {
    const usersData = yield call(doFetchUsers, slotId);

    if (usersData) {
      yield put(onFetchUsersSuccess(usersData));
    }
  } catch (error) {
    yield put(fetchError(error));
  }
}

export function* actionsWatcher() {
  yield takeLatest(FETCH_USERS_REQUEST, fetchUsers);
  yield takeLatest(ADD_USER, doAddUser);
  yield takeLatest(ADD_USER_TO_GROUP, doAddUserToGroup);
  yield takeEvery(MUTE_PARTICIPANTS, doMuteParticipants);
  yield takeEvery(KICK_PARTICIPANT, doKickParticipants);
  yield takeEvery(BAN_PARTICIPANT, doBanParticipants);
  yield takeEvery(SET_PARTICIPANT_LIVE_VIDEO, doLiveVideoChange);
  yield takeEvery(SET_PARTICIPANT_LIVE_AUDIO, doLiveAudioChange);
  yield takeEvery(CLEAR_LIVE_PARTICIPANTS, doClearLive);
  yield takeEvery(ADD_USER_AS_SPEAKER, doAddUserAsSpeaker);
  yield takeEvery(ADD_USER_AS_SPEAKER_SUCCESS, doAddUserAsSpeakerSuccess);
  yield takeEvery(ADD_USER_AS_SPEAKER_FAIL, doAddUserAsSpeakerFail);
  yield takeEvery(REMOVE_USER_AS_SPEAKER, doRemoveUserAsSpeaker);
  yield takeEvery(REMOVE_USER_AS_SPEAKER_SUCCESS, doRemoveUserAsSpeakerSuccess);
  yield takeEvery(REMOVE_USER_AS_SPEAKER_FAIL, doRemoveUserAsSpeakerFail);
  yield takeEvery(REMOVE_USER_VIDEO, doRemoveUserVideo);
  yield takeEvery(REMOVE_USER_VIDEO_SUCCESS, doRemoveUserVideoSuccess);
  yield takeEvery(REMOVE_USER_VIDEO_FAIL, doRemoveUserVideoFail);
}

export default function* rootSaga() {
  yield all([fork(actionsWatcher)]);
}
