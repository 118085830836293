import _ from 'lodash';

import JitsiConference from '@jitsi/JitsiConference';

/* eslint-disable no-undef */
const config = JitsiConfig;

class JitsiConnection {
  /**
   * @description The constructor required the token returned
   * from the "/slots/{slotId}/join-info" endpoint we hit
   * @param token
   */
  constructor(token) {
    this.token = token;
    this.attachedListeners = [];
  }

  /**
   * Function which create JitsiConnection object
   * @returns {JitsiConnection}
   */
  async initConnection(configOverride = null) {
    const conf = {
      ..._.cloneDeep(config),
      ..._.cloneDeep(configOverride),
    };

    if (window.audi?.connection?.token === this.token) {
      window.audi.connection.connect();
      console.log('[TEST][RECONNECT] initConnection - RETURN');
      return this;
    }

    if (window.audi?.room?.leave && window.audi.room.isJoined()) {
      await JitsiConference.leave();
    }
    if (window.audi?.connection?.disconnect) {
      await JitsiConnection.disconnect();
    }

    window.audi.connection = new window.JitsiMeetJS.JitsiConnection(
      null,
      this.token,
      configOverride ? conf : config,
    );

    return this;
  }

  /**
   * @description Function which we use to attach listeners to the real JitsiConnection object
   * @param jitsiEvent
   * @param listener
   * @returns {JitsiConnection}
   */
  addListener(jitsiEvent, listener) {
    if (!_.isEmpty(window.audi.connection)) {
      window.audi.connection.addEventListener(
        jitsiEvent,
        listener,
      );
      this.attachedListeners.push([jitsiEvent, listener]);
    }

    return this;
  }

  /**
   * @description Remove all attached listeners
   */
  removeConnectionListeners() {
    this.attachedListeners.forEach((listener) => {
      window.audi.connection.removeEventListener(
        listener[0], // represents the JITSI official event
        listener[1], // represents our listener function attached to the event
      );
    });

    this.attachedListeners = [];
  }

  /**
   * Remove single listener
   */
  static removeConnectionListener(jitsiEvent, listener) {
    window.audi.connection.removeEventListener(
      jitsiEvent, // represents the JITSI official event
      listener, // represents our listener function attached to the event
    );
  }

  /**
   * @description Connect to the our Jitsi Server
   */
  connect() {
    if (!_.isEmpty(window.audi.connection)) {
      window.audi.connection.connect();
    }
  }

  /**
   * @description Disconnect from the Jitsi Server
   */
  disconnect() {
    if (!_.isEmpty(window.audi.connection)) {
      window.audi.connection.disconnect();
    }
  }

  /**
   * Disconnect from the Jitsi Server (static method for the cleanupHook)
   */
  static disconnectForCleanupHook() {
    return () => window.audi.connection.disconnect();
  }

  /**
   * @description Disconnect from Jitsi Server (static method)
   * @returns {Promise}
   */
  static disconnect() {
    if (!_.isEmpty(window.audi.connection)) {
      return window.audi.connection.disconnect();
    }

    return Promise.reject('JitsiConnection object is undefined');
  }
}

export default JitsiConnection;
