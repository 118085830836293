import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Table, Input, Form, Popconfirm } from 'antd';
import { onFetchEvent, onUpdateEvent } from '@actions/events/EventsActions';
import { onFetchSlotsByEvent } from '@actions/groups/GroupsActions';
import IntlMessages from '../../util/IntlMessages';
import ScheduleHeader from '@routes/Schedule/ScheduleHeader';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              whitespace: true,
              message: <IntlMessages id={`schedule.table.events.${title.toLowerCase()}.errorRequired`} />
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

/**
 * @description Renders Schedule Initial view with all events table
 */
class ScheduleDetails extends React.Component {
  constructor(props) {
    super(props);
    const { intl } = this.props;
    this.formRef = React.createRef();

    this.columns = [];

    this.tableKeys = [
      'name',
      'title',
      'last_update',
      'actions',
      'operations',
    ];

    this.tableKeys.forEach((value) => {
      if (value === 'operations') {
        this.columns.push({
          title: intl.formatMessage({ id: `schedule.table.events.${value}` }),
          dataIndex: intl.formatMessage({ id: `schedule.table.events.${value}` }),
          render: (_, record) => {
            const editable = this.isEditing(record);
            return editable ? (
              <span>
                <a href="#" onClick={(e) => { e.preventDefault(); this.save(record); }} style={{ marginRight: 8 }}>
                  <IntlMessages id="schedule.table.events.operations.save" />
                </a>
                <a onClick={() => this.cancel()}>
                  <IntlMessages id="schedule.table.events.operations.cancel" />
                </a>
              </span>
            ) : (
              <a disabled={this.state.editingKey !== ''} onClick={() => this.edit(record)}>
                <IntlMessages id="schedule.table.events.operations.edit" />
              </a>
            );
          },
        });
      } else {
        this.columns.push({
          title: intl.formatMessage({ id: `schedule.table.events.${value}` }),
          minWidth: 200,
          dataIndex: value,
          editable: (value !== 'last_update') && (value !== 'actions'),
          render: (content) => this.cellRenderer(content, value),
        });
      }
    });

    this.mergedColumns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    this.state = { editingKey: '' };
  }

  /**
   * @description Function to start the "editing", so we will put
   * "Edit" anc "Cancel" options in the "Operations" column
   * @param {object} record
   */
  edit = (record) => {
    this.formRef.current.setFieldsValue({
      ...record
    });

    this.setState({ editingKey: record.key });
  }

  /**
   * @description Function to cancel the "editing"
   */
  cancel = () => {
    this.setState({ editingKey: '' });
  }

  /**
   * @description Function to check if we are editing some row at the moment"
   * @param {object} record
   * @returns {boolean}
   */
  isEditing = (record) => record.key === this.state.editingKey;

  /**
   * @description Function which is triggered when we click "save"
   * It will validate all fields and if everything is correct will make
   * req to update an event
   * @param record
   * @returns {Promise<void>}
   */
  save = async (record) => {
    const { events, onEventUpdate } = this.props;
    const eventId = record.actions;

    try {
      const row = await this.formRef.current.validateFields();
      const newData = this.dataAdapter(events);
      const index = newData.findIndex((item) => record.key === item.key);

      if (index > -1) {
        onEventUpdate(eventId, row);
        this.setState({ editingKey: '' })
      } else {
        this.setState({ editingKey: '' })
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  }
  /**
   * @description Adapts the data
   * @param {Array<Object>} data
   * @returns {Object}
   */
  dataAdapter = (data = []) => {
    const adaptedData = [];

    data.forEach((value, index) => {
      adaptedData.push({
        key: index,
        name: value.name,
        title: value.title,
        last_update: value.last_update,
        actions: value.id,
      });
    });

    return adaptedData;
  }

  /**
   * @description Invokes groups by event
   * @param {Number} id
   * @returns {void}
   */
  loadGroupsTable = (id) => {
    const { onFetchSlotsByEvent, onFetchEvent } = this.props;

    onFetchSlotsByEvent(id);
    onFetchEvent(id);
  }

  /**
   * @description Render cells in table
   * @param {string} content
   * @param {string} key
   * @returns {JSX}
   */
  cellRenderer = (content, key) => {
    let cell;

    switch (key) {
      case 'actions':
        cell = (
          <Link
            to={`/schedule/${content}/slots`}
            onClick={() => this.loadGroupsTable(content)}
          >
            <IntlMessages id="schedule.table.show_groups" />
          </Link>
        );
        break;

      default:
        cell = content
    }

    return cell;
  }

  render() {
    const { events, loading } = this.props;

    return (
      <div>
        <ScheduleHeader />
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24} className="schedule-details-col">
            <div className="audi-table">
              <Form ref={this.formRef} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    }
                  }}
                  bordered
                  dataSource={this.dataAdapter(events)}
                  columns={this.mergedColumns}
                  pagination={false}
                  loading={loading}
                />
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

ScheduleDetails.propTypes = {
  intl: PropTypes.object,
  match: PropTypes.object,
  events: PropTypes.array,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ eventsReducer }) => {
  const { events } = eventsReducer || { events: [] };
  const { loading } = eventsReducer;

  return { events, loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchSlotsByEvent: (id) => dispatch(onFetchSlotsByEvent(id)),
  onFetchEvent: (eventId) => dispatch(onFetchEvent(eventId)),
  onEventUpdate: (eventId, newEventData) => dispatch(onUpdateEvent(eventId, newEventData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withRouter(ScheduleDetails)));
