import React from 'react';

const CookieGuidelines = () => {
  return (
    <div>
      <p><strong>1. General information</strong></p>
      <p>The cookie guidelines explain which cookies we use for which
        purposes on our websites and what options you have in terms of
        how the cookies are processed.<br />
          <br />
            On our website, we use technologies that make it easier to use
            and/or that provide a variety of functions (thereby making the
            website more user-friendly, effective and secure overall). These
            technologies include cookies, pixels and scripts (hereinafter
            referred to as “cookies”).<br />
            <br />
              Cookies are small data records that are saved on your device and
              contain data such as personal website settings and login
              information. These data records are generated and sent to you by
              the web server that you connected to through your web
              browser.</p>
      <p><strong>2. How cookies are processed</strong></p>
      <p>In principle, it is possible to access our website without
        cookies. However, if you wish to make extensive use of the
        functionality of our website, you should accept cookies, which in
        turn will enable certain functions or make them more
        convenient.<br />
          <br />
            Most web browsers are set up by default to automatically accept
            cookies. However, you can object to the use of cookies at any
            time with immediate effect for the future by setting up your
            browser such that it does not accept cookies or only accepts
            certain cookies, or so that you are notified as soon as cookies
            are sent. The result may be that you cannot make full use of all
            the functions of our website. If you accept cookies in your
            browser settings, you are giving consent to the use of these
            cookies by using our website.<br />
            <br />
              Please note that the settings in your browser always only apply
              to the browser in question. If you use several browsers or
              devices, you must apply the settings again. You can also delete
              cookies from your computer’s storage at any time. Please consult
              the Help function on your browser for information on cookie
              settings and how to change them, and how to delete cookies.<br />
              <br />
                There are also software products that manage cookies for you. For
                more on cookies and how to work with cookies, please look at the
                many internet sites with helpful information.</p>
      <p><strong>3. Which cookies do we use?</strong></p>
      <p>We mainly use the following categories of cookies:</p>
      <table className="table table-condensed table-striped">
        <tbody>
        <tr style={{height: '17px' }}>
          <td style={{width: '245.717px', height: '17px' }}>
            <strong>Category</strong></td>
          <td style={{width: '738.283px', height: '17px' }}>
            <strong>Description</strong></td>
        </tr>
        <tr style={{height: '34px' }}>
          <td style={{width: '245.717px', height: '34px' }}>Essential
            cookies
          </td>
          <td style={{width: '738.283px', height: '34px' }}>These are
            essential for the website to function. Without them,
            services such as the guest management would not work.
          </td>
        </tr>
        <tr style={{height: '41.6px' }}>
          <td style={{width: '245.717px', height: '41.6px' }}>Functional
            cookies
          </td>
          <td style={{width: '738.283px', height: '41.6px' }}>These cookies
            allow a website to save information that has already been
            entered (such as user names, configurations) and provide
            the user with better and more personalized functions.
          </td>
        </tr>
        <tr style={{height: '85px' }}>
          <td style={{width: '245.717px', height: '85px' }}>Performance
            cookies
          </td>
          <td style={{width: '738.283px', height: '85px' }}>These are used
            to improve the user-friendliness of our website and hence
            the user experience. They collect information about how our
            website is used, the number of visits, average length of
            stay and the pages accessed. Performance cookies help us to
            identify which elements of our internet offering are used
            most frequently, for example, and help us further develop
            the website based on your needs.
          </td>
        </tr>
        </tbody>
      </table>
      <p>a) Storage period<br />
        <br />
          Cookies can store files on your device permanently or
          temporarily.<br />
          <br />
            What are known as permanent cookies store a file on your device
            for a set period until it expires (unless you delete it
            beforehand). This means language settings can be saved, for
            example, so you do not have to re-enter these on our website.<br />
            <br />
              Session cookies temporarily store a session ID while you are
              active on the website. This means you do not, for instance, have
              to log in again when you change pages. Session cookies are
              deleted when you log off or cease to be valid once your session
              has expired.<br />
              <br />
                b) First-party and third-party cookies<br />
                <br />
                  (1) First-party cookies<br />
                  <br />
                    First-party cookies are cookies that are placed by a controller
                    (or their processor) responsible for processing. The website
                    operator (see legal notice) is responsible for data processing.
                    The website operator processes the data collected from the cookie
                    for their own purposes independently of whether they programmed
                    the cookie themselves or use a third-party cookie.<br />
                    <br />
                      (2) Third-party cookies<br />
                      <br />
                        Third-party cookies are cookies that are placed by a third party
                        who is not identical to the operator of the website that the user
                        is currently visiting. Third-party cookies come from various
                        third-party domains that can use cookies flexibly in order to
                        track user behavior on websites outside of the offer provided by
                        the third party (especially for user-specific advertising). The
                        first time the user visits a site on which the advertising
                        network advertises, the advertisement on the website can store a
                        third-party cookie on the user’s computer. The site visits and
                        the time the user spends on the respective site are usually
                        stored. When the user visits a site on which the advertising
                        party also advertises, they can recognize the user on the basis
                        of the cookie and offer them individual advertisements from their
                        own repertoire thanks to the collected information. The third
                        party processes the data collected from the cookie for their own
                        purposes and on their own responsibility or in joint
                        responsibility with us.<br />
                        <br />
                          c) Cookies used on this website<br />
                          <br />
                            Below you will find a list of the cookies we use; this is
                            intended to inform you of the purpose and nature of the
                            respective cookies and their storage period.</p>
      <p>(1) Essential cookies</p>
      <table className="table table-condensed table-striped">
        <tbody>
        <tr style={{height: '14px' }}>
          <td style={{width: '75px', height: '14px' }}><strong>Name of
            cookie</strong></td>
          <td style={{width: '92px', height: '14px' }}>
            <strong>First-/third-party cookie</strong></td>
          <td style={{width: '188.267px', height: '14px' }}><strong>Purpose
            of cookie</strong></td>
          <td style={{width: '150.733px', height: '14px' }}><strong>Type of
            cookie (session cookie or persistent cookie)</strong></td>
        </tr>
        <tr style={{height: '5px' }}>
          <td style={{width: '75px', height: '5px' }}>Local Storage</td>
          <td style={{width: '92px', height: '5px' }}>First Party
            Cookie
          </td>
          <td>
            <div>
              Persistend data storage
            </div>
          </td>
          <td>Local Storage</td>
        </tr>
        <tr style={{ height: '11px' }}>
          <td style={{ width: '75px', height: '11px' }}>Session Storage</td>
          <td style={{ width: '92px', height: '11px' }}>First Party
            Cookie
          </td>
          <td>
            <div>
              Session data storage
            </div>
          </td>
          <td>Session cookie</td>
        </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>(2) Functional cookies: none</p>
      <p>(3) Performance cookies: none</p>
      <p><strong>&nbsp;</strong></p>
    </div>
  )
};

export default CookieGuidelines;
