import { useEffect } from 'react';
import JitsiTrack from '@jitsi/JitsiTrack';
import { useSelector } from 'react-redux';
import { useCreateLocalTracksHook } from './useCreateLocalTracksHook';

/**
 * Custom Hook which we use in the components where we are creating local tracks and add
 * them in the conference.
 *
 * Atm these component are 'Presenter' and 'Translator'
 *
 * When we get connected to the jitsi server,
 * we create and get the user local tracks -
 * ( WITH ANOTHER CUSTOM HOOK) 'useCreateLocalTracksHook',
 *
 * When we join the conference, we add the tracks made above in the conference
 *
 * @returns [] - array with the local tracks
 */
export function useLocalTracksInConferenceHook() {
  const isConnected = useSelector((state) => state.loggedUser.isConnected);
  const isInConference = useSelector((state) => state.loggedUser.isInConference);
  const changingLocalTrack = useSelector((state) => state.loggedUser.changingLocalTrack);

  /**
   * Here we use another custom hook from which we get the created local tracks
   * and a setter to lately change them (if the presenter/translator are
   * changing their devices)
   */
  const { localTracks, setLocalTracks } = useCreateLocalTracksHook();

  /**
   * Effect which we execute when we are changing our local track
   */
  useEffect(() => {
    if (changingLocalTrack && isConnected && isInConference) {
      setTimeout(() => {
        JitsiTrack.getLocalTracksFromTheConferenceWindowObject()
          .then((result) => {
            setLocalTracks(result);
          });
      }, 1500);
    }
  }, [changingLocalTrack]);

  /**
   * Effect which we execute when we join the conference
   */
  useEffect(() => {
    if (isInConference && localTracks.length > 0) {
      JitsiTrack.addLocalTracksToTheConference(localTracks);
    }
  }, [isInConference, localTracks]);

  return { localTracks };
}
