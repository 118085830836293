import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactHlsPlayer from 'react-hls-player';


const HTLPlayer = (props) => {
  const { playlistURL } = props;
  const playerRef = React.useRef();

  return (
    <ReactHlsPlayer
      playerRef={playerRef}
      src={playlistURL}
      autoPlay={true}
      controls={true}
      hlsConfig={{
        liveDurationInfinity: true
      }}
      muted={true}
      playsInline={true}
      width="100%"
      height="auto"
    />
  );
};


HTLPlayer.propTypes = {
  playlistURL: PropTypes.string.isRequired,
};


export default React.memo(HTLPlayer);