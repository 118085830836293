import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import JitsiConference from '@jitsi/JitsiConference';
import {
  hideNotification,
  showNotification,
} from '@actions/Common';
import { useFirstRender } from '../../CustomHooks/useFirstRender';
import { joinJitsiConference } from '@actions/jitsiConference/JitsiConferenceActions';
import { setHasToFetchSpeakersAudioTracks } from '@actions/conference/ConferenceActions';
import { decodeDisplayName } from "@jitsi/helper";

const SpeakersTracks = (props) => {
  const {
    speakers,
    hasToFetchSpeakersTracks,
    conferenceVolume,
    defaultSpeakerId,
    onShowNotification,
    onHideNotification,
    onSetHasToFetchSpeakersAudioTracks,
    skipDatabaseId,
    withTimeout,
  } = props;

  const [remoteAudioTracks, _setRemoteAudioTracks] = useState([]);
  const audioRefs = useRef([React.createRef(), React.createRef()]);
  const conferenceRemoteAudioTracks = React.useRef(remoteAudioTracks);
  const setConferenceRemoteAudioTracks = (data) => {
    conferenceRemoteAudioTracks.current = data;
    _setRemoteAudioTracks(data);
  };
  const isFirstRender = useFirstRender();

  /**
   * Effect which we execute when we have new audio tracks which we have to play.
   *
   * So when the moderator put someone as a speaker, he send us a message about that,
   * we check the message -> execute redux action [setHasToFetchSpeakersTracks(true)]
   * and the bottom effect gets triggered.
   *
   * <b>NOTE:</b> On the moderator side, when we add/remove someone as a speaker, we check first
   * if the adding/removing the user as a speaker on the backend is successful and ONLY then
   * we send a message. So we are sure the speakers list is up to date before executing the bottom
   * effect
   */
  useEffect(() => {
    if (hasToFetchSpeakersTracks.value && speakers && speakers.length > 0) {
      /** represent the JitsiParticipants Objects */
      const conferenceSpeakers = JitsiConference.getConferenceSpeakers(speakers);

      /** an array where we get the tracks we have to play and put them on local state */
      const conferenceSpeakersTracks = [];

      if (conferenceSpeakers && conferenceSpeakers.length > 0) {
        if (withTimeout) {
          setTimeout(() => {
            conferenceSpeakers.forEach((s) => {
              const decodedDisplayName = decodeDisplayName(s.getDisplayName());

              if (skipDatabaseId && skipDatabaseId.toString() === decodedDisplayName.databaseId) {
                return;
              }
              conferenceSpeakersTracks.push(s.getTracksByMediaType('audio')[0]);
            });

            if (conferenceSpeakersTracks.length > 0) {
              setConferenceRemoteAudioTracks(conferenceSpeakersTracks);
            }
          }, 2000);
        } else {
          conferenceSpeakers.forEach((s) => {
            const decodedDisplayName = decodeDisplayName(s.getDisplayName());

            if (skipDatabaseId && skipDatabaseId.toString() === decodedDisplayName.databaseId) {
              return;
            }
            conferenceSpeakersTracks.push(s.getTracksByMediaType('audio')[0]);
          });

          if (conferenceSpeakersTracks.length > 0) {
            setConferenceRemoteAudioTracks(conferenceSpeakersTracks);
          }
        }
      } else {
        /**
         * Here, if there are NO speakers in the speakers list for out language
         * we just loop over all audioRefs and remove the srcObject
         */
        audioRefs.current.forEach((ar) => {
          if (ar.current) {
            ar.current.srcObject = null;
          }
        });
      }
    }
  }, [hasToFetchSpeakersTracks]);

  useEffect(() => {
    if (speakers && speakers.length > 0) {
      onSetHasToFetchSpeakersAudioTracks(true);
    }
  }, [speakers]);

  /**
   * Effect which we execute when the local state containing the tracks we have to play has been changed
   */
  useEffect(() => {
    if (conferenceRemoteAudioTracks.current.length > 0) {
      conferenceRemoteAudioTracks.current.map((track) => {
        let hasAudioRefForThatParticipant = '';

        if (track) {
          hasAudioRefForThatParticipant = audioRefs.current.find((ar) => {
            if (ar && ar.current) {
              return ar.current.id === track.ownerEndpointId;
            }
            return null;
          });
        }

        if (track
          && track.getType() === 'audio'
          && hasAudioRefForThatParticipant !== ''
          && hasAudioRefForThatParticipant?.current
        ) {
          track.attach(hasAudioRefForThatParticipant.current);

          hasAudioRefForThatParticipant.current.play()
            .catch(e => {
              console.log('Audio Play error: ', e);
            });

          if (hasAudioRefForThatParticipant.current && typeof hasAudioRefForThatParticipant.current.setSinkId === 'function') {
            hasAudioRefForThatParticipant.current.setSinkId(defaultSpeakerId);
          }

          hasAudioRefForThatParticipant.current.volume = conferenceVolume;
        }
      });
    }
  }, [conferenceRemoteAudioTracks.current]);

  /**
   * Effect which we execute when the Audio Volume from the settings menu is changed
   */
  useEffect(() => {
    if (!isFirstRender) {
      audioRefs.current.forEach((audioRef) => {
        if (audioRef.current) {
          audioRef.current.volume = conferenceVolume;
        }
      });
    }
  }, [conferenceVolume]);

  useEffect(() => {
    if (!isFirstRender) {
      audioRefs.current.forEach((audioRef) => {
        if (audioRef.current) {
          audioRef.current.setSinkId(defaultSpeakerId)
            .catch((error) => {
              onShowNotification('success', 'notification.speakerChangeFail');
              onHideNotification();
            });
        }
      });
    }
  }, [defaultSpeakerId]);

  return (
    <div>
      {
        conferenceRemoteAudioTracks.current.map(
          (tr, index) => {
            if (tr) {
              return (
                <audio
                  autoPlay
                  key={tr.ownerEndpointId}
                  ref={audioRefs.current[index]}
                  id={tr.ownerEndpointId}
                  data-trid={tr.getId()}
                />
              );
            }

            return null;
          },
        )
      }
    </div>
  );
};

SpeakersTracks.propTypes = {
  speakers: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasToFetchSpeakersTracks: PropTypes.oneOfType([PropTypes.object]).isRequired,
  conferenceVolume: PropTypes.number.isRequired,
  defaultSpeakerId: PropTypes.string,
  onShowNotification: PropTypes.func.isRequired,
  onHideNotification: PropTypes.func.isRequired,
  onConferenceJoin: PropTypes.func.isRequired,
  browserName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSetHasToFetchSpeakersAudioTracks: PropTypes.func.isRequired,
  skipDatabaseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withTimeout: PropTypes.bool,
};

const mapStateToProps = ({ loggedUser, conferenceReducer, common }) => ({
  isConnected: loggedUser.isConnected,
  isInConference: loggedUser.isInConference,
  presenterId: conferenceReducer.presenterId,
  hasPresenterVideoTrack: conferenceReducer.hasPresenterVideoTrack,
  hasConferenceAudioTrack: conferenceReducer.hasConferenceAudioTrack,
  roomName: loggedUser.roomName,
  deviceType: loggedUser.deviceType,
  isOnFullScreen: loggedUser.isOnFullScreen,
  isBeingPreviewed: loggedUser.beingPreviewed,
  notificationType: common.notificationType,
  notificationMessage: common.notificationMessage,
  speakers: conferenceReducer.chosenLanguageSpeakers,
  hasToFetchSpeakersTracks: conferenceReducer.hasToFetchSpeakersAudioTracks,
  conferenceVolume: conferenceReducer.conferenceVolume,
  defaultSpeakerId: loggedUser.defaultSpeakerId,
});

const mapDispatchToProps = (dispatch) => ({
  onShowNotification: (notificationType, notificationMessage) => dispatch(showNotification(notificationType, notificationMessage)),
  onHideNotification: () => dispatch(hideNotification()),
  onConferenceJoin: () => dispatch(joinJitsiConference()),
  onSetHasToFetchSpeakersAudioTracks: (value) => dispatch(setHasToFetchSpeakersAudioTracks(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(React.memo(SpeakersTracks)));
