import {
  FETCH_GROUPS_BY_EVENT_REQUEST,
  FETCH_GROUPS_BY_EVENT_SUCCESS,
  FETCH_SLOT_FAILURE,
  FETCH_SLOT_REQUEST,
  FETCH_SLOT_SUCCESS,
  SET_SELECTED_GROUP,
  UPDATE_SLOT_REQUEST,
  UPDATE_SLOT_SUCCESS,
} from '@constants/ActionTypes';

/**
 * @description Fetch groups list request
 * @param {Number} id
 * @returns {Object}
 */
export const onFetchSlotsByEvent = (id) => {
  return {
    type: FETCH_GROUPS_BY_EVENT_REQUEST,
    id,
  };
};

/**
 * @description Fetch groups list success
 * @param {Object} payload
 * @returns {Object}
 */
export const onFetchSlotsByEventSuccess = (payload) => {
  return {
    type: FETCH_GROUPS_BY_EVENT_SUCCESS,
    payload,
  };
};

/**
 * @description Fetch slot by id
 * @param {Number} slotId
 * @returns {Object}
 */
export const fetchSlot = (slotId) => {
  return {
    type: FETCH_SLOT_REQUEST,
    slotId,
  };
};

/**
 * @description Fetch slot by id success
 * @param {Object} payload
 * @returns {Object}
 */
export const fetchSlotSuccess = (payload) => {
  return {
    type: FETCH_SLOT_SUCCESS,
    payload,
  };
};

/**
 * @description Triggers when fetching slots fails
 */
export const fetchSlotFailure = () => {
  return {
    type: FETCH_SLOT_FAILURE,
  };
};

/**
 * @description Handles UPDATE_SLOT_REQUEST
 * @param {Number} slotId
 * @param {Object} data
 * @returns {Object}
 */
export const updateSlot = (slotId, data) => {
  return {
    type: UPDATE_SLOT_REQUEST,
    slotId,
    data,
  };
};

/**
 * @description Handles UPDATE_SLOT_SUCCESS
 * @param {Object} payload
 * @returns {Object}
 */
export const updateSlotSuccess = (payload) => {
  return {
    type: UPDATE_SLOT_SUCCESS,
    payload,
  };
};

export const setSelectedGroup = (groupId) => ({
  type: SET_SELECTED_GROUP,
  groupId,
});
