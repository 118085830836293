import {
  ADD_EVENT_REQUEST,
  ADD_EVENT_SUCCESS,
  FETCH_ALL_EVENTS_REQUEST,
  FETCH_ALL_EVENTS_SUCCESS,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  SET_SELECTED_EVENT,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_REQUEST_SUCCESS,
} from '@constants/ActionTypes';
import _ from 'lodash';

const INIT_STATE = {
  events: [],
  selectedEvent: {},
  loading: false,
};

/**
 * Function to set the 'selectedEvent'
 * @param state
 * @param eventId
 * @returns {*}
 */
const onSetSelectedEvent = (state, eventId) => {
  const newState = _.cloneDeep(state);
  const selectedEvent = newState.events.find((e) => e.id === eventId);

  if (selectedEvent) {
    newState.selectedEvent = selectedEvent;
  }

  return { ...state, ...newState };
};

/**
 * @description Events reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case FETCH_ALL_EVENTS_REQUEST:
      newState = { loading: true };
      return { ...state, ...newState };

    case FETCH_ALL_EVENTS_SUCCESS:
      const events = Object.keys(action.payload).map((key) => action.payload[key]);
      newState = { loading: false, events };
      return { ...state, ...newState };

    case FETCH_EVENT_REQUEST:
      return Object.assign(state, {
        loading: true,
        selectedEventId: null,
      });

    case FETCH_EVENT_SUCCESS:
      newState = { loading: false, selectedEvent: action.payload };
      return { ...state, ...newState };

    case UPDATE_EVENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case UPDATE_EVENT_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case ADD_EVENT_REQUEST:
      return _.cloneDeep(state);

    case ADD_EVENT_SUCCESS:
      return _.cloneDeep(state);

    case SET_SELECTED_EVENT:
      return onSetSelectedEvent(state, action.eventId);

    default:
      return state;
  }
};
