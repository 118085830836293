import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DeviceTypeSelect from '@components/audi/DeviceTypeSelect/DeviceTypeSelect';
import { JITSI_DEVICES_TYPES_MAP } from '@constants/Settings';
import { setHasMutedLocalAudioTrack, setHasMutedLocalVideoTrack } from '@actions/loggedUser/LoggedUserActions';
import { Button } from 'antd';

const DevicesSelectors = (props) => {
  const {
    withVideoDeviceSelect = false,
    withAudioDeviceSelect = false,
    withSpeakersDeviceSelect = false,
    withVideoDeviceMuteButton = false,
    withAudioDeviceMuteButton = false,
    devices,
    hasMutedLocalAudioTrack,
    hasMutedLocalVideoTrack,
    onSetHasMutedLocalVideoTrack,
    onSetHasMutedLocalAudioTrack,
  } = props;

  const doesBrowserSupportSpeakerChange = () => {
    const audio = new Audio();

    return typeof audio.setSinkId === 'function';
  };

  const shouldDisplayDeviceSelect = (deviceType) => {
    switch (deviceType) {
      case 'audioinput':
        return withAudioDeviceSelect;
      case 'audiooutput':
        return withSpeakersDeviceSelect && doesBrowserSupportSpeakerChange();
      case 'videoinput':
        return withVideoDeviceSelect;
      default:
        return false;
    }
  };

  return (
    <div>
      {Object.keys(devices)
        .map(
          (deviceType) => (
            <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 auto' }} key={deviceType}>
              {
                shouldDisplayDeviceSelect(deviceType)
                  ? (
                    <DeviceTypeSelect
                      deviceType={JITSI_DEVICES_TYPES_MAP[deviceType]}
                      devices={devices[deviceType]}
                    />
                  )
                  : null
              }
              {
                deviceType === JITSI_DEVICES_TYPES_MAP.audioinput.key && withAudioDeviceMuteButton && withAudioDeviceSelect
                  ? (
                    <Button
                      type="dashed"
                      shape="round"
                      onClick={() => onSetHasMutedLocalAudioTrack(!hasMutedLocalAudioTrack)}
                      style={{ minWidth: '80px', lineHeight: '20px', marginLeft: 'auto' }}
                      size="small"
                      key={JITSI_DEVICES_TYPES_MAP[deviceType].label}
                    >
                      {hasMutedLocalAudioTrack ? 'UNMUTE' : 'MUTE'}
                    </Button>
                  )
                  : null
              }
              {
                deviceType === JITSI_DEVICES_TYPES_MAP.videoinput.key && withVideoDeviceMuteButton && withVideoDeviceSelect
                  ? (
                    <Button
                      type="dashed"
                      shape="round"
                      onClick={() => onSetHasMutedLocalVideoTrack(!hasMutedLocalVideoTrack)}
                      size="small"
                      style={{ minWidth: '80px', lineHeight: '20px', marginLeft: 'auto' }}
                    >
                      {hasMutedLocalVideoTrack ? 'UNMUTE' : 'MUTE'}
                    </Button>
                  )
                  : null
              }
            </div>
          ),
        )}
    </div>
  );
};

DevicesSelectors.propTypes = {
  withVideoDeviceSelect: PropTypes.bool.isRequired,
  withAudioDeviceSelect: PropTypes.bool.isRequired,
  withSpeakersDeviceSelect: PropTypes.bool.isRequired,
  withVideoDeviceMuteButton: PropTypes.bool.isRequired,
  withAudioDeviceMuteButton: PropTypes.bool.isRequired,
  devices: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hasMutedLocalAudioTrack: PropTypes.bool.isRequired,
  hasMutedLocalVideoTrack: PropTypes.bool.isRequired,
  onSetHasMutedLocalVideoTrack: PropTypes.func.isRequired,
  onSetHasMutedLocalAudioTrack: PropTypes.func.isRequired,
};

const mapStateToProps = ({ loggedUser }) => ({
  devices: loggedUser.devices,
  hasMutedLocalAudioTrack: loggedUser.hasMutedLocalAudioTrack,
  hasMutedLocalVideoTrack: loggedUser.hasMutedLocalVideoTrack,
});

const mapDispatchToProps = (dispatch) => ({
  onSetHasMutedLocalVideoTrack: (hasMutedLocalVideoTrack) => dispatch(setHasMutedLocalVideoTrack(hasMutedLocalVideoTrack)),
  onSetHasMutedLocalAudioTrack: (hasMutedLocalAudioTrack) => dispatch(setHasMutedLocalAudioTrack(hasMutedLocalAudioTrack)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DevicesSelectors);
