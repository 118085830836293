import { all } from 'redux-saga/effects';
import EventsSaga from '@sagas/events/EventsSaga';
import GroupsSaga from '@sagas/groups/GroupsSaga';
import UsersSaga from '@sagas/users/UsersSaga';
import LoggedUserSaga from '@sagas/loggedUser/LoggedUserSaga';
import QuestionsSaga from '@sagas/questions/QuestionsSaga';
import JitsiConnectionSaga from '@sagas/jitsiConnection/JitsiConnectionSaga';
import JitsiConferenceSaga from '@sagas/jitsiConference/JitsiConferenceSaga';
import ConferenceSaga from '@sagas/conference/ConferenceSaga';
import PredefinedMessagesSaga from '@sagas/predefinedMessages/PredefinedMessagesSaga';
import MucsSaga from '@sagas/mucs/MucsSaga';
import authSagas from './Auth';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    EventsSaga(),
    GroupsSaga(),
    UsersSaga(),
    LoggedUserSaga(),
    QuestionsSaga(),
    JitsiConnectionSaga(),
    JitsiConferenceSaga(),
    PredefinedMessagesSaga(),
    ConferenceSaga(),
    MucsSaga(),
  ]);
}
