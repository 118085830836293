import {
  FETCH_PREDEFINED_MESSAGES_REQUEST,
  FETCH_PREDEFINED_MESSAGES_SUCCESS,
  ADD_PREDEFINED_MESSAGE,
  ADD_PREDEFINED_MESSAGE_SUCCESS,
  SAVE_PREDEFINED_MESSAGE,
  SAVE_PREDEFINED_MESSAGE_SUCCESS,
  DELETE_PREDEFINED_MESSAGE,
  DELETE_PREDEFINED_MESSAGE_SUCCESS,
} from '@constants/ActionTypes';

const INIT_STATE = {
  predefinedMessages: [],
  loading: false,
};

/**
 * @description Modify state on add new message in list
 * @param {Object} state
 * @param {Object} message
 * @returns {Object}
 */
function addPredefinedMessage(state, message) {
  let newState = {};

  const updatedList = [...state.predefinedMessages, message];
  newState = { loading: false, predefinedMessages: updatedList };

  return { ...state, ...newState };
}

/**
 * @description Modify state on delete message from list
 * @param {Object} state
 * @param {Number} messageId
 * @returns {Object}
 */
function deletePredefinedMessage(state, messageId) {
  let newState = {};

  const updatedList = state.predefinedMessages.filter((message) => message.id !== messageId);
  newState = { loading: false, predefinedMessages: updatedList };

  return { ...state, ...newState };
}

/**
 * @description Predefined messages reducer
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
export default (state = INIT_STATE, action) => {
  let newState;

  switch (action.type) {
    case FETCH_PREDEFINED_MESSAGES_REQUEST:
      newState = { loading: true };
      return { ...state, ...newState };

    case FETCH_PREDEFINED_MESSAGES_SUCCESS:
      newState = { loading: false, predefinedMessages: action.payload };
      return { ...state, ...newState };

    case ADD_PREDEFINED_MESSAGE:
      newState = { loading: true };
      return { ...state, ...newState };

    case ADD_PREDEFINED_MESSAGE_SUCCESS:
      addPredefinedMessage(state, action.message);
      break;

    case DELETE_PREDEFINED_MESSAGE:
      newState = { loading: true };
      return { ...state, ...newState };

    case DELETE_PREDEFINED_MESSAGE_SUCCESS:
      deletePredefinedMessage(state, action.messageId);
      break;

    case SAVE_PREDEFINED_MESSAGE:
      return {
        ...state,
        loading: false,
      };

    case SAVE_PREDEFINED_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
