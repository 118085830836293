import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Checkbox,
  Modal,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import IntlMessages from '@util/IntlMessages';
import {
  JITSI_PARTICIPANT_CONNECTION_STATUSES,
  JITSI_PARTICIPANT_VISITOR_PREVIEW_MESSAGES,
  PARTICIPANTS_TABLE,
  USER_ROLES,
} from '@constants/Settings';
import { connect } from 'react-redux';
import {
  checkParticipantSuccess,
  setParticipantLiveAudio,
  setParticipantLiveVideo,
  toggleParticipantChat,
} from '@actions/users/UsersActions';
import { chatSoundNotification } from '@actions/loggedUser/LoggedUserActions';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandPointer,
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons';
import {
  GlobalOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { faHandPointer as farHandPointer } from '@fortawesome/free-regular-svg-icons';
import JitsiConference from '@jitsi/JitsiConference';
import { ReactComponent as ChatIcon } from '@assets/images/actions-icons/chat_icon_blue.svg';
import { ReactComponent as ChatIconFilled } from '@assets/images/actions-icons/chat_icon_blue_filled.svg';
import {
  hideNotification,
  showNotification,
} from '@actions/Common';
import { openPreviewWindow } from '@jitsi/helper';
import ParticipantConnStatusIndicator
  from '@components/audi/ParticipantConnStatusIndicator/ParticipantConnStatusIndicator';
import TableLegend from './TableLegend';
import ParticipantChat from '../Chat/ParticipantChat';
import noSoundAudio from '../../../assets/sounds/no-sound.mp3';
import { chatObjectToArray } from '../../../CustomHooks/HelperFuncs';

const shouldTheLiveVideoSwitchBeDisabled = (record) => ((!record.camera || !record.microphone) && !record.liveAV)
    || (record.status === PARTICIPANTS_TABLE.rowStatusClasses.offline && !record.liveAV)
    || (record.connStatus !== JITSI_PARTICIPANT_CONNECTION_STATUSES.ACTIVE && !record.liveAV);

const shouldTheLiveAudioSwitchBeDisabled = (record) => (!record.liveAudio && !record.microphone)
    || (record.status === PARTICIPANTS_TABLE.rowStatusClasses.offline && !record.liveAudio)
    || (record.connStatus !== JITSI_PARTICIPANT_CONNECTION_STATUSES.ACTIVE && !record.liveAudio);

/**
 * Helper function the choose the correct "ParticipantType" icon depending on backend participant properties
 */
const getParticipantTypeComponent = (participantTypeProp, intl) => {
  let tooltipText = intl?.formatMessage({ id: 'participants.table.guest' });
  let icon = <StarOutlined className="tableRow__icon" />;

  switch (participantTypeProp) {
    case USER_ROLES.stage:
      tooltipText = intl?.formatMessage({ id: 'participants.table.host' });
      icon = <StarFilled className="tableRow__icon" />;
      break;
    case USER_ROLES.translator:
      tooltipText = intl?.formatMessage({ id: 'participants.table.translator' });
      icon = <GlobalOutlined className="tableRow__icon" />;
      break;
    default:
      break;
  }

  return (<Tooltip title={tooltipText} color="blue">{icon}</Tooltip>);
};

/**
 * Helper function the choose the correct "Microphone" icon depending on backend
 * participant properties
 */
const getMicrophoneComponent = (participantMicrophoneProp, intl) => {
  const tooltipText = participantMicrophoneProp ? intl?.formatMessage({ id: 'participants.table.microphoneON' }) : intl?.formatMessage({ id: 'participants.table.microphoneOFF' });
  const microphoneIcon = participantMicrophoneProp
    ? <FontAwesomeIcon className="tableRow__icon" icon={faMicrophone} />
    : <FontAwesomeIcon className="tableRow__icon" icon={faMicrophoneSlash} />;

  return (
    <Tooltip title={tooltipText} color="blue">
      {microphoneIcon}
    </Tooltip>
  );
};

/**
 * Helper function the choose the correct "Camera" icon depending on backend
 * participant properties
 */
const getCameraComponent = (participantCameraProp, intl) => {
  const tooltipText = participantCameraProp ? intl?.formatMessage({ id: 'participants.table.cameraON' }) : intl?.formatMessage({ id: 'participants.table.cameraOFF' });
  const cameraIcon = participantCameraProp ? <FontAwesomeIcon className="tableRow__icon" icon={faVideo} />
    : <FontAwesomeIcon className="tableRow__icon" icon={faVideoSlash} />;

  return (
    <Tooltip title={tooltipText} color="blue">
      {cameraIcon}
    </Tooltip>
  );
};

/**
 * Helper function the choose the correct "Hand" icon depending on backend participant properties
 */
const getRaiseHandComponent = (participantRaiseHandProp, intl) => {
  const tooltipText = participantRaiseHandProp ? intl?.formatMessage({ id: 'participants.table.raisedHand' }) : intl?.formatMessage({ id: 'participants.table.raisedHandNot' });
  const handIcon = participantRaiseHandProp ? <FontAwesomeIcon className="tableRow__icon" icon={faHandPointer} />
    : <FontAwesomeIcon className="tableRow__icon" icon={farHandPointer} />;

  return (
    <Tooltip title={tooltipText} color="blue">
      {handIcon}
    </Tooltip>
  );
};

const DataTable = (props) => {
  const {
    intl, participants, loading, onCheckParticipant, onLiveVideoChange, onLiveAudioChange, users,
    loggedUserDatabaseId, selectedGroup, onToggleParticipantChat, onChatSoundNotification,
    onShowNotification, onHideNotification,
  } = props;

  const [isModalOpened, setIsModalOpened] = useState(false);
  const audio = new Audio(noSoundAudio);

  useEffect(() => {
    const isModalShown = sessionStorage.getItem('hasToShowSoundConfirmation');
    if (isModalShown && isModalShown === 'firstRender') {
      setIsModalOpened(true);
      sessionStorage.setItem('hasToShowSoundConfirmation', 'notFirstRender');
    }
  }, []);

  /**
   * @description Handles onOk click on modal
   * @returns {void}
   */
  const handleModalOk = useCallback(() => {
    audio.play();
    onChatSoundNotification(true);
    setIsModalOpened(false);
  }, []);

  /**
   * @description Handles onCancel click on modal
   * @returns {void}
   */
  const handleModalCancel = useCallback(() => {
    setIsModalOpened(false);
    onChatSoundNotification(false);
  }, []);

  /**
   * Function to trigger "Live Video change" action (When click OK on popconfirm)
   */
  const liveVideoConfirm = useCallback((record) => {
    if (record.participantType !== USER_ROLES.stage) {
      onLiveVideoChange(record);
    } else {
      setTimeout(() => {
        onShowNotification('warning', 'notification.participantPresenterLiveStatusChange');
        onHideNotification();
      }, 50);
    }
  }, []);

  /**
   * Function to trigger "LiveAudio change" action (When click OK on popconfirm)
   */
  const liveAudioConfirm = useCallback((record) => {
    if (record.participantType !== USER_ROLES.stage) {
      onLiveAudioChange(record);
    } else {
      setTimeout(() => {
        onShowNotification('warning', 'notification.participantPresenterLiveStatusChange');
        onHideNotification();
      }, 50);
    }
  }, []);

  /**
   * @description The function which we execute when 'Preview' on particular participant is clicked.
   *
   * @description First we check if the chosen participant has 'userConfId' in the redux.
   * (so if we click 'Preview' on participant who is not online or who is not visitor, nothing gonna happen)
   *
   * @description After the check if everything is OK, we send a private message to the participant, which includes
   * the command to 'startPreview_${someUUDV4 code constant}'.
   *
   * @description After that we open a new window where we load the preview component
   * (in the URL of the new window we include the 'participantId', 'roomName')
   * @param e
   * @param record
   */
  const startPreview = useCallback((e, record) => {
    e.preventDefault();

    if (record.userConfId
      && record.participantType === USER_ROLES.visitor
      && JitsiConference.checkIfParticipantIsOnlineAndActiveConnStatus(record.userConfId)
    ) { // record.star === true IF the user is the presenter
      JitsiConference
        .sendPrivateSystemMessage(JITSI_PARTICIPANT_VISITOR_PREVIEW_MESSAGES.startPreview, record.userConfId);

      setTimeout(() => {
        openPreviewWindow(
          `${window.location.origin}/preview?room=${selectedGroup.uid}&previewedUserId=${record.userConfId}`,
          window,
          800,
          600,
        );
      }, 1000);
    }
  }, []);

  /**
   * @description Search for a user with unread message
   * @param {Object} tableRowUser
   * @returns {bool}
   */
  const findUserWithUnreadMessage = (tableRowUser) => {
    let check = false;
    if (tableRowUser.userConfId) {
      const currentUser = users.find(
        (user) => user.confProps.userConfId === tableRowUser.userConfId,
      );
      if (currentUser && currentUser.confProps.conversation) {
        const userConversation = chatObjectToArray(currentUser.confProps.conversation);
        const findUnreadMessage = userConversation.find((message) => message.read === false);
        if (findUnreadMessage && !currentUser.chatOpened) {
          check = true;
        }
      }
    }

    return check;
  };

  /**
   * Table columns array
   */
  const columns = [
    {
      title: 'ParticipantConnectionStatus',
      dataIndex: 'ParticipantConnectionStatus',
      key: 'ParticipantConnectionStatus',
      width: '30px',
      className: PARTICIPANTS_TABLE.columnClassConnStatus,
      shouldCellUpdate: (record, prevRecord) => record.connStatus !== prevRecord.connStatus,
      render: (text, record) => (
        <ParticipantConnStatusIndicator connStatus={record.connStatus} participantId={record.userConfId} />
      ),
    },
    {
      title: 'ParticipantType',
      dataIndex: 'ParticipantType',
      key: 'ParticipantType',
      width: '30px',
      className: PARTICIPANTS_TABLE.columnClass,
      shouldCellUpdate: (record, prevRecord) => record.participantType !== prevRecord.participantType,
      render: (text, record) => (
        getParticipantTypeComponent(record.participantType)
      ),
    },
    {
      title: 'Checked',
      dataIndex: 'checked',
      key: 'checked',
      width: '30px',
      className: PARTICIPANTS_TABLE.columnClass,
      // shouldCellUpdate: (record, prevRecord) => record.checked !== prevRecord.checked,
      render: (text, record) => (
        <Checkbox className="tableRow__checkbox" checked={record.checked} onChange={() => onCheckParticipant(record.key)} />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: PARTICIPANTS_TABLE.columnClass,
      shouldCellUpdate: (record, prevRecord) => record.name !== prevRecord.name,
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      className: PARTICIPANTS_TABLE.columnClass,
      shouldCellUpdate: (record, prevRecord) => record.companyName !== prevRecord.companyName,
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      className: PARTICIPANTS_TABLE.columnClass,
      shouldCellUpdate: (record, prevRecord) => record.group !== prevRecord.group,
    },
    {
      title: 'Language',
      dataIndex: 'language',
      width: '30px',
      key: 'language',
      className: PARTICIPANTS_TABLE.columnClass,
      shouldCellUpdate: (record, prevRecord) => record.language !== prevRecord.language,
    },
    {
      title: 'Preview',
      dataIndex: 'preview',
      key: 'preview',
      className: PARTICIPANTS_TABLE.columnClass,
      shouldCellUpdate: () => true,
      render: (text, record) => {
        if ((record.camera || record.microphone) && record.participantType !== USER_ROLES.stage) {
          return (
            <a onClick={(e) => startPreview(e, record)}>
              <IntlMessages id="participants.table.preview" />
            </a>
          );
        }

        return <a style={{ color: 'gray', cursor: 'default' }}><IntlMessages id="participants.table.preview" /></a>;
      },
    },
    {
      title: 'Live Video',
      dataIndex: 'liveAV',
      key: 'liveAV',
      className: PARTICIPANTS_TABLE.columnClass,
      width: '150px',
      shouldCellUpdate: () => true,
      render: (text, record) => (
        <Popconfirm
          title={intl.formatMessage({ id: 'participants.table.liveAV.confirm' })}
          onConfirm={() => liveVideoConfirm(record)}
          disabled={shouldTheLiveVideoSwitchBeDisabled(record)}
        >
          <Space align="center">
            <IntlMessages id="participants.table.liveAV" />
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={record.liveAV}
              size="small"
              disabled={shouldTheLiveVideoSwitchBeDisabled(record)}
            />
          </Space>
        </Popconfirm>
      ),
    },
    {
      title: 'Live Audio',
      dataIndex: 'liveAudio',
      key: 'liveAudio',
      className: PARTICIPANTS_TABLE.columnClass,
      width: '150px',
      shouldCellUpdate: () => true,
      render: (text, record) => (
        <Popconfirm
          title={intl.formatMessage({ id: 'participants.table.liveAudio.confirm' })}
          onConfirm={() => liveAudioConfirm(record)}
          disabled={shouldTheLiveAudioSwitchBeDisabled(record)}
        >
          <Space align="center">
            <IntlMessages id="participants.table.liveAudio" />
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={record.liveAudio}
              size="small"
              disabled={shouldTheLiveAudioSwitchBeDisabled(record)}
            />
          </Space>
        </Popconfirm>
      ),
    },
    {
      title: 'Microphone',
      dataIndex: 'microphone',
      width: '40px',
      key: 'microphone',
      className: PARTICIPANTS_TABLE.columnClass,
      shouldCellUpdate: (record, prevRecord) => record.microphone !== prevRecord.microphone,
      render: (text, record) => getMicrophoneComponent(record.microphone, intl),
    },
    {
      title: 'Camera',
      dataIndex: 'camera',
      key: 'camera',
      width: '40px',
      className: PARTICIPANTS_TABLE.columnClass,
      shouldCellUpdate: (record, prevRecord) => record.camera !== prevRecord.camera,
      render: (text, record) => getCameraComponent(record.camera, intl),
    },
    {
      title: 'Rise Hand',
      dataIndex: 'riseHand',
      width: '40px',
      key: 'riseHand',
      className: PARTICIPANTS_TABLE.columnClass,
      shouldCellUpdate: (record, prevRecord) => record.handRaised !== prevRecord.handRaised,
      render: (text, record) => getRaiseHandComponent(record.handRaised, intl),
    },
    {
      title: 'Chat',
      dataIndex: 'chat',
      width: '40px',
      key: 'chat',
      className: 'tableRow__column__last',
      render: (text, record) => (
        <Button
          className="tableRow__chatButton"
          onClick={() => onToggleParticipantChat(record.key)}
        >
          {!record.chatOpened
            ? (
              <Badge
                dot={findUserWithUnreadMessage(record)}
                offset={[-10, 10]}
              >
                <div style={{ width: '40px' }}>
                  <ChatIcon className="tableRow__icon" />
                </div>
              </Badge>
            )
            : <ChatIconFilled />}
        </Button>
      ),
    },
  ];

  const dataSource = participants.tableData
    .filter((p) => p.user_id !== loggedUserDatabaseId)
    .map((p) => ({
      key: p.user_id,
      userConfId: p.confProps.userConfId,
      uid: p.uid,
      participantType: p.confProps.userRole,
      checked: p.checked,
      name: `${p.first_name} ${p.last_name}`,
      companyName: p.company,
      group: p.user_group,
      language: p.confProps.userConfLang || p.language,
      liveAV: p.confProps.liveAV,
      liveAudio: p.confProps.liveAudio,
      microphone: p.confProps.microphone,
      camera: p.confProps.camera,
      handRaised: p.confProps.handRaised,
      status: p.confProps.status,
      chatOpened: p.chatOpened,
      connStatus: p.confProps.connStatus,
    }));

  /**
   * @description Check if is opened chat with some user
   * @returns {bool}
   */
  const findOpenedChatUser = users.find((user) => user.chatOpened);

  return (
    <div>
      <Modal
        title="Notifications"
        visible={isModalOpened}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>
          <IntlMessages id="participants.chat.modal" />
        </p>
      </Modal>
      <div className={`participantsTableAndChat${findOpenedChatUser ? '__flex' : '__block'}`}>
        <div>
          <Table
            className={`tableWrapper ${findOpenedChatUser ? 'tableWrapper_chatOpened' : ''}`}
            rowClassName={(record) => [PARTICIPANTS_TABLE.rowClass, `${PARTICIPANTS_TABLE.rowClass}--${record.status}`]}
            dataSource={dataSource}
            columns={columns}
            showHeader={false}
            pagination={false}
            scroll={{ x: true }}
            loading={{
              spinning: loading,
              size: 'large',
              tip: `${intl.formatMessage({ id: 'participants.table.loadingSpinner' })}`,
            }}
          />
          {users.length > 0 ? <TableLegend /> : null}
        </div>
        <div style={{ flexGrow: 6 }}>
          {findOpenedChatUser
            ? (
              <ParticipantChat
                conversation={chatObjectToArray(findOpenedChatUser.confProps.conversation)}
                userUid={findOpenedChatUser.uid}
                intl={intl}
              />
            )
            : null}
        </div>
      </div>
    </div>
  );
};

DataTable.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onLiveVideoChange: PropTypes.func.isRequired,
  onLiveAudioChange: PropTypes.func.isRequired,
  onCheckParticipant: PropTypes.func.isRequired,
  loggedUserDatabaseId: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  participants: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectedGroup: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onToggleParticipantChat: PropTypes.func.isRequired,
  onChatSoundNotification: PropTypes.func,
  onShowNotification: PropTypes.func.isRequired,
  onHideNotification: PropTypes.func.isRequired,
};

const mapStateToProps = ({ usersReducer, loggedUser, groupsReducer }) => ({
  users: usersReducer.users,
  loggedUserDatabaseId: loggedUser.databaseId,
  selectedGroup: groupsReducer.selectedGroup,
  /**
   * participants - represents the data from the usersReducer which is related and used only in
   * the 'Participants Page' but still related with the 'Users'.
   */
  participants: usersReducer.participantsPage,
  loading: usersReducer.loading,
});

const mapDispatchToProps = (dispatch) => ({
  onLiveVideoChange: (record) => dispatch(setParticipantLiveVideo(record)),
  onLiveAudioChange:
    (record) => dispatch(setParticipantLiveAudio(record)),
  onCheckParticipant: (recordId) => dispatch(checkParticipantSuccess(recordId)),
  onToggleParticipantChat: (payload) => dispatch(toggleParticipantChat(payload)),
  onChatSoundNotification: (permission) => dispatch(chatSoundNotification(permission)),
  onShowNotification: (notificationType, notificationMessage) => dispatch(showNotification(notificationType, notificationMessage)),
  onHideNotification: () => dispatch(hideNotification()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DataTable));
