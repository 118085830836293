import {delay, put, select} from 'redux-saga/effects';
import {
  JITSI_MUTE_MESSAGES,
  JITSI_OTHER_MESSAGES,
  JITSI_SPEAKER_MESSAGES,
  USER_ROLES,
  VIDEO_QUALITY
} from '@constants/Settings';
import {
  checkIfUserIsModeratorByUserId,
  checkIfUserIsWithParticularRole,
  checkIfUserWhoHaveJoinedIsInTheListOfSpeakers
} from '@jitsi/helper';
import {setBeingSpeaker, setHasMutedLocalAudioTrack} from '@actions/loggedUser/LoggedUserActions';
import {hideNotification, showNotification} from '@actions/Common';
import JitsiConference from '@jitsi/JitsiConference';
import {
  moderatorHasJoined,
  moderatorHasLeft,
  presenterHasJoined,
  presenterHasLeft,
  setHasToFetchSpeakersAudioTracks,
  setVisitorsSpeakers
} from '@actions/conference/ConferenceActions';

/**
 * TRANSLATOR ROLE Generator which we execute when a the translator has received private message
 * @param adaptedMessage
 */
export function* conferencePrivateMessageReceivedGeneratorTranslator(adaptedMessage) {
  const isTheSenderModerator = yield checkIfUserIsModeratorByUserId(
    adaptedMessage.userId,
  );

  if (adaptedMessage.message === JITSI_SPEAKER_MESSAGES.startSpeaker
    && isTheSenderModerator) {
    yield put(setBeingSpeaker(true, false));
  } else if (adaptedMessage.message === JITSI_SPEAKER_MESSAGES.endSpeaker
    && isTheSenderModerator) {
    yield put(setBeingSpeaker(false, false));
  } else if (adaptedMessage.message === JITSI_MUTE_MESSAGES.muteAudio
    && isTheSenderModerator) {
    yield put(setHasMutedLocalAudioTrack(true));
  } else if (adaptedMessage.message === JITSI_OTHER_MESSAGES.alreadyInConference) {
    yield put(showNotification('warning', 'notification.alreadyInConference', 0)); //using intl id for the message
    yield put(hideNotification());
  }
}

/**
 * TRANSLATOR Generator function which we execute when some user has left the conference
 * @param user
 */
export function* conferenceUserLeftGeneratorTranslator(user) {
  const currentVisitorsSpeakers = yield select((state) => state.conferenceReducer.visitorsSpeakers);

  if (checkIfUserIsWithParticularRole(user, USER_ROLES.stage)) {
    /**
     * The IF below is used on the situation where the presenter account joins
     * the conference for second time
     */
    if (!JitsiConference.checkIfThereIsAPresenterInTheConference()) {
      yield put(presenterHasLeft());
      yield put(showNotification('warning', 'notification.presenterOffline')); //using intl id for the message
      yield put(hideNotification());
    }
  } else if (checkIfUserIsWithParticularRole(user, USER_ROLES.moderator)) {
    yield put(moderatorHasLeft(user.getId()));
    yield put(showNotification('warning', 'notification.moderatorOffline')); //using intl id for the message
    yield put(hideNotification());
  } else if (currentVisitorsSpeakers.find((s) => s === user.getId())) {
    yield put(setVisitorsSpeakers([]));
  }
}

/**
 * TRANSLATOR Generator function which we execute when user joins the conference
 * @param joinedUser
 */
export function* conferenceUserJoinedGeneratorTranslator(joinedUser) {
  const speakers = yield select((state) => state.conferenceReducer.chosenLanguageSpeakers);

  if (checkIfUserIsWithParticularRole(joinedUser, USER_ROLES.stage)) {
    yield put(presenterHasJoined(joinedUser.getId()));
    yield put(showNotification('info', 'notification.presenterOnline')); //using intl id for the message
    yield put(hideNotification());
    yield JitsiConference.setReceiverVideoConstraint(VIDEO_QUALITY.Hd);
  } else if (checkIfUserIsWithParticularRole(joinedUser, USER_ROLES.moderator)) {
    yield put(moderatorHasJoined(joinedUser.getId()));
    yield put(showNotification('info', 'notification.moderatorOnline')); //using intl id for the message
    yield put(hideNotification());
  } else if (checkIfUserWhoHaveJoinedIsInTheListOfSpeakers(joinedUser, speakers)
    && !checkIfUserIsWithParticularRole(joinedUser, USER_ROLES.visitor)) {
    yield delay(700);
    yield put(setHasToFetchSpeakersAudioTracks(true));
  }
}
