import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, } from 'antd';
import { injectIntl } from 'react-intl';
import { clearLiveParticipants } from '@actions/users/UsersActions';
import { connect } from 'react-redux';
import AddUserModal from '@routes/Participants/AddUserModal/AddUserModal';
import _ from 'lodash';

const ActionsBottom = (props) => {
  const { intl, participants, users, loggedUserDatabaseId } = props;
  const [visible, setVisible] = useState(false);
  const [onlineCount, setOnlineCount] = useState(0);
  const [totalParticipantsCount, setTotalParticipantsCount] = useState(0);

  /**
   * Effect which counts how many users are with one of the statuses
   * ['online', 'signed', 'onlineRaised'] so we show the count
   * Ignores moderators and users with company name 'LMC-Test' or usergroup 'Test'
   */
  useEffect(() => {
    let LandingUsers = 0;

    const usersOnline = users.filter((p) => _.includes(['online', 'signed', 'onlineRaised'], p.confProps.status));
    const usersOnlineCount = _.countBy(usersOnline, (p) => (p.landing_page && !(p.company === 'LMC-Test')
      && !(p.user_group === 'Test')));

    users.forEach((e) => {
      if (e.landing_page && e.company !== 'LMC-Test' && e.user_group !== 'Test') LandingUsers += 1;
    });

    setTotalParticipantsCount(LandingUsers);
    setOnlineCount(usersOnlineCount.true || 0);
  }, [participants]);

  return (
    <Row className={['actionsWrapper', 'actionsWrapper--bottom'].join(' ')} justify="space-between" align="middle">
      <AddUserModal
        intl={intl}
        visible={visible}
        hideModal={() => setVisible(false)}
      />

      <Col>
        <Row justify="flex-start" align="top" gutter={[20, 60]} className="actionsWrapper__actionsSubRow">
          <Col>
            <span>{`${intl.formatMessage({ id: 'participants.actionsBottom.userOnline' })} ${onlineCount}/${totalParticipantsCount || 0}`}</span>
          </Col>

         {/* <Col> -------- HIDDEN ELEMENT FOR NOW -------------
            <Button className="actionsWrapper__button" type="primary" ghost size="small" onClick={() => setVisible(true)}><IntlMessages id="participants.actionsBottom.addUser" /></Button>
          </Col>*/}
        </Row>
      </Col>

      {/*<Col> -------- HIDDEN ELEMENTS FOR NOW -------------
        <Row justify="flex-start" gutter={[20, 60]} className="actionsWrapper__actionsSubRow">
          <Col>
            <Button className="actionsWrapper__button" type="primary" ghost size="small"><IntlMessages id="participants.actionsBottom.takePreview" /></Button>
          </Col>

          <Col>
            <Popconfirm
              title={intl.formatMessage({ id: 'participants.actionsBottom.clearPreview.confirm' })}
              onConfirm={() => console.log('cleared preview')}
            >
              <Button className="actionsWrapper__button" type="primary" ghost size="small"><IntlMessages id="participants.actionsBottom.clearPreview" /></Button>
            </Popconfirm>
          </Col>

          <Col>
            <Popconfirm
              title={intl.formatMessage({ id: 'participants.actionsBottom.clearLive.confirm' })}
              onConfirm={() => props.onClearLiveParticipants(participants.tableData)}
            >
              <Button className="actionsWrapper__button" type="primary" ghost size="small"><IntlMessages id="participants.actionsBottom.clearLive" /></Button>
            </Popconfirm>
          </Col>
        </Row>
      </Col>*/}
    </Row>
  );
};

ActionsBottom.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  participants: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onClearLiveParticipants: PropTypes.func.isRequired,
  loggedUserDatabaseId: PropTypes.number,
};

const mapStateToProps = ({ usersReducer, loggedUser }) => ({
  users: usersReducer.users,
  participants: usersReducer.participantsPage,
  loggedUserDatabaseId: loggedUser.databaseId,
});

const mapDispatchToProps = (dispatch) => ({
  onClearLiveParticipants: (tableData) => dispatch(clearLiveParticipants(tableData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ActionsBottom));
