import {
  ADD_CONVERSATION,
  ADD_USER,
  ADD_USER_AS_SPEAKER,
  ADD_USER_AS_SPEAKER_FAIL,
  ADD_USER_AS_SPEAKER_SUCCESS,
  ADD_USER_SUCCESS,
  ADD_USER_TO_GROUP,
  ADD_USER_TO_GROUP_SUCCESS,
  BAN_PARTICIPANT,
  BAN_PARTICIPANT_FAIL,
  BAN_PARTICIPANT_SUCCESS,
  CHECK_MESSAGE_AS_READ,
  CHECK_PARTICIPANT_SUCCESS,
  CLEAR_LIVE_PARTICIPANTS,
  CLEAR_LIVE_PARTICIPANTS_SUCCESS,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FILTER_SEARCH_PARTICIPANTS_SUCCESS,
  KICK_PARTICIPANT,
  KICK_PARTICIPANT_FAIL,
  KICK_PARTICIPANT_SUCCESS,
  MUTE_PARTICIPANTS,
  MUTE_PARTICIPANTS_SUCCESS,
  REMOVE_USER_AS_SPEAKER,
  REMOVE_USER_AS_SPEAKER_FAIL,
  REMOVE_USER_AS_SPEAKER_SUCCESS,
  REMOVE_USER_VIDEO,
  REMOVE_USER_VIDEO_FAIL,
  REMOVE_USER_VIDEO_SUCCESS,
  RESET_OR_LEAVE_CURRENT_PARTICIPANT_STATUS,
  SELECT_PARTICIPANTS_SUCCESS,
  SET_PARTICIPANT_LIVE_AUDIO,
  SET_PARTICIPANT_LIVE_AUDIO_SUCCESS,
  SET_PARTICIPANT_LIVE_VIDEO,
  SET_PARTICIPANT_LIVE_VIDEO_SUCCESS,
  SET_USERS_FROM_STORAGE,
  TOGGLE_PARTICIPANT_CHAT,
  UNSELECT_PARTICIPANTS_SUCCESS,
  USER_CONN_STATUS_HAS_CHANGED,
  USER_HAS_JOINED,
  USER_HAS_LEFT,
  USER_LOCAL_TRACK_MUTE_CHANGE,
  USER_RAISE_HAND_CHANGE,
  USER_TRACK_MUTE_CHANGED,
  USER_TRACK_WAS_ADDED,
  USER_TRACK_WAS_REMOVED,
} from '@constants/ActionTypes';

/**
 * @description Fetch users list
 * @param {Number} slotId
 * @returns {Object}
 */
export const onFetchUsers = (slotId) => ({
  type: FETCH_USERS_REQUEST,
  slotId,
});

/**
 * @description Fetch users list success
 * @param {Object} payload
 * @returns {Object}
 */
export const onFetchUsersSuccess = (payload) => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});

export const filterAndSearchParticipantsSuccess = (filter, searchValue) => ({
  type: FILTER_SEARCH_PARTICIPANTS_SUCCESS,
  filter,
  searchValue,
});

export const setParticipantLiveVideo = (record) => ({
  type: SET_PARTICIPANT_LIVE_VIDEO,
  record,
});

export const setParticipantLiveVideoSuccess = (record, liveVideoValue) => ({
  type: SET_PARTICIPANT_LIVE_VIDEO_SUCCESS,
  record,
  liveVideoValue,
});

export const setParticipantLiveAudio = (record) => ({
  type: SET_PARTICIPANT_LIVE_AUDIO,
  record,
});

export const setParticipantLiveAudioSuccess = (record, liveAudioValue) => ({
  type: SET_PARTICIPANT_LIVE_AUDIO_SUCCESS,
  record,
  liveAudioValue,
});

export const selectParticipantsSuccess = (selectionType) => ({
  type: SELECT_PARTICIPANTS_SUCCESS,
  selectionType,
});

export const unselectParticipantsSuccess = () => ({
  type: UNSELECT_PARTICIPANTS_SUCCESS,
});

export const checkParticipantSuccess = (recordId) => ({
  type: CHECK_PARTICIPANT_SUCCESS,
  recordId,
});

export const muteParticipants = (data) => ({
  type: MUTE_PARTICIPANTS,
  data,
});

export const muteParticipantsSuccess = () => ({
  type: MUTE_PARTICIPANTS_SUCCESS,
});

export const kickParticipant = () => ({
  type: KICK_PARTICIPANT,
});

export const kickParticipantSuccess = () => ({
  type: KICK_PARTICIPANT_SUCCESS,
});

export const kickParticipantFail = () => ({
  type: KICK_PARTICIPANT_FAIL,
});

export const banParticipant = () => ({
  type: BAN_PARTICIPANT,
});

export const banParticipantSuccess = () => ({
  type: BAN_PARTICIPANT_SUCCESS,
});

export const banParticipantFail = () => ({
  type: BAN_PARTICIPANT_FAIL,
});

export const clearLiveParticipants = (data) => ({
  type: CLEAR_LIVE_PARTICIPANTS,
  data,
});

export const clearLiveParticipantsSuccess = (result) => ({
  type: CLEAR_LIVE_PARTICIPANTS_SUCCESS,
  result,
});

export const resetOrLeaveCurrentParticipantStatus = (user, status) => ({
  type: RESET_OR_LEAVE_CURRENT_PARTICIPANT_STATUS,
  user,
  status,
});

export const addUser = (userData, slotId) => ({
  type: ADD_USER,
  userData,
  slotId,
});

export const addUserSuccess = () => ({
  type: ADD_USER_SUCCESS,
});

export const addUserToGroup = (userDataFromResponse, slotId) => ({
  type: ADD_USER_TO_GROUP,
  userDataFromResponse,
  slotId,
});

export const addUserToGroupSuccess = () => ({
  type: ADD_USER_TO_GROUP_SUCCESS,
});

export const addMessageToConversation = (message) => ({
  type: ADD_CONVERSATION,
  message,
});

export const checkMessageAsRead = (conversation) => ({
  type: CHECK_MESSAGE_AS_READ,
  conversation,
});

export const userHasJoined = (user) => ({
  type: USER_HAS_JOINED,
  user,
});

export const userHasLeft = (userId) => ({
  type: USER_HAS_LEFT,
  userId,
});

export const userRaiseHandChange = (user, raiseHandValue) => ({
  type: USER_RAISE_HAND_CHANGE,
  user,
  raiseHandValue,
});

export const userLocalTrackMuteChange = (user, localTrackMuteValue, trackType) => ({
  type: USER_LOCAL_TRACK_MUTE_CHANGE,
  user,
  localTrackMuteValue,
  trackType,
});

export const setUsersFromStorage = (users) => ({
  type: SET_USERS_FROM_STORAGE,
  users,
});

export const addUserAsSpeaker = (slotId, record, withVideo = false) => ({
  type: ADD_USER_AS_SPEAKER,
  slotId,
  record,
  withVideo,
});

export const addUserAsSpeakerSuccess = (record, withVideo) => ({
  type: ADD_USER_AS_SPEAKER_SUCCESS,
  record,
  withVideo,
});

export const addUserAsSpeakerFail = () => ({
  type: ADD_USER_AS_SPEAKER_FAIL,
});

export const removeUserVideo = (slotId, record) => ({
  type: REMOVE_USER_VIDEO,
  slotId,
  record,
});

export const removeUserVideoSuccess = (record) => ({
  type: REMOVE_USER_VIDEO_SUCCESS,
  record,
});

export const removeUserVideoFail = () => ({
  type: REMOVE_USER_VIDEO_FAIL,
});

export const removeUserAsSpeaker = (slotId, record) => ({
  type: REMOVE_USER_AS_SPEAKER,
  slotId,
  record,
});

export const removeUserAsSpeakerSuccess = (record) => ({
  type: REMOVE_USER_AS_SPEAKER_SUCCESS,
  record,
});

export const removeUserAsSpeakerFail = () => ({
  type: REMOVE_USER_AS_SPEAKER_FAIL,
});

export const userTrackWasAdded = (track) => ({
  type: USER_TRACK_WAS_ADDED,
  track,
});

export const userTrackWasRemoved = (track) => ({
  type: USER_TRACK_WAS_REMOVED,
  track,
});

export const userTrackMuteChanged = (track) => ({
  type: USER_TRACK_MUTE_CHANGED,
  track,
});

export const userConnStatusHasChanged = (userConfId, status) => ({
  type: USER_CONN_STATUS_HAS_CHANGED,
  userConfId,
  status,
});

export const toggleParticipantChat = (payload) => ({
  type: TOGGLE_PARTICIPANT_CHAT,
  payload,
});
