import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  connect,
  useSelector,
} from 'react-redux';
import PropTypes from 'prop-types';
import { ReactComponent as PlayIcon } from '@assets/images/actions-icons/play_icon-small.svg';
import { setLoggedUserIsOnFullScreen } from '@actions/loggedUser/LoggedUserActions';
import MicCamActionsButtons from '@routes/UserView/Fullscreen/Mobile/MicCamActionsButtons';
import {
  CompressOutlined,
  ExpandOutlined,
} from '@ant-design/icons';
import {
  DEVICES_TYPES,
  JITSI_TRACK_EVENTS,
} from '@constants/Settings';
import {
  isSafariBrowser,
  toggleFullscreenHelper,
} from '../../CustomHooks/HelperFuncs';
import JitsiConference from '@jitsi/JitsiConference';
import { joinJitsiConference } from '@actions/jitsiConference/JitsiConferenceActions';
import { useFirstRender } from '../../CustomHooks/useFirstRender';

const UserViewVideoTracks = (props) => {
  const {
    deviceType,
    isOnFullScreen,
    onSetFullscreen,
    onConferenceJoin,
    browserName,
    localTracks,
    hasPresenterVideoTrack,
    presenterId,
    intl,
  } = props;

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [presenterVideoTrackMuted, setPresenterVideoTrackMuted] = useState(false);
  const [visitorSpeakerVideoTrackMuted, setVisitorSpeakerVideoTrackMuted] = useState(true);

  const hasSpeakerVideoTrack = useSelector((state) => state.conferenceReducer.hasSpeakerVideoTrack);
  const visitorsSpeakers = useSelector((state) => state.conferenceReducer.visitorsSpeakers);
  const hasMutedLocalVideoTrack = useSelector((state) => state.loggedUser.hasMutedLocalVideoTrack);

  const isFirstRender = useFirstRender();

  const presenterRemoteVideoTrack = React.useRef(null);

  const presenterVideoRef = useRef();
  const myVideoRef = useRef();
  const speakerVideoRef = useRef();

  useEffect(() => {
    presenterVideoRef.current.onplaying = () => setIsVideoPlaying(true);

    return () => {
      if (presenterRemoteVideoTrack.current) {
        const oldTrack = presenterRemoteVideoTrack.current;
        oldTrack.detach(presenterVideoRef.current);
      }
    };
  }, []);

  /**
   * Effect When we have a presenter video track we attack the media stream to the
   * video element
   */
  useEffect(() => {
    if (hasPresenterVideoTrack) {
      const conferencePresenter = JitsiConference.getConferencePresenterParticipant(presenterId);
      if (conferencePresenter) {
        const remoteTracks = conferencePresenter.getTracksByMediaType('video');

        if (remoteTracks.length > 0) {
          presenterRemoteVideoTrack.current = remoteTracks[0];
          remoteTracks[0].addEventListener(JITSI_TRACK_EVENTS.trackMuteChanged, (track) => setPresenterVideoTrackMuted(track.isMuted()));
          remoteTracks[0].attach(presenterVideoRef.current);

          if (presenterVideoRef.current) {
            presenterVideoRef.current.play()
              .catch(e => {
                console.log('Video Play error: ', e);
              });
          }
        }

        JitsiConference.setReceiverConstraints([conferencePresenter.getId()]);
      }
    }
  }, [hasPresenterVideoTrack]);

  /**
   * Effect we have made our local tracks to attach
   * the video local track to a video element
   */
  useEffect(() => {
    if (localTracks.length > 0) {
      const localVideoTrack = localTracks.find((tr) => tr.getType() === 'video');

      if (localVideoTrack) {
        localVideoTrack.attach(myVideoRef.current);
      }
    }
  }, [localTracks]);

  /**
   * Effect in which we react on the changes if
   * we have a visitor speaker OR/AND when that visitor has video track
   *
   * So basically the logic what should we display on the bottom left corner
   * (where the visitor speaker preview sits)
   */
  useEffect(() => {
    let speakerVideoTrack = null;

    if (visitorsSpeakers.length > 0 || hasSpeakerVideoTrack.value) {
      const visitorSpeaker = window.audi.room.getParticipants().find((p) => p.getId() === visitorsSpeakers[0]);

      if (visitorSpeaker) {
        const videoTrack = visitorSpeaker.getTracks().find((tr) => tr.getType() === 'video');

        if (videoTrack && hasSpeakerVideoTrack.value) {
          speakerVideoTrack = videoTrack;

          speakerVideoTrack.addEventListener(JITSI_TRACK_EVENTS.trackMuteChanged, (track) => setVisitorSpeakerVideoTrackMuted(track.isMuted()));
          speakerVideoTrack.attach(speakerVideoRef.current);
          setVisitorSpeakerVideoTrackMuted(speakerVideoTrack.isMuted());

          JitsiConference.selectParticipants([presenterId, visitorSpeaker.getId()]);
        } else {
          setVisitorSpeakerVideoTrackMuted(true);
        }
      }
    } else if ((visitorsSpeakers.length === 0 || !hasSpeakerVideoTrack.value) && !isFirstRender) {
      JitsiConference.selectParticipants([presenterId]);
      speakerVideoRef.current.srcObject = null;
      setVisitorSpeakerVideoTrackMuted(true);
    }

    return () => {
      if (speakerVideoTrack) {
        speakerVideoTrack.detach(speakerVideoRef.current);
      }
    };
  }, [hasSpeakerVideoTrack, visitorsSpeakers.length]);

  const playVideo = () => {
    if (isSafariBrowser(browserName)) {
      JitsiConference
        .leave()
        .then(() => {
          onConferenceJoin();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      presenterVideoRef.current.play()
        .catch((e) => {
          JitsiConference
            .leave()
            .then(() => {
              onConferenceJoin();
            })
            .catch((error) => {
              console.log(error);
            });
        });
    }
  };

  /**
   * Function to toggle the fullscreen
   */
  const toggleFullscreen = (isItOnFullscreen) => toggleFullscreenHelper(isItOnFullscreen, onSetFullscreen, true, browserName);

  return (
    <>
      <div className={
        `userViewVideoTracks userViewVideoTracks--${deviceType}
         userViewVideoTracks userViewVideoTracks--${deviceType}${isOnFullScreen ? '--fullscreen' : ''}`
      }
      >
        {
          presenterVideoRef.current && !isVideoPlaying
            ? (<PlayIcon className="userViewVideoTracks__videoPlayIcon" onClick={() => playVideo()} />)
            : null
        }
        <video
          ref={presenterVideoRef}
          width="100%"
          height="100%"
          playsInline
          autoPlay={0}
          muted="muted"
          className={
            `userViewVideoTracks__mainVideoTag
            userViewVideoTracks__mainVideoTag--${deviceType}
            userViewVideoTracks__mainVideoTag--${deviceType}--${browserName}
            userViewVideoTracks__mainVideoTag--${deviceType}${isOnFullScreen ? '--fullscreen' : ''}`
          }
          style={{ display: `${!presenterVideoTrackMuted || (visitorsSpeakers.length > 0 && visitorSpeakerVideoTrackMuted) ? 'block' : 'none'}`, background: 'black' }}
        />
        <div
          className={`userViewVideoTracks__presenterVideoMuted--${(presenterVideoTrackMuted && visitorsSpeakers.length <= 0) || (visitorsSpeakers.length > 0 && visitorSpeakerVideoTrackMuted) ? 'dflex' : 'dnone'}`}
          style={{ background: 'black' }}
        >
          <h3 style={{ color: 'white' }}>{ intl.formatMessage({ id: 'userView.presenterCameraMuted.text' })}</h3>
        </div>
        <video
          className={
            `userViewVideoTracks__mainVideoTag
            userViewVideoTracks__mainVideoTag--${deviceType}
            userViewVideoTracks__mainVideoTag--${deviceType}--${browserName}
            userViewVideoTracks__mainVideoTag--${deviceType}${isOnFullScreen ? '--fullscreen' : ''}`
          }
          width="100%"
          height="100%"
          ref={speakerVideoRef}
          autoPlay
          playsInline
          style={{ display: `${(visitorsSpeakers.length <= 0 || visitorSpeakerVideoTrackMuted) ? 'none' : 'block'}`, background: 'black' }}
        />
        {
          isOnFullScreen
            ? (
              <CompressOutlined
                onClick={() => toggleFullscreen(!isOnFullScreen)}
                className={`userViewVideoTracks__videoFullScreenIcon userViewVideoTracks__videoFullScreenIcon--${isOnFullScreen ? 'fullscreen' : ''}`}
              />
            )
            : (
              <ExpandOutlined
                className="userViewVideoTracks__videoFullScreenIcon"
                onClick={() => toggleFullscreen(!isOnFullScreen)}
              />
            )
        }
        {
          (isOnFullScreen || deviceType === DEVICES_TYPES.desktop)
            ? <MicCamActionsButtons deviceType={deviceType} />
            : null
        }
        <video
          className={`userViewVideoTracks__myVideoTag--${deviceType}${hasMutedLocalVideoTrack ? '--dnone' : ''}`}
          ref={myVideoRef}
          autoPlay
          playsInline
        />
      </div>
    </>
  );
};

UserViewVideoTracks.propTypes = {
  isOnFullScreen: PropTypes.bool.isRequired,
  onSetFullscreen: PropTypes.func.isRequired,
  onConferenceJoin: PropTypes.func.isRequired,
  browserName: PropTypes.string,
  localTracks: PropTypes.arrayOf(PropTypes.object),
  presenterId: PropTypes.string,
  hasPresenterVideoTrack: PropTypes.bool.isRequired,
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onConferenceJoin: () => dispatch(joinJitsiConference()),
  onSetFullscreen: (isOnFullScreen) => dispatch(setLoggedUserIsOnFullScreen(isOnFullScreen)),
});

export default connect(null, mapDispatchToProps)(React.memo(UserViewVideoTracks));
