import React from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Tooltip,
} from 'antd';
import { ReactComponent as MicrophoneEnabled } from '@assets/images/actions-icons/microphone_icon_black.svg';
import { ReactComponent as MicrophoneMuted } from '@assets/images/actions-icons/microphone_icon_black-muted.svg';
import { CHAT_STATUS } from '@constants/Settings';
import ParticipantConnStatusIndicator
  from "@components/audi/ParticipantConnStatusIndicator/ParticipantConnStatusIndicator";
import { chatObjectToArray } from '../../../../CustomHooks/HelperFuncs';

const UserCell = ({
  chat,
  selectedSectionId,
  onSelectUser,
  isTextAreaFocused,
  selectedUser,
  props,
}) => {
  const { intl } = props;
  let countUnreadMessages = 0;

  /**
   * @description Counts unread messages for every user
   * @param {Array} conversation
   * @returns {Number}
   */
  const checkForUnreadMessages = (conversation) => {
    if (conversation) {
      chatObjectToArray(conversation).forEach((message) => {
        if (!message.read) {
          countUnreadMessages += 1;
        }
        if (selectedUser && chat.id === selectedUser.user_id && isTextAreaFocused) {
          countUnreadMessages = 0;
        }
      });
    }

    return countUnreadMessages;
  };

  /**
   * @description Get name initials
   * @param {Object} user
   * @return {String}
   */
  const getNameInitials = (user) => {
    const firstNameInitial = user.first_name.charAt(0);
    const lastNameInitial = user.last_name.charAt(0);

    return firstNameInitial + lastNameInitial;
  };

  /**
   * @description Get last message from conversation
   * @param {Array} conversation
   * @returns {String}
   */
  const getLastMessage = (conversation) => {
    if (conversation && conversation.length > 0) {
      const conversationLength = conversation.length;
      const lastMessage = conversation[conversationLength - 1];

      return lastMessage.message;
    }
  };

  /**
   * @description Gets user status
   * @returns {void}
   */
  const getChatStatus = () => {
    const currentStatus = chat.confProps.status;
    let status;
    if ((currentStatus === CHAT_STATUS.online) || (currentStatus === CHAT_STATUS.onlineRaised)) {
      status = 'online';
    }

    return status;
  };

  /**
   * @description Check if audio is on or off
   * @returns {void}
   */
  const getMicrophoneStatus = () => {
    let microphoneStatus;
    if (chat.confProps.microphone) {
      microphoneStatus = (
        <Tooltip
          title={intl.formatMessage({ id: 'chat.users.microphoneOn' })}
        >
          <MicrophoneEnabled className="user-multimedia-icon" />
        </Tooltip>
      );
    } else {
      microphoneStatus = (
        <Tooltip
          title={intl.formatMessage({ id: 'chat.users.microphoneMuted' })}
        >
          <MicrophoneMuted className="user-multimedia-icon" />
        </Tooltip>
      );
    }

    return microphoneStatus;
  };

  const getConnStatus = () => {
    if (chat.confProps.connStatus) {
      return <ParticipantConnStatusIndicator connStatus={chat.confProps.connStatus} participantId={chat.confProps.userConfId} />;
    }

    return null;
  };

  return (
    <div
      className={`gx-chat-user-item ${selectedSectionId === chat.id ? 'active' : ''}`}
      onClick={() => {
        onSelectUser(chat);
      }}
    >
      <div className="gx-chat-user-row">
        <div className="gx-chat-avatar">
          <div className="gx-status-pos">
            <Tooltip
              title={`${`${chat.first_name} ${chat.last_name}`}`}
            >
              <Avatar src={chat.thumb} className="gx-size-40" alt={chat.name}>
                {getNameInitials(chat)}
              </Avatar>
            </Tooltip>
            <span
              className={`gx-status gx-small gx-${getChatStatus()}`}
            />
          </div>
        </div>

        <div className="gx-chat-info">
          <span className="gx-name h5">
            {chat.first_name}
            {' '}
          </span>
          <span className="gx-name h5">{chat.last_name}</span>
          <div className="gx-chat-info-des gx-text-truncate">
            {getLastMessage(chat.confProps.conversation)
              ? `${getLastMessage(chat.confProps.conversation).substring(0, 25)}...`
              : (
                <i>
                  {`${intl.formatMessage({ id: 'chat.users.list.noMessages' })}`}
                </i>
              )}
          </div>
          <div className="gx-last-message-time">{chat.lastMessageTime}</div>
        </div>
        {checkForUnreadMessages(chat.confProps.conversation) > 0 ? (
          <div className="gx-chat-date">
            <div className="gx-bg-primary gx-rounded-circle gx-badge gx-text-white">{countUnreadMessages}</div>
          </div>
        ) : null}

        <div className="multimedia-status">
          {getMicrophoneStatus()}
          {getConnStatus()}
        </div>
      </div>
    </div>
  );
};

UserCell.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]),
  chat: PropTypes.oneOfType([PropTypes.object]).isRequired,
  selectedSectionId: PropTypes.number,
  onSelectUser: PropTypes.func.isRequired,
  props: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isTextAreaFocused: PropTypes.bool,
  selectedUser: PropTypes.oneOfType([PropTypes.object]),
};

export default UserCell;
