import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import JitsiConference from '@jitsi/JitsiConference';
import {
  JITSI_CONFERENCE_EVENTS,
  JITSI_CONNECTION_EVENTS,
} from '@constants/Settings';
import JitsiConnection from '@jitsi/JitsiConnection';
import { injectIntl } from 'react-intl';

const Preview = (props) => {
  const {
    location,
    intl,
  } = props;
  const { room, previewedUserId } = location.query;
  const [isInConference, setIsInConference] = useState(false);
  const [isConnectedToJitsi, setIsConnectedToJitsi] = useState(false);

  const videoRef = useRef();
  const audioRef = useRef();

  /**
   * When the component is loaded we make new jitsi connection.
   *
   * We do a cleanup when the component is destroyed OR the tab/window is closed
   */
  useEffect(() => {
    const timeOut = setTimeout(async () => {
      const jitsiConnection = new JitsiConnection(sessionStorage.getItem('jitsiToken'));

      await jitsiConnection.initConnection();
      jitsiConnection.addListener(JITSI_CONNECTION_EVENTS.connectionEstablished,
        () => setIsConnectedToJitsi(true));
      jitsiConnection.connect();
    }, 1500);

    /**
     * Making a cleanup function which will be called when the window/tab is closed
     * (when closing the window we send a message to the visitor that the preview has ended)
     */
    const cleanup = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', cleanup);

    return () => {
      clearTimeout(timeOut);
      JitsiConnection.disconnect()
        .catch((error) => {
          console.log(error);
        });
    };
  }, []);

  useEffect(() => {
    if (isConnectedToJitsi) {
      const jitsiConference = new JitsiConference(room);

      jitsiConference.initConference();
      jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceJoined, () => setIsInConference(true));
      jitsiConference.addListener(JITSI_CONFERENCE_EVENTS.conferenceTrackAdded, (track) => {
        if (track.ownerEndpointId === previewedUserId) {
          JitsiConference.selectParticipant(previewedUserId);
          const trackType = track.getType();

          if (trackType === 'audio') {
            track.attach(audioRef.current);
          }
          if (trackType === 'video') {
            track.attach(videoRef.current);
          }
        }
      });
      jitsiConference.join();
    }
  }, [isConnectedToJitsi]);

  return (
    <div style={{ background: 'black' }}>
      <video
        autoPlay="1"
        ref={videoRef}
        width="100%"
        height="100%"
        playsInline
        muted
        style={{ display: 'block', position: 'absolute', background: 'black' }}
      />
      <audio
        autoPlay
        ref={audioRef}
      />
    </div>
  );
};

Preview.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = ({ router, common }) => ({
  location: router.location,
  error: common.error,
});

export default connect(mapStateToProps, null)(injectIntl(Preview));
